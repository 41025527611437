import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32Recommended = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="m 15.8207,9.0839637 c 0.0733,-0.1487 0.2853,-0.1487 0.3586,0 l 2.1253,4.3062003 c 0.0291,0.0591 0.0855,0.1 0.1506,0.1095 l 4.7522,0.6905 c 0.1641,0.0238 0.2296,0.2254 0.1109,0.3411 l -3.4388,3.352 c -0.0471,0.0459 -0.0686,0.1121 -0.0575,0.177 l 0.8118,4.733 c 0.028,0.1634 -0.1435,0.288 -0.2902,0.2109 l -4.2505,-2.2347 c -0.0583,-0.0306 -0.1279,-0.0306 -0.1862,0 l -4.2505,2.2347 c -0.1467,0.0771 -0.3182,-0.0475 -0.2902,-0.2109 l 0.8118,-4.733 c 0.0111,-0.0649 -0.0104,-0.1311 -0.0575,-0.177 l -3.4388,-3.352 c -0.1187,-0.1157 -0.0532,-0.3173 0.1109,-0.3411 l 4.7522,-0.6905 c 0.0651,-0.0095 0.1215,-0.0504 0.1506,-0.1095 z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32Recommended;
