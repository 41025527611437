import { Gql } from "@api/Api";
import { ReduxAction } from "@redux/actions/ReduxAction";
import { BackendEnvironmentsActionTypes } from "@redux/actions/backendEnvironmentsActions";

export type BackendEnvironmentsReducerType = Gql.BackendEnvironment[];

const initialState: BackendEnvironmentsReducerType = [];

export default function backendEnvironmentsReducer(state: BackendEnvironmentsReducerType = initialState, action: ReduxAction): BackendEnvironmentsReducerType {
    switch (action.type) {
        case BackendEnvironmentsActionTypes.UPDATE:
            return action.payload.backendEnvironments;
        default:
            return state;
    }
}
