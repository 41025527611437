const HeadElementNextJsApp = {};

const HeadElementReactApp = {
    Cherrisk:
        '<link rel="preconnect" href="https://use.typekit.net/" crossorigin="anonymous" /><link rel="preload" href="https://use.typekit.net/exa5rij.css" as="style" /><link rel="stylesheet" href="https://use.typekit.net/exa5rij.css" />',
    Uniqa: '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&amp;display=swap" rel="stylesheet">',
};

export const HeadElement = {
    NextJsApp: HeadElementNextJsApp,
    ReactApp: HeadElementReactApp,
};
