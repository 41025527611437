import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverHistory = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="mask0_597_2496"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={2}
                y={18}
                width={31}
                height={14}
            >
                <path d="M2 32V20H11V18H33V32H2Z" fill="white" />
            </mask>
            <g mask="url(#mask0_597_2496)">
                <path
                    d="M16 2.5C19.7279 2.5 23.1029 4.01104 25.5459 6.45406C27.989 8.89708 29.5 12.2721 29.5 16C29.5 19.7279 27.989 23.1029 25.5459 25.5459C23.1029 27.989 19.7279 29.5 16 29.5C12.2721 29.5 8.89708 27.989 6.45406 25.5459C4.01104 23.1029 2.5 19.7279 2.5 16C2.5 12.2721 4.01104 8.89708 6.45406 6.45406C8.89708 4.01104 12.2721 2.5 16 2.5ZM16 6.5C13.3766 6.5 11.0016 7.56332 9.28249 9.28249C7.56332 11.0016 6.5 13.3766 6.5 16C6.5 18.6234 7.56332 20.9984 9.28249 22.7175C11.0016 24.4367 13.3766 25.5 16 25.5C18.6234 25.5 20.9984 24.4367 22.7175 22.7175C24.4367 20.9984 25.5 18.6234 25.5 16C25.5 13.3766 24.4367 11.0016 22.7175 9.28249C20.9984 7.56332 18.6234 6.5 16 6.5Z"
                    stroke="currentColor"
                />
            </g>
            <mask
                id="mask1_597_2496"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={32}
                height={18}
            >
                <path d="M32 0H0V18H32V0Z" fill="white" />
            </mask>
            <g mask="url(#mask1_597_2496)">
                <path
                    d="M16 2.5C19.7279 2.5 23.1029 4.01104 25.5459 6.45406C27.989 8.89708 29.5 12.2721 29.5 16C29.5 19.5531 28.1274 22.7857 25.8834 25.1962C23.7389 27.4999 20.7987 29.0531 17.5002 29.4176L17.5006 25.3821C19.7064 25.0319 21.6595 23.9229 23.0835 22.3305C24.5863 20.6501 25.5 18.4318 25.5 16C25.5 13.3766 24.4367 11.0016 22.7175 9.28249C20.9984 7.56332 18.6234 6.5 16 6.5C14.0789 6.5 12.291 7.07039 10.7962 8.05078C9.16498 9.12069 7.88311 10.6793 7.1584 12.5184L9.10942 14.6637L2 15.5L1.5 7.58661L4.26014 9.32983C6.58291 5.25081 10.9702 2.5 16 2.5Z"
                    stroke="currentColor"
                />
            </g>
            <mask
                id="mask2_597_2496"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={2}
                y={18}
                width={31}
                height={14}
            >
                <path d="M2 32V20H11V18H33V32H2Z" fill="white" />
            </mask>
            <g mask="url(#mask2_597_2496)">
                <path
                    d="M16 2.5C19.7279 2.5 23.1029 4.01104 25.5459 6.45406C27.989 8.89708 29.5 12.2721 29.5 16C29.5 19.7279 27.989 23.1029 25.5459 25.5459C23.1029 27.989 19.7279 29.5 16 29.5C12.2721 29.5 8.89708 27.989 6.45406 25.5459C4.01104 23.1029 2.5 19.7279 2.5 16C2.5 12.2721 4.01104 8.89708 6.45406 6.45406C8.89708 4.01104 12.2721 2.5 16 2.5ZM16 6.5C13.3766 6.5 11.0016 7.56332 9.28249 9.28249C7.56332 11.0016 6.5 13.3766 6.5 16C6.5 18.6234 7.56332 20.9984 9.28249 22.7175C11.0016 24.4367 13.3766 25.5 16 25.5C18.6234 25.5 20.9984 24.4367 22.7175 22.7175C24.4367 20.9984 25.5 18.6234 25.5 16C25.5 13.3766 24.4367 11.0016 22.7175 9.28249C20.9984 7.56332 18.6234 6.5 16 6.5Z"
                    stroke="currentColor"
                />
            </g>
            <mask
                id="mask3_597_2496"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={32}
                height={18}
            >
                <path d="M32 0H0V18H32V0Z" fill="white" />
            </mask>
            <g mask="url(#mask3_597_2496)">
                <path
                    d="M16 2.5C19.7279 2.5 23.1029 4.01104 25.5459 6.45406C27.989 8.89708 29.5 12.2721 29.5 16C29.5 19.5531 28.1274 22.7857 25.8834 25.1962C23.7389 27.4999 20.7987 29.0531 17.5002 29.4176L17.5006 25.3821C19.7064 25.0319 21.6595 23.9229 23.0835 22.3305C24.5863 20.6501 25.5 18.4318 25.5 16C25.5 13.3766 24.4367 11.0016 22.7175 9.28249C20.9984 7.56332 18.6234 6.5 16 6.5C14.0789 6.5 12.291 7.07039 10.7962 8.05078C9.16498 9.12069 7.88311 10.6793 7.1584 12.5184L9.10942 14.6637L2 15.5L1.5 7.58661L4.26014 9.32983C6.58291 5.25081 10.9702 2.5 16 2.5Z"
                    stroke="currentColor"
                />
            </g>
            <path d="M14.5 11.5V17.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.5 17.5H14.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </SVGBase>
    );
};

export default SvgIcon32CoverHistory;
