import { AppBar } from "@components/AppBar";
import { Page } from "@components/Page";
import { Text } from "@monster/chr-ui";
import { LoctoolMessage, LoctoolHTMLMessage } from "@monster/loctool";
import { Box, Cookie } from "@monster/shared";
import { Path } from "@utils/Path";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";

export const AccidentClaimPage = () => {
    const theme = useTheme();
    const history = useHistory();

    useEffect(() => {
        Cookie.claimSessionId.remove();
    }, []);

    return (
        <Page appBar={<AppBar activeIndex={0} onLogoClick={() => history.replace(Path.login)} />}>
            <Box $style={{ maxWidth: 504, margin: "0 auto", width: "100%" }}>
                <Text as="h1" $variant="h2Desktop" $style={{ margin: 0, padding: "40px 16px 16px" }} $styleLarge={{ marginBottom: -16, paddingTop: 44 }}>
                    <LoctoolMessage id="pages.accident.title" />
                </Text>

                <Text as="p" $variant="h3Desktop" $style={{ margin: 0, padding: "40px 16px 16px", fontWeight: 700 }}>
                    <LoctoolMessage id="pages.accident.subtitle" />
                </Text>

                <Box $style={{ backgroundColor: theme.color.white, marginBottom: 80, borderRadius: 16, padding: "40px 24px 32px", textAlign: "center" }}>
                    <Text as="p" $variant="h3Desktop" $style={{ margin: 0, fontWeight: 700, a: { color: theme.color.blueD, textDecoration: "none" } }}>
                        <LoctoolHTMLMessage id="pages.accident.description" />
                    </Text>
                </Box>

                <Box $style={{ marginTop: -80 + 16 }}>
                    <Box $style={{ backgroundColor: theme.color.highlight300, borderRadius: 16, padding: 16 }}>
                        <LoctoolMessage id="pages.accident.todo" />
                        <Box
                            as={"ul"}
                            $style={{
                                margin: 0,
                                paddingLeft: 24,
                                listStyleType: "none",
                                li: {
                                    position: "relative",
                                    "&::before": {
                                        content: '""',
                                        position: "absolute",
                                        top: 10,
                                        left: -14,
                                        width: 4,
                                        height: 4,
                                        borderRadius: "50%",
                                        backgroundColor: "currentColor",
                                    },
                                },
                            }}
                        >
                            <LoctoolHTMLMessage id="pages.accident.todoList" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Page>
    );
};
