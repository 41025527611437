import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravelFamily = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="mask0_1129_3676"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={19}
                y={21}
                width={13}
                height={15}
            >
                <rect x={19.5} y={21.5} width={12} height={14} rx={6} stroke="currentColor" />
            </mask>
            <g mask="url(#mask0_1129_3676)">
                <rect width={13} height={6} transform="matrix(1 0 0 -1 19 27)" fill="currentColor" />
            </g>
            <rect x={21.6177} y={12.5} width={7.88232} height={9} rx={3.5} stroke="currentColor" />
            <mask
                id="mask1_1129_3676"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={5}
                width={28}
                height={22}
            >
                <mask
                    id="mask2_1129_3676"
                    style={{
                        maskType: "alpha",
                    }}
                    maskUnits="userSpaceOnUse"
                    x={0}
                    y={18}
                    width={18}
                    height={21}
                >
                    <rect x={0.5} y={18.5} width={17} height={20} rx={8.5} stroke="currentColor" />
                </mask>
                <g mask="url(#mask2_1129_3676)">
                    <rect width={14} height={9} transform="matrix(1 0 0 -1 0 27)" fill="currentColor" />
                </g>
                <mask
                    id="mask3_1129_3676"
                    style={{
                        maskType: "alpha",
                    }}
                    maskUnits="userSpaceOnUse"
                    x={3}
                    y={5}
                    width={12}
                    height={14}
                >
                    <rect x={3.5} y={5.5} width={11} height={13} rx={5.5} stroke="currentColor" />
                </mask>
                <g mask="url(#mask3_1129_3676)">
                    <rect x={2} y={5} width={12} height={14} fill="currentColor" />
                </g>
                <mask
                    id="mask4_1129_3676"
                    style={{
                        maskType: "alpha",
                    }}
                    maskUnits="userSpaceOnUse"
                    x={10}
                    y={18}
                    width={18}
                    height={21}
                >
                    <rect x={10.5} y={18.5} width={17} height={20} rx={8.5} stroke="currentColor" />
                </mask>
                <g mask="url(#mask4_1129_3676)">
                    <rect width={18} height={9} transform="matrix(1 0 0 -1 10 27)" fill="currentColor" />
                </g>
                <rect x={13.5} y={5.5} width={11} height={13} rx={5.5} stroke="currentColor" />
            </mask>
            <g mask="url(#mask1_1129_3676)">
                <path d="M20 27H0L-1 16L3.5 3.5L25.5 3L27 10.5L24.5 12.5L22.5 13.5L21.5 16L22 19.5L25 21.5L20 27Z" fill="currentColor" />
            </g>
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravelFamily;
