import React from "react";
import {
    SVGSharedIcon32Bicycle,
    SVGSharedIcon32CoverBag,
    SVGSharedIcon32CoverBrokenpipe,
    SVGSharedIcon32CoverCrime,
    SVGSharedIcon32CoverEmergency,
    SVGSharedIcon32CoverEtc,
    SVGSharedIcon32CoverFire,
    SVGSharedIcon32CoverGlass,
    SVGSharedIcon32CoverHistory,
    SVGSharedIcon32CoverLimitedTime,
    SVGSharedIcon32CoverPhysical,
    SVGSharedIcon32CoverTravelCancellation,
} from "@monster/shared/dist/svg_assets";
import { ClaimCauseType, DeHomePackagesClaimCauseType, HomeClaimCauseType, TravelClaimCauseType } from "@utils/ClaimCauseHelpers";

type Props = {
    claimCauseType: ClaimCauseType | null;
};

export const ClaimCauseIcon = ({ claimCauseType }: Props) => {
    switch (claimCauseType) {
        case TravelClaimCauseType.damaged_luggage:
        case TravelClaimCauseType.luggage_theft:
            return <SVGSharedIcon32CoverBag />;

        case TravelClaimCauseType.illness:
            return <SVGSharedIcon32CoverEmergency />;

        case TravelClaimCauseType.flight_delay:
            return <SVGSharedIcon32CoverLimitedTime />;

        case TravelClaimCauseType.cancellation:
            return <SVGSharedIcon32CoverTravelCancellation />;

        case TravelClaimCauseType.luggage_delay:
            return <SVGSharedIcon32CoverHistory />;

        case DeHomePackagesClaimCauseType.modularHome_fireAndSimilarDamages:
        case HomeClaimCauseType.home_physicalEvents:
            return <SVGSharedIcon32CoverFire />;

        case DeHomePackagesClaimCauseType.modularHome_physicalNatureEvents:
        case HomeClaimCauseType.home_natureEvents:
        case DeHomePackagesClaimCauseType.modularHome_elementaryDamage:
            return <SVGSharedIcon32CoverPhysical />;

        case DeHomePackagesClaimCauseType.modularHome_bicycleAndEbikeTheft:
            return <SVGSharedIcon32Bicycle />;

        case HomeClaimCauseType.home_tapWater:
        case DeHomePackagesClaimCauseType.modularHome_tapWater:
            return <SVGSharedIcon32CoverBrokenpipe />;

        case DeHomePackagesClaimCauseType.modularHome_glassBreakage:
        case HomeClaimCauseType.home_glassBreakage:
            return <SVGSharedIcon32CoverGlass />;

        case HomeClaimCauseType.home_propertyCrime:
        case DeHomePackagesClaimCauseType.modularHome_propertyCrime:
            return <SVGSharedIcon32CoverCrime />;

        case TravelClaimCauseType.other:
        case HomeClaimCauseType.home_otherDamage:
        case DeHomePackagesClaimCauseType.modularHome_otherDamage:
            return <SVGSharedIcon32CoverEtc />;
        default:
            console.warn("unhandled claimCauseType", { claimCauseType });
            return <SVGSharedIcon32CoverEtc />;
    }
};
