import { gql } from "@apollo/client";
import { OpeningHoursFragment } from "./fragments/OpeningHoursFragment";

export const CmsQueries = Object.freeze({
    openingHours: gql`
        query openingHours($countryCode: CountryCode!) {
            chat: openingHours(countryCode: $countryCode, service: chat) {
                ...OpeningHours
            }
            isChatAvailable: isServiceOpen(countryCode: $countryCode, service: chat)

            tel: openingHours(countryCode: $countryCode, service: tel) {
                ...OpeningHours
            }
            isTelAvailable: isServiceOpen(countryCode: $countryCode, service: tel)
        }
        ${OpeningHoursFragment}
    `,
});
