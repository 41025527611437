import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgLogoPayPal = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={66} height={46} viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.4419 12H35.266C37.188 12 39.4675 12.0625 40.9925 13.4073C42.0111 14.3057 42.5457 15.7371 42.4221 17.2737C42.0037 22.4845 38.8868 25.4031 34.7072 25.4031H31.3415C30.7688 25.4031 30.3891 25.7826 30.2275 26.8107L29.2883 32.7851C29.2274 33.1727 29.0594 33.4011 28.7527 33.4288H24.5489C24.0825 33.4288 23.9167 33.0719 24.0386 32.2991L27.0658 13.1337C27.187 12.3656 27.6072 12 28.4419 12Z"
                fill="#1B3D92"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.8306 18H39.6479C43.3077 18 44.6858 19.8526 44.4731 22.5791C44.1222 27.0735 41.4048 29.5587 37.8005 29.5587H35.9806C35.4867 29.5587 35.1543 29.8853 35.0197 30.7728L34.2392 35.9251C34.1885 36.2598 34.0125 36.4563 33.7481 36.4803H29.4734C29.0712 36.4803 28.9281 36.1725 29.0333 35.506L31.6438 18.9778C31.7483 18.3153 32.1106 18 32.8306 18Z"
                fill="#00A2D3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 26.5204L31.1916 18.9777C31.2963 18.3154 31.6587 18.0001 32.3787 18.0001H39.196C40.3245 18.0001 41.2362 18.1762 41.9507 18.5007C41.2657 23.1383 38.2662 25.715 34.3385 25.715H30.9727C30.5298 25.715 30.1988 25.9415 30 26.5204Z"
                fill="#1B2E7F"
            />
        </SVGBase>
    );
};

export default SvgLogoPayPal;
