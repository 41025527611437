/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendValidationEmail
// ====================================================

export interface sendValidationEmail_sendValidationEmail {
    __typename: "EmailValidationResult";
    sessionId: string;
    maskedEmail: string;
}

export interface sendValidationEmail {
    /**
     * create a session and send a validation email to the related email address.
     */
    sendValidationEmail: sendValidationEmail_sendValidationEmail;
}

export interface sendValidationEmailVariables {
    insuranceId: string;
    dateOfBirth: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendValidationEmailByClaimId
// ====================================================

export interface sendValidationEmailByClaimId_sendValidateEmailByClaimId {
    __typename: "EmailValidationResult";
    sessionId: string;
    maskedEmail: string;
}

export interface sendValidationEmailByClaimId {
    /**
     * create a session and send a validation email to the related email address.
     */
    sendValidateEmailByClaimId: sendValidationEmailByClaimId_sendValidateEmailByClaimId;
}

export interface sendValidationEmailByClaimIdVariables {
    claimId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: validateEmail
// ====================================================

export interface validateEmail_validateEmail {
    __typename: "VoidType";
    code: number;
}

export interface validateEmail {
    /**
     * submit the verification code received by email to confirm ownership of that email
     */
    validateEmail: validateEmail_validateEmail;
}

export interface validateEmailVariables {
    code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createClaim
// ====================================================

export interface createClaim_createClaim {
    __typename: "Claim";
    id: string | null;
}

export interface createClaim {
    /**
     * Create a new claim for German Modular Travel
     */
    createClaim: createClaim_createClaim;
}

export interface createClaimVariables {
    insuranceId: string;
    claimCauseId: any;
    dateOfIncident: any;
    description: string;
    claimAmount: any;
    iban: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClaim
// ====================================================

export interface updateClaim_updateClaim {
    __typename: "Claim";
    id: string | null;
}

export interface updateClaim {
    /**
     * Update claim. Update data for the created claim.
     */
    updateClaim: updateClaim_updateClaim;
}

export interface updateClaimVariables {
    claimId: string;
    dateOfIncident: any;
    description: string;
    claimAmount: any;
    iban: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: submitClaim
// ====================================================

export interface submitClaim_submitClaim {
    __typename: "Claim";
    id: string | null;
}

export interface submitClaim {
    /**
     * Submit claim. Recent claim task must be open and 'waiting for client' state.
     */
    submitClaim: submitClaim_submitClaim;
}

export interface submitClaimVariables {
    claimId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: submitClaimTask
// ====================================================

export interface submitClaimTask_submitClaimTask {
    __typename: "VoidType";
    code: number;
}

export interface submitClaimTask {
    /**
     * Submit claim task.
     */
    submitClaimTask: submitClaimTask_submitClaimTask;
}

export interface submitClaimTaskVariables {
    claimTaskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadAnswerForClaimTaskItem
// ====================================================

export interface uploadAnswerForClaimTaskItem_uploadAnswerForClaimTaskItem {
    __typename: "VoidType";
    code: number;
}

export interface uploadAnswerForClaimTaskItem {
    /**
     * Upload text answer for a claim task item
     */
    uploadAnswerForClaimTaskItem: uploadAnswerForClaimTaskItem_uploadAnswerForClaimTaskItem;
}

export interface uploadAnswerForClaimTaskItemVariables {
    claimTaskItemId: string;
    answer: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteUploadedPhoto
// ====================================================

export interface deleteUploadedPhoto_deleteUploadedPhoto {
    __typename: "VoidType";
    code: number;
}

export interface deleteUploadedPhoto {
    /**
     * Delete uploaded photo.
     */
    deleteUploadedPhoto: deleteUploadedPhoto_deleteUploadedPhoto;
}

export interface deleteUploadedPhotoVariables {
    claimTaskItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateActiveClaim
// ====================================================

export interface updateActiveClaim_updateActiveClaim {
    __typename: "VoidType";
    code: number;
}

export interface updateActiveClaim {
    /**
     * Set different claimId as active claim for the bemo session
     */
    updateActiveClaim: updateActiveClaim_updateActiveClaim;
}

export interface updateActiveClaimVariables {
    claimId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppData
// ====================================================

export interface getAppData_backendEnvironments {
    __typename: "Env";
    /**
     * Name of environment variable
     */
    name: string;
    /**
     * Value (serialized to string)
     */
    value: string;
}

export interface getAppData_effectiveLegalDocuments {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getAppData {
    /**
     * Get environment variables
     *
     * @auth None
     */
    backendEnvironments: getAppData_backendEnvironments[];
    effectiveLegalDocuments: getAppData_effectiveLegalDocuments[];
}

export interface getAppDataVariables {
    countryCode: CountryCode;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRelatedData
// ====================================================

export interface getRelatedData_getRelatedData_accountData {
    __typename: "AccountData";
    firstName: string;
    lastName: string;
    dateOfBirth: any;
    email: string;
    phoneNumber: string | null;
}

export interface getRelatedData_getRelatedData_insuranceData {
    __typename: "InsuranceData";
    id: string;
    insuranceType: InsuranceType;
    created: any;
    firstDay: any;
    lastDay: any;
}

export interface getRelatedData_getRelatedData_modTravelData_travelMembers {
    __typename: "ModularTravelDEInsuranceContractMember";
    ageCategory: AgeCategory | null;
    deleted: any | null;
}

export interface getRelatedData_getRelatedData_modTravelData {
    __typename: "ModularTravelData";
    typeOfCoverage: CoverageBase;
    duration: Duration;
    destination: Destination;
    travelMembers: getRelatedData_getRelatedData_modTravelData_travelMembers[];
}

export interface getRelatedData_getRelatedData_homeData {
    __typename: "HomeData";
    policyNumber: string;
    tariff: any;
    endOfCoverage: any | null;
    zipCode: string;
    homeInsuranceType: HomeInsuranceType | null;
}

export interface getRelatedData_getRelatedData_deHomePackagesData {
    __typename: "DeHomePackagesData";
    policyNumber: string;
    tariff: any;
    endOfCoverage: any | null;
    zipCode: string;
    typeOfCoverages: ModHomeBase | null;
}

export interface getRelatedData_getRelatedData_claimData_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface getRelatedData_getRelatedData_claimData_uploadedDocuments_file {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
}

export interface getRelatedData_getRelatedData_claimData_uploadedDocuments {
    __typename: "UploadedClaimDocument";
    id: string;
    file: getRelatedData_getRelatedData_claimData_uploadedDocuments_file;
}

export interface getRelatedData_getRelatedData_claimData {
    __typename: "ClaimData";
    id: string;
    claimCause: getRelatedData_getRelatedData_claimData_claimCause;
    claimStatus: ClaimStatus;
    mappedClaimStatus: string | null;
    bankAccountNumber: string;
    claimAmount: any;
    dateOfReport: any;
    dateOfEvent: any | null;
    closeTime: any | null;
    uploadedDocuments: getRelatedData_getRelatedData_claimData_uploadedDocuments[];
}

export interface getRelatedData_getRelatedData_otherClaimData_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface getRelatedData_getRelatedData_otherClaimData_uploadedDocuments_file {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
}

export interface getRelatedData_getRelatedData_otherClaimData_uploadedDocuments {
    __typename: "UploadedClaimDocument";
    id: string;
    file: getRelatedData_getRelatedData_otherClaimData_uploadedDocuments_file;
}

export interface getRelatedData_getRelatedData_otherClaimData {
    __typename: "ClaimData";
    id: string;
    claimCause: getRelatedData_getRelatedData_otherClaimData_claimCause;
    claimStatus: ClaimStatus;
    mappedClaimStatus: string | null;
    bankAccountNumber: string;
    claimAmount: any;
    dateOfReport: any;
    dateOfEvent: any | null;
    closeTime: any | null;
    uploadedDocuments: getRelatedData_getRelatedData_otherClaimData_uploadedDocuments[];
}

export interface getRelatedData_getRelatedData_completedTasks_items {
    __typename: "ClaimTaskItemData";
    claimTaskItemId: string;
    claimTaskGroupId: string;
    claimTaskItemType: string;
    description: string;
    answer: string | null;
}

export interface getRelatedData_getRelatedData_completedTasks {
    __typename: "ClaimTaskData";
    claimTaskId: string;
    claimId: string;
    dateOfTask: any;
    deadLine: any;
    items: getRelatedData_getRelatedData_completedTasks_items[];
}

export interface getRelatedData_getRelatedData_openTasks_items {
    __typename: "ClaimTaskItemData";
    claimTaskItemId: string;
    claimTaskGroupId: string;
    claimTaskItemType: string;
    description: string;
    answer: string | null;
}

export interface getRelatedData_getRelatedData_openTasks {
    __typename: "ClaimTaskData";
    claimTaskId: string;
    claimId: string;
    dateOfTask: any;
    deadLine: any;
    items: getRelatedData_getRelatedData_openTasks_items[];
}

export interface getRelatedData_getRelatedData {
    __typename: "RelatedData";
    accountData: getRelatedData_getRelatedData_accountData;
    insuranceData: getRelatedData_getRelatedData_insuranceData;
    modTravelData: getRelatedData_getRelatedData_modTravelData | null;
    homeData: getRelatedData_getRelatedData_homeData | null;
    deHomePackagesData: getRelatedData_getRelatedData_deHomePackagesData | null;
    claimData: getRelatedData_getRelatedData_claimData | null;
    otherClaimData: getRelatedData_getRelatedData_otherClaimData[];
    completedTasks: getRelatedData_getRelatedData_completedTasks[];
    openTasks: getRelatedData_getRelatedData_openTasks[];
}

export interface getRelatedData {
    /**
     * get the related account, insurance and claim data for the session based on sessionId.
     */
    getRelatedData: getRelatedData_getRelatedData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClaimCauses
// ====================================================

export interface getClaimCauses_claimCauses {
    __typename: "ClaimCause";
    id: any | null;
    insuranceType: InsuranceType | null;
    name: string | null;
    countryCode: CountryCode | null;
    translationKey: string | null;
}

export interface getClaimCauses {
    /**
     * Get claim causes for German Modular Travel
     */
    claimCauses: getClaimCauses_claimCauses[];
}

export interface getClaimCausesVariables {
    insuranceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BackendEnvironment
// ====================================================

export interface BackendEnvironment {
    __typename: "Env";
    /**
     * Name of environment variable
     */
    name: string;
    /**
     * Value (serialized to string)
     */
    value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClaimCause
// ====================================================

export interface ClaimCause {
    __typename: "ClaimCause";
    id: any | null;
    insuranceType: InsuranceType | null;
    name: string | null;
    countryCode: CountryCode | null;
    translationKey: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClaimData
// ====================================================

export interface ClaimData_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface ClaimData_uploadedDocuments_file {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
}

export interface ClaimData_uploadedDocuments {
    __typename: "UploadedClaimDocument";
    id: string;
    file: ClaimData_uploadedDocuments_file;
}

export interface ClaimData {
    __typename: "ClaimData";
    id: string;
    claimCause: ClaimData_claimCause;
    claimStatus: ClaimStatus;
    mappedClaimStatus: string | null;
    bankAccountNumber: string;
    claimAmount: any;
    dateOfReport: any;
    dateOfEvent: any | null;
    closeTime: any | null;
    uploadedDocuments: ClaimData_uploadedDocuments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClaimTaskData
// ====================================================

export interface ClaimTaskData_items {
    __typename: "ClaimTaskItemData";
    claimTaskItemId: string;
    claimTaskGroupId: string;
    claimTaskItemType: string;
    description: string;
    answer: string | null;
}

export interface ClaimTaskData {
    __typename: "ClaimTaskData";
    claimTaskId: string;
    claimId: string;
    dateOfTask: any;
    deadLine: any;
    items: ClaimTaskData_items[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClaimTaskItemData
// ====================================================

export interface ClaimTaskItemData {
    __typename: "ClaimTaskItemData";
    claimTaskItemId: string;
    claimTaskGroupId: string;
    claimTaskItemType: string;
    description: string;
    answer: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmailValidationResult
// ====================================================

export interface EmailValidationResult {
    __typename: "EmailValidationResult";
    sessionId: string;
    maskedEmail: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LegalDocumentWL
// ====================================================

export interface LegalDocumentWL {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RelatedData
// ====================================================

export interface RelatedData_accountData {
    __typename: "AccountData";
    firstName: string;
    lastName: string;
    dateOfBirth: any;
    email: string;
    phoneNumber: string | null;
}

export interface RelatedData_insuranceData {
    __typename: "InsuranceData";
    id: string;
    insuranceType: InsuranceType;
    created: any;
    firstDay: any;
    lastDay: any;
}

export interface RelatedData_modTravelData_travelMembers {
    __typename: "ModularTravelDEInsuranceContractMember";
    ageCategory: AgeCategory | null;
    deleted: any | null;
}

export interface RelatedData_modTravelData {
    __typename: "ModularTravelData";
    typeOfCoverage: CoverageBase;
    duration: Duration;
    destination: Destination;
    travelMembers: RelatedData_modTravelData_travelMembers[];
}

export interface RelatedData_homeData {
    __typename: "HomeData";
    policyNumber: string;
    tariff: any;
    endOfCoverage: any | null;
    zipCode: string;
    homeInsuranceType: HomeInsuranceType | null;
}

export interface RelatedData_deHomePackagesData {
    __typename: "DeHomePackagesData";
    policyNumber: string;
    tariff: any;
    endOfCoverage: any | null;
    zipCode: string;
    typeOfCoverages: ModHomeBase | null;
}

export interface RelatedData_claimData_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface RelatedData_claimData_uploadedDocuments_file {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
}

export interface RelatedData_claimData_uploadedDocuments {
    __typename: "UploadedClaimDocument";
    id: string;
    file: RelatedData_claimData_uploadedDocuments_file;
}

export interface RelatedData_claimData {
    __typename: "ClaimData";
    id: string;
    claimCause: RelatedData_claimData_claimCause;
    claimStatus: ClaimStatus;
    mappedClaimStatus: string | null;
    bankAccountNumber: string;
    claimAmount: any;
    dateOfReport: any;
    dateOfEvent: any | null;
    closeTime: any | null;
    uploadedDocuments: RelatedData_claimData_uploadedDocuments[];
}

export interface RelatedData_otherClaimData_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface RelatedData_otherClaimData_uploadedDocuments_file {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
}

export interface RelatedData_otherClaimData_uploadedDocuments {
    __typename: "UploadedClaimDocument";
    id: string;
    file: RelatedData_otherClaimData_uploadedDocuments_file;
}

export interface RelatedData_otherClaimData {
    __typename: "ClaimData";
    id: string;
    claimCause: RelatedData_otherClaimData_claimCause;
    claimStatus: ClaimStatus;
    mappedClaimStatus: string | null;
    bankAccountNumber: string;
    claimAmount: any;
    dateOfReport: any;
    dateOfEvent: any | null;
    closeTime: any | null;
    uploadedDocuments: RelatedData_otherClaimData_uploadedDocuments[];
}

export interface RelatedData_completedTasks_items {
    __typename: "ClaimTaskItemData";
    claimTaskItemId: string;
    claimTaskGroupId: string;
    claimTaskItemType: string;
    description: string;
    answer: string | null;
}

export interface RelatedData_completedTasks {
    __typename: "ClaimTaskData";
    claimTaskId: string;
    claimId: string;
    dateOfTask: any;
    deadLine: any;
    items: RelatedData_completedTasks_items[];
}

export interface RelatedData_openTasks_items {
    __typename: "ClaimTaskItemData";
    claimTaskItemId: string;
    claimTaskGroupId: string;
    claimTaskItemType: string;
    description: string;
    answer: string | null;
}

export interface RelatedData_openTasks {
    __typename: "ClaimTaskData";
    claimTaskId: string;
    claimId: string;
    dateOfTask: any;
    deadLine: any;
    items: RelatedData_openTasks_items[];
}

export interface RelatedData {
    __typename: "RelatedData";
    accountData: RelatedData_accountData;
    insuranceData: RelatedData_insuranceData;
    modTravelData: RelatedData_modTravelData | null;
    homeData: RelatedData_homeData | null;
    deHomePackagesData: RelatedData_deHomePackagesData | null;
    claimData: RelatedData_claimData | null;
    otherClaimData: RelatedData_otherClaimData[];
    completedTasks: RelatedData_completedTasks[];
    openTasks: RelatedData_openTasks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UploadedClaimDocument
// ====================================================

export interface UploadedClaimDocument_file {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
}

export interface UploadedClaimDocument {
    __typename: "UploadedClaimDocument";
    id: string;
    file: UploadedClaimDocument_file;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VoidType
// ====================================================

export interface VoidType {
    __typename: "VoidType";
    code: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AgeCategory {
    adult = "adult",
    child = "child",
    senior = "senior",
}

/**
 * Claim status
 */
export enum ClaimStatus {
    accepted_finished = "accepted_finished",
    accepted_payment_pending = "accepted_payment_pending",
    assistance_opened_by_partner = "assistance_opened_by_partner",
    automatic_accepted_finished = "automatic_accepted_finished",
    call_arranged = "call_arranged",
    canceled_by_user = "canceled_by_user",
    checking_local_expert = "checking_local_expert",
    closed_by_partner = "closed_by_partner",
    closed_draft_timeout = "closed_draft_timeout",
    closed_totaled = "closed_totaled",
    closed_with_regress = "closed_with_regress",
    closed_without_payment = "closed_without_payment",
    closed_without_payment_invalid_data = "closed_without_payment_invalid_data",
    draft_canceled = "draft_canceled",
    open_draft = "open_draft",
    open_reopened = "open_reopened",
    open_submitted = "open_submitted",
    reimbursement_closed_with_payment = "reimbursement_closed_with_payment",
    reimbursement_closed_without_payment = "reimbursement_closed_without_payment",
    reimbursement_opened_by_partner = "reimbursement_opened_by_partner",
    rejected = "rejected",
    rejected_timeout = "rejected_timeout",
    repair_done = "repair_done",
    repair_in_progress = "repair_in_progress",
    sending_to_partner = "sending_to_partner",
    waiting_for_client = "waiting_for_client",
    waiting_for_client_payment = "waiting_for_client_payment",
    waiting_for_expert = "waiting_for_expert",
    waiting_for_operator = "waiting_for_operator",
}

/**
 * Country codes
 */
export enum CountryCode {
    CZ = "CZ",
    DE = "DE",
    HU = "HU",
    RO = "RO",
    SK = "SK",
}

export enum CoverageBase {
    cancellationInterruption = "cancellationInterruption",
    full = "full",
    health = "health",
    healthAndCancellation = "healthAndCancellation",
    healthAndLuggage = "healthAndLuggage",
    luggage = "luggage",
    luggageAndCancellation = "luggageAndCancellation",
}

export enum Destination {
    america = "america",
    europe = "europe",
    notEUAM = "notEUAM",
}

export enum Duration {
    DAILY = "DAILY",
    YEARLY = "YEARLY",
}

export enum HomeInsuranceType {
    BOTH = "BOTH",
    IMMOVABLES = "IMMOVABLES",
    MOVABLES = "MOVABLES",
    MOVABLES_DE = "MOVABLES_DE",
}

/**
 * Insurance types
 */
export enum InsuranceType {
    CAR = "CAR",
    CZ_CANCELLATION_WL = "CZ_CANCELLATION_WL",
    CZ_TRAVEL_WL = "CZ_TRAVEL_WL",
    DE_HOME_PACKAGES = "DE_HOME_PACKAGES",
    DE_TRAVEL_MOD_A = "DE_TRAVEL_MOD_A",
    DE_TRAVEL_MOD_S = "DE_TRAVEL_MOD_S",
    HOME = "HOME",
    LIFE = "LIFE",
    RO_CANCELLATION_WL = "RO_CANCELLATION_WL",
    RO_TRAVEL_WL = "RO_TRAVEL_WL",
    SK_CANCELLATION_WL = "SK_CANCELLATION_WL",
    SK_TRAVEL_WL = "SK_TRAVEL_WL",
    TRAVEL = "TRAVEL",
    TRIP_CANCELLATION = "TRIP_CANCELLATION",
}

/**
 * Legal document required user interaction types
 */
export enum LegalDocumentInteraction {
    NEED_TO_ACCEPT = "NEED_TO_ACCEPT",
    NONE = "NONE",
    NOTIFY = "NOTIFY",
}

export enum ModHomeBase {
    basic = "basic",
    comfort = "comfort",
    premium = "premium",
}

export enum legalDocumentCategory {
    ANNOUNCEMENT = "ANNOUNCEMENT",
    CHERRISK_PRIVACY_POLICY = "CHERRISK_PRIVACY_POLICY",
    COMPLAINT = "COMPLAINT",
    GENERAL_CONTRACT_INFORMATION = "GENERAL_CONTRACT_INFORMATION",
    HEALTH_DATA = "HEALTH_DATA",
    INSURANCE_INTERMEDIARY_INFO = "INSURANCE_INTERMEDIARY_INFO",
    IPID = "IPID",
    MARKETING_POLICY = "MARKETING_POLICY",
    PRIVACY_POLICY = "PRIVACY_POLICY",
    PROTOCOL_OF_ADVICE = "PROTOCOL_OF_ADVICE",
    REGISTRATION_POLICY = "REGISTRATION_POLICY",
    SCALE_MARKETING_POLICY = "SCALE_MARKETING_POLICY",
    TERMS = "TERMS",
    WL_HU_CLAIM_SETTLEMENT_NOTICE = "WL_HU_CLAIM_SETTLEMENT_NOTICE",
    WL_HU_COMPLAINT = "WL_HU_COMPLAINT",
    WL_HU_COOKIE_POLICY = "WL_HU_COOKIE_POLICY",
    WL_HU_IPID = "WL_HU_IPID",
    WL_HU_MARKETING_POLICY = "WL_HU_MARKETING_POLICY",
    WL_HU_PRIVACY_POLICY = "WL_HU_PRIVACY_POLICY",
    WL_HU_SCALE_MARKETING_POLICY = "WL_HU_SCALE_MARKETING_POLICY",
    WL_HU_TERMS = "WL_HU_TERMS",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
