import React from "react";
import styled from "styled-components";
import { Flex } from "@monster/shared";
import { SVGChrIcon32BasicsOk } from "../../svg_assets";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    id: string;
};

export const Checkbox: React.FC<Props> = ({ id, children, ...props }) => {
    return (
        <>
            <StyledInput id={id} className="show-for-sr" type="checkbox" {...props} />

            <Flex.Container as="label" htmlFor={id} $style={{ display: "inline-flex", verticalAlign: "bottom" }}>
                <StyledCheckbox as="span" $shrink={32}>
                    <SVGChrIcon32BasicsOk />
                </StyledCheckbox>

                {children && (
                    <Flex.Item as="span" $shrink="auto">
                        {children}
                    </Flex.Item>
                )}
            </Flex.Container>
        </>
    );
};

const StyledCheckbox = styled(Flex.Item).attrs(() => ({
    "aria-hidden": true,
}))`
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.blueN};
    cursor: pointer;
    display: block;
    height: ${({ $shrink }) => $shrink}px;

    svg {
        color: ${({ theme }) => theme.color.greyD};
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.color.greyD};

            svg {
                color: ${({ theme }) => theme.color.white};
            }
        }
    }
`;

const StyledInput = styled.input`
    &:focus-visible {
        & + label {
            ${StyledCheckbox} {
                outline: 2px solid;
            }
        }
    }

    &:checked {
        & + label {
            ${StyledCheckbox} {
                background-color: ${({ theme }) => theme.color.blueN};

                svg {
                    color: ${({ theme }) => theme.color.white};
                }
            }
        }
    }

    &:disabled {
        & + label {
            cursor: not-allowed;

            ${StyledCheckbox} {
                background-color: ${({ theme }) => theme.color.body.backgroundColor};
                box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
                cursor: inherit;

                svg {
                    color: ${({ theme }) => theme.color.greyD};
                }
            }
        }
    }
`;
