import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon68X72ProductsTravel = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={68} height={70} viewBox="0 0 68 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M34 58C48.3594 58 60 46.3594 60 32C60 17.6406 48.3594 6 34 6C19.6406 6 8 17.6406 8 32C8 46.3594 19.6406 58 34 58Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 12C22.9543 12 14 20.9543 14 32C14 43.0457 22.9543 52 34 52C45.0457 52 54 43.0457 54 32C54 20.9543 45.0457 12 34 12ZM12 32C12 19.8497 21.8497 10 34 10C46.1503 10 56 19.8497 56 32C56 44.1503 46.1503 54 34 54C21.8497 54 12 44.1503 12 32Z"
                fill="#ECF0FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 25C32.3429 25 30.8442 25.6705 29.7574 26.7574C28.6705 27.8442 28 29.3429 28 31C28 31.9509 28.4107 33.1654 29.4134 34.7307C30.4071 36.282 31.9241 38.0888 33.9992 40.2066C33.9993 40.2065 33.9995 40.2065 33.9997 40.2064L34.0013 40.2055C34.0018 40.2052 34.0026 40.2047 34.0036 40.2038C34.0042 40.2033 34.0048 40.2027 34.0055 40.2021C36.0266 38.1721 37.5436 36.3812 38.5499 34.8163C39.5654 33.2371 40 31.9811 40 31C40 29.3429 39.3295 27.8442 38.2426 26.7574C37.1558 25.6705 35.6571 25 34 25ZM28.3431 25.3431C29.7898 23.8965 31.7911 23 34 23C36.2089 23 38.2102 23.8965 39.6569 25.3431C41.1035 26.7898 42 28.7911 42 31C42 32.5359 41.3374 34.1793 40.2321 35.898C39.1181 37.6305 37.4918 39.5354 35.4202 41.6157L35.4172 41.6188C35.0269 42.0074 34.5133 42.2068 33.9978 42.2068C33.4903 42.2068 32.9753 42.0129 32.5884 41.6244L32.5828 41.6188L32.5828 41.6188C30.4583 39.4516 28.8307 37.529 27.7293 35.8095C26.6349 34.1011 26 32.5013 26 31C26 28.7911 26.8965 26.7898 28.3431 25.3431Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon68X72ProductsTravel;
