import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { bp, Box, Flex, List } from "@monster/shared";
import { AppBar as ChrAppbar, Text } from "@monster/chr-ui";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { SVGChrBrandCherriskByUniqa } from "@monster/chr-ui/dist/svg_assets";
import { SVGChrIcon32BasicsArrowOpen } from "@monster/chr-ui/dist/svg_assets";
import { SVGChrIconBrand } from "@monster/chr-ui/dist/svg_assets";

type Props = {
    activeIndex: number;
    onLogoClick?: () => void;
    onBackButtonClick?: () => void;
    onItemClick?: (itemIndex: number) => void;
};

export const AppBar = ({ activeIndex, onBackButtonClick, onItemClick, onLogoClick }: Props) => {
    const theme = useTheme();

    const dummyBreadcrumbs = useMemo(
        () =>
            [
                { key: "identification", subSteps: [{ key: "policyDetails" }, { key: "emailConfirmation" }] },
                { key: "claimReporting", subSteps: [{ key: "whatCause" }, { key: "claimDescription" }, { key: "attachment" }, { key: "submitClaim" }] },
            ].map((key, index) => {
                // TODO: isActive is static this way.
                const isActive = index === 0 ? activeIndex <= 2 : index === 1 ? activeIndex >= 3 : false;
                return {
                    id: key.key,
                    label: <LoctoolMessage id={`components.appbar.step.${key.key}`} />,
                    isActive,
                    subSteps: key.subSteps,
                };
            }),
        [activeIndex]
    );
    // TODO: totalSteps is static this way.
    const totalSteps = dummyBreadcrumbs[0].subSteps.length + dummyBreadcrumbs[1].subSteps.length;

    const renderDummyBreadcrumbs = useMemo(() => {
        let isActiveFound = false;
        return dummyBreadcrumbs
            ? dummyBreadcrumbs.map(
                  (
                      breadcrumb: {
                          id: string;
                          label: React.ReactNode;
                          isActive?: boolean;
                          subSteps: { key: string }[];
                      },
                      index: number
                  ) => {
                      let state: "done" | "active" | undefined = "done";
                      if (isActiveFound) {
                          state = undefined;
                      } else if (breadcrumb.isActive) {
                          state = "active";
                          isActiveFound = true;
                      }
                      return (
                          state === "active" && (
                              <Text
                                  key={breadcrumb.id}
                                  onClick={() => {
                                      onItemClick && onItemClick(index);
                                  }}
                                  className="hide-for-large"
                                  $variant="bodyMobile"
                              >
                                  {/* TODO: this is static this way */}
                                  {index === 0 ? activeIndex : index === 1 ? activeIndex - dummyBreadcrumbs[0].subSteps.length : 0} / {dummyBreadcrumbs[index].subSteps.length}{" "}
                                  <span className="show-for-sr">{breadcrumb.label}</span>
                              </Text>
                          )
                      );
                  }
              )
            : undefined;
    }, [dummyBreadcrumbs, activeIndex, onItemClick]);

    const renderDummyBreadcrumbsLarge = useMemo(() => {
        let isActiveFound = false;
        return dummyBreadcrumbs
            ? dummyBreadcrumbs.map(
                  (
                      breadcrumb: {
                          id: string;
                          label: React.ReactNode;
                          isActive?: boolean;
                          subSteps: { key: string }[];
                      },
                      index: number
                  ) => {
                      let state: "done" | "active" | undefined = "done";
                      if (isActiveFound) {
                          state = undefined;
                      } else if (breadcrumb.isActive) {
                          state = "active";
                          isActiveFound = true;
                      }
                      return (
                          <BreadcrumbItem
                              as="li"
                              key={`${breadcrumb.id}-large`}
                              $shrink="shrink"
                              onClick={() => {
                                  onItemClick && onItemClick(index);
                              }}
                              $style={{ color: state === "done" ? theme.color.greenN : state === "active" ? theme.color.blueD : theme.color.blueL }}
                          >
                              {index > 0 ? (
                                  <>
                                      <Box as="span" $style={{ display: "block", transformOrigin: "center", transform: "rotate(-90deg)" }}>
                                          <SVGChrIcon32BasicsArrowOpen />
                                      </Box>

                                      {breadcrumb.label}
                                  </>
                              ) : (
                                  breadcrumb.label
                              )}

                              {/* NOTE: visually hidden subSteps */}
                              {breadcrumb.subSteps.length > 0 && (
                                  <ol className="show-for-sr">
                                      {breadcrumb.subSteps.map((subStep: { key: string }, j: number) => (
                                          // TODO: aria-current="step | undefined" is static this way
                                          <li
                                              key={subStep.key}
                                              aria-current={
                                                  index === 0
                                                      ? activeIndex === j + 1
                                                          ? "step"
                                                          : undefined
                                                      : index === 1
                                                      ? activeIndex - dummyBreadcrumbs[0].subSteps.length === j + 1
                                                          ? "step"
                                                          : undefined
                                                      : undefined
                                              }
                                          >
                                              <LoctoolMessage id={`components.appbar.step.${subStep.key}`} />
                                          </li>
                                      ))}
                                  </ol>
                              )}
                          </BreadcrumbItem>
                      );
                  }
              )
            : undefined;
    }, [dummyBreadcrumbs, theme, activeIndex, onItemClick]);

    return (
        <>
            {/* NOTE: in chr-claim-app's Figma: AppBar's and cart layout's container (maxWidth + margin: "0 auto") is narrower than the other apps'. &&& shame CSS. */}
            <Box $style={{ "&&&+*>*": { maxWidth: 1008 + 2 * 16 } }} />

            <ChrAppbar
                leftItemChild={<AppBarLogo onClick={onLogoClick} />}
                centerItemChild={
                    totalSteps >= activeIndex && activeIndex > 0 ? (
                        <>
                            {renderDummyBreadcrumbs}

                            <Box className="show-for-large" $style={{ textAlign: "center" }}>
                                <Flex.Container as={List} forcedAs="ol" $style={{ display: "inline-flex", flexWrap: "wrap" }}>
                                    {renderDummyBreadcrumbsLarge}
                                </Flex.Container>
                            </Box>
                        </>
                    ) : null
                }
                rightItemChild={
                    totalSteps >= activeIndex ? (
                        // NOTE: in order to the breadcrumbs be aligned horizontally dead center, we set the same width as the leftItemChild has.
                        <Box $styleLarge={{ width: 144 }}>
                            {activeIndex > 0 ? (
                                <StyledAppBarButton type="button" className="hide-for-large" onClick={onBackButtonClick}>
                                    <Box as="span" $style={{ display: "block", transformOrigin: "center", transform: "rotate(90deg)" }}>
                                        <SVGChrIcon32BasicsArrowOpen />
                                    </Box>

                                    {Loctool.instance.formatMessage({ id: "common.previous" })}
                                </StyledAppBarButton>
                            ) : undefined}
                        </Box>
                    ) : null
                }
            />
        </>
    );
};

export const AppBarLogo = ({ onClick }: { onClick?: React.MouseEventHandler }) => {
    return (
        <div onClick={onClick}>
            <SVGChrIconBrand className="hide-for-large" title={`${Loctool.instance.formatMessage({ id: "common.brandName" })} ${Loctool.instance.formatMessage({ id: "common.brandNameBy" })}`} />

            <SVGChrBrandCherriskByUniqa
                className="show-for-large"
                title={`${Loctool.instance.formatMessage({ id: "common.brandName" })} ${Loctool.instance.formatMessage({ id: "common.brandNameBy" })}`}
            />
        </div>
    );
};

const StyledAppBarButton = styled.button`
    color: ${({ theme }) => theme.color.body.textColor};

    /* NOTE: increases tappable area at least 44 * 44 pixels */
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    &::before {
        content: "";
        height: 44px;
        left: 0;
        min-width: 44px;
        position: absolute;
        right: 0;
    }

    /* NOTE: we don't need this increased tappable area for large */
    ${bp.large} {
        &::before {
            content: normal;
        }
    }
`;

const BreadcrumbItem = styled(Flex.Item)`
    align-items: center;
    display: flex;

    /* a {
        color: inherit;
        text-decoration: none;
    }

    @media (hover: hover) {
        a {
            &:hover {
                color: ${({ theme }) => theme.color.greenN};
            }
        }
    } */
`;
