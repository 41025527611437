enum Breakpoint {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
    xxlarge = "xxlarge",
}

export const breakpoints: { [key in Breakpoint]: number } = {
    small: 375,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1920,
};

/*
    EXAMPLE

    largeDown // ↓
    ${bp.custom(`max-width: ${breakpoints.large - 0.02}px`)} {}

    largeOnly // ⭰⭲
    ${bp.custom(`(min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.xlarge - 0.02}px)`)} {}

    Between medium and xlarge // ⭰ medium, large, xlarge ⭲
    ${bp.custom(`(min-width: breakpoints.medium) and max-width: ${breakpoints.xlarge - 0.02}px`)} {}
*/
function customMediaQuery(mediaQuery: string): string {
    return `@media ${mediaQuery}`;
}

// NOTE: breakpointNameUp // ↑
export const bp: { [key in Breakpoint]: string } & {
    custom: (mediaQuery: string) => string;
} = {
    custom: customMediaQuery,
    small: customMediaQuery(`(min-width: ${breakpoints.small}px)`),
    medium: customMediaQuery(`(min-width: ${breakpoints.medium}px)`),
    large: customMediaQuery(`(min-width: ${breakpoints.large}px)`),
    xlarge: customMediaQuery(`(min-width: ${breakpoints.xlarge}px)`),
    xxlarge: customMediaQuery(`(min-width: ${breakpoints.xxlarge}px)`),
};
