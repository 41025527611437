import { LegalDocumentWL } from "@api/graphql/types";
import { ActionsUnion, ReduxHelpers } from "@monster/shared";

export enum LegalDocumentActionTypes {
    UPDATE_LEGAL_DOCUMENTS = "LegalDocumentAction/UPDATE_LEGAL_DOCUMENTS",
}

export const LegalDocumentActions = {
    update: (legalDocuments: LegalDocumentWL[]) => ReduxHelpers.createAction(LegalDocumentActionTypes.UPDATE_LEGAL_DOCUMENTS, { legalDocuments }),
};

export type LegalDocumentActions = ActionsUnion<typeof LegalDocumentActions>;
