import { Root, Trigger, Portal, Overlay, Content, Title, Description, Close, DialogProps, DialogContentProps } from "@radix-ui/react-dialog";

export interface SharedDialogProps extends DialogProps {
    triggerElement?: React.ReactElement;
    // NOTE: because of Sandbox's iframe portalled={false}
    portalled?: boolean;
}

export interface SharedDialogContentProps extends DialogContentProps {}

export const SharedDialog = {
    Root,
    Trigger,
    Portal,
    Overlay,
    Content,
    Title,
    Description,
    Close,
};
