import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsEdit = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9393 13.6465L11.5858 23H9V20.4142L18.3535 11.0607L20.9393 13.6465ZM22.3535 13.6465L24.2929 11.7071C24.6834 11.3166 24.6834 10.6834 24.2929 10.2929L21.7071 7.70708C21.3166 7.31655 20.6834 7.31655 20.2929 7.70708L8.14645 19.8535C8.05268 19.9473 8 20.0745 8 20.2071V23.75C8 23.888 8.11193 24 8.25 24H11.7929C11.9255 24 12.0527 23.9473 12.1464 23.8535L21.6464 14.3536L21.6464 14.3536L22.3536 13.6465L22.3535 13.6465ZM21.6464 12.9393L19.0606 10.3536L21 8.41418L23.5858 11L21.6464 12.9393Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32BasicsEdit;
