import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsHomeHouse = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.5 11.8519C6.5 11.6801 6.58816 11.5204 6.73348 11.4289L6.46696 11.0058L6.73348 11.4289L15.7335 5.75886C15.8963 5.65625 16.1037 5.65625 16.2665 5.75886L25.2665 11.4289C25.4118 11.5204 25.5 11.6801 25.5 11.8519V25C25.5 25.2761 25.2761 25.5 25 25.5H7C6.72386 25.5 6.5 25.2761 6.5 25V11.8519Z"
                stroke="currentColor"
            />
            <path
                d="M3.73247 12.7776C3.49918 12.9253 3.42984 13.2342 3.57759 13.4675C3.72534 13.7008 4.03424 13.7702 4.26753 13.6224L3.73247 12.7776ZM16 5.59998L16.2675 5.17757C16.1042 5.07411 15.8958 5.07411 15.7325 5.17757L16 5.59998ZM27.7325 13.6224C27.9658 13.7702 28.2747 13.7008 28.4224 13.4675C28.5702 13.2342 28.5008 12.9253 28.2675 12.7776L27.7325 13.6224ZM4.26753 13.6224L16.2675 6.02238L15.7325 5.17757L3.73247 12.7776L4.26753 13.6224ZM15.7325 6.02238L27.7325 13.6224L28.2675 12.7776L16.2675 5.17757L15.7325 6.02238Z"
                fill="currentColor"
            />
            <rect x={13.5} y={18.5} width={5} height={7} rx={0.5} stroke="currentColor" />
            <circle cx={16} cy={12.5} r={2} stroke="currentColor" />
            <path d="M25.5 12V7H22.5V10" stroke="currentColor" strokeLinejoin="round" />
        </SVGBase>
    );
};

export default SvgIcon32ProductsHomeHouse;
