import { gql } from "@apollo/client";

export const ClaimTaskItemDataFragment = gql`
    fragment ClaimTaskItemData on ClaimTaskItemData {
        claimTaskItemId
        claimTaskGroupId
        claimTaskItemType
        description
        answer
    }
`;
