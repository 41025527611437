import { ReduxHelpers, ActionsUnion } from "@monster/shared";

export enum PageSteps {
    ClauseSelection = 1,
    ClaimDescription = 2,
    ClaimMandatoryAttachments = 3,
    ClaimOptionalAttachments = 4,
    ClaimPrepareSubmit = 5,
}

export enum AppStateActionTypes {
    RESET = "AppStateAction/RESET",
    SET_CURRENT_STEP = "AppStateActions/SET_CURRENT_STEP",
}

export const AppStateActions = {
    reset: () => ReduxHelpers.createAction(AppStateActionTypes.RESET),
    setCurrentStep: (step: PageSteps) => ReduxHelpers.createAction(AppStateActionTypes.SET_CURRENT_STEP, { step }),
};

export type AppStateActions = ActionsUnion<typeof AppStateActions>;
