import sessionStorage from "redux-persist/es/storage/session";
import { persistCombineReducers } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import { ReduxAction } from "./actions/ReduxAction";
import sessionReducer, { SessionReducerType } from "./reducers/sessionReducer";
import backendEnvironmentsReducer, { BackendEnvironmentsReducerType } from "./reducers/backendEnvironmentsReducer";
import appStateReducer, { AppStateReducerType } from "./reducers/appStateReducer";
import legalDocumentReducer, { LegalDocumentReducerType } from "./reducers/legalDocumentReducer";
import openingHoursReducer, { OpeningHoursReducerType } from "./reducers/openingHoursReducer";

export interface ApplicationState {
    appState: AppStateReducerType;
    backendEnvironments: BackendEnvironmentsReducerType;
    legalDocuments: LegalDocumentReducerType;
    openingHours: OpeningHoursReducerType;
    session: SessionReducerType;
}

const config: PersistConfig<ApplicationState> = {
    key: "chr-claim-app",
    storage: sessionStorage,
    debug: process.env.NODE_ENV === "development",
    whitelist: ["appState", "session"],
};

const rootReducer = persistCombineReducers<ApplicationState, ReduxAction>(config, {
    appState: appStateReducer,
    backendEnvironments: backendEnvironmentsReducer,
    legalDocuments: legalDocumentReducer,
    openingHours: openingHoursReducer,
    session: sessionReducer,
});

export default rootReducer;
