import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32Bicycle = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_12403_41890)">
                <path
                    d="M7.87053 23.7098C10.8636 23.7098 13.2899 21.2835 13.2899 18.2904C13.2899 15.2974 10.8636 12.8711 7.87053 12.8711C4.8775 12.8711 2.45117 15.2974 2.45117 18.2904C2.45117 21.2835 4.8775 23.7098 7.87053 23.7098Z"
                    stroke="currentColor"
                />
                <path
                    d="M24.1283 23.7098C27.1214 23.7098 29.5477 21.2835 29.5477 18.2904C29.5477 15.2974 27.1214 12.8711 24.1283 12.8711C21.1353 12.8711 18.709 15.2974 18.709 18.2904C18.709 21.2835 21.1353 23.7098 24.1283 23.7098Z"
                    stroke="currentColor"
                />
                <path
                    d="M13.29 7H8.77388C8.52446 7 8.32227 7.20219 8.32227 7.45161C8.32227 7.70103 8.52446 7.90323 8.77388 7.90323H13.29C13.5394 7.90323 13.7416 7.70103 13.7416 7.45161C13.7416 7.20219 13.5394 7 13.29 7Z"
                    fill="currentColor"
                />
                <path d="M11.0312 7.45117L15.999 18.2899" stroke="currentColor" />
                <path
                    d="M21.8698 10.1619H22.7731C23.2719 10.1619 23.6763 9.75753 23.6763 9.25869C23.6763 8.75986 23.2719 8.35547 22.7731 8.35547C21.5688 8.35547 20.3645 8.35547 19.1602 8.35547C19.1602 8.35547 20.8161 11.6673 24.1279 18.291"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M19.4389 11.0645L16.311 18.066L8.94922 18.2902L12.0772 11.2887L19.4389 11.0645Z" stroke="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_12403_41890">
                    <rect width={28} height={17.1613} fill="white" transform="translate(2 7)" />
                </clipPath>
            </defs>
        </SVGBase>
    );
};

export default SvgIcon32Bicycle;
