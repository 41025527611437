import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverLiability = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5 2C16.8807 2 18 3.11929 18 4.5L18.0235 4.48236C18.4373 4.17909 18.9477 4 19.5 4C20.8807 4 22 5.11929 22 6.5V14.748L23.25 12.5849C23.9404 11.3892 25.4693 10.9795 26.6651 11.6699C27.8608 12.3602 28.2705 13.8892 27.5801 15.0849L23.9622 21.3528C23.531 26.1999 19.4593 30 14.5 30C9.25329 30 5 25.7467 5 20.5V9.5C5 8.11929 6.11929 7 7.5 7C8.06324 7 8.58298 7.18626 9.00097 7.50055L9 6.5C9 5.11929 10.1193 4 11.5 4C11.9928 4 12.4524 4.14261 12.8396 4.3888L13 4.5C13 3.11929 14.1193 2 15.5 2ZM15.5 3C14.7203 3 14.0796 3.59489 14.0069 4.35554L14 4.5V15.5C14 15.7761 13.7761 16 13.5 16C13.2239 16 13 15.7761 13 15.5V6.5C13 5.7203 12.4051 5.07955 11.6445 5.00687L11.5 5C10.7203 5 10.0796 5.59489 10.0069 6.35554L10 6.5V15.5C10 15.7761 9.77614 16 9.5 16C9.22386 16 9 15.7761 9 15.5V9.5C9 8.7203 8.40511 8.07955 7.64446 8.00687L7.5 8C6.7203 8 6.07955 8.59489 6.00687 9.35554L6 9.5V20.5C6 25.1091 9.66845 28.8613 14.2448 28.9962L14.5 29C18.903 29 22.5241 25.6522 22.9567 21.3632L22.9785 21.1099C22.9812 21.0707 24.2265 18.8957 26.7141 14.5849C27.104 13.9097 26.9091 13.0573 26.2867 12.6141L26.1651 12.5359C25.4898 12.1461 24.6375 12.3409 24.1942 12.9633L24.116 13.0849L21.278 18L20.9888 18.5004C20.8101 18.8096 20.4801 19 20.123 19H20C17.2386 19 15 21.2386 15 24V24.5C15 24.7761 14.7761 25 14.5 25C14.2239 25 14 24.7761 14 24.5V24C14 20.7616 16.5656 18.1224 19.7751 18.0041L19.8382 18.0034C20.0148 18.0013 20.1771 17.9062 20.2653 17.7533L21 16.48V6.5C21 5.7203 20.4051 5.07955 19.6445 5.00687L19.5 5C18.7203 5 18.0796 5.59489 18.0069 6.35554L18 6.5V15.5C18 15.7761 17.7761 16 17.5 16C17.2239 16 17 15.7761 17 15.5V4.5C17 3.7203 16.4051 3.07955 15.6445 3.00687L15.5 3Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverLiability;
