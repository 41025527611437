import React from "react";
import styled, { css } from "styled-components";
import { Box, SelectProps } from "@monster/shared";
import { StyledTextField, StyledTextFieldBorder, StyledTextFieldHelperText, StyledTextFieldLabel, StyledTextFieldStateStyleWrapper, StyledTextFieldSuffixWrapper } from "./TextField.styled";
import { SVGChrIcon32BasicsArrowOpen } from "../../svg_assets";

export const Select: React.FC<SelectProps> = React.forwardRef<HTMLSelectElement, SelectProps>(({ children, id, label, placeholder, errorText, helperText, successText, icon, ...props }, ref) => {
    const selectIcon = <SVGChrIcon32BasicsArrowOpen />;
    const hasError = !!errorText;
    const hasSuccess = !!successText;
    const hasSuffixItem = true;
    const renderHelperText = errorText ?? successText ?? helperText;

    return (
        <StyledSelectStateStyleWrapper $hasError={hasError} $hasSuccess={hasSuccess} $hasSuffixItem={hasSuffixItem} $hasLabel={!!label}>
            <Box $style={{ position: "relative", lineHeight: 1 }}>
                {label && (
                    <StyledTextFieldLabel as="label" htmlFor={id}>
                        {label}
                    </StyledTextFieldLabel>
                )}

                <Box $style={{ position: "relative", lineHeight: 1 }}>
                    <StyledSelect ref={ref} as={"select"} id={id} aria-describedby={renderHelperText ? `${id}-desc` : undefined} {...props}>
                        <option disabled hidden value="">
                            {placeholder ?? "Please choose"}
                        </option>
                        {children}
                    </StyledSelect>

                    <StyledTextFieldBorder />

                    {/* NOTE: dummy label for styling by input's states */}
                    {label && (
                        <StyledTextFieldLabel aria-hidden="true" $style={{ pointerEvents: "none", position: "absolute", left: 0, bottom: "100%", zIndex: 1 }}>
                            {label}
                        </StyledTextFieldLabel>
                    )}

                    {hasSuffixItem && <StyledTextFieldSuffixWrapper>{icon ?? selectIcon}</StyledTextFieldSuffixWrapper>}
                </Box>

                <div aria-atomic="true" aria-live="assertive">
                    {renderHelperText && <StyledTextFieldHelperText id={`${id}-desc`}>{renderHelperText}</StyledTextFieldHelperText>}
                </div>
            </Box>
        </StyledSelectStateStyleWrapper>
    );
});

const commonStyledSelectCSS = css`
    option {
        color: ${({ theme }) => theme.color.body.textColor};
    }

    /* &:focus {
        ~ ${StyledTextFieldSuffixWrapper} {
            transform-origin: center;
            transform: scaleY(-1);
        }
    } */
`;

const cherriskStyledSelectCSS = css`
    ${commonStyledSelectCSS}

    &[required] {
        &:invalid {
            color: ${({ theme }) => theme.color.blueL};
            font-weight: 400;

            /* TODO: stylequide has more than one variant for "unselected" state */
            /* ~ ${StyledTextFieldBorder} {
                border-color: ${({ theme }) => theme.color.greyD};
            } */
        }
    }
`;

export const StyledSelect = styled(StyledTextField)`
    ${cherriskStyledSelectCSS}
`;

const StyledSelectStateStyleWrapper = styled(StyledTextFieldStateStyleWrapper)<{ $hasError: boolean; $hasLabel?: boolean }>`
    ${({ theme, $hasError }) =>
        $hasError
            ? `
    ${StyledSelect} {
        &[required] {
            &:invalid {
                color: ${theme.color.redN};

                ~ ${StyledTextFieldBorder} {
                    border-color: ${theme.color.redN};
                }
            }
        }
    }
    `
            : ""}
`;
