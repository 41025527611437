import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravelTypeCombo = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.2732 17.1362L29.4472 18.7236C29.786 18.893 30 19.2393 30 19.618V21.5C30 25.5 28 28.3333 24 30C21.8559 29.1066 20.2864 27.878 19.2916 26.3141L19.1026 26.001L12 26C10.8954 26 10 25.1046 10 24V21.6229C10 21.2551 10.2019 20.9169 10.5258 20.7425C11.5086 20.2131 12 19.6322 12 19C12 17.8954 11.1046 17 10 17H5C3.89543 17 3 16.1046 3 15V8C3 6.89543 3.89543 6 5 6H8V5C8 3.89543 8.89543 3 10 3H12C13.1046 3 14 3.89543 14 5V6H17C18.1046 6 19 6.89543 19 8V12H25C26.1046 12 27 12.8954 27 14V16.1719C27 16.5587 26.7775 16.9066 26.436 17.0719L26.2732 17.1362ZM23.7764 17.2298L19.2764 19.4798C19.107 19.5645 19 19.7377 19 19.9271V21.5C19 24.9566 20.6246 27.3823 24 28.9099C27.3754 27.3823 29 24.9566 29 21.5V19.9271C29 19.7377 28.893 19.5645 28.7236 19.4798L24.2236 17.2298C24.0828 17.1595 23.9172 17.1595 23.7764 17.2298ZM18.7618 25.3316L18.622 25.0009L18.7011 25.1931L18.7618 25.3316ZM15.5 17C15.7761 17 16 17.2239 16 17.5V20.5C16 20.7761 15.7761 21 15.5 21C15.2239 21 15 20.7761 15 20.5V17.5C15 17.2239 15.2239 17 15.5 17ZM15 7H7V16H10V14C10 12.8954 10.8954 12 12 12H15V7ZM6 7H5C4.44772 7 4 7.44772 4 8V15C4 15.5523 4.44772 16 5 16H6V7ZM17 7H16V12H18V8C18 7.44772 17.5523 7 17 7ZM12 4H10C9.44772 4 9 4.44772 9 5V6H13V5C13 4.44772 12.5523 4 12 4ZM25.1998 16.5999C25.3925 16.4552 25.6031 16.3331 25.8278 16.2377L26 16.1707V14C26 13.4477 25.5523 13 25 13H16V14.5C16 14.7761 15.7761 15 15.5 15C15.2239 15 15 14.7761 15 14.5V13H12C11.4477 13 11 13.4477 11 14V16.1707C12.1652 16.5825 13 17.6938 13 19C13 20.3062 12.1652 21.4175 11 21.8293V24C11 24.5523 11.4477 25 12 25H15V23.5C15 23.2239 15.2239 23 15.5 23C15.7761 23 16 23.2239 16 23.5V25L18.622 25.0009L18.5303 24.7582C18.1768 23.7753 18 22.6892 18 21.5V19.618C18 19.2393 18.214 18.893 18.5528 18.7236L23.5528 16.2236C23.8343 16.0828 24.1657 16.0828 24.4472 16.2236L25.1998 16.5999Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravelTypeCombo;
