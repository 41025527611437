import { css } from "styled-components";
import { commonNormalize } from "@monster/shared";

const cherriskNormalize = css``;

export const normalize = css`
    ${commonNormalize}

    ${cherriskNormalize}
`;
