import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsRefresh = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1096 3.99986C20.9526 3.99986 25.1255 6.8688 27.0215 10.9999H25.9102C24.0895 7.43818 20.3843 4.99986 16.1096 4.99986C10.0485 4.99986 5.13231 9.90203 5.10966 15.9578L6.4068 14.9218C6.62243 14.7493 6.93708 14.7842 7.10958 14.9999C7.28209 15.2155 7.24713 15.5301 7.0315 15.7026L4.92193 17.3903C4.73932 17.5364 4.47985 17.5364 4.29724 17.3903L2.18767 15.7026C1.97204 15.5301 1.93708 15.2155 2.10958 14.9999C2.28209 14.7842 2.59674 14.7493 2.81237 14.9218L4.11 15.9589L4.11338 15.6949C4.27519 9.20841 9.58413 3.99986 16.1096 3.99986Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9139 27.9999C11.0709 27.9999 6.89795 25.1309 5.00194 20.9999H6.11327C7.93398 24.5615 11.6392 26.9999 15.9139 26.9999C21.975 26.9999 26.8911 22.0977 26.9138 16.0419L25.6166 17.0779C25.401 17.2505 25.0864 17.2155 24.9139 16.9999C24.7413 16.7842 24.7763 16.4696 24.9919 16.2971L27.1015 14.6094C27.2841 14.4633 27.5436 14.4633 27.7262 14.6094L29.8358 16.2971C30.0514 16.4696 30.0864 16.7842 29.9139 16.9999C29.7413 17.2155 29.4267 17.2505 29.2111 17.0779L27.9134 16.0409L27.9101 16.3048C27.7482 22.7913 22.4393 27.9999 15.9139 27.9999Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32BasicsRefresh;
