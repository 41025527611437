import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsCoupon = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.7678 5.93933C16.0491 5.65803 16.4306 5.49999 16.8284 5.49999L27 5.50001C27.8284 5.50001 28.5 6.17159 28.5 7.00001L28.5 17.1716C28.5 17.5694 28.342 17.9509 28.0607 18.2322L16.0607 30.2322C15.4749 30.818 14.5251 30.818 13.9393 30.2322L3.76777 20.0607C3.18198 19.4749 3.18198 18.5251 3.76777 17.9393L15.7678 5.93933Z"
                stroke="currentColor"
            />
            <circle cx={22.5061} cy={11.7279} r={2.5} transform="rotate(45 22.5061 11.7279)" stroke="currentColor" />
            <line x1={6.35355} y1={15.6464} x2={18.3536} y2={27.6464} stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsCoupon;
