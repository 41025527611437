import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverNoincome = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M25.5 7.5H6.5C5.39543 7.5 4.5 8.39543 4.5 9.5V21.5C4.5 22.6046 5.39543 23.5 6.5 23.5H25.5C26.6046 23.5 27.5 22.6046 27.5 21.5V9.5C27.5 8.39543 26.6046 7.5 25.5 7.5Z"
                stroke="currentColor"
            />
            <path
                d="M11.5 11.5H8.5C7.94772 11.5 7.5 11.9477 7.5 12.5V14.5C7.5 15.0523 7.94772 15.5 8.5 15.5H11.5C12.0523 15.5 12.5 15.0523 12.5 14.5V12.5C12.5 11.9477 12.0523 11.5 11.5 11.5Z"
                stroke="currentColor"
            />
            <path d="M3 27.4558L28.4558 2" stroke="currentColor" strokeLinecap="round" />
            <path d="M16.5 19H7.5C7.22386 19 7 19.2239 7 19.5C7 19.7761 7.22386 20 7.5 20H16.5C16.7761 20 17 19.7761 17 19.5C17 19.2239 16.7761 19 16.5 19Z" fill="currentColor" />
            <path d="M23.5 19H20.5C20.2239 19 20 19.2239 20 19.5C20 19.7761 20.2239 20 20.5 20H23.5C23.7761 20 24 19.7761 24 19.5C24 19.2239 23.7761 19 23.5 19Z" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverNoincome;
