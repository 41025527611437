import { BackendEnvironments } from "./BackendEnvironments";
import { Constants } from "./Constants";
import { DateUtils } from "./DateHelpers";

export enum ValidatorMessage {
    required = "required",
    invalidEmail = "invalidEmail",

    /** name not match with backend given regex */
    invalidName = "invalidName",
    invalidNameSpecialCharacters = "invalidNameSpecialCharacters",
    invalidFirstDay = "invalidFirstDay",

    invalidPolicyNumber = "invalidPolicyNumber",
    invalidIBANNumber = "invalidIBANNumber",
    invalidClaimId = "invalidClaimId",
    invalidClaimDate = "invalidClaimDate",

    invalidAccountDateOfBirth = "invalidAccountDateOfBirth",
}

export class Validator {
    public static required<T>(value: T): ValidatorMessage | undefined {
        if (value === null || typeof value === "undefined" || (typeof value === "string" && value === "")) {
            return ValidatorMessage.required;
        }
        return undefined;
    }

    public static policyNumber(policy: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(policy);
        if (requiredError) {
            return requiredError;
        }
        if (!policy.toUpperCase().match(new RegExp(/^C[A-Z]{2}[0-9]{7}$/g))) {
            return ValidatorMessage.invalidPolicyNumber;
        }
        return undefined;
    }

    public static claimId(claimId: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(claimId);
        if (requiredError) {
            return requiredError;
        }
        if (!claimId.toUpperCase().match(new RegExp(/^C[A-Z]{4}[0-9]{9}$/g))) {
            return ValidatorMessage.invalidClaimId;
        }
        return undefined;
    }

    public static email(email: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(email);
        if (requiredError) {
            return requiredError;
        }
        if (!email.match(BackendEnvironments.validatorEmail())) {
            return ValidatorMessage.invalidEmail;
        }
        return undefined;
    }

    public static accountDateOfBirth(dateOfBirthString: string | null): ValidatorMessage | undefined {
        const requiredError = Validator.required(dateOfBirthString);
        if (requiredError) {
            return requiredError;
        }

        const dateOfBirth = DateUtils.parse(dateOfBirthString!);
        if (DateUtils.getAgeByDate(dateOfBirth!) < BackendEnvironments.accountMinAge()) {
            return ValidatorMessage.invalidAccountDateOfBirth;
        }
        return undefined;
    }

    public static firstDay(firstDay: string | null): ValidatorMessage | undefined {
        const requiredError = Validator.required(firstDay);
        if (requiredError) {
            return requiredError;
        }

        const startOfFirstDay = DateUtils.startOfDay(DateUtils.parse(firstDay!));
        if (DateUtils.isBefore(startOfFirstDay, Constants.firstDayMin()) || DateUtils.isAfter(startOfFirstDay, Constants.firstDayMax())) {
            return ValidatorMessage.invalidFirstDay;
        }

        return undefined;
    }

    public static claimDateValidator(value: string | null, firstDay: Date, lastDay: Date): ValidatorMessage | undefined {
        const requiredError = Validator.required(value);
        if (requiredError) {
            return requiredError;
        }

        const current = DateUtils.parse(value!);

        if (DateUtils.isBefore(current, DateUtils.startOfDay(firstDay)) || DateUtils.isAfter(current, DateUtils.startOfDay(DateUtils.addDays(lastDay, 1)))) {
            return ValidatorMessage.invalidClaimDate;
        }
    }

    public static ibanValidator(iban: string | null): ValidatorMessage | undefined {
        const requiredError = Validator.required(iban);
        if (requiredError) {
            return requiredError;
        }
        if (!/^[A-Z]{2}[0-9]{20}$/g.test(iban?.toUpperCase() ?? "")) {
            return ValidatorMessage.invalidIBANNumber;
        }
    }
}
