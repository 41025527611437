import Cookies, { CookieSetOptions } from "universal-cookie";

enum CookieKey {
    // chr
    auth = "chrAuthToken",
    channel = "chrChannel",
    transaction = "chrTransaction",

    // claims
    claimSessionId = "chrClaimSessionId",
    claimsLanguage = "chrClaimsLanguage",

    // uniqa cz
    uniqaCzAuth = "uniqaCzAuthToken",
    uniqaCzTransaction = "uniqaCzTransaction",

    // uniqa sk
    uniqaSkAuth = "uniqaSkAuthToken",
    uniqaSkTransaction = "uniqaSkTransaction",
}

const createCookieField = <T>(key: CookieKey, options: CookieSetOptions = Cookie.defaultOptions()) => {
    const cookies = new Cookies();
    return {
        set: (value: T): void => {
            cookies.set(key, value, options);
        },
        get: (): T | null => {
            return cookies.get(key) ?? null;
        },
        remove: (): void => {
            cookies.remove(key, options);
        },
    };
};

export class Cookie {
    public static defaultOptions(): CookieSetOptions {
        const developmentDomain = process.env.REACT_APP_URL?.includes("cherrisk") ? ".cherrisk.local" : ".uniqatravel.local";
        return {
            domain: process.env.NODE_ENV === "development" ? developmentDomain : process.env.REACT_APP_AUTH_COOKIE_DOMAIN,
            secure: process.env.REACT_APP_AUTH_COOKIE_HTTPS_ONLY === "true",
            path: "/",
        };
    }

    public static readonly authToken = createCookieField<string>(CookieKey.auth);
    public static readonly claimSessionId = createCookieField<string>(CookieKey.claimSessionId);
    public static readonly channel = createCookieField<"CHERRISK_WAPP_DE_2022">(CookieKey.channel);
    public static readonly transaction = createCookieField<TransactionCookie>(CookieKey.transaction);

    public static readonly claimsLanguage = createCookieField<"de" | "en">(CookieKey.claimsLanguage);

    public static readonly uniqaCzAuthToken = createCookieField<string>(CookieKey.uniqaCzAuth);
    public static readonly uniqaCzTransaction = createCookieField<Omit<TransactionCookie, "insuranceType">>(CookieKey.uniqaCzTransaction);

    public static readonly uniqaSkAuthToken = createCookieField<string>(CookieKey.uniqaSkAuth);
    public static readonly uniqaSkTransaction = createCookieField<Omit<TransactionCookie, "insuranceType">>(CookieKey.uniqaSkTransaction);
}

type TransactionCookie = {
    paymentMode: string;
    paymentRecurringEnabled: boolean;
    currency: string;
    coupon?: string;
    price: number;
    variant: string;
    insuranceType: string;
};
