import { gql } from "@apollo/client";
import { ClaimDataFragment } from "./ClaimDataFragment";
import { ClaimTaskDataFragment } from "./ClaimTaskDataFragment";

export const RelatedDataFragment = gql`
    fragment RelatedData on RelatedData {
        accountData {
            firstName
            lastName
            dateOfBirth
            email
            phoneNumber
        }
        insuranceData {
            id
            insuranceType
            created
            firstDay
            lastDay
        }
        modTravelData {
            typeOfCoverage
            duration
            destination
            travelMembers {
                ageCategory
                deleted
            }
        }
        homeData {
            policyNumber
            tariff
            endOfCoverage
            zipCode
            homeInsuranceType
        }
        deHomePackagesData {
            policyNumber
            tariff
            endOfCoverage
            zipCode
            typeOfCoverages
        }
        claimData {
            ...ClaimData
        }
        otherClaimData {
            ...ClaimData
        }
        completedTasks {
            ...ClaimTaskData
        }
        openTasks {
            ...ClaimTaskData
        }
    }
    ${ClaimDataFragment}
    ${ClaimTaskDataFragment}
`;
