import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverCrime = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M28 27H5V28H28V27Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 8.5C18.7091 8.5 20.7091 9.39543 22.1569 10.8431C23.6046 12.2909 24.5 14.2909 24.5 16.5V27.5H8.5V16.5C8.5 14.2909 9.39543 12.2909 10.8431 10.8431C12.2909 9.39543 14.2909 8.5 16.5 8.5Z"
                stroke="currentColor"
            />
            <path d="M7.70711 6.00005L7 6.70715L9.12132 8.82847L9.82843 8.12137L7.70711 6.00005Z" fill="currentColor" />
            <path d="M25.1213 6.00005L25.8284 6.70715L23.7071 8.82847L23 8.12137L25.1213 6.00005Z" fill="currentColor" />
            <path d="M17 3H16V6H17V3Z" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverCrime;
