import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { batchActions } from "redux-batched-actions";
import { AppBar } from "@components/AppBar";
import { ApplicationState } from "@redux/Reducers";
import { useTheme } from "styled-components";
import { Box, Cookie, SharedDialog } from "@monster/shared";
import { LoctoolMessage } from "@monster/loctool";
import { Accordion, AccordionContent, AccordionHeader, AccordionItem, AccordionTriggerWithoutHeader, Button, Dialog, Surface, Text } from "@monster/chr-ui";
import { FileClaimPageHeaderAndCartWrapper, FileClaimPageList, FileClaimPageListItem } from "@components/PageListItems";
import { Cart } from "@pages/CreateClaim/Cart";
import { ClaimCauseSelectionForm } from "@pages/CreateClaim/fileclaim/ClaimCauseSelectionForm";
import { ClaimDescriptionForm } from "@pages/CreateClaim/fileclaim/ClaimDescriptionForm";
import { Page } from "@components/Page";
import { SubmitClaimForm } from "@pages/CreateClaim/fileclaim/SubmitClaimForm";
import { OpenClaimsDialog } from "@pages/CreateClaim/OpenClaimsDialog";
import { AppStateActions, PageSteps } from "@redux/actions/appStateActions";
import { SessionActions } from "@redux/actions/sessionActions";
import { MandatoryAttachmentForm } from "@pages/CreateClaim/fileclaim/MandatoryAttachmentForm";
import { OptionalAttachmentForm } from "@pages/CreateClaim/fileclaim/OptionalAttachmentForm";

export const CreateClaimPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const pageStep = useSelector((state: ApplicationState) => state.appState.currentStep);
    const [showChangeCauseWarning, setShowChangeCauseWarning] = React.useState(false);
    const claim = useSelector((state: ApplicationState) => state.session.relatedData?.claimData);

    return (
        <Page
            appBar={
                <AppBar
                    activeIndex={pageStep}
                    onBackButtonClick={() => {
                        if (pageStep > 1) {
                            dispatch(AppStateActions.setCurrentStep(pageStep - 1));
                        } else {
                            history.goBack();
                        }
                    }}
                    onItemClick={itemIndex => {
                        if (itemIndex === 0) {
                            dispatch(AppStateActions.reset());
                            Cookie.claimSessionId.remove();
                        }
                    }}
                />
            }
        >
            <Box $styleLarge={{ margin: "0 auto", maxWidth: 1024, minHeight: 644 }}>
                <Box $styleLarge={{ maxWidth: 618 }}>
                    <FileClaimPageHeaderAndCartWrapper>
                        <Text as="h1" $variant="h2Desktop" $style={{ margin: 0, padding: "40px 16px 16px" }} $styleLarge={{ paddingTop: 44, paddingBottom: 0 }}>
                            <LoctoolMessage id="page.fileClaim.title" />
                        </Text>

                        <Accordion className="hide-for-large" type="single" collapsible>
                            <AccordionItem value="item-1">
                                <AccordionHeader asChild>
                                    <h2>
                                        <AccordionTriggerWithoutHeader>
                                            <LoctoolMessage id="pages.fileClaim.detailspane.title" />
                                        </AccordionTriggerWithoutHeader>
                                    </h2>
                                </AccordionHeader>
                                <AccordionContent>
                                    <Cart />
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </FileClaimPageHeaderAndCartWrapper>

                    <Box
                        className="show-for-large"
                        $styleLarge={{
                            backgroundColor: theme.color.white,
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            width: `${parseFloat(((500 * 100) / 1280).toFixed(4))}%`,
                            zIndex: 2,
                        }}
                    >
                        <Box
                            $styleLarge={{
                                position: "sticky",
                                top: 64,
                                padding: "0 16px 40px",
                                maxHeight: "calc(100vh - 64px)",
                                maxWidth: 356 + 2 * 16,
                                overflow: "auto",
                            }}
                        >
                            <Cart />
                        </Box>
                    </Box>

                    <FileClaimPageList as="ol">
                        <FileClaimPageListItem
                            id="WhatCause"
                            headingText={<LoctoolMessage id="pages.fileClaim.causes.title" />}
                            isActive={pageStep === PageSteps.ClauseSelection}
                            isFinished={pageStep > PageSteps.ClauseSelection}
                            hideContent={pageStep < PageSteps.ClauseSelection}
                            onEditClick={() => setShowChangeCauseWarning(true)}
                        >
                            <ClaimCauseSelectionForm disabled={pageStep !== PageSteps.ClauseSelection} />

                            <Dialog open={showChangeCauseWarning}>
                                <SharedDialog.Content>
                                    <Box $style={{ position: "fixed", inset: 0, zIndex: theme.zIndex.modal, overflow: "auto", display: "flex", alignItems: "center" }}>
                                        <Box $style={{ maxWidth: 504, margin: "0 auto" }}>
                                            <Surface $variant="white">
                                                <Box $style={{ padding: "0 32px 32px", textAlign: "center", "&&&": { button: { paddingLeft: 8, paddingRight: 8 } } }}>
                                                    <SharedDialog.Title asChild>
                                                        <Text as="h2" $variant="h3Desktop" $style={{ margin: 0, padding: "40px 0 16px" }}>
                                                            <LoctoolMessage id="pages.fileClaim.causeChangeDialog.title" />
                                                        </Text>
                                                    </SharedDialog.Title>

                                                    <Box as="p" $style={{ margin: "8px 0" }}>
                                                        <LoctoolMessage id="pages.fileClaim.causeChangeDialog.question" />
                                                    </Box>

                                                    <Box $style={{ maxWidth: 208, margin: "0 auto", paddingTop: 24 }}>
                                                        <Button.Primary
                                                            btnLabel={<LoctoolMessage id="pages.fileClaim.causeChangeDialog.btn.yes" />}
                                                            isExpanded
                                                            onClick={() => {
                                                                setShowChangeCauseWarning(false);
                                                                dispatch(batchActions([SessionActions.updateClaimInputData(null), AppStateActions.setCurrentStep(PageSteps.ClauseSelection)]));
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box $style={{ maxWidth: 208, margin: "0 auto", paddingTop: 16 }}>
                                                        <Button.Secondary
                                                            btnLabel={<LoctoolMessage id="pages.fileClaim.causeChangeDialog.btn.cancel" />}
                                                            isExpanded
                                                            onClick={() => setShowChangeCauseWarning(false)}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Surface>
                                        </Box>
                                    </Box>
                                </SharedDialog.Content>
                            </Dialog>
                        </FileClaimPageListItem>

                        <FileClaimPageListItem
                            id="ClaimDescription"
                            headingText={<LoctoolMessage id="pages.fileClaim.claimDescription.title" />}
                            isActive={pageStep === PageSteps.ClaimDescription}
                            isFinished={pageStep > PageSteps.ClaimDescription}
                            hideContent={pageStep < PageSteps.ClaimDescription}
                            onEditClick={() => {
                                dispatch(AppStateActions.setCurrentStep(PageSteps.ClaimDescription));
                            }}
                        >
                            <ClaimDescriptionForm disabled={pageStep !== PageSteps.ClaimDescription} />
                        </FileClaimPageListItem>
                        <FileClaimPageListItem
                            id="MandatoryAttachment"
                            headingText={<LoctoolMessage id="pages.fileClaim.mandatoryAttachment.title" />}
                            isActive={pageStep === PageSteps.ClaimMandatoryAttachments}
                            isFinished={pageStep > PageSteps.ClaimMandatoryAttachments}
                            hideContent={pageStep < PageSteps.ClaimMandatoryAttachments}
                            onEditClick={() => {
                                dispatch(AppStateActions.setCurrentStep(PageSteps.ClaimMandatoryAttachments));
                            }}
                        >
                            {claim && <MandatoryAttachmentForm claim={claim} disabled={pageStep !== PageSteps.ClaimMandatoryAttachments} />}
                        </FileClaimPageListItem>

                        <FileClaimPageListItem
                            id="Attachment"
                            headingText={<LoctoolMessage id="pages.fileClaim.attachment.title" />}
                            isActive={pageStep === PageSteps.ClaimOptionalAttachments}
                            isFinished={pageStep > PageSteps.ClaimOptionalAttachments}
                            hideContent={pageStep < PageSteps.ClaimOptionalAttachments}
                            onEditClick={() => {
                                dispatch(AppStateActions.setCurrentStep(PageSteps.ClaimOptionalAttachments));
                            }}
                        >
                            {claim && <OptionalAttachmentForm claim={claim} disabled={pageStep !== PageSteps.ClaimOptionalAttachments} />}
                        </FileClaimPageListItem>

                        <FileClaimPageListItem
                            id="SubmitClaim"
                            headingText={<LoctoolMessage id="pages.fileClaim.submitClaim.title" />}
                            isActive={pageStep === PageSteps.ClaimPrepareSubmit}
                            isFinished={pageStep > PageSteps.ClaimPrepareSubmit}
                            hideContent={pageStep < PageSteps.ClaimPrepareSubmit}
                        >
                            {claim && <SubmitClaimForm claim={claim} />}
                        </FileClaimPageListItem>
                    </FileClaimPageList>
                </Box>

                <OpenClaimsDialog />
            </Box>
        </Page>
    );
};
