import { css } from "styled-components";
import { commonUtilityClassNames } from "@monster/shared";

const cherriskUtilityClassNames = css``;

export const utilityClassNames = css`
    ${commonUtilityClassNames}

    ${cherriskUtilityClassNames}
`;
