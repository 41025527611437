import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverBrokenpipe = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5843 16.5437L16.6848 16.6265L16.9524 16.881C19.3175 19.1559 20.5 21.0289 20.5 22.5C20.5 24.9853 18.4853 27 16 27C13.5147 27 11.5 24.9853 11.5 22.5C11.5 20.9744 12.7717 19.0166 15.3152 16.6265C15.668 16.295 16.2014 16.2674 16.5843 16.5437ZM16 17.3553L15.743 17.5996C13.5586 19.7008 12.5 21.3722 12.5 22.5C12.5 24.433 14.067 26 16 26C17.933 26 19.5 24.433 19.5 22.5C19.5 21.4187 18.5243 19.8316 16.5101 17.8459L16.2635 17.6057L16 17.3553ZM29.5 5C29.7761 5 30 5.22386 30 5.5C30 5.77614 29.7761 6 29.5 6H16.934L15.0377 8.84453C15.007 8.89048 15.0194 8.95256 15.0654 8.9832C15.0818 8.99415 15.1011 9 15.1209 9H19C19.4027 9 19.6289 9.44403 19.4242 9.7654L19.3737 9.83218L16.557 13H29.5C29.7761 13 30 13.2239 30 13.5C30 13.7761 29.7761 14 29.5 14H2.5C2.22386 14 2 13.7761 2 13.5C2 13.2239 2.22386 13 2.5 13H15.219L17.7381 10.1664C17.7748 10.1251 17.7711 10.0619 17.7298 10.0253C17.7115 10.009 17.6878 10 17.6633 10H14C13.6292 10 13.3973 9.61624 13.5434 9.29538L13.584 9.22265L15.731 6H2.5C2.22386 6 2 5.77614 2 5.5C2 5.22386 2.22386 5 2.5 5H29.5Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverBrokenpipe;
