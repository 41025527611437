import React from "react";
import { useTheme } from "styled-components";
import { Box, SharedDialog, SharedDialogProps } from "@monster/shared";
import { Button, Surface, StyledOverlay, Text } from "@monster/chr-ui";
import { LoctoolHTMLMessage, LoctoolMessage } from "@monster/loctool";

type Props = SharedDialogProps;

export const FileClaimViaEmailDialog = (props: Props) => {
    const theme = useTheme();
    return (
        <SharedDialog.Root {...props}>
            <Box as={StyledOverlay} $style={{ display: "grid", placeItems: "flex-start center", overflow: "auto" }} $styleMedium={{ placeItems: "center" }}>
                <SharedDialog.Content asChild aria-describedby={undefined}>
                    <Surface
                        $variant={"white"}
                        $style={{ maxWidth: 764, width: "100%", margin: "64px 0", padding: "56px 16px 40px", "&:focus-visible": { outline: "none" } }}
                        $styleMedium={{ paddingLeft: 80, paddingRight: 80 }}
                    >
                        <Box $style={{ textAlign: "center" }}>
                            <SharedDialog.Title asChild>
                                <Text as={"h2"} $variant={"h3Desktop"} $style={{ margin: 0 }}>
                                    <LoctoolMessage id="components.fileClaimViaEmailDialog.title" />
                                </Text>
                            </SharedDialog.Title>
                            <Box as={"p"} $style={{ margin: "24px 0 32px", a: { color: theme.color.secondary500, textDecoration: "none" } }}>
                                <LoctoolHTMLMessage id="components.fileClaimViaEmailDialog.description" />
                            </Box>
                        </Box>
                        <Box $style={{ backgroundColor: theme.color.highlight300, borderRadius: 16, padding: 16 }}>
                            <LoctoolMessage id="components.fileClaimViaEmailDialog.todo" />
                            <Box
                                as={"ul"}
                                $style={{
                                    margin: 0,
                                    paddingLeft: 24,
                                    listStyleType: "none",
                                    li: {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: 10,
                                            left: -14,
                                            width: 4,
                                            height: 4,
                                            borderRadius: "50%",
                                            backgroundColor: "currentColor",
                                        },
                                    },
                                }}
                            >
                                <LoctoolHTMLMessage id="components.fileClaimViaEmailDialog.todoList" />
                            </Box>
                        </Box>
                        <Box $style={{ maxWidth: 288, margin: "0 auto", paddingTop: 48 }} $styleMedium={{ maxWidth: 208 }}>
                            <SharedDialog.Close asChild>
                                <Button.Primary btnLabel={<LoctoolMessage id="common.close" />} isExpanded />
                            </SharedDialog.Close>
                        </Box>
                    </Surface>
                </SharedDialog.Content>
            </Box>
        </SharedDialog.Root>
    );
};
