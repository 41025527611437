import { Api } from "@api/Api";
import { UploadedClaimDocument } from "@api/graphql/types";
import { Button, Text } from "@monster/chr-ui";
import { LoctoolMessage, LoctoolHTMLMessage } from "@monster/loctool";
import { Box, List } from "@monster/shared";
import { SVGSharedIcon32BasicsArrowBot } from "@monster/shared/dist/svg_assets";
import { ClaimDocument, DocumentItem } from "@components/ClaimDocument";
import { SessionActions } from "@redux/actions/sessionActions";
import { Constants } from "@utils/Constants";
import { Helpers } from "@utils/Helpers";
import React, { useCallback, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import styled from "styled-components";

type Props = {
    onFileDrop: (fileList: FileList) => Promise<void>;
    disabled: boolean;
    attachedFiles: DocumentItem[];
    uploadingDocList: DocumentItem[];
    uploadInProgress: boolean;
    maxFilesCount: number;
};

export const DropZone = ({ onFileDrop, disabled, attachedFiles, uploadingDocList, uploadInProgress, maxFilesCount }: Props) => {
    const renderClaimDocument = (claimDocument: DocumentItem) => {
        return <ClaimDocument key={claimDocument.id} claimDocument={claimDocument} />;
    };
    return (
        <>
            <Box $style={{ padding: 16 }} $styleLarge={{ paddingTop: 0, paddingBottom: 10 }}>
                <Box $style={{ margin: "0 -16px", paddingBottom: 32 }}>
                    <List>{[...attachedFiles, ...uploadingDocList].map(renderClaimDocument)}</List>
                </Box>

                <UploaderWrapper>
                    <FileUploader
                        classes="drop_zone"
                        handleChange={onFileDrop}
                        name="file"
                        maxSize={10}
                        multiple={true}
                        disabled={disabled}
                        onTypeError={() => alert("Error in type")}
                        types={Constants.claimAllowedFileTypes}
                    >
                        <StyledDropZone className="show-for-large" $isHighlighted={true}>
                            <Text $variant="h3Desktop" $style={{ maxWidth: 423, margin: "0 auto" }}>
                                {uploadInProgress ? (
                                    <LoctoolMessage id="pages.fileClaim.attachments.upload.inprogress" />
                                ) : attachedFiles.length >= maxFilesCount ? (
                                    <LoctoolMessage id="pages.fileClaim.attachments.upload.maxfilesreached" />
                                ) : (
                                    <LoctoolHTMLMessage
                                        id="pages.fileClaim.attachments.upload.title"
                                        jsxValues={{
                                            maxFilesCount: <>{maxFilesCount}</>,
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            strong: (node: string) => {
                                                return (<strong>{node}</strong>) as JSX.Element;
                                            },
                                        }}
                                    />
                                )}
                            </Text>
                        </StyledDropZone>
                    </FileUploader>
                </UploaderWrapper>
            </Box>

            <Box $style={{ textAlign: "center" }}>
                <Box
                    as="input"
                    type="file"
                    id="manualFile"
                    multiple
                    accept="image/*"
                    $style={{ display: "none" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.files && onFileDrop(e.target.files);
                    }}
                    disabled={disabled}
                />
                <Box $style={{ position: "relative", display: "inline-block", verticalAlign: "bottom", minWidth: 196 }} $styleLarge={{ minWidth: 200 }}>
                    <Box aria-hidden="true" $style={{ pointerEvents: "none" }}>
                        <Button.Secondary
                            className="hide-for-large"
                            btnLabel={<LoctoolMessage id="pages.fileClaim.attachments.btn.uploadFiles" />}
                            icon={
                                <Box as="span" $style={{ display: "block", transform: "scale(-1)" }}>
                                    <SVGSharedIcon32BasicsArrowBot />
                                </Box>
                            }
                            iconPosition="right"
                            isExpanded
                            disabled={disabled}
                        />

                        <Button.Secondary className="show-for-large" btnLabel={<LoctoolMessage id="pages.fileClaim.attachments.btn.uploadFiles" />} isExpanded disabled={disabled} />
                    </Box>

                    <Box as="label" htmlFor="manualFile" $style={{ display: "block", position: "absolute", inset: 0, cursor: "pointer" }}>
                        <span className="show-for-sr" role="button" tabIndex={0}>
                            <LoctoolMessage id="pages.fileClaim.attachments.btn.uploadFiles" />
                        </span>
                    </Box>

                    <Box aria-atomic="true" aria-live="polite" $style={{ marginTop: 10 }}>
                        <LoctoolMessage id="pages.fileClaim.attachments.uploadCount" values={{ upload: attachedFiles.length, max: maxFilesCount }} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export type OnDropZoneProgressChange = (progress: number) => void;
export type UploadFunction = (file: File, onProgressChange: OnDropZoneProgressChange) => Promise<void>;

export const useDropZone = (uploadFile: UploadFunction, alreadyAttachedFiles: UploadedClaimDocument[], maxFilesCount: number) => {
    const dispatch = useDispatch();
    const [uploadingDocList, setUploadingDocList] = useState<DocumentItem[]>([]);
    const uploadInProgress = uploadingDocList.length > 0;
    const alreadyUploadedDocumentItems: DocumentItem[] = useMemo(() => {
        return alreadyAttachedFiles.map(f => ({
            state: "uploaded",
            progress: 0,
            name: f.file.name?.replace(`_${f.id}`, "") ?? "",
            size: f.file.size,
            fileExt: Helpers.getFileExtension(f.file.name),
            id: f.id,
        }));
    }, [alreadyAttachedFiles]);

    const uploadFileTask = useCallback(
        async (id: string, file: File) => {
            try {
                await uploadFile(file, (progress: number) => {
                    document.dispatchEvent(new CustomEvent<{ id: string; progress: number }>("fileUploadProgressChanged", { detail: { id, progress } }));
                });
            } catch (e) {
                console.warn(e);
                setUploadingDocList(prevDocs => prevDocs.filter(d => d.id !== id));
            }
        },
        [uploadFile]
    );

    const onFileDrop = useCallback(
        async (fileList: FileList) => {
            if (uploadInProgress) {
                return;
            }
            const uploadedFilePromised = [];
            const newItems = [];
            const remainingFiles = maxFilesCount - alreadyUploadedDocumentItems.length;

            for (let i = 0; i < Math.min(fileList.length, remainingFiles); i++) {
                const file = fileList.item(i);
                const id = `uploading_${Date.now()}${Math.floor(Math.random() * 1000)}`;
                const docItem: DocumentItem = {
                    id,
                    name: file!.name,
                    fileExt: Helpers.getFileExtension(file!.name),
                    progress: 0,
                    size: file!.size,
                    state: "uploading",
                };
                newItems.push(docItem);
                uploadedFilePromised.push(uploadFileTask(id, fileList[i]));
            }
            setUploadingDocList(newItems);
            await Promise.allSettled(uploadedFilePromised);
            const result = await Api.getRelatedData();
            dispatch(SessionActions.updateRelatedData(result));
            setUploadingDocList([]);
        },
        [alreadyUploadedDocumentItems, dispatch, maxFilesCount, uploadFileTask, uploadInProgress]
    );

    return { onFileDrop, uploadInProgress, attachedFiles: alreadyAttachedFiles, uploadingDocList, alreadyUploadedDocumentItems };
};

const UploaderWrapper = styled.div`
    > .drop_zone {
        border: none !important;
    }
`;

const StyledDropZone = styled.div<{ $isHighlighted: boolean }>`
    align-items: center;
    border-radius: 16px;
    border: ${({ theme }) => `3px dashed ${theme.color.greyD}`};
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    min-height: 180px;
    padding: 16px;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => `${theme.color.greyD}`};
        border-color: ${({ theme }) => `${theme.color.blueN}`};
    }
`;
