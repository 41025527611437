import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon68X72ProductsHome = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={68} height={72} viewBox="0 0 68 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M33.1619 4.38684L9.16188 15.4638C8.45358 15.7907 8 16.4996 8 17.2797V26C8 41.3241 16.5724 52.5506 33.7171 59.6797V59.6797C34.2177 59.8879 34.7814 59.8839 35.2791 59.6686C51.7597 52.539 60 41.3161 60 26C60 21.9613 60 19.0545 60 17.2797C60 16.4996 59.5464 15.7907 58.8381 15.4638L34.8381 4.38684C34.3063 4.1414 33.6937 4.1414 33.1619 4.38684Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 21.5C33.5783 21.5 33.1555 21.6334 32.8 21.9L26.8 26.4C26.2964 26.7777 26 27.3705 26 28V38C26 38.552 26.2249 39.0534 26.5858 39.4142C26.9466 39.7751 27.448 40 28 40H40C40.552 40 41.0534 39.7751 41.4142 39.4142C41.7751 39.0534 42 38.552 42 38V28C42 27.3705 41.7036 26.7777 41.2 26.4L35.2 21.9C34.8445 21.6334 34.4217 21.5 34 21.5ZM28 28L34 23.5L40 28V38H35V34C35 33.4477 34.5523 33 34 33C33.4477 33 33 33.4477 33 34V38H28V28Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54 19.839L34 10.6083L14.0004 19.8388L14.0027 26.432C14.0799 32.612 15.8187 37.8659 19.2477 42.3394C22.5706 46.6744 27.5685 50.3895 34.4519 53.4636C41.0907 50.3695 45.8958 46.6207 49.0726 42.2312C52.3603 37.6887 54 32.329 54 26.0001V19.839ZM34.469 55.6548C49.0245 49.1289 56 39.3963 56 26.0001V19.839C56 19.0589 55.5464 18.35 54.8381 18.0231L34.8381 8.79236C34.3063 8.54692 33.6937 8.54692 33.1619 8.79236L13.1623 18.0229C12.4538 18.3499 12.0002 19.0592 12.0004 19.8395L12.0027 26.4446C12.1626 39.5942 19.4413 49.1891 34.469 55.6548Z"
                fill="#ECF0FF"
            />
        </SVGBase>
    );
};

export default SvgIcon68X72ProductsHome;
