import { Loctool, Locale } from "@monster/loctool";
import { Cookie } from "@monster/shared";
import de from "./locales/de.json";
import en from "./locales/en.json";

export const initLoctool = () => {
    const cookieValue = Cookie.claimsLanguage.get() ?? "en";
    const defaultLanguage = (["de", "en"].includes(cookieValue) ? cookieValue : "de") as Locale;
    Loctool.instance = new Loctool({ de: Object.assign({}, en, de), en }, defaultLanguage, process.env.REACT_APP_ENVIRONMENT !== "prod");
};
