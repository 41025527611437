import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsAccidentChildren = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16 29.5C23.4558 29.5 29.5 23.4558 29.5 16C29.5 8.54416 23.4558 2.5 16 2.5C8.54416 2.5 2.5 8.54416 2.5 16C2.5 23.4558 8.54416 29.5 16 29.5Z" stroke="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 16C15.8841 16 17.5654 16.8684 18.6654 18.2269C19.083 18.0803 19.5321 18 20 18C22.2091 18 24 19.7909 24 22V22.5C24 22.7761 23.7761 23 23.5 23C23.2239 23 23 22.7761 23 22.5V22C23 20.3431 21.6569 19 20 19C18.3431 19 17 20.3431 17 22V22.5C17 22.7761 16.7761 23 16.5 23C16.2239 23 16 22.7761 16 22.5V22C16 20.6255 16.6933 19.4129 17.7493 18.6928C16.8339 17.6547 15.4934 17 14 17C11.2386 17 9 19.2386 9 22V22.5C9 22.7761 8.77614 23 8.5 23C8.22386 23 8 22.7761 8 22.5V22C8 18.6863 10.6863 16 14 16ZM20 13C21.1046 13 22 13.8954 22 15C22 16.1046 21.1046 17 20 17C18.8954 17 18 16.1046 18 15C18 13.8954 18.8954 13 20 13ZM20 14C19.4477 14 19 14.4477 19 15C19 15.5523 19.4477 16 20 16C20.5523 16 21 15.5523 21 15C21 14.4477 20.5523 14 20 14ZM14 9C15.6569 9 17 10.3431 17 12C17 13.6569 15.6569 15 14 15C12.3431 15 11 13.6569 11 12C11 10.3431 12.3431 9 14 9ZM14 10C12.8954 10 12 10.8954 12 12C12 13.1046 12.8954 14 14 14C15.1046 14 16 13.1046 16 12C16 10.8954 15.1046 10 14 10Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsAccidentChildren;
