import React from "react";
import styled from "styled-components";
import { SwitchThumbProps } from "@radix-ui/react-switch";
import { Switch as SharedSwitch } from "@monster/shared";
import { SVGChrIcon32BasicsOk } from "../svg_assets";
import { SVGSharedIcon32BasicsX } from "@monster/shared/dist/svg_assets";

const StyledRoot = styled(SharedSwitch.Root)`
    background-color: ${({ theme }) => theme.color.greyD};

    &[data-state="checked"] {
        background-color: ${({ theme }) => theme.color.blueN};
    }

    /* NOTE: in Figma there is no disabled Switch in use at the moment. */
    /* &[data-disabled] {
        background-color: ${({ theme }) => theme.color.greyL};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
    } */
`;

const ThumbComponent = (props: SwitchThumbProps) => {
    return (
        <StyledThumb {...props}>
            <StyledThumbUncheckedIcon as={SVGSharedIcon32BasicsX} />

            <StyledThumbCheckedIcon as={SVGChrIcon32BasicsOk} />
        </StyledThumb>
    );
};

const StyledThumbUncheckedIcon = styled(SharedSwitch.ThumbUncheckedIcon)`
    color: ${({ theme }) => theme.color.body.textColor};
`;

const StyledThumbCheckedIcon = styled(SharedSwitch.ThumbCheckedIcon)`
    color: ${({ theme }) => theme.color.body.textColor};
`;

const StyledThumb = styled(SharedSwitch.Thumb)`
    background-color: ${({ theme }) => theme.color.white};

    /* NOTE: in Figma there is no disabled Switch in use at the moment. */
    /* &[data-disabled] {
        ${StyledThumbCheckedIcon},
        ${StyledThumbUncheckedIcon} {
            color: ${({ theme }) => theme.color.blueD};
        }
    } */
`;

export const Switch = {
    Root: StyledRoot,
    Thumb: ThumbComponent,
    ThumbCheckedIcon: StyledThumbCheckedIcon,
    ThumbUncheckedIcon: StyledThumbUncheckedIcon,
};
