import React from "react";
import { useTheme } from "styled-components";
import { Box, Cookie } from "@monster/shared";
import { AppBar } from "@components/AppBar";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppStateActions } from "@redux/actions/appStateActions";
import { LoctoolMessage, LoctoolHTMLMessage } from "@monster/loctool";
import { Button, Text } from "@monster/chr-ui";
import { Page } from "@components/Page";
import { Path } from "@utils/Path";

export const ClaimErrorPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    return (
        <Page
            appBar={
                <AppBar
                    activeIndex={0}
                    onBackButtonClick={() => {
                        history.goBack();
                    }}
                    onLogoClick={() => {
                        Cookie.claimSessionId.remove();
                        dispatch(AppStateActions.reset());
                    }}
                />
            }
        >
            <Box $style={{ maxWidth: 504, margin: "0 auto", width: "100%" }}>
                <Text as="h1" $variant="h2Desktop" $style={{ margin: 0, padding: "40px 16px 16px" }} $styleLarge={{ paddingTop: 36, paddingBottom: 28 }}>
                    <LoctoolMessage id="pages.claimResult.submitError.title" />
                </Text>

                <Box $style={{ backgroundColor: theme.color.white, marginBottom: 80, borderRadius: 16, padding: "40px 24px 32px", textAlign: "center" }}>
                    <Text as="p" $variant="h3Desktop" $style={{ margin: 0, fontWeight: 700 }}>
                        <LoctoolHTMLMessage id="pages.claimResult.submitError.text" />
                    </Text>

                    <Box $style={{ maxWidth: 208, margin: "40px auto 0" }}>
                        <Button.Primary btnLabel={<LoctoolMessage id="pages.claimResult.submitError.btn.tryAgain" />} isExpanded onClick={() => history.push(Path.createClaim)} />
                    </Box>
                </Box>
            </Box>
        </Page>
    );
};
