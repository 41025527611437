import React, { useEffect, useContext, useState, useCallback, useMemo } from "react";
import { ProcessScreenDialog, ProcessScreenDialogContent, ProcessScreen, ProcessAnimatedIcon } from "./ProcessScreen";
import throttle from "lodash/throttle";
import { LoadingContext, usePrevious } from "@monster/shared";

type Props = {
    variant: "accident" | "general" | "home" | "travel";
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
};

const LOADER_SHOW_THRESHOLD = 100; // ms
const LOADER_MIN_VISIBILITY = 1500; // ms

export const PageLoader = ({ variant, title, subTitle }: Props) => {
    const [isVisible, setVisible] = useState(true);
    const context = useContext(LoadingContext);
    const prevLoadingCount = usePrevious(context.loadingCount);

    const hide = useCallback((): void => setVisible(false), []);
    const show = useCallback((): void => setVisible(true), []);
    const throttledHide = useMemo(() => throttle(hide, LOADER_MIN_VISIBILITY, { leading: false }), [hide]);
    const throttledShow = useMemo(() => throttle(show, LOADER_SHOW_THRESHOLD, { leading: false }), [show]);

    useEffect(() => {
        if (prevLoadingCount === 0 && context.loadingCount > 0) {
            throttledHide.cancel();
            throttledShow();
        } else if (typeof prevLoadingCount !== "undefined" && prevLoadingCount > 0 && context.loadingCount === 0) {
            throttledShow.cancel();
            throttledHide();
        }
        // NOTE: we don't want prevLoaderCount as dep
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, throttledHide, throttledShow]);

    return (
        <ProcessScreenDialog open={isVisible} onOpenChange={setVisible}>
            <ProcessScreenDialogContent>
                <ProcessScreen icon={<ProcessAnimatedIcon variant={variant} />} title={title} subTitle={subTitle} />
            </ProcessScreenDialogContent>
        </ProcessScreenDialog>
    );
};
