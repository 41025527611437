import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsArrowRight = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x={10} y={15.5} width={12} height={1} rx={0.5} fill="currentColor" />
            <path d="M18 11.5L22 16L18 20.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsArrowRight;
