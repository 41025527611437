import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsX = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <line x1={20.6} y1={11.4038} x2={11.4076} y2={20.5961} stroke="currentColor" strokeLinecap="round" />
            <line x1={20.5929} y1={20.5962} x2={11.4005} y2={11.4038} stroke="currentColor" strokeLinecap="round" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsX;
