import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverRehab = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M17 11.5L17.5 14.5H20L20.5 17L17.5 17.5V20L15 20.5L14.5 17.4988L12 17.5L11.5 15L14.5 14.4987V12L17 11.5Z" stroke="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1096 4C20.9526 4 25.1255 6.86894 27.0215 11H25.9102C24.0895 7.43832 20.3843 5 16.1096 5C10.0485 5 5.13231 9.90217 5.10966 15.958L6.4068 14.9219C6.62243 14.7494 6.93708 14.7844 7.10958 15C7.28209 15.2156 7.24713 15.5303 7.0315 15.7028L4.92193 17.3904C4.73932 17.5365 4.47985 17.5365 4.29724 17.3904L2.18767 15.7028C1.97204 15.5303 1.93708 15.2156 2.10958 15C2.28209 14.7844 2.59674 14.7494 2.81237 14.9219L4.11 15.959L4.11338 15.695C4.27519 9.20854 9.58413 4 16.1096 4Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9119 28C11.0689 28 6.89602 25.1311 5 21H6.11134C7.93205 24.5617 11.6372 27 15.9119 27C21.973 27 26.8892 22.0978 26.9118 16.042L25.6147 17.0781C25.3991 17.2506 25.0844 17.2156 24.9119 17C24.7394 16.7844 24.7744 16.4697 24.99 16.2972L27.0996 14.6096C27.2822 14.4635 27.5417 14.4635 27.7243 14.6096L29.8338 16.2972C30.0495 16.4697 30.0844 16.7844 29.9119 17C29.7394 17.2156 29.4248 17.2506 29.2091 17.0781L27.9115 16.041L27.9081 16.305C27.7463 22.7915 22.4374 28 15.9119 28Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverRehab;
