import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverExtreme = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M28 17.9999V23.9999C28 25.1045 27.1046 25.9999 26 25.9999H8.54595C7.59178 25.9999 6.71261 25.4826 6.24922 24.6485L4.94171 22.295C3.09472 18.9704 2.95982 14.9603 4.5792 11.5191C5.48123 9.60233 7.16934 8.14915 9.23559 7.68183C15.5804 6.24685 21.2194 6.03129 28 6.99995L27 8.99995L18.5 11.4999L15.7784 14.6104C15.2983 15.1591 15.1548 15.9256 15.404 16.6108L16.5213 19.6834C16.8087 20.4738 17.5598 20.9999 18.4009 20.9999H23.4648C23.7992 20.9999 24.1114 20.8328 24.2969 20.5546L26 17.9999H28Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line x1={4} y1={14.5} x2={16} y2={14.5} stroke="currentColor" />
            <line x1={4} y1={18.5} x2={16} y2={18.5} stroke="currentColor" />
            <path d="M22 10.5L22.8033 11.9459C23.9019 13.9233 24.2975 16.2149 23.9256 18.4463L23.5 21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </SVGBase>
    );
};

export default SvgIcon32CoverExtreme;
