import React, { ClipboardEvent, KeyboardEvent, createRef, useCallback, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { Flex } from "@monster/shared";
import { Text } from "../Text";

type CodeInputProps = {
    id: string;
    values: string[];
    setValues: (value: React.SetStateAction<string[]>) => void;
    autoFocus?: boolean;
    errorMessage?: React.ReactNode;
};

export const CodeInput = ({ values, setValues, autoFocus, id, errorMessage }: CodeInputProps) => {
    const theme = useTheme();
    const inputRefs = useRef(values.map(() => createRef<HTMLInputElement>()));
    const wrapperRef = useRef<HTMLDivElement>(null);

    const focusInput = useCallback(
        (index: number) => {
            let inputRef;
            if (index < 0) {
                inputRef = inputRefs.current[0];
            } else if (index >= inputRefs.current.length) {
                wrapperRef.current?.focus();
                inputRefs.current[values.length - 1].current?.blur();
                return;
            } else {
                inputRef = inputRefs.current[index];
            }
            inputRef.current?.focus();
            inputRef.current?.select();
        },
        [values.length]
    );

    useEffect(() => {
        if (autoFocus) {
            focusInput(0);
        }
    }, [focusInput, autoFocus]);
    return (
        <CodeInputWrapper ref={wrapperRef} $hasError={!!errorMessage}>
            {values.map((value, index) => (
                <StyledCodeInput
                    key={index}
                    ref={inputRefs.current[index]}
                    type="text"
                    inputMode="numeric"
                    maxLength={1}
                    id={`${id}-${index}`}
                    value={value}
                    onFocus={() => {
                        inputRefs.current[index].current?.select();
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={() => {}}
                    onPaste={(event: ClipboardEvent<HTMLInputElement>) => {
                        const pastedText = event.clipboardData.getData("text/plain");
                        const numbersOnly = pastedText.match(/\d/g);

                        if (numbersOnly) {
                            setValues(numbersOnly.splice(0, values.length));
                        }
                    }}
                    onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                        if (event.ctrlKey || event.metaKey) {
                            return;
                        }

                        event.preventDefault();
                        let key = event.keyCode || event.charCode;
                        if (key === 8 || key === 46) {
                            focusInput(index - 1);
                            setValues(currentValues => {
                                return currentValues.map((value, i) => (i === index ? "" : value));
                            });
                            return;
                        }

                        // https://stackoverflow.com/questions/5630918/get-correct-keycode-for-keypadnumpad-keys
                        if (key >= 96 && key <= 105) {
                            // Numpad keys
                            key -= 48;
                        }

                        const char = String.fromCharCode(key).replace(/\D/g, "");
                        if (char !== "") {
                            setValues(currentValues => {
                                return currentValues.map((value, i) => (i === index ? char : value));
                            });
                            focusInput(index + 1);
                        }
                    }}
                />
            ))}

            <Flex.Item $shrink={1} aria-atomic="true" aria-live="assertive">
                {errorMessage && (
                    <Text $variant="captionMobile" $style={{ padding: "16px 16px 8px", textAlign: "center", color: theme.color.redN }}>
                        {errorMessage}
                    </Text>
                )}
            </Flex.Item>
        </CodeInputWrapper>
    );
};

const CodeInputWrapper = styled.div<{ $hasError?: boolean }>`
    display: flex;
    column-gap: 16px;
    justify-content: center;
    flex-wrap: wrap;

    ${({ theme, $hasError }) =>
        $hasError
            ? `
        ${StyledCodeInput} {
            border-color: ${theme.color.redN};
            color: ${theme.color.redN};
        }
    `
            : ""}
`;

const StyledCodeInput = styled.input`
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.greyD};
    caret-color: currentColor;
    display: block;
    font-family: inherit;
    /* NOTE: filter: none; to overdefine :-moz-autofill yellow input backgroundcolor */
    filter: none;

    border-radius: 16px;
    color: ${({ theme }) => theme.color.blueN};
    width: 60px;
    height: 80px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type="number"] {
        -moz-appearance: textfield;
    }

    &:focus {
        border: 1px solid ${({ theme }) => theme.color.blueN};
    }

    &:focus-visible {
        outline: 0;
    }
`;
