import { gql } from "@apollo/client";

export const ClaimCauseFragment = gql`
    fragment ClaimCause on ClaimCause {
        id
        insuranceType
        name
        countryCode
        translationKey
    }
`;
