import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactAliceCarousel, { Props as ReactAliceCarouselProps, EventObject } from "react-alice-carousel";
import styled from "styled-components";

export const throttle = <R, A extends unknown[]>(fn: (...args: A) => R, delay: number): [(...args: A) => R | undefined, () => void] => {
    let wait = false;
    let timeout: undefined | number;
    let cancelled = false;

    return [
        (...args: A) => {
            if (cancelled) return undefined;
            if (wait) return undefined;

            const val = fn(...args);

            wait = true;
            timeout = window.setTimeout(() => {
                wait = false;
            }, delay);

            return val;
        },
        () => {
            cancelled = true;
            clearTimeout(timeout);
        },
    ];
};

export type CarouselEvent = {} & EventObject;

type AliceCarouselComponentProps = {
    className?: string;
    handleSlideChanged?: (event: CarouselEvent) => void;
} & ReactAliceCarouselProps;

export const AliceCarousel: React.FC<AliceCarouselComponentProps> = ({ children, handleSlideChanged, ...props }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [itemsInSlide, setItemsInSlide] = useState(0);
    const [numberOfItems, setNumberOfItems] = useState(0);
    const carouselRef = useRef<HTMLDivElement | null>(null);

    const initializeCarousel = (event: { item?: number; itemsInSlide: number; isPrevSlideDisabled?: boolean; isNextSlideDisabled?: boolean; slide?: number; type?: string }) => {
        const numberOfCarouselItems = carouselRef.current?.querySelectorAll(".alice-carousel__stage-item").length;

        setIsInitialized(true);
        setItemsInSlide(event.itemsInSlide);
        setNumberOfItems(numberOfCarouselItems ? numberOfCarouselItems : 0);
    };

    const resizeCarousel = (event: { item?: number; itemsInSlide: number; isPrevSlideDisabled?: boolean; isNextSlideDisabled?: boolean; slide?: number; type?: string }) => {
        setActiveIndex(activeIndex => {
            const itemsInSlideDifference = itemsInSlide - event.itemsInSlide;

            if (activeIndex > event.itemsInSlide) {
                return activeIndex + itemsInSlideDifference;
            }

            return activeIndex;
        });
        setItemsInSlide(event.itemsInSlide);
    };

    const nextSlide = useCallback(() => {
        setActiveIndex(activeIndex => {
            if (numberOfItems - itemsInSlide === activeIndex) {
                return numberOfItems - itemsInSlide;
            }

            return activeIndex + 1;
        });
    }, [itemsInSlide, numberOfItems]);

    const previousSlide = useCallback(() => {
        setActiveIndex(activeIndex => {
            if (activeIndex > 0) {
                return activeIndex - 1;
            }

            return activeIndex;
        });
    }, []);

    // TODO: Refactor this, right now it works but far from perfect
    const handleScrollAndTouchEvents = useCallback(
        (event: WheelEvent) => {
            const getTouchDirection = () => {
                const isTouchPad = true;

                if (isTouchPad && event.deltaX > 20) {
                    //console.log("touchdirection: right");
                    return "right";
                } else if (isTouchPad && event.deltaX < -20) {
                    //console.log("touchdirection: left");
                    return "left";
                }

                return null;
            };

            const getScrollDirection = () => {
                if (event.shiftKey && event.deltaY > 20) {
                    //console.log("scrolldirection: right");
                    return "right";
                } else if (event.shiftKey && event.deltaY < -20) {
                    //console.log("scrolldirection: left");
                    return "left";
                }

                return null;
            };

            if (getScrollDirection() === "right" || getTouchDirection() === "right") {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                nextSlide();
                return;
            }

            if (getScrollDirection() === "left" || getTouchDirection() === "left") {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                previousSlide();
                return;
            }
        },
        [nextSlide, previousSlide]
    );

    const throttledWheelEvent = React.useMemo(() => throttle(handleScrollAndTouchEvents, 400)[0], [handleScrollAndTouchEvents]);

    useEffect(() => {
        const carouselCurrentRef = carouselRef.current;
        carouselCurrentRef?.addEventListener("wheel", throttledWheelEvent);

        return () => {
            carouselCurrentRef?.removeEventListener("wheel", throttledWheelEvent);
        };
    }, [handleScrollAndTouchEvents, carouselRef, throttledWheelEvent]);

    return (
        <StyledAliceCarouselWrapper ref={carouselRef} $isInitialized={isInitialized} className={props.className || undefined}>
            <ReactAliceCarousel
                activeIndex={activeIndex}
                animationDuration={240}
                keyboardNavigation
                onInitialized={event => initializeCarousel(event)}
                onSlideChanged={event => {
                    setActiveIndex(event.item);
                    if (handleSlideChanged) {
                        handleSlideChanged(event);
                    }
                }}
                onResized={event => resizeCarousel(event)}
                {...props}
            >
                {children}
            </ReactAliceCarousel>
        </StyledAliceCarouselWrapper>
    );
};

const StyledAliceCarouselWrapper = styled.div<{ $isInitialized: boolean }>`
    opacity: ${({ $isInitialized }) => ($isInitialized ? 1 : 0)};
    transition: opacity 120ms;
`;
