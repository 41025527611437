import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsNotify = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16.4923 18.008L16.7483 10.328H15.2443L15.5003 18.008H16.4923ZM15.9963 21.16C16.4763 21.16 16.8763 20.744 16.8763 20.28C16.8763 19.8 16.4763 19.4 15.9963 19.4C15.5163 19.4 15.1163 19.8 15.1163 20.28C15.1163 20.744 15.5163 21.16 15.9963 21.16Z"
                fill="#445591"
            />
            <path
                d="M13.8349 7.75C14.7972 6.08333 17.2028 6.08333 18.1651 7.75L25.0933 19.75C26.0555 21.4167 24.8527 23.5 22.9282 23.5H9.07179C7.14729 23.5 5.94448 21.4167 6.90673 19.75L13.8349 7.75Z"
                stroke="#445591"
            />
        </SVGBase>
    );
};

export default SvgIcon32BasicsNotify;
