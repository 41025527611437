import React, { useCallback, useState } from "react";
import { useTheme } from "styled-components";
import { batchActions } from "redux-batched-actions";
import { Box, hexToRGBA } from "@monster/shared";
import { Button, Checkbox, Surface } from "@monster/chr-ui";
import { LoctoolHTMLMessage, LoctoolMessage } from "@monster/loctool";
import { useDispatch, useSelector } from "react-redux";
import { ClaimData, legalDocumentCategory } from "@api/graphql/types";
import { Helpers } from "@utils/Helpers";
import { Api } from "@api/Api";
import { AppStateActions, PageSteps } from "@redux/actions/appStateActions";
import { useHistory } from "react-router-dom";
import { Path } from "@utils/Path";
import { LegalDocumentSelectors } from "@redux/selectors/LegalDocumentSelectors";
import { SessionActions } from "@redux/actions/sessionActions";

type Props = {
    claim: ClaimData;
};

export const SubmitClaimForm = ({ claim }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const [healthDocAccepted, setHealthDocAccepted] = useState<boolean>(false);
    const [cherriskPrivacyPolicyAccepted, setCherriskPrivacyPolicyAccepted] = useState<boolean>(false);
    const legalPrivacyDoc = useSelector(LegalDocumentSelectors.getDocumentByCategory(legalDocumentCategory.CHERRISK_PRIVACY_POLICY));
    const legalHealthDoc = useSelector(LegalDocumentSelectors.getDocumentByCategory(legalDocumentCategory.HEALTH_DATA));

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            try {
                await Api.submitClaim({ claimId: claim.id });
                const relatedData = await Api.getRelatedData();
                dispatch(batchActions([SessionActions.updateRelatedData(relatedData), AppStateActions.setCurrentStep(PageSteps.ClauseSelection)]));
                history.replace(Path.claim(claim.id));
            } catch (e) {
                history.push(Path.claimError);
                dispatch(batchActions([SessionActions.resetClaimInput(), AppStateActions.setCurrentStep(PageSteps.ClauseSelection)]));
            }
        },
        [claim, dispatch, history]
    );

    return (
        <Surface $variant="white">
            <form onSubmit={onSubmit}>
                <Box as="fieldset" $style={{ ">*+*": { boxShadow: `0px -1px 0px 0px ${theme.color.greyD}` } }}>
                    <Box as="legend" $style={{ padding: 16, fontWeight: 700 }}>
                        <LoctoolMessage id="pages.fileClaim.submitClaim.boxtitle" />
                    </Box>

                    <Box $style={{ padding: 16 }}>
                        <Box $style={{ marginBottom: 28 }}>
                            <Box id="acd8" $style={{ borderRadius: 16, padding: 16, textAlign: "center", backgroundColor: hexToRGBA(theme.color.yellowN, 0.5) }}>
                                <LoctoolMessage id="pages.fileClaim.submitClaim.warning" />
                            </Box>
                        </Box>

                        <Checkbox
                            id="f777"
                            checked={healthDocAccepted}
                            aria-describedby="acd8"
                            onChange={() => {
                                setHealthDocAccepted(!healthDocAccepted);
                            }}
                        >
                            <Box as="span" $style={{ display: "block", paddingTop: 4, paddingLeft: 16, a: { color: theme.color.blueN, textDecoration: "none" } }}>
                                <LoctoolHTMLMessage
                                    id="pages.fileClaim.submitClaim.consent1"
                                    jsxValues={{
                                        doclink: (
                                            <a target={"_blank"} href={Helpers.getLegalDocumentPdfLink(legalHealthDoc)}>
                                                <LoctoolMessage id="pages.fileClaim.submitClaim.consent1.doclink" />
                                            </a>
                                        ),
                                    }}
                                />
                            </Box>
                        </Checkbox>
                    </Box>

                    <Box $style={{ padding: "12px 16px 16px" }}>
                        <Checkbox
                            id="f637"
                            checked={cherriskPrivacyPolicyAccepted}
                            aria-describedby="acd8"
                            onChange={() => {
                                setCherriskPrivacyPolicyAccepted(!cherriskPrivacyPolicyAccepted);
                            }}
                        >
                            <Box as="span" $style={{ display: "block", paddingTop: 4, paddingLeft: 16, a: { color: theme.color.blueN, textDecoration: "none" } }}>
                                <LoctoolHTMLMessage
                                    id="pages.fileClaim.submitClaim.consent2"
                                    jsxValues={{
                                        doclink: (
                                            <a target={"_blank"} href={Helpers.getLegalDocumentPdfLink(legalPrivacyDoc)}>
                                                <LoctoolMessage id="pages.fileClaim.submitClaim.consent2.doclink" />
                                            </a>
                                        ),
                                    }}
                                />
                            </Box>
                        </Checkbox>
                    </Box>

                    <div>
                        <Box $style={{ maxWidth: 208 + 2 * 16, margin: "0 auto", padding: "24px 16px" }}>
                            <Button.Primary
                                type="submit"
                                disabled={!(healthDocAccepted && cherriskPrivacyPolicyAccepted)}
                                btnLabel={<LoctoolMessage id="pages.fileClaim.submitClaim.btn.submit" />}
                                isExpanded
                            />
                        </Box>
                    </div>
                </Box>
            </form>
        </Surface>
    );
};
