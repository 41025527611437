export enum GraphqlErrorMessage {
    illegal_insurance_type = "illegal_insurance_type",
    insurance_not_found = "insurance_not_found",
    unauthenticated = "unauthenticated",
    claim_reporting_not_allowed = "claim_reporting_not_allowed",
    missing_date_time = "missing_date_time",
    iban_checksum_fail = "iban_checksum_fail",
    claim_not_found = "claim_not_found",
    modular_travel_insurance_contract_not_found = "modular_travel_insurance_contract_not_found",
}
