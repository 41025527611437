import { ApolloClient, ApolloQueryResult, ApolloError, QueryOptions, MutationOptions } from "apollo-client";
import { ApolloLink, NextLink, Operation } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError, ErrorResponse } from "apollo-link-error";
import { FetchResult, OperationVariables } from "@apollo/client";
import { Loctool } from "@monster/loctool";
import { Cookie } from "@monster/shared";
import { GraphQLError, DocumentNode } from "graphql";
import { print } from "graphql/language";

const claimHttpLink = createHttpLink({ uri: `${process.env.REACT_APP_API_URL || ""}` });
const cmsHttpLink = createHttpLink({ uri: `${process.env.REACT_APP_CMS_API_URL || ""}` });

/**
 * progress value is a number between 0 and 1
 */
export type OnProgressChangeHandler = (progress: number) => void;

export interface GraphQLFileUploadOptions<V = {}> {
    mutation: DocumentNode;
    variables: V;
    file: File;
    authToken?: string | null;
    onProgressChange?: OnProgressChangeHandler;
}

const errorLink = onError((_: ErrorResponse) => {
    return;
});

const headerLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    const token = Cookie.claimSessionId.get();
    operation.setContext({
        headers: {
            ...operation.getContext().headers,
            "X-GQLOperation": operation.operationName,
            ...(token && operation.getContext().client !== "cms" ? { authorization: token } : {}),
        },
    });

    return forward(operation);
});

const parseErrorMessageFromGraphQLError = (gqlError: string) => {
    const regex = /(\[.*\]\s*)?(.+)/;
    const result = regex.exec(gqlError);
    if (result === null || result.length !== 3 || typeof result[2] === "undefined") {
        return gqlError;
    }
    return result[2];
};

export class GraphQLClientError extends Error {
    code: number;
    intlMessage: string;
    __proto__: GraphQLClientError;

    constructor(code: number, message: string) {
        super(message);
        this.name = this.constructor.name;
        this.code = code;
        this.constructor = GraphQLClientError;
        this.__proto__ = GraphQLClientError.prototype;
        if (typeof Error.captureStackTrace === "function") {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
        this.message = message;
        this.intlMessage = Loctool.instance.formatMessage({ id: `api.errors.${message}`, defaultMessage: Loctool.instance.formatMessage({ id: "api.errors.unknown" }) });
    }
}

class GraphQLClient {
    static isFirstRequest = true;

    static client = new ApolloClient({
        link: errorLink.concat(headerLink).split(operation => operation.getContext().client === "cms", cmsHttpLink, claimHttpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        },
    });

    static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                throw error;
            }
            throw GraphQLClient.handleErrors(error as Error);
        }
    }

    static async query<R, V extends OperationVariables = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response: ApolloQueryResult<R> = await GraphQLClient.client.query<R>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                throw error;
            }
            throw GraphQLClient.handleErrors(error as Error);
        }
    }

    static uploadFile<R, V = {}>(options: GraphQLFileUploadOptions<V>): Promise<R> {
        return new Promise((resolve: (result: R) => void, reject: (error: Error) => void) => {
            const xhr = new XMLHttpRequest();
            const formData = new FormData();
            if (!options.mutation.loc) {
                reject(new Error("options.mutation.loc not found!"));
            }
            // backend accepts mutation through query field
            formData.append("data", JSON.stringify({ query: print(options.mutation), variables: options.variables }));
            formData.append("bin", options.file);

            xhr.onerror = () => {
                reject(new GraphQLClientError(xhr.status, xhr.statusText));
            };

            xhr.ontimeout = () => {
                reject(new GraphQLClientError(xhr.status, xhr.statusText));
            };

            if (options.onProgressChange) {
                xhr.upload.addEventListener(
                    "progress",
                    (event: ProgressEvent<XMLHttpRequestEventTarget>): void => {
                        if (event.loaded && event.total) {
                            options.onProgressChange!(event.loaded / event.total);
                        }
                    },
                    false
                );
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        try {
                            const response: { data: R; errors?: GraphQLError[] } = JSON.parse(xhr.response);
                            if (response.errors) {
                                reject(GraphQLClient.handleErrors(new ApolloError({ graphQLErrors: response.errors })));
                                return;
                            }
                            resolve(response.data);
                        } catch (error) {
                            reject(GraphQLClient.handleErrors(error as Error));
                        }
                    } else {
                        // TODO: handle failed upload
                        reject(new Error("Unknown error occured"));
                    }
                }
            };

            if (!process.env.REACT_APP_FILE_API_URL) {
                reject(new Error("process.env.REACT_APP_FILE_API_URL not set!"));
                return;
            }
            xhr.open("POST", `${process.env.REACT_APP_FILE_API_URL}`, true);

            xhr.setRequestHeader("Accept", "*/*");
            if (options.authToken) {
                xhr.setRequestHeader("Authorization", options.authToken);
            }

            xhr.send(formData);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static getResult<R>(response: ApolloQueryResult<R> | FetchResult<R> | any): R {
        if (response.errors && response.errors.length > 0) {
            throw GraphQLClient.handleErrors(response.errors[0]);
        }

        return response.data;
    }

    private static handleErrors(error: Error): Error {
        if (error instanceof ApolloError && error.graphQLErrors?.length > 0) {
            return new GraphQLClientError(400, error.graphQLErrors[0].message ? parseErrorMessageFromGraphQLError(error.graphQLErrors[0].message) : "unknown");
        } else if (typeof error === "object" && error.message) {
            return new GraphQLClientError(400, parseErrorMessageFromGraphQLError(error.message));
        }
        return error;
    }
}

export default GraphQLClient;
