import { Gql } from "@api/Api";
import { LegalDocumentWL } from "@api/graphql/types";
import { ApplicationState } from "@redux/Reducers";

export class LegalDocumentSelectors {
    public static getDocumentByCategory(category: Gql.legalDocumentCategory) {
        return (state: ApplicationState): LegalDocumentWL | null => {
            const doc = state.legalDocuments.find(ld => ld.category === category);
            if (!doc) {
                console.warn(`Document for category ${category} is missing!`, "Available document categories: ", new Set(state.legalDocuments.map(d => d.category)));
            }
            return doc ?? null;
        };
    }
}
