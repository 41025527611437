import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsMinus = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x={22} y={16.5} width={12} height={1} rx={0.5} transform="rotate(-180 22 16.5)" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsMinus;
