import styled, { css, useTheme } from "styled-components";
import { BoxProps, boxTransientStylePropsCSS } from "@monster/shared";
import { useCallback } from "react";

export type IText = {
    $variant: "h1Mobile" | "h1Desktop" | "h2Mobile" | "h2Desktop" | "h3Mobile" | "h3Desktop" | "bodyMobile" | "bodyDesktop" | "captionMobile" | "captionDesktop";
};

export const useTextVariant = () => {
    const theme = useTheme();

    return useCallback(
        ({ $variant }: IText) => {
            if (theme) {
                return {
                    fontSize: theme.text[$variant].fontSize,
                    lineHeight: theme.text[$variant].lineHeight,
                };
            } else {
                console.error(`TextVariant: theme is ${theme}`);
                return {};
            }
        },
        [theme]
    );
};

export const TextBase = styled.div<IText>`
    ${({ theme, $variant }) => {
        return css({
            fontSize: theme.text[$variant].fontSize,
            lineHeight: theme.text[$variant].lineHeight,
        });
    }}
`;

export const Text = styled(TextBase)<BoxProps>`
    ${boxTransientStylePropsCSS}
`;
