import { gql } from "@apollo/client";

export const UploadedClaimDocumentFragment = gql`
    fragment UploadedClaimDocument on UploadedClaimDocument {
        id
        file {
            url
            name
            size
        }
    }
`;
