import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsAccidentAdult = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_291_4)">
                <path d="M16 29.5C23.4558 29.5 29.5 23.4558 29.5 16C29.5 8.54416 23.4558 2.5 16 2.5C8.54416 2.5 2.5 8.54416 2.5 16C2.5 23.4558 8.54416 29.5 16 29.5Z" stroke="currentColor" />
                <g filter="url(#filter0_d_291_4)">
                    <path
                        d="M16 16C19.3137 16 22 18.6863 22 22V22.5C22 22.7761 21.7761 23 21.5 23C21.2239 23 21 22.7761 21 22.5V22C21 19.2386 18.7614 17 16 17C13.2386 17 11 19.2386 11 22V22.5C11 22.7761 10.7761 23 10.5 23C10.2239 23 10 22.7761 10 22.5V22C10 18.6863 12.6863 16 16 16ZM16 9C17.6569 9 19 10.3431 19 12C19 13.6569 17.6569 15 16 15C14.3431 15 13 13.6569 13 12C13 10.3431 14.3431 9 16 9ZM16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10Z"
                        fill="black"
                    />
                </g>
                <path
                    d="M16 16C19.3137 16 22 18.6863 22 22V22.5C22 22.7761 21.7761 23 21.5 23C21.2239 23 21 22.7761 21 22.5V22C21 19.2386 18.7614 17 16 17C13.2386 17 11 19.2386 11 22V22.5C11 22.7761 10.7761 23 10.5 23C10.2239 23 10 22.7761 10 22.5V22C10 18.6863 12.6863 16 16 16ZM16 9C17.6569 9 19 10.3431 19 12C19 13.6569 17.6569 15 16 15C14.3431 15 13 13.6569 13 12C13 10.3431 14.3431 9 16 9ZM16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <filter id="filter0_d_291_4" x={-14} y={-11} width={60} height={62} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={12} />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.396078 0 0 0 0 0.494118 0 0 0 0 0.839216 0 0 0 0.4 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_291_4" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_291_4" result="shape" />
                </filter>
                <clipPath id="clip0_291_4">
                    <rect width={32} height={32} fill="white" />
                </clipPath>
            </defs>
        </SVGBase>
    );
};

export default SvgIcon32ProductsAccidentAdult;
