import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32NavChat = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="path-1-inside-1_66_1055" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.2079 23.7921C22.6684 22.9377 26 19.5496 26 15.5C26 10.8056 21.5228 7 16 7C10.4772 7 6 10.8056 6 15.5C6 19.6122 9.43552 23.0424 14 23.83V26.7929C14 27.2383 14.5386 27.4614 14.8536 27.1464L18.2079 23.7921Z"
                />
            </mask>
            <path
                d="M18.2079 23.7921L18.0197 22.81L17.718 22.8678L17.5008 23.085L18.2079 23.7921ZM14 23.83H15V22.9877L14.17 22.8445L14 23.83ZM14.8536 27.1464L14.1464 26.4393L14.1464 26.4393L14.8536 27.1464ZM25 15.5C25 18.9536 22.1334 22.022 18.0197 22.81L18.396 24.7743C23.2034 23.8534 27 20.1455 27 15.5H25ZM16 8C21.1291 8 25 11.5041 25 15.5H27C27 10.1071 21.9166 6 16 6V8ZM7 15.5C7 11.5041 10.8709 8 16 8V6C10.0834 6 5 10.1071 5 15.5H7ZM14.17 22.8445C9.95733 22.1177 7 19.0064 7 15.5H5C5 20.2181 8.91371 23.9672 13.83 24.8154L14.17 22.8445ZM15 26.7929V23.83H13V26.7929H15ZM14.1464 26.4393C14.4614 26.1244 15 26.3474 15 26.7929H13C13 28.1292 14.6157 28.7985 15.5607 27.8536L14.1464 26.4393ZM17.5008 23.085L14.1464 26.4393L15.5607 27.8536L18.915 24.4992L17.5008 23.085Z"
                fill="currentColor"
                mask="url(#path-1-inside-1_66_1055)"
            />
        </SVGBase>
    );
};

export default SvgIcon32NavChat;
