import styled, { css, CSSObject } from "styled-components";
import { bp } from "./Breakpoints";

type ICSSReset = {
    $cssReset?: boolean;
};

function sortObjKeysAlphabetically(obj: CSSObject) {
    const ordered: CSSObject = {};
    Object.keys(obj)
        .sort()
        .forEach(key => {
            ordered[key] = obj[key];
        });
    return ordered;
}

// NOTE: General component for local styling. You may use the $style or css prop ( https://styled-components.com/docs/api#css-prop ) instead of inline styles. "To enable support for the css prop you have to use the Babel plugin."
// Warning: When using css prop: every instance creates new className even if the content is the same. Ex.: <><Box css={{ marginBottom: 30 }} /><Box css={{ marginBottom: 30 }} /></> will be <style>.x{margin-bottom:30px;}.y{margin-bottom:30px;}</style>
export type BoxProps = {
    $style?: CSSObject;
    $styleSmall?: CSSObject;
    $styleMedium?: CSSObject;
    $styleLarge?: CSSObject;
    $styleXLarge?: CSSObject;
    $styleXXLarge?: CSSObject;
};

export const boxTransientStylePropsCSS = css<BoxProps>`
    ${({ $style }) => ($style && Object.keys($style).length !== 0 ? sortObjKeysAlphabetically($style) : {})}

    ${({ $styleSmall }) => ($styleSmall && Object.keys($styleSmall).length !== 0 ? ` ${bp.small} { ${css(sortObjKeysAlphabetically($styleSmall)).join("")} } ` : {})}

    ${({ $styleMedium }) => ($styleMedium && Object.keys($styleMedium).length !== 0 ? ` ${bp.medium} { ${css(sortObjKeysAlphabetically($styleMedium)).join("")} } ` : {})}

    ${({ $styleLarge }) => ($styleLarge && Object.keys($styleLarge).length !== 0 ? ` ${bp.large} { ${css(sortObjKeysAlphabetically($styleLarge)).join("")} } ` : {})}

    ${({ $styleXLarge }) => ($styleXLarge && Object.keys($styleXLarge).length !== 0 ? ` ${bp.xlarge} { ${css(sortObjKeysAlphabetically($styleXLarge)).join("")} } ` : {})}

    ${({ $styleXXLarge }) => ($styleXXLarge && Object.keys($styleXXLarge).length !== 0 ? ` ${bp.xxlarge} { ${css(sortObjKeysAlphabetically($styleXXLarge)).join("")} } ` : {})}
`;

export const Box = styled.div`
    ${boxTransientStylePropsCSS}
`;

// Flex
const StyledFlexContainerBase = styled.div`
    display: flex;
`;

const StyledFlexContainer = styled(StyledFlexContainerBase)<BoxProps>`
    ${boxTransientStylePropsCSS}
`;

// $shrinkGap NOTE: if Flex.Container has `gap` CSS property AND we have $shrink number >= 1, please add Flex.Container's gap to $shrinkGap too.
export type IFlexItemShrinkType = {
    $shrink?: "auto" | "shrink" | number;
    $shrinkGap?: number;
};

export const flexItemShrinkVariant = (props: IFlexItemShrinkType) => {
    const { $shrink, $shrinkGap } = props;

    return {
        flex: $shrink === "auto" ? "1 1 0px" : "",
        width:
            typeof $shrink === "number" && $shrink > 1
                ? `${$shrink}px`
                : typeof $shrink === "number"
                ? !!$shrinkGap && $shrink !== 1
                    ? `calc(${parseFloat(($shrink * 100).toFixed(4))}% - ${Math.ceil($shrinkGap - $shrinkGap * $shrink)}px)`
                    : `${parseFloat(($shrink * 100).toFixed(4))}%`
                : "",
    };
};

const StyledFlexItemBase = styled.div<IFlexItemShrinkType>`
    ${props => css(flexItemShrinkVariant(props))}
    min-height: 0;
    min-width: 0;
`;

const StyledFlexItem = styled(StyledFlexItemBase)<BoxProps>`
    ${boxTransientStylePropsCSS}
`;

export const Flex = {
    Container: StyledFlexContainer,
    Item: StyledFlexItem,
};

// List
const listCSSReset = css`
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
`;

export const List = styled.ul.attrs<ICSSReset & { as?: "ol"; forcedAs?: "ol" }>(props => ({ as: props.forcedAs ?? props.as, $cssReset: props.$cssReset ?? true }))<BoxProps & ICSSReset>`
    ${({ $cssReset }) => ($cssReset ? `${listCSSReset}` : "")}

    ${boxTransientStylePropsCSS}
`;

// NOTE: placeholder for :hover { fontWeight } jump
export const FontWeightJumpPlaceholder = styled.span.attrs(() => ({ "aria-hidden": true }))<{ $fontWeight: 700; $textAlign?: "center" | "left" | "right" }>`
    display: block;
    font-weight: ${({ $fontWeight }) => $fontWeight};
    height: 0;
    overflow: hidden;
    text-align: ${({ $textAlign }) => $textAlign ?? ""};
`;

type ContainerProps = {
    $maxWidth?: number;
};

export const Container = styled.div<BoxProps & ContainerProps>`
    width: 100%;
    max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth + 2 * 16 : 1024 + 2 * 16)}px;
    margin-inline: auto;
    padding-inline: 16px;

    ${boxTransientStylePropsCSS}
`;
