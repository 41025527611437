import { gql } from "@apollo/client";
import { OpeningHourIntervalFragment } from "./OpeningHourInterval";

export const OpeningHoursFragment = gql`
    fragment OpeningHours on OpeningHours {
        isActive
        data {
            monday {
                ...OpeningHourInterval
            }
            tuesday {
                ...OpeningHourInterval
            }
            wednesday {
                ...OpeningHourInterval
            }
            thursday {
                ...OpeningHourInterval
            }
            friday {
                ...OpeningHourInterval
            }
            saturday {
                ...OpeningHourInterval
            }
            sunday {
                ...OpeningHourInterval
            }
        }
    }
    ${OpeningHourIntervalFragment}
`;
