function parseRGBHex(rgbHex: string): [r: number, g: number, b: number] {
    const result = /^#(?:([0-9A-Fa-f])([0-9A-Fa-f])([0-9A-Fa-f])|([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2}))$/.exec(rgbHex);
    if (!result) {
        throw new Error(`Invalid RGB hex string: "${rgbHex}"`);
    }

    const [, r1, g1, b1, r2, g2, b2] = result;
    const r = parseInt((r1 ? `${r1}${r1}` : r2) ?? "0", 16);
    const g = parseInt((g1 ? `${g1}${g1}` : g2) ?? "0", 16);
    const b = parseInt((b1 ? `${b1}${b1}` : b2) ?? "0", 16);

    return [r, g, b];
}

export function hexToRGBA(hex: string, opacity = 1): string {
    const [r, g, b] = parseRGBHex(hex);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const imageBasePath = "/images/";

export const scaleFn = (ext: string, scale: number) => (ext.toLowerCase() !== "svg" ? `@${scale}x` : "");

export const iSrc = (filename: string) => {
    return `${imageBasePath}${filename}`;
};

export const iSrcSet = (filename: string) => {
    let ext = filename.split(".").pop();
    if (ext) {
        if (ext.length === filename.length) {
            ext = "";
        }
        const fname = filename.substr(0, filename.length - ext.length - 1);
        return `${imageBasePath}${fname}.${ext} 1x, ${imageBasePath}${fname}${scaleFn(ext, 2)}.${ext} 2x, ${imageBasePath}${fname}${scaleFn(ext, 3)}.${ext} 3x`;
    }

    return undefined;
};

export const imgSource = (filename: string) => {
    return {
        srcSet: iSrcSet(filename),
        src: iSrc(filename),
    };
};
