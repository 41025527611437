import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32MenuDocs = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9.5 8.04719V5C9.5 4.44772 9.94772 4 10.5 4H24.5C25.0523 4 25.5 4.44772 25.5 5V23C25.5 23.5523 25.0523 24 24.5 24H21.5608" stroke="currentColor" />
            <path d="M20 7.5H7C6.44772 7.5 6 7.94772 6 8.5V26.5C6 27.0523 6.44772 27.5 7 27.5H20C20.5523 27.5 21 27.0523 21 26.5V8.5C21 7.94772 20.5523 7.5 20 7.5Z" stroke="currentColor" />
            <path d="M12 10.5H10C9.44772 10.5 9 10.9477 9 11.5V14.5C9 15.0523 9.44772 15.5 10 15.5H12C12.5523 15.5 13 15.0523 13 14.5V11.5C13 10.9477 12.5523 10.5 12 10.5Z" stroke="currentColor" />
            <path d="M9 18.5H18" stroke="currentColor" strokeLinecap="square" />
            <path d="M9 21.5H18" stroke="currentColor" strokeLinecap="square" />
        </SVGBase>
    );
};

export default SvgIcon32MenuDocs;
