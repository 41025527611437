import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { Button, Text, useNotification } from "@monster/chr-ui";
import { LoctoolMessage } from "@monster/loctool";
import { Flex, flexItemShrinkVariant, Box } from "@monster/shared";
import { SVGSharedIcon32BasicsDelete } from "@monster/shared/dist/svg_assets";
import { SessionActions } from "@redux/actions/sessionActions";
import { Helpers } from "@utils/Helpers";
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";

export type DocumentItem = {
    id: string;
    name: string;
    size: number;
    fileExt: string;
    progress: number;
    state: "uploaded" | "uploading" | "error";
};

type Props = {
    claimDocument: DocumentItem;
};

export const ClaimDocument = ({ claimDocument }: Props) => {
    if (claimDocument.state === "uploaded") {
        return <ClaimTaskFile claimDocument={claimDocument} />;
    }
    if (claimDocument.state === "uploading") {
        return <UploadingFile claimDocument={claimDocument} />;
    }
    return null;
};

const ClaimTaskFile = ({ claimDocument }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const showNotification = useNotification();

    const onDelete = useCallback(async () => {
        try {
            await Api.deleteUploadedPhoto({ claimTaskItemId: claimDocument.id });
            const result = await Api.getRelatedData();
            dispatch(SessionActions.updateRelatedData(result));
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                showNotification(error.intlMessage);
            }
            console.warn(error);
        }
    }, [claimDocument.id, dispatch, showNotification]);

    return (
        <Flex.Container as="li" $style={{ columnGap: 16, boxShadow: `inset 0px -1px 0px 0px ${theme.color.greyN}`, padding: 16 }}>
            <Flex.Item $shrink={1 / 2} $style={{ wordBreak: "break-all" }} $styleLarge={{ width: "70%" }}>
                {claimDocument.name}{" "}
                <Text $variant="captionMobile">
                    {Helpers.humanFileSize(claimDocument.size)} · {claimDocument.fileExt}
                </Text>
            </Flex.Item>

            <Flex.Item $shrink={1 / 2} $style={{ textAlign: "right" }} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
                <Button.PrimaryAlt btnLabel={<LoctoolMessage id="common.delete" />} icon={<SVGSharedIcon32BasicsDelete />} size="xsmall" onClick={onDelete} />
            </Flex.Item>
        </Flex.Container>
    );
};

const UploadingFile = ({ claimDocument }: Props) => {
    const theme = useTheme();
    const [progress, setProgress] = useState(claimDocument.progress);

    useEffect(() => {
        const updateProgress = (event: Event) => {
            if ((event as CustomEvent).detail.id === claimDocument.id) {
                setProgress(Math.floor((event as CustomEvent).detail.progress * 100));
            }
        };

        document.addEventListener("fileUploadProgressChanged", updateProgress);
        return () => document.removeEventListener("fileUploadProgressChanged", updateProgress);
    }, [claimDocument.id]);

    return (
        <Flex.Container as="li" $style={{ columnGap: 16, boxShadow: `inset 0px -1px 0px 0px ${theme.color.greyN}`, padding: 16 }}>
            <Flex.Item $shrink={1 / 2} $style={{ wordBreak: "break-all" }} $styleLarge={{ width: "70%" }}>
                {claimDocument.name}{" "}
                <Text $variant="captionMobile">
                    {Helpers.humanFileSize(claimDocument.size)} · {claimDocument.fileExt}
                </Text>
            </Flex.Item>

            <Flex.Item $shrink={1 / 2} $style={{ textAlign: "right" }} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
                <Box $style={{ margin: -8 }}>
                    <progress className="show-for-sr" max={100} value={progress}>
                        {progress}%
                    </progress>

                    <Box $style={{ position: "relative", height: 4, borderRadius: 2, marginTop: 18, backgroundColor: theme.color.greyN }}>
                        <Box
                            $style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                minWidth: 4,
                                borderRadius: "inherit",
                                backgroundColor: theme.color.blueD,
                                transition: "width 120ms cubic-bezier(0.87, 0, 0.13, 1)",
                            }}
                            style={{ width: `${progress}%` }}
                        />
                    </Box>
                </Box>
            </Flex.Item>
        </Flex.Container>
    );
};
