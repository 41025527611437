import React, { useCallback, useMemo } from "react";
import { useTheme } from "styled-components";
import { Box, Flex, hexToRGBA, List } from "@monster/shared";
import { Button, Surface } from "@monster/chr-ui";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { Api } from "@api/Api";
import { AppStateActions, PageSteps } from "@redux/actions/appStateActions";
import { ClaimData } from "@api/graphql/types";
import { Constants } from "@utils/Constants";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { ClaimCauseHelpers } from "@utils/ClaimCauseHelpers";
import { AttachmentList } from "./AttachmentList";
import { UploadFunction, DropZone, OnDropZoneProgressChange, useDropZone } from "@components/Input/DropZone";

type Props = {
    claim: ClaimData;
    disabled: boolean;
};

export const OptionalAttachmentForm = ({ claim, disabled }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const claimInputData = useSelector((state: ApplicationState) => state.session.claimInput);
    const claimCauseType = useMemo(() => ClaimCauseHelpers.getClaimCauseTypeById(claimInputData?.claimCauseId), [claimInputData?.claimCauseId]);
    const alreadyAttachedFiles = useSelector(SessionSelectors.getClaimOptionalUploadedDocumentsByClaimId(claim.id));

    const uploadFile: UploadFunction = useCallback(
        async (file: File, onProgressChange: OnDropZoneProgressChange): Promise<void> => {
            await Api.uploadClaimTaskFile(claim.id, file, progress => onProgressChange(progress));
        },
        [claim.id]
    );

    const { onFileDrop, uploadInProgress, uploadingDocList, alreadyUploadedDocumentItems } = useDropZone(uploadFile, alreadyAttachedFiles, Constants.claimMaxOptionalAttachedFileCount);

    const onSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (uploadInProgress) {
                return;
            }
            dispatch(AppStateActions.setCurrentStep(PageSteps.ClaimPrepareSubmit));
        },
        [dispatch, uploadInProgress]
    );

    if (disabled) {
        return <AttachmentList uploadedDocuments={alreadyAttachedFiles} />;
    }

    return (
        <>
            <Flex.Container
                $style={{
                    columnGap: 16,
                    backgroundColor: theme.color.greyN,
                    margin: "-4px 0 16px",
                    borderRadius: 8,
                    boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyD}`,
                    padding: 16,
                }}
            >
                <Flex.Item $shrink="shrink">
                    <Box
                        $style={{
                            width: 32,
                            height: 32,
                            backgroundImage:
                                'image-set( url("/images/global-emma-avatar-question-32.png") 1x, url("/images/global-emma-avatar-question-32@2x.png") 2x, url("/images/global-emma-avatar-question-32@3x.png") 3x)',
                        }}
                    />
                </Flex.Item>

                <Flex.Item $shrink="auto" $style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <LoctoolMessage id="pages.fileClaim.attachments.note" />
                </Flex.Item>
            </Flex.Container>

            <Box $style={{ paddingBottom: 24 }} $styleLarge={{ paddingBottom: 0 }}>
                <Surface $variant="whiteArrowBottom" $styleLarge={{ boxShadow: theme.shadow.white, "&::after": { content: "normal" } }}>
                    <form onSubmit={onSubmit}>
                        <fieldset>
                            <Box as="legend" $style={{ padding: 16, fontWeight: 700 }}>
                                <LoctoolMessage id="pages.fileClaim.attachments.uploadordrop" />
                            </Box>

                            {ClaimCauseHelpers.isOptionalDocumentNoteVisible(claimCauseType) && (
                                <Box $style={{ boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, padding: 16 }}>
                                    <Box id="a4734" $style={{ borderRadius: 16, padding: 16, backgroundColor: hexToRGBA(theme.color.yellowN, 0.5) }}>
                                        <LoctoolMessage id="pages.fileClaim.attachments.requiredDocumentList.optionalAttachments" />
                                        <List
                                            $style={{
                                                "&&&": { paddingLeft: 24 },
                                                ">li": {
                                                    position: "relative",
                                                    "&::before": { content: '""', position: "absolute", top: 10, left: -14, borderRadius: 2, width: 4, height: 4, backgroundColor: "currentColor" },
                                                },
                                            }}
                                        >
                                            {Loctool.instance
                                                .formatMessage({ id: `pages.fileClaim.attachments.documentList.${claimCauseType}` })
                                                .split("\n")
                                                .map((line, index) => (
                                                    <li key={index}>{line}</li>
                                                ))}
                                        </List>
                                    </Box>
                                </Box>
                            )}

                            <DropZone
                                attachedFiles={alreadyUploadedDocumentItems}
                                onFileDrop={onFileDrop}
                                maxFilesCount={Constants.claimMaxOptionalAttachedFileCount}
                                uploadInProgress={uploadInProgress}
                                uploadingDocList={uploadingDocList}
                                disabled={uploadInProgress || alreadyUploadedDocumentItems.length >= Constants.claimMaxOptionalAttachedFileCount}
                            />

                            <Box $style={{ maxWidth: 288 + 2 * 16, margin: "0 auto", padding: "24px 16px" }} $styleLarge={{ maxWidth: 242 + 2 * 16 }}>
                                <Button.Primary
                                    type="submit"
                                    disabled={uploadInProgress}
                                    btnLabel={<LoctoolMessage id={`pages.fileClaim.attachments.btn.${alreadyAttachedFiles.length === 0 ? "skip" : "continue"}`} />}
                                    isExpanded
                                />
                            </Box>
                        </fieldset>
                    </form>

                    <Surface className="hide-for-large" $variant="whiteArrowBottomShadow" />
                </Surface>
            </Box>
        </>
    );
};
