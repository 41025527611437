import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverCrash = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 4.5V8.5H25.7081L27.3451 14.5H29L29.5 17L27.5 17.503V26C27.5 26.4142 27.3321 26.7892 27.0607 27.0607C26.7892 27.3321 26.4142 27.5 26 27.5H24C23.5858 27.5 23.2108 27.3321 22.9393 27.0607C22.6679 26.7892 22.5 26.4142 22.5 26V24.5H9.5V26C9.5 26.4142 9.33211 26.7892 9.06066 27.0607C8.78921 27.3321 8.41421 27.5 8 27.5H6C5.58579 27.5 5.21079 27.3321 4.93934 27.0607C4.66789 26.7892 4.5 26.4142 4.5 26V17.5H3L2.5 15L4.65491 14.5L6.67851 7.07909C6.88615 6.31773 7.33574 5.67296 7.93071 5.21853C8.52567 4.76409 9.26602 4.5 10.0552 4.5H16.5Z"
                stroke="currentColor"
            />
            <path d="M11 17.5H9C8.17157 17.5 7.5 18.1716 7.5 19C7.5 19.8284 8.17157 20.5 9 20.5H11C11.8284 20.5 12.5 19.8284 12.5 19C12.5 18.1716 11.8284 17.5 11 17.5Z" stroke="currentColor" />
            <path d="M23 17.5H21C20.1716 17.5 19.5 18.1716 19.5 19C19.5 19.8284 20.1716 20.5 21 20.5H23C23.8284 20.5 24.5 19.8284 24.5 19C24.5 18.1716 23.8284 17.5 23 17.5Z" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverCrash;
