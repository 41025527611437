import { createGlobalStyle } from "styled-components";
import { normalize } from "./CherriskNormalize";
import { utilityClassNames } from "./CherriskUtilityClassNames";
import { shameCSS } from "./CherriskShame";
import { AliceCarouselCSS } from "@monster/shared";

export const GlobalStyle = createGlobalStyle`
    /* "Normalized" browsers' default styles */
    ${normalize}

    /* Global themed styles */
    body {
        background-color: ${({ theme }) => theme.color.body.backgroundColor};
        color: ${({ theme }) => theme.color.body.textColor};
        font-family: ${({ theme }) => theme.text.fontFamily};
        font-size: ${({ theme }) => theme.text.bodyMobile.fontSize};
        line-height: ${({ theme }) => theme.text.bodyMobile.lineHeight};
    }

    /* Not CSS-in-JS CSS classes */
    ${utilityClassNames}

    /* https://css-tricks.com/introduction-reduced-motion-media-query/ */
    @media (prefers-reduced-motion: reduce) {
        * {
            animation-duration: 0.01ms !important;
            animation-iteration-count: 1 !important;
            transition-duration: 0.01ms !important;
            scroll-behavior: auto !important;
        }
    }

    ${AliceCarouselCSS}

    /* https://csswizardry.com/2013/04/shame-css/ */
    ${shameCSS}
`;
