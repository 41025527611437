import React from "react";
import { Surface } from "@monster/chr-ui";
import { LoctoolMessage } from "@monster/loctool";
import { Box, List } from "@monster/shared";
import { Helpers } from "@utils/Helpers";
import { UploadedClaimDocument } from "@api/graphql/types";

type Props = {
    uploadedDocuments: UploadedClaimDocument[];
};

export const AttachmentList = ({ uploadedDocuments }: Props) => {
    return (
        <Surface $variant="greyBorder">
            <Box $style={{ padding: 16 }}>
                <List>
                    {uploadedDocuments.length === 0 ? (
                        <LoctoolMessage id="common.noDocumentsUploaded" />
                    ) : (
                        uploadedDocuments.map(uploadedDocument => {
                            return <li key={uploadedDocument.id}>{Helpers.getUploadedClaimDocumentFileName(uploadedDocument)}</li>;
                        })
                    )}
                </List>
            </Box>
        </Surface>
    );
};
