import { gql } from "@apollo/client";

export const FileMutations = Object.freeze({
    uploadClaimTaskFile: gql`
        mutation uploadClaimTaskFile($claimId: String!) {
            uploadClaimTaskFile(claimId: $claimId) {
                id
            }
        }
    `,

    uploadImageForClaimTaskItem: gql`
        mutation uploadImageForClaimTaskItem($claimTaskItemId: String!) {
            uploadImageForClaimTaskItem(claimTaskItemId: $claimTaskItemId) {
                id
            }
        }
    `,
});
