import { LegalDocumentWL } from "@api/graphql/types";
import { LegalDocumentActionTypes } from "@redux/actions/legalDocumentActions";
import { ReduxAction } from "@redux/actions/ReduxAction";

export type LegalDocumentReducerType = LegalDocumentWL[];

const initialState: LegalDocumentReducerType = [];

export default function legalDocumentReducer(state: LegalDocumentReducerType = initialState, action: ReduxAction): LegalDocumentReducerType {
    switch (action.type) {
        case LegalDocumentActionTypes.UPDATE_LEGAL_DOCUMENTS:
            return [...action.payload.legalDocuments];
        default:
            return state;
    }
}
