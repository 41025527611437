import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverNature = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 3C14.5966 3 17.7004 5.10963 19.1415 8.15896C20.2472 7.42651 21.5738 7 23 7C26.866 7 30 10.134 30 14C30 17.7855 26.9952 20.8691 23.2407 20.9959L23 21H22V28.5C22 28.7761 21.7761 29 21.5 29C21.2239 29 21 28.7761 21 28.5V21H16.29L14.862 23.499L18 23.5C18.3826 23.5 18.6127 23.9056 18.4457 24.2274L18.4 24.3L15.4 28.3C15.2343 28.5209 14.9209 28.5657 14.7 28.4C14.5036 28.2527 14.4464 27.9887 14.5525 27.7767L14.6 27.7L17 24.499L14 24.5C13.6456 24.5 13.4133 24.1466 13.5294 23.8301L13.5659 23.7519L15.137 21H11V28.5C11 28.7761 10.7761 29 10.5 29C10.2239 29 10 28.7761 10 28.5L10.0005 20.9856C5.53859 20.727 2 17.0268 2 12.5V12C2 7.02944 6.02944 3 11 3ZM11 4C6.66509 4 3.13546 7.44784 3.00381 11.7508L3 12V12.5C3 16.395 5.97819 19.6213 9.81249 19.969L10.0584 19.9872L10.172 20.0001L15.986 20.0001C15.993 19.9999 16 19.9999 16.0071 20L22.9831 20.0001L23.2069 19.9965C26.4322 19.8876 29 17.2368 29 14C29 10.6863 26.3137 8 23 8C21.8059 8 20.6662 8.34848 19.6938 8.99264C19.1859 9.32908 18.4977 9.13706 18.2374 8.58625C16.9248 5.80896 14.1262 4 11 4Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverNature;
