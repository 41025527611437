import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravel = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M29.5 16C29.5 23.4558 23.4558 29.5 16 29.5C8.54416 29.5 2.5 23.4558 2.5 16C2.5 8.54416 8.54416 2.5 16 2.5C23.4558 2.5 29.5 8.54416 29.5 16Z" stroke="currentColor" />
            <path
                d="M19.5 15C19.5 15.3061 19.3947 15.6935 19.1854 16.1461C18.9788 16.5929 18.6869 17.0695 18.3517 17.5444C17.6813 18.4944 16.8674 19.3972 16.3058 19.9836C16.1352 20.1619 15.8648 20.1619 15.6942 19.9836C15.1326 19.3972 14.3187 18.4944 13.6483 17.5444C13.3131 17.0695 13.0212 16.5929 12.8146 16.1461C12.6053 15.6935 12.5 15.3061 12.5 15C12.5 13.067 14.067 11.5 16 11.5C17.933 11.5 19.5 13.067 19.5 15Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravel;
