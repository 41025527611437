import React, { useEffect, useState } from "react";
import { Box, useCountdown, useLoading } from "@monster/shared";
import { Button, CodeInput, Surface, Text, useNotification } from "@monster/chr-ui";
import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { LoctoolMessage } from "@monster/loctool";
import { useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { SessionActions } from "@redux/actions/sessionActions";
import { batchActions } from "redux-batched-actions";
import { GraphqlErrorMessage } from "@api/CustomTypes";
import { ClaimNotAllowedDialog } from "./ClaimNotAllowedDialog";

const RESEND_TIMER_START_VALUE = 20;

type Props = {
    maskedEmail: string;
    onResendClick: () => Promise<void>;
};

export const EmailCodeConfirmationForm = (props: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [code, setCode] = React.useState(["", "", "", ""]);
    const [errorMessageId, setErrorMessageId] = useState<string | null>(null);
    const { restart, secondsRemaining } = useCountdown();
    const loading = useLoading();
    const showNotification = useNotification();
    const [isClaimAllowed, setIsClaimAllowed] = useState(false);
    const isIncomplete = code.some(value => value.length === 0);

    useEffect(() => {
        setErrorMessageId(null);
    }, [code]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        loading.addLoading();
        try {
            await Api.validateEmail({ code: code.join("") });

            try {
                const relatedData = await Api.getRelatedData();
                const claimCauses = await Api.getClaimCauses(relatedData.insuranceData.id);
                dispatch(batchActions([SessionActions.updateRelatedData(relatedData), SessionActions.updateClaimCauses(claimCauses)]));
                window.scrollTo({ top: 0 });
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    if (([GraphqlErrorMessage.claim_reporting_not_allowed, GraphqlErrorMessage.modular_travel_insurance_contract_not_found] as string[]).includes(error.message)) {
                        setIsClaimAllowed(true);
                    } else {
                        showNotification(error.intlMessage);
                    }
                }
            }
        } catch (error) {
            setErrorMessageId("pages.identification.codeForm.invalidCode");
        }
        loading.removeLoading();
    };

    const onResendClick = async () => {
        try {
            await props.onResendClick();
            restart(RESEND_TIMER_START_VALUE);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                showNotification(error.intlMessage);
            }
        }
    };

    return (
        <>
            <Surface $variant="greyBorder">
                <Box $style={{ padding: 16 }}>{props.maskedEmail}</Box>
            </Surface>

            <Box as="form" onSubmit={handleSubmit} $style={{ marginTop: 16 }}>
                <Surface as="fieldset" $variant="white">
                    <Text as="legend" $variant="h3Mobile" $style={{ padding: "40px 16px 16px", fontWeight: 700, textAlign: "center" }}>
                        {<LoctoolMessage id="pages.identification.codeForm.title" />}
                    </Text>

                    <Box $style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <CodeInput id="d230" values={code} setValues={setCode} errorMessage={errorMessageId ? <LoctoolMessage id={errorMessageId} /> : null} autoFocus />
                    </Box>

                    <Box $style={{ maxWidth: 242 + 2 * 16, margin: "0 auto", padding: "40px 16px 32px" }}>
                        <Button.Primary type="submit" btnLabel={<LoctoolMessage id="pages.identification.codeForm.btn.continue" />} isExpanded disabled={isIncomplete} />

                        <Box $style={{ marginTop: 16, margin: "0 auto" }}>
                            {secondsRemaining > 0 ? (
                                <Text $variant="bodyMobile" $style={{ padding: "12px 0", backgroundColor: theme.color.white, display: "inline-block", position: "relative", color: theme.color.blueN }}>
                                    <LoctoolMessage id="pages.identification.codeForm.resendIn" values={{ seconds: secondsRemaining }} />
                                </Text>
                            ) : (
                                <Button.Text btnLabel={<LoctoolMessage id="pages.identification.codeForm.btn.resend" />} isExpanded onClick={onResendClick} />
                            )}
                        </Box>
                    </Box>
                </Surface>
            </Box>
            <ClaimNotAllowedDialog open={isClaimAllowed} />
        </>
    );
};
