import React from "react";
import { SVGProps } from "react";

export type SVGRProps = {
    title?: string;
    titleId?: string;
};

type Props = React.PropsWithChildren<SVGProps<SVGSVGElement> & SVGRProps>;

export const SVGBase = ({ title, titleId, children, ...props }: Props) => {
    const focusable = title || props["aria-labelledby"] ? undefined : false;
    const ariaHidden = title || props["aria-labelledby"] ? undefined : true;
    return (
        <svg {...props} focusable={focusable} aria-hidden={ariaHidden} aria-labelledby={props["aria-labelledby"]}>
            {title ? <title id={titleId}>{title}</title> : null}
            {children}
        </svg>
    );
};
