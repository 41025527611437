import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon68X72ProductsTravelShadow = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={68} height={70} viewBox="0 0 68 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_89_9)">
                <path d="M34 58C48.3594 58 60 46.3594 60 32C60 17.6406 48.3594 6 34 6C19.6406 6 8 17.6406 8 32C8 46.3594 19.6406 58 34 58Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_89_9" x={0} y={2} width={68} height={68} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={4} />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.92549 0 0 0 0 0.941176 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_89_9" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_9" result="shape" />
                </filter>
            </defs>
        </SVGBase>
    );
};

export default SvgIcon68X72ProductsTravelShadow;
