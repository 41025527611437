import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverCommunication = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 2C18.2091 2 20 3.79086 20 6V12H26C28.2091 12 30 13.7909 30 16V26C30 28.2091 28.2091 30 26 30H16C13.7909 30 12 28.2091 12 26V20H6C3.79086 20 2 18.2091 2 16V6C2 3.79086 3.79086 2 6 2H16ZM26 13H16C14.4023 13 13.0963 14.2489 13.0051 15.8237L13 16V26C13 27.5977 14.2489 28.9037 15.8237 28.9949L16 29H26C27.5977 29 28.9037 27.7511 28.9949 26.1763L29 26V16C29 14.4023 27.7511 13.0963 26.1763 13.0051L26 13ZM21.4472 17.2236C21.602 17.301 21.7334 17.4169 21.8293 17.5593L21.8944 17.6708L25.4472 24.7764C25.5707 25.0234 25.4706 25.3237 25.2236 25.4472C25.0041 25.557 24.7424 25.4901 24.6002 25.3004L24.5528 25.2236L23.4399 22.9998H18.5589L17.4472 25.2236C17.3374 25.4432 17.0879 25.5466 16.8604 25.4802L16.7764 25.4472C16.5568 25.3374 16.4534 25.0879 16.5198 24.8604L16.5528 24.7764L20.1056 17.6708C20.3526 17.1768 20.9532 16.9766 21.4472 17.2236ZM21 18.119L19.0589 21.9998H22.9399L21 18.119ZM15 7C15.2761 7 15.5 7.22386 15.5 7.5C15.5 7.77614 15.2761 8 15 8L13.9431 8.00085C13.7126 10.0028 12.8008 11.6518 11.5766 12.7878C12.0276 13.0591 12.5029 13.2572 12.9833 13.3746C13.7162 12.532 14.796 12 16 12H19V6C19 4.40232 17.7511 3.09634 16.1763 3.00509L16 3H6C4.40232 3 3.09634 4.24892 3.00509 5.82373L3 6V16C3 17.5977 4.24892 18.9037 5.82373 18.9949L6 19H12V16C12 15.372 12.1447 14.7778 12.4026 14.249C11.8309 14.0713 11.2724 13.8 10.7494 13.4455C9.80486 14.0862 8.7446 14.4547 7.69734 14.4961L7.5 14.5V13.5C8.31509 13.5 9.15453 13.2502 9.92454 12.7885C8.9098 11.8474 8.11009 10.5547 7.73422 9.00094L8.76812 9.00076C9.10292 10.2003 9.72696 11.205 10.5012 11.9584C10.5826 12.0376 10.6656 12.114 10.7501 12.1876C11.8534 11.2267 12.7012 9.78306 12.9353 8.00106L7 8C6.72386 8 6.5 7.77614 6.5 7.5C6.5 7.22386 6.72386 7 7 7H10.5V6.5C10.5 6.22386 10.7239 6 11 6C11.2761 6 11.5 6.22386 11.5 6.5V7H15Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverCommunication;
