// NOTE: "dummy" Button for "selector" radio inputs.
import React from "react";
import { ButtonButtonProps } from "./ButtonTypes";
import { ButtonContent } from "./ButtonContent";
import { StyledIconOnlySelectorButton, StyledIconOnlySelectorInput } from "./ButtonIconOnly.styled";

const SelectorButton = (props: Omit<ButtonButtonProps, "btnLabel">) => {
    return (
        <StyledIconOnlySelectorButton forwardedAs="span" {...props} btnLabel="" aria-hidden="true">
            <ButtonContent {...props} btnLabel="" />
        </StyledIconOnlySelectorButton>
    );
};

export const Selector = {
    Button: SelectorButton,
    Input: StyledIconOnlySelectorInput,
};
