import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsHomeFlat = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.2 7.5H23.8C24.2994 7.5 24.5 7.80417 24.5 7.95455V27.0455C24.5 27.1958 24.2994 27.5 23.8 27.5H8.2C7.70062 27.5 7.5 27.1958 7.5 27.0455V7.95455C7.5 7.80417 7.70062 7.5 8.2 7.5Z"
                stroke="currentColor"
            />
            <rect x={10.5} y={4.5} width={8} height={3} rx={0.5} stroke="currentColor" />
            <rect x={10.5} y={10.5} width={11} height={3} rx={0.5} stroke="currentColor" />
            <rect x={10.5} y={15.5} width={11} height={3} rx={0.5} stroke="currentColor" />
            <rect x={13.5} y={21.5} width={5} height={6} rx={0.5} stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32ProductsHomeFlat;
