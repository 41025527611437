import GraphQLClient, { OnProgressChangeHandler } from "./graphql/GraphQLClient";
import * as Gql from "./graphql/types";
import * as CmsGql from "./graphql/cmsTypes";
import { Queries } from "./graphql/claim/Queries";
import { Mutations } from "./graphql/claim/Mutations";
import { FileMutations } from "./graphql/claimFile/FileMutations";
import { Cookie } from "@monster/shared";
import { CmsQueries } from "./graphql/cms/CmsQueries";

export class Api {
    public static async getAppData(countryCode: Gql.CountryCode): Promise<Gql.getAppData> {
        return GraphQLClient.query<Gql.getAppData, Gql.getAppDataVariables>({ query: Queries.getAppData, variables: { countryCode } });
    }

    public static async getRelatedData(): Promise<Gql.RelatedData> {
        const result = await GraphQLClient.query<Gql.getRelatedData>({ query: Queries.getRelatedData });
        return result.getRelatedData;
    }

    public static async getClaimCauses(insuranceId: string): Promise<Gql.ClaimCause[]> {
        const result = await GraphQLClient.query<Gql.getClaimCauses, Gql.getClaimCausesVariables>({ query: Queries.getClaimCauses, variables: { insuranceId } });
        return result.claimCauses;
    }

    public static async sendValidationEmail(variables: Gql.sendValidationEmailVariables): Promise<Gql.EmailValidationResult> {
        const result = await GraphQLClient.mutate<Gql.sendValidationEmail, Gql.sendValidationEmailVariables>({ mutation: Mutations.sendValidationEmail, variables });
        return result.sendValidationEmail;
    }

    public static async sendValidationEmailByClaimId(claimId: string): Promise<Gql.EmailValidationResult> {
        const result = await GraphQLClient.mutate<Gql.sendValidationEmailByClaimId, Gql.sendValidationEmailByClaimIdVariables>({
            mutation: Mutations.sendValidationEmailByClaimId,
            variables: { claimId },
        });
        return result.sendValidateEmailByClaimId;
    }

    public static async validateEmail(variables: Gql.validateEmailVariables): Promise<Gql.validateEmail_validateEmail> {
        const result = await GraphQLClient.mutate<Gql.validateEmail, Gql.validateEmailVariables>({ mutation: Mutations.validateEmail, variables });
        return result.validateEmail;
    }

    public static async createClaim(variables: Gql.createClaimVariables): Promise<Gql.createClaim_createClaim> {
        const result = await GraphQLClient.mutate<Gql.createClaim, Gql.createClaimVariables>({ mutation: Mutations.createClaim, variables });
        return result.createClaim;
    }

    public static async updateClaim(variables: Gql.updateClaimVariables): Promise<Gql.updateClaim_updateClaim> {
        const result = await GraphQLClient.mutate<Gql.updateClaim, Gql.updateClaimVariables>({ mutation: Mutations.updateClaim, variables });
        return result.updateClaim;
    }

    public static async submitClaim(variables: Gql.submitClaimVariables): Promise<Gql.submitClaim_submitClaim> {
        const result = await GraphQLClient.mutate<Gql.submitClaim, Gql.submitClaimVariables>({ mutation: Mutations.submitClaim, variables });
        return result.submitClaim;
    }

    public static async uploadClaimTaskFile(claimId: string, file: File, onProgressChange: OnProgressChangeHandler): Promise<void> {
        await GraphQLClient.uploadFile({
            mutation: FileMutations.uploadClaimTaskFile,
            variables: { claimId },
            file,
            onProgressChange,
            authToken: Cookie.claimSessionId.get(),
        });
    }

    public static async uploadImageForClaimTaskItem(claimTaskItemId: string, file: File, onProgressChange: OnProgressChangeHandler): Promise<void> {
        await GraphQLClient.uploadFile({
            mutation: FileMutations.uploadImageForClaimTaskItem,
            variables: { claimTaskItemId },
            file,
            onProgressChange,
            authToken: Cookie.claimSessionId.get(),
        });
    }

    public static async deleteUploadedPhoto(input: Gql.deleteUploadedPhotoVariables): Promise<Gql.VoidType> {
        const result = await GraphQLClient.mutate<Gql.deleteUploadedPhoto, Gql.deleteUploadedPhotoVariables>({
            mutation: Mutations.deleteUploadedPhoto,
            variables: input,
        });
        return result.deleteUploadedPhoto;
    }

    public static async uploadAnswerForClaimTaskItem(input: Gql.uploadAnswerForClaimTaskItemVariables): Promise<void> {
        await GraphQLClient.mutate<Gql.uploadAnswerForClaimTaskItem, Gql.uploadAnswerForClaimTaskItemVariables>({
            mutation: Mutations.uploadAnswerForClaimTaskItem,
            variables: input,
        });
    }

    public static async submitClaimTask(input: Gql.submitClaimTaskVariables): Promise<void> {
        await GraphQLClient.mutate<Gql.submitClaimTask, Gql.submitClaimTaskVariables>({
            mutation: Mutations.submitClaimTask,
            variables: input,
        });
    }

    public static async getOpeningHours(countryCode: CmsGql.CountryCode): Promise<CmsGql.openingHours> {
        return GraphQLClient.query<CmsGql.openingHours, CmsGql.openingHoursVariables>({
            query: CmsQueries.openingHours,
            variables: { countryCode },
            context: { client: "cms" },
        });
    }

    public static async updateActiveClaim(claimId: string): Promise<void> {
        await GraphQLClient.mutate<Gql.updateActiveClaim, Gql.updateActiveClaimVariables>({
            mutation: Mutations.updateActiveClaim,
            variables: { claimId },
        });
    }
}

export { Gql, CmsGql };
