import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32MenuClaims = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16.3692 5.01733C16.4712 4.98962 16.5821 4.99437 16.687 5.03717C16.7913 5.07926 16.8752 5.15341 16.93 5.24502C16.9844 5.33617 17.0099 5.44463 16.9973 5.55637L16.0597 14.0015H24.5013C24.5966 14.0015 24.6879 14.0285 24.7658 14.077C24.8433 14.1252 24.9076 14.1948 24.9495 14.28C24.9917 14.3658 25.0077 14.46 24.9987 14.5516C24.9897 14.6428 24.9557 14.7315 24.898 14.8067L14.8979 27.8068C14.8132 27.917 14.6887 27.9838 14.5567 27.9985C14.2645 27.9178 14.1368 27.8656 14.0726 27.7581C14.0181 27.6669 13.9927 27.5585 14.0052 27.4467L14.9429 19.0015H6.50127C6.40591 19.0015 6.31466 18.9745 6.2367 18.926C6.15872 18.8775 6.09409 18.8074 6.05153 18.721C6.00938 18.6357 5.99343 18.5422 6.00243 18.4512L16.1036 5.19535C16.1721 5.10636 16.2661 5.04533 16.3692 5.01733Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32MenuClaims;
