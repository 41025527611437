import React from "react";
import styled, { css } from "styled-components";
import {
    Accordion as SharedAccordion,
    AccordionItem as SharedAccordionItem,
    AccordionHeader as SharedAccordionHeader,
    AccordionTrigger as SharedAccordionTrigger,
    AccordionTriggerWithoutHeader as SharedAccordionTriggerWithoutHeader,
    AccordionContent as SharedAccordionContent,
} from "@monster/shared";
import { SVGChrIcon32BasicsArrowOpen } from "../svg_assets";

export const Accordion = SharedAccordion;

export const AccordionItem = styled(SharedAccordionItem)`
    & + & {
        box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.color.greyN};
    }
`;

const accordionTriggerCSS = css`
    align-items: center;
    column-gap: 16px;
    padding: 12px 16px;
    font-weight: 700;
    font-size: ${({ theme }) => theme.text.bodyMobile.fontSize};
    line-height: ${({ theme }) => theme.text.bodyMobile.lineHeight};
`;

export const AccordionHeader = SharedAccordionHeader;

export const AccordionTrigger = styled(SharedAccordionTrigger).attrs(({ iconElement }) => ({ iconElement: iconElement ?? <SVGChrIcon32BasicsArrowOpen /> }))`
    ${accordionTriggerCSS}
`;

export const AccordionTriggerWithoutHeader = styled(SharedAccordionTriggerWithoutHeader).attrs(({ iconElement }) => ({ iconElement: iconElement ?? <SVGChrIcon32BasicsArrowOpen /> }))`
    ${accordionTriggerCSS}
`;

export const AccordionContent = styled(SharedAccordionContent)`
    box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.color.greyD};
`;
