import styled, { css } from "styled-components";
import { StyledButton } from "./Button.styled";

const cherriskCommonButtonCSS = css`
    border-radius: 36px;
`;

const cherriskIconOnlyButtonCSS = css`
    ${cherriskCommonButtonCSS}

    background-color: ${({ theme }) => theme.color.white};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
    color: ${({ theme }) => theme.color.blueD};

    @media (hover: hover) {
        &:hover {
            box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.greyD};
        }
    }

    &.disabled,
    &:disabled {
        background-color: ${({ theme }) => theme.color.greyN};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
        color: ${({ theme }) => theme.color.blueL};
        cursor: not-allowed;
    }
`;

const cherriskIconOnlyButtonIsInverseCSS = css`
    ${cherriskCommonButtonCSS}

    background-color: ${({ theme }) => theme.color.blueN};
    box-shadow: none;
    color: ${({ theme }) => theme.color.white};

    /* NOTE: missing from Figma */
    @media (hover: hover) {
        &:hover {
            box-shadow: none;
        }
    }

    &.disabled,
    &:disabled {
        background-color: ${({ theme }) => theme.color.greyN};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
        color: ${({ theme }) => theme.color.blueL};
        cursor: not-allowed;
    }
`;
export const StyledIconOnlyButton = styled(StyledButton)`
    ${({ isInverse }) => (isInverse ? cherriskIconOnlyButtonIsInverseCSS : cherriskIconOnlyButtonCSS)}
`;

export const StyledIconOnlySelectorButton = styled(StyledButton)`
    ${cherriskIconOnlyButtonCSS}

    cursor: pointer;
    user-select: none;
`;

export const StyledIconOnlySelectorInput = styled.input`
    &:checked {
        & + label {
            ${StyledIconOnlySelectorButton} {
                ${cherriskIconOnlyButtonIsInverseCSS}
            }
        }
    }

    &.disabled,
    &:disabled {
        & + label {
            ${StyledIconOnlySelectorButton} {
                background-color: ${({ theme }) => theme.color.greyN};
                box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
                color: ${({ theme }) => theme.color.blueL};
                cursor: not-allowed;
            }
        }
    }
`;
