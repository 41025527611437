import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravelIndividual = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="mask0_1129_3674"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={7}
                y={18}
                width={18}
                height={21}
            >
                <rect x={7.5} y={18.5} width={17} height={20} rx={8.5} stroke="currentColor" />
            </mask>
            <g mask="url(#mask0_1129_3674)">
                <rect width={18} height={9} transform="matrix(1 0 0 -1 7 27)" fill="currentColor" />
            </g>
            <rect x={10.5} y={5.5} width={11} height={13} rx={5.5} stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravelIndividual;
