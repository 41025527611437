import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon72X71ProductsAccident = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={72} height={71} viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M47 6C56.3888 6 64 13.6112 64 23C64 23.4317 63.9839 23.8596 63.9523 24.2832L63.9515 24.2965C63.2234 34.086 54.3048 45.3591 37.1955 58.116C36.4862 58.6448 35.5138 58.6448 34.8045 58.116L34.2372 57.6913C17.4981 45.1088 8.7685 33.9772 8.04854 24.2965L8.0477 24.2832C8.01609 23.8596 8 23.4317 8 23C8 13.6112 15.6112 6 25 6C29.1952 6 33.0354 7.5196 36.0004 10.0384C38.9654 7.51926 42.8053 6 47 6Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.9657 23.8812L57.9672 23.8594L57.9689 23.8367C57.9895 23.5616 58 23.2825 58 23C58 16.9249 53.0751 12 47 12C44.2808 12 41.8061 12.9789 39.8852 14.6109L37.2955 16.8112C36.5487 17.4456 35.4524 17.4456 34.7056 16.8113L32.1157 14.6111C30.1948 12.9792 27.7196 12 25 12C18.9249 12 14 16.9249 14 23C14 23.2825 14.0105 23.5615 14.0311 23.8366L14.0328 23.8599L14.0343 23.8814C14.282 27.1028 15.9204 31.1834 19.857 36.2032C23.3777 40.6926 28.5052 45.6354 35.3889 51.0153C35.7479 51.2958 36.2521 51.2959 36.611 51.0153C43.4064 45.7045 48.4927 40.8179 52.0109 36.3708C56.0414 31.2763 57.7151 27.14 57.9657 23.8812ZM59.9609 24.0209C59.6723 27.8234 57.7535 32.3356 53.5794 37.6117C49.7166 42.4943 44.0949 47.8109 36.6041 53.5494C36.2479 53.8222 35.752 53.8222 35.3958 53.5493C27.813 47.7404 22.148 42.3655 18.2832 37.4373C14.2033 32.2347 12.3246 27.7806 12.0391 24.0212L12.0383 24.0088L12.0366 23.9855C12.0124 23.6609 12 23.3322 12 23C12 15.8203 17.8203 10 25 10C28.2113 10 31.1416 11.1592 33.4106 13.0869L36.0005 15.287L38.5903 13.0867C40.8593 11.1589 43.7891 10 47 10C54.1797 10 60 15.8203 60 23C60 23.3323 59.9876 23.6609 59.9634 23.9855L59.9617 24.0084L59.9609 24.0209Z"
                fill="#ECF0FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38 22C39.1046 22 40 22.8954 40 24V27H43C44.1046 27 45 27.8954 45 29V33C45 34.1046 44.1046 35 43 35H40V38C40 39.1046 39.1046 40 38 40H34C32.8954 40 32 39.1046 32 38V35H29C27.8954 35 27 34.1046 27 33V29C27 27.8954 27.8954 27 29 27H32V24C32 22.8954 32.8954 22 34 22H38ZM34 24H38V28C38 28.5523 38.4477 29 39 29H43V33H39C38.4477 33 38 33.4477 38 34V38H34V34C34 33.4477 33.5523 33 33 33H29V29H33C33.5523 29 34 28.5523 34 28V24Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon72X71ProductsAccident;
