type OpeningHourInterval = {
    from: string | null;
    to: string | null;
};

type OpeningHoursData = {
    monday: OpeningHourInterval;
    tuesday: OpeningHourInterval;
    wednesday: OpeningHourInterval;
    thursday: OpeningHourInterval;
    friday: OpeningHourInterval;
    saturday: OpeningHourInterval;
    sunday: OpeningHourInterval;
};

type Day = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

type OpeningHours = {
    day: Day | "weekends";
    dayTo?: Day;
    from: string | null; // null means Closed
    to: string | null;
};

export const OpeningHoursHelpers = {
    get: (data: OpeningHoursData) => {
        const days: Day[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        const result = days.reduce((prev: OpeningHours[], day): OpeningHours[] => {
            const prevDay: OpeningHours | undefined = prev[prev.length - 1];
            if (typeof prevDay !== "undefined" && data[day].from === prevDay.from && data[day].to === prevDay.to) {
                prevDay.dayTo = day;
            } else {
                prev.push({ day, from: data[day].from, to: data[day].to });
            }
            return prev;
        }, []);

        const lastItem = result[result.length - 1];
        if (lastItem.day === "saturday" && lastItem.dayTo === "sunday") {
            lastItem.day = "weekends";
            lastItem.dayTo = undefined;
        }

        return result;
    },
};
