/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ComponentType, useCallback, useContext, useMemo, useState } from "react";

export type LoadingProp = {
    addLoading: () => void;
    removeLoading: () => void;
    loadingCount: number;
};

export const LoadingContext = React.createContext<LoadingProp>({
    addLoading: () => {},
    removeLoading: () => {},
    loadingCount: 1,
});

export const LoadingProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [loadingCount, setLoadingCount] = useState(1);
    const addLoading = useCallback(() => setLoadingCount(prev => prev + 1), []);
    const removeLoading = useCallback(() => setLoadingCount(prev => (prev || 1) - 1), []);
    const contextValue = useMemo(() => {
        return { addLoading, removeLoading, loadingCount };
    }, [addLoading, loadingCount, removeLoading]);
    return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => {
    return useContext(LoadingContext);
};

export function withLoading<P extends LoadingProp>(WrappedComponent: ComponentType<P>) {
    return class WithNotificationClass extends React.Component<Omit<P, keyof LoadingProp>> {
        public render() {
            return (
                <LoadingContext.Consumer>
                    {({ addLoading, removeLoading, loadingCount }) => <WrappedComponent {...(this.props as P)} addLoading={addLoading} removeLoading={removeLoading} loadingCount={loadingCount} />}
                </LoadingContext.Consumer>
            );
        }
    };
}
