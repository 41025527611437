import styled, { css } from "styled-components";
import { StyledButton } from "./Button.styled";

const cherriskCommonButtonCSS = css`
    border-radius: 36px;
`;

const cherriskTextButtonCSS = css`
    ${cherriskCommonButtonCSS}

    color: ${({ theme }) => theme.color.blueN};

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.color.greyD};
            color: ${({ theme }) => theme.color.blueD};
        }
    }

    &:disabled {
        color: ${({ theme }) => theme.color.greyD};
    }
`;

const cherriskTextButtonIsInverseCSS = css`
    ${cherriskCommonButtonCSS}

    /* NOTE: missing from Figma */
    color: ${({ theme }) => theme.color.white};

    /* NOTE: missing from Figma */
    /* &:hover {
    } */

    /* NOTE: missing from Figma */
    &:disabled {
        color: ${({ theme }) => theme.color.greyD};
    }
`;

export const StyledTextButton = styled(StyledButton)`
    ${({ isInverse }) => (isInverse ? cherriskTextButtonIsInverseCSS : cherriskTextButtonCSS)}
`;
