import React from "react";
import { Box, bp, breakpoints, List } from "@monster/shared";
import styled, { useTheme } from "styled-components";
import { AccordionHeader, AccordionItem, Button, Text } from "@monster/chr-ui";
import { LoctoolMessage } from "@monster/loctool";
import { SVGSharedIcon32BasicsEdit } from "@monster/shared/dist/svg_assets";

export const FileClaimPageList = styled(List)`
    /* NOTE: "Don’t name your counter list-item" https://stackoverflow.com/questions/53420948/strange-browser-behavior-with-counter-identifier-list-item */
    counter-reset: my-counter;

    > li {
        > h2 {
            hyphens: auto;

            &::before {
                counter-increment: my-counter;
                content: counter(my-counter) ". ";
            }
        }
    }
`;

type PageListItemType = {
    id: string;
    isActive: boolean;
    hideContent?: boolean;
    headingText: React.ReactNode;
    isFinished: boolean;
    forceEditButton?: boolean;
    onEditClick?: () => void;
};

export const FileClaimPageListItem = (props: React.PropsWithChildren<PageListItemType>) => {
    const theme = useTheme();
    const isEditVisible = props.forceEditButton || (!props.isActive && props.isFinished);
    return (
        <li id={props.id}>
            <Text
                as="h2"
                $variant="h3Mobile"
                $style={
                    props.isFinished || props.isActive
                        ? { position: "relative", margin: "40px 16px 16px", paddingRight: !isEditVisible ? "" : 56 }
                        : { margin: "40px 16px 16px", fontWeight: 400, color: theme.color.blueL }
                }
            >
                {props.headingText}

                {isEditVisible && (
                    <Box as="span" $style={{ position: "absolute", top: -4, right: 0 }}>
                        <Button.Secondary btnLabel={<LoctoolMessage id="common.edit" />} icon={<SVGSharedIcon32BasicsEdit />} size="small" onClick={props.onEditClick} />
                    </Box>
                )}
            </Text>

            {(typeof props.hideContent === "undefined" || props.hideContent === false) && props.children}
        </li>
    );
};

export const FileClaimPageHeaderAndCartWrapper = styled.div`
    ${bp.custom(`(max-width: ${breakpoints.large - 0.02}px)`)} {
        display: flex;
        flex-direction: column;

        table {
            background-color: ${({ theme }) => theme.color.greyN};
        }

        caption {
            display: none;
        }

        tbody {
            background-color: ${({ theme }) => theme.color.white};
        }

        tr,
        th {
            box-shadow: ${({ theme }) => `inset 0px -1px 0px 0px ${theme.color.greyN}`};
            font-weight: 400;
        }

        th,
        td {
            background-color: transparent;
            border-radius: 0;
            padding: 12px 16px;

            &::before {
                content: normal;
            }
        }

        td {
            &:last-of-type {
                font-weight: 700;
            }
        }

        dl {
            box-shadow: none;
            border-radius: 0;
            padding: 0 0 4px;
            margin: -4px 0 0;
        }

        .cart__svg-wrapper {
            display: none;
        }

        ${AccordionItem} {
            background-color: ${({ theme }) => theme.color.greyN};
        }

        ${AccordionHeader} {
            box-shadow: inset 0 -1px ${({ theme }) => theme.color.greyD};
        }

        h1 {
            order: 2;
        }
    }
`;

export const Main = styled.main`
    padding-bottom: 80px;
    width: 100%;

    ${bp.large} {
        flex: 1 1 0px;
        position: relative;
    }
`;

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
`;
