import { Gql } from "@api/Api";
import { ReduxHelpers, ActionsUnion } from "@monster/shared";

export enum BackendEnvironmentsActionTypes {
    UPDATE = "BackendEnvironmentsAction/UPDATE",
}

export const BackendEnvironmentsActions = {
    update: (backendEnvironments: Gql.BackendEnvironment[]) => ReduxHelpers.createAction(BackendEnvironmentsActionTypes.UPDATE, { backendEnvironments }),
};

export type BackendEnvironmentsActions = ActionsUnion<typeof BackendEnvironmentsActions>;
