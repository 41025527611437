import React, { ForwardedRef, useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";
import { Box, DateFormat, useLoading } from "@monster/shared";
import { Button, DateInput, Surface, TextField, Tooltip, TooltipContent, TooltipTrigger } from "@monster/chr-ui";
import { SVGChrIcon32BasicsQuestionMark } from "@monster/chr-ui/dist/svg_assets";
import { DateInputParser, DateUtils } from "@utils/DateHelpers";
import { Validator } from "@utils/Validator";
import { Constants } from "@utils/Constants";
import { FormHelpers } from "@utils/FormHelpers";
import { Loctool, LoctoolHTMLMessage, LoctoolMessage } from "@monster/loctool";
import { FileClaimViaEmailDialog } from "@pages/Login/FileClaimViaEmailDialog";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { GraphqlErrorMessage } from "@api/CustomTypes";
import { useHistory } from "react-router-dom";
import { Path } from "@utils/Path";
import { ClaimNotAllowedDialog } from "@components/ClaimNotAllowedDialog";

type FormValues = {
    insuranceId: string;
    dateOfBirth: string;
};

type Props = {
    initialValues: FormValues;
    onSubmit: (result: FormValues) => Promise<void>;
    disabled: boolean;
};

export const PolicyDetailsForm = React.forwardRef((props: Props, ref: ForwardedRef<HTMLFormElement>) => {
    const theme = useTheme();
    const history = useHistory();
    const loading = useLoading();
    const [openFileClaimViaEmailDialog, setOpenFileClaimViaEmailDialog] = React.useState(false);
    const [isClaimNotAllowed, setClaimNotAllowed] = useState(false);
    const {
        handleSubmit,
        register,
        clearErrors,
        watch,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormValues>({ defaultValues: props.initialValues, mode: "onTouched" });
    const dateOfBirth = watch("dateOfBirth");

    useEffect(() => {
        reset(props.initialValues);
    }, [props.initialValues, reset]);

    const onSubmit = useCallback(
        async (data: FormValues): Promise<void> => {
            loading.addLoading();
            try {
                await props.onSubmit(data);
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    switch (error.message) {
                        case GraphqlErrorMessage.illegal_insurance_type:
                            history.push(Path.accidentClaim);
                            break;
                        case GraphqlErrorMessage.unauthenticated:
                            setError("dateOfBirth", { type: "api", message: Loctool.instance.formatMessage({ id: "components.policydetailsform.mismatchError" }) });
                            break;
                        case GraphqlErrorMessage.insurance_not_found:
                            history.push(Path.customerCare);
                            break;
                        case GraphqlErrorMessage.claim_reporting_not_allowed:
                        case GraphqlErrorMessage.modular_travel_insurance_contract_not_found:
                            setClaimNotAllowed(true);
                            break;
                    }
                }
            }
            loading.removeLoading();
        },
        [history, loading, props, setError]
    );

    if (props.disabled) {
        return (
            <Surface $variant="greyBorder">
                <Box as="dl" $style={{ margin: 0, padding: 16, "dd+dt": { marginTop: theme.text.bodyDesktop.lineHeight } }}>
                    <Box as="dt" $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id="components.policydetailsform.policynumber.label" />
                    </Box>

                    <Box as="dd" $style={{ margin: 0 }}>
                        {props.initialValues.insuranceId}
                    </Box>

                    <Box as="dt" $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id="components.policydetailsform.dateofbirth.label" />
                    </Box>

                    <Box as="dd" $style={{ margin: 0 }}>
                        {DateUtils.formatForLocale(DateInputParser.parse(props.initialValues.dateOfBirth))}
                    </Box>
                </Box>
            </Surface>
        );
    }

    return (
        <Box ref={ref} as="form" $style={{ paddingBottom: 24 }} $styleLarge={{ paddingBottom: 0 }} onSubmit={handleSubmit(onSubmit)}>
            <Surface $variant="whiteArrowBottom" $styleLarge={{ boxShadow: theme.shadow.white, "&::after": { content: "normal" } }}>
                <Box $style={{ padding: "8px 16px 0" }}>
                    <TextField
                        id="PolicyDetailsPolicyNumber"
                        label={<LoctoolMessage id="components.policydetailsform.policynumber.label" />}
                        autoFocus={true}
                        placeholder={Loctool.instance.formatMessage({ id: "components.policydetailsform.policynumber.placeholder" })}
                        {...register("insuranceId", {
                            validate: Validator.policyNumber,
                            onChange: () => {
                                if (errors.dateOfBirth?.type === "api") {
                                    clearErrors("dateOfBirth");
                                }
                            },
                        })}
                        errorText={FormHelpers.getError(errors.insuranceId)}
                        hasError={errors.dateOfBirth?.type === "api"}
                        customSuffixElement={
                            <Box $style={{ pointerEvents: "all", position: "absolute", top: 8, right: 8, zIndex: 1 }}>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button.Secondary tabIndex={-1} btnLabel={"info"} icon={<SVGChrIcon32BasicsQuestionMark />} size="xsmall" />
                                    </TooltipTrigger>
                                    <TooltipContent align="end" alignOffset={-16}>
                                        <Box $style={{ width: "calc(100vw - 3.5 * 16px)" }} $styleSmall={{ width: 320 }} $styleMedium={{ width: 374 }}>
                                            <LoctoolHTMLMessage id="components.policydetailsform.policynumber.hint" />
                                        </Box>
                                    </TooltipContent>
                                </Tooltip>
                            </Box>
                        }
                    />

                    <DateInput
                        id="PolicyDetailsBday"
                        label={<LoctoolMessage id="components.policydetailsform.dateofbirth.label" />}
                        value={dateOfBirth}
                        defaultSelectedDate={dateOfBirth === "" ? DateUtils.format(Constants.defaultDateOfBirth(), DateFormat.api) : undefined}
                        {...register("dateOfBirth", {
                            validate: Validator.accountDateOfBirth,
                            onChange: () => {
                                if (errors.dateOfBirth?.type === "api") {
                                    clearErrors("insuranceId");
                                }
                            },
                        })}
                        min={DateUtils.format(Constants.accountDateOfBirthMin())}
                        max={DateUtils.format(Constants.accountDateOfBirthMax())}
                        dateUtils={DateUtils}
                        error={FormHelpers.getError(errors.dateOfBirth)}
                        hideCalendar
                        placeholder={DateUtils.formatForLocale(Constants.defaultDateOfBirth()).replace(/( \/ )/g, ".")}
                        customSuffixElement={
                            <Box $style={{ pointerEvents: "all", position: "absolute", top: 8, right: 8, zIndex: 1 }}>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button.Secondary tabIndex={-1} btnLabel={<LoctoolMessage id="common.moreInfo" />} icon={<SVGChrIcon32BasicsQuestionMark />} size="xsmall" />
                                    </TooltipTrigger>
                                    <TooltipContent align="end" alignOffset={-16}>
                                        <Box $style={{ width: "calc(100vw - 3.5 * 16px)" }} $styleSmall={{ width: 320 }} $styleMedium={{ width: 374 }}>
                                            <LoctoolMessage id="components.policydetailsform.dateofbirth.tooltip" />
                                        </Box>
                                    </TooltipContent>
                                </Tooltip>
                            </Box>
                        }
                    />
                </Box>

                <Box $style={{ maxWidth: 242 + 2 * 16, margin: "0 auto", padding: "24px 16px" }}>
                    <Button.Primary type="submit" btnLabel={<LoctoolMessage id="components.policydetailsform.btn.continue" />} isExpanded />

                    <Box $style={{ marginTop: 10, button: { paddingLeft: 8, paddingRight: 8 } }}>
                        <Button.Text btnLabel={<LoctoolMessage id="components.policydetailsform.btn.dunno" />} isExpanded onClick={() => setOpenFileClaimViaEmailDialog(true)} />
                    </Box>
                </Box>

                <Surface className="hide-for-large" $variant="whiteArrowBottomShadow" />
            </Surface>

            <FileClaimViaEmailDialog open={openFileClaimViaEmailDialog} onOpenChange={() => setOpenFileClaimViaEmailDialog(false)} />
            <ClaimNotAllowedDialog open={isClaimNotAllowed} />
        </Box>
    );
});
