import { openingHours_tel, openingHours_chat } from "@api/graphql/cmsTypes";
import { OpeningHoursActionTypes } from "@redux/actions/openingHoursActions";
import { ReduxAction } from "@redux/actions/ReduxAction";

export type OpeningHoursReducerType = {
    chat?: openingHours_chat;
    isChatAvailable?: boolean;
    tel?: openingHours_tel;
    isTelAvailable?: boolean;
};

const initialState: OpeningHoursReducerType = {};

export default function openingHoursReducer(state: OpeningHoursReducerType = initialState, action: ReduxAction): OpeningHoursReducerType {
    switch (action.type) {
        case OpeningHoursActionTypes.UPDATE_LEGAL_DOCUMENTS:
            return { ...action.payload.openingHours };
        default:
            return state;
    }
}
