import * as DateFns from "date-fns";
import { ObjectUtils } from "./ObjectUtils";
import { ServerDateClass } from "./ServerDate";

export enum DateFormat {
    api = "yyyy-MM-dd",
    long = "yyyy. MM. dd. HH:mm:ss",
    monthName = "LLLL",
    hu = "yyyy. MM. dd.",
    en = "dd / MM / yyyy",
    de = "dd.MM.yyyy",
    deShort = "dd/MM/yyyy",
    cz = "dd / MM / yyyy",
    czShort = "dd/MM/yyyy",
    sk = "dd. MM. yyyy",
    skShort = "dd.MM.yyyy",
}

export class DateUtils {
    private serverDate: ServerDateClass;
    public locale: DateFns.Locale;

    constructor(serverDate: ServerDateClass, locale: DateFns.Locale) {
        this.serverDate = serverDate;
        this.locale = locale;
    }

    public setLocale(locale: DateFns.Locale) {
        this.locale = locale;
    }

    public now(): Date {
        return this.serverDate.getDate();
    }

    public formatForLocale(date: Date | number | null | string | undefined): string {
        if (!date) {
            return "";
        }

        let parsedDate;
        if (typeof date === "string") {
            parsedDate = this.parse(date);
        } else {
            parsedDate = date;
        }
        let format = DateFormat.api;
        switch (this.locale.code) {
            case "hu": {
                format = DateFormat.hu;
                break;
            }
            case "en-US":
            case "en-GB": {
                format = DateFormat.en;
                break;
            }
            case "de": {
                format = DateFormat.de;
                break;
            }
        }
        return DateFns.format(parsedDate, format, { locale: this.locale });
    }

    public format(date: Date | number | null | string | undefined, format: DateFormat = DateFormat.api): string {
        if (!date) {
            return "";
        }

        let parsedDate;
        if (typeof date === "string") {
            parsedDate = this.parse(date);
        } else {
            parsedDate = date;
        }
        return DateFns.format(parsedDate, format, { locale: this.locale });
    }

    public parse(dateString: string, currentFormat: DateFormat = DateFormat.api): Date {
        return DateFns.parse(dateString, currentFormat, this.now());
    }

    public parseISO(dateString: string): Date {
        return DateFns.parseISO(dateString);
    }

    public startOfToday(): Date {
        return this.startOfDay(this.now());
    }

    public addDays(date: Date, amount: number): Date {
        return DateFns.addDays(date, amount);
    }

    public subDays(date: Date, amount: number): Date {
        return DateFns.subDays(date, amount);
    }

    public addYears(date: Date, amount: number): Date {
        return DateFns.addYears(date, amount);
    }

    public subYears(date: Date, amount: number): Date {
        return DateFns.subYears(date, amount);
    }

    public startOfDay(date: Date): Date {
        return DateFns.startOfDay(date);
    }

    public isSameDay(date1: Date, date2: Date): boolean {
        return DateFns.isSameDay(date1, date2);
    }

    public isBefore(date: Date, dateToCompare: Date): boolean {
        return DateFns.isBefore(date, dateToCompare);
    }

    public isAfter(date: Date, dateToCompare: Date): boolean {
        return DateFns.isAfter(date, dateToCompare);
    }

    public isDate(date: Date | null | undefined): date is Date {
        return ObjectUtils.hasValue(date) && DateFns.isDate(date);
    }

    public lastDayOfMonth(date: Date): Date {
        return DateFns.lastDayOfMonth(date);
    }

    public addMonths(date: Date, amount: number): Date {
        return DateFns.addMonths(date, amount);
    }

    public getMonthName(date: Date): string {
        return this.format(date, DateFormat.monthName).toLocaleLowerCase(this.locale.code);
    }

    public getAgeByDate(dateOfBirth: Date): number {
        const ageDiffInMs = DateFns.startOfToday().getTime() - dateOfBirth.getTime();
        const ageDate = new Date(ageDiffInMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    public differenceInCalendarDays(date1: Date, date2: Date): number {
        return DateFns.differenceInCalendarDays(date1, date2);
    }
}
