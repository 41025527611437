import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverGlass = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M25.5 4.5H6.5C5.39543 4.5 4.5 5.39543 4.5 6.5V25.5C4.5 26.6046 5.39543 27.5 6.5 27.5H25.5C26.6046 27.5 27.5 26.6046 27.5 25.5V6.5C27.5 5.39543 26.6046 4.5 25.5 4.5Z"
                stroke="currentColor"
            />
            <path d="M23 4.5L20 9H25L22 14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 5H11V27H12V5Z" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverGlass;
