import { gql } from "@apollo/client";
import { BackendEnvironmentFragment } from "./fragments/BackendEnvironmentFragment";
import { RelatedDataFragment } from "./fragments/RelatedDataFragment";
import { LegalDocumentWLFragment } from "./fragments/LegalDocumentWLFragment";
import { ClaimCauseFragment } from "./fragments/ClaimCauseFragment";

export const Queries = Object.freeze({
    getAppData: gql`
        query getAppData($countryCode: CountryCode!) {
            backendEnvironments: env(country: $countryCode) {
                ...BackendEnvironment
            }
            effectiveLegalDocuments {
                ...LegalDocumentWL
            }
        }
        ${BackendEnvironmentFragment}
        ${LegalDocumentWLFragment}
    `,

    getRelatedData: gql`
        query getRelatedData {
            getRelatedData {
                ...RelatedData
            }
        }
        ${RelatedDataFragment}
    `,

    getClaimCauses: gql`
        query getClaimCauses($insuranceId: String!) {
            claimCauses(id: $insuranceId) {
                ...ClaimCause
            }
        }
        ${ClaimCauseFragment}
    `,
});
