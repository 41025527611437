import React from "react";
import ReactDOM from "react-dom";
import { CherriskThemeProvider, NotificationProvider } from "@monster/chr-ui";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "@redux/store";
import { Cookie, LoadingProvider, renderAppBanner } from "@monster/shared";
import { PageLoader } from "@components/PageLoader";
import { initLoctool } from "./i18n";
import App from "./App";
import { setDateUtilsLocale } from "@utils/DateHelpers";
import { Locale, Loctool } from "@monster/loctool";

// Handle language change
if (["/de-de", "/de-en"].includes(document.location.pathname)) {
    const language = document.location.pathname.substring(4) as "de" | "en";
    Cookie.claimsLanguage.set(language);
    document.location.href = "/";
}
// TODO: update loctool config without reloading page
const searchParams = new URLSearchParams(window.location.search);
const locale = searchParams.get("lang");
if (locale === Locale.en || locale === Locale.de) {
    Cookie.claimsLanguage.set(locale);
    searchParams.delete("lang");
    const updatedSearchString = searchParams.toString();
    const newURL = window.location.pathname + (updatedSearchString ? "?" + updatedSearchString : "");
    window.location.replace(newURL);
}

let translationVersionNr = "N/A";
try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const translationDataJSON = require("./i18n/locales/.loctool.cache.json");
    translationVersionNr = translationDataJSON.systemVersion ?? translationDataJSON.draftVersion ?? null;
} catch (e) {}

const banner = `
  _____ _    _ _____     _____ _       _               
 / ____| |  | |  __ \\   / ____| |     (_)              
| |    | |__| | |__) | | |    | | __ _ _ _ __ ___  ___ 
| |    |  __  |  _  /  | |    | |/ _\` | | '_ \` _ \\/ __|
| |____| |  | | | \\ \\  | |____| | (_| | | | | | | \\__ \\
 \\_____|_|  |_|_|  \\_\\  \\_____|_|\\__,_|_|_| |_| |_|___/`;

const translationVersion = process.env.REACT_APP_LOCTOOL_TRANSLATION_FLAG
    ? `${process.env.REACT_APP_LOCTOOL_PRODUCT_ID}:${process.env.REACT_APP_LOCTOOL_TRANSLATION_FLAG.toLowerCase()}, version: ${translationVersionNr}`
    : process.env.REACT_APP_LOCTOOL_DRAFT_ID
    ? `Draft version: ${process.env.REACT_APP_LOCTOOL_DRAFT_ID}`
    : undefined;
renderAppBanner({ banner, buildSHA: process.env.REACT_APP_BUILD_SHA, lastBuildTime: process.env.REACT_APP_LAST_BUILD_TIME, translationVersion });

initLoctool();

setDateUtilsLocale(Loctool.instance.getConfig().locale);

ReactDOM.render(
    <React.StrictMode>
        <CherriskThemeProvider>
            <LoadingProvider>
                <BrowserRouter>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <NotificationProvider>
                                <App />
                                <div id={`lpGuestChat${Loctool.instance.getConfig().locale.toUpperCase()}`} style={{ display: "none" }} />
                            </NotificationProvider>
                        </PersistGate>
                    </Provider>
                    <PageLoader />
                </BrowserRouter>
            </LoadingProvider>
        </CherriskThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
