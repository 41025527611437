import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverSurgery = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.4017 3.5C23.6964 3.5 25.7112 4.37156 27.1508 5.82808C28.5925 7.28668 29.4543 9.32828 29.4543 11.653C29.4543 17.9737 18.5927 26.4316 16 28.3763C13.4059 26.4305 2.54571 17.9733 2.54571 11.653C2.54571 9.32828 3.4075 7.28668 4.84918 5.82808C6.28881 4.37156 8.30363 3.5 10.5983 3.5C12.3393 3.5 14.2621 4.35675 15.4833 5.14774C17.6796 4.37711 19.6327 3.5 21.4017 3.5Z"
                stroke="currentColor"
            />
            <path
                d="M18.7929 16.2071C19.1834 15.8166 19.1834 15.1834 18.7929 14.7929C18.4024 14.4024 17.7692 14.4024 17.3787 14.7929C16.9882 15.1834 16.9882 15.8166 17.3787 16.2071C17.7692 16.5976 18.4024 16.5976 18.7929 16.2071Z"
                fill="currentColor"
            />
            <path
                d="M20.2071 17.6214C20.5976 17.2309 20.5976 16.5977 20.2071 16.2072C19.8166 15.8167 19.1834 15.8167 18.7929 16.2072C18.4024 16.5977 18.4024 17.2309 18.7929 17.6214C19.1834 18.0119 19.8166 18.0119 20.2071 17.6214Z"
                fill="currentColor"
            />
            <path
                d="M20.2071 14.7929C20.5976 14.4024 20.5976 13.7692 20.2071 13.3787C19.8166 12.9882 19.1834 12.9882 18.7929 13.3787C18.4024 13.7692 18.4024 14.4024 18.7929 14.7929C19.1834 15.1834 19.8166 15.1834 20.2071 14.7929Z"
                fill="currentColor"
            />
            <path
                d="M21.6213 16.2071C22.0118 15.8166 22.0118 15.1834 21.6213 14.7929C21.2308 14.4024 20.5976 14.4024 20.2071 14.7929C19.8166 15.1834 19.8166 15.8166 20.2071 16.2071C20.5976 16.5976 21.2308 16.5976 21.6213 16.2071Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.0355 9.13605C23.4261 8.74553 23.9379 8.55026 24.4497 8.55026C24.9616 8.55026 25.4734 8.74553 25.864 9.13605C26.2545 9.52657 26.4497 10.0384 26.4497 10.5503C26.4497 11.0621 26.2545 11.574 25.864 11.9645L23.0355 14.7929L20.2071 11.9645L23.0355 9.13605Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.136 19.0356C12.7455 19.4261 12.5503 19.9379 12.5503 20.4498C12.5503 20.9616 12.7455 21.4735 13.136 21.864C13.5266 22.2545 14.0384 22.4498 14.5503 22.4498C15.0621 22.4498 15.5739 22.2545 15.9645 21.864L18.7929 19.0356L15.9645 16.2072L13.136 19.0356Z"
                stroke="currentColor"
            />
            <path
                d="M18.7929 11.9645L15.9645 14.7929C15.5739 15.1834 15.5739 15.8166 15.9645 16.2071L18.7929 19.0355C19.1834 19.4261 19.8166 19.4261 20.2071 19.0355L23.0355 16.2071C23.4261 15.8166 23.4261 15.1834 23.0355 14.7929L20.2071 11.9645C19.8166 11.5739 19.1834 11.5739 18.7929 11.9645Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverSurgery;
