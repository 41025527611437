import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverFractures = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 3.5C21.9665 3.5 22.8415 3.89175 23.4749 4.52513C24.1083 5.1585 24.5 6.0335 24.5 7C24.5 7.18423 24.4858 7.36513 24.4585 7.54163C24.635 7.51422 24.8158 7.5 25 7.5C25.9665 7.5 26.8415 7.89175 27.4749 8.52513C28.1083 9.1585 28.5 10.0335 28.5 11C28.5 11.9665 28.1083 12.8415 27.4749 13.4749C26.8415 14.1082 25.9665 14.5 25 14.5C24.3521 14.5 23.7453 14.3242 23.2249 14.0172L14.0172 23.2249C14.3242 23.7453 14.5 24.3521 14.5 25C14.5 25.9665 14.1082 26.8415 13.4749 27.4749C12.8415 28.1083 11.9665 28.5 11 28.5C10.0652 28.5 9.21593 28.1335 8.58815 27.5363C7.9573 26.9362 7.54991 26.1033 7.50429 25.1751C6.02898 24.5655 5.12775 24.107 4.48971 23.4379C3.86902 22.7871 3.5 21.9369 3.5 21C3.5 20.0335 3.89175 19.1585 4.52513 18.5251C5.1585 17.8917 6.0335 17.5 7 17.5C7.79891 17.5 8.5354 17.7675 9.12441 18.2183L18.2183 9.12441C17.7675 8.5354 17.5 7.79891 17.5 7C17.5 6.0335 17.8917 5.1585 18.5251 4.52513C19.1585 3.89175 20.0335 3.5 21 3.5Z"
                stroke="currentColor"
            />
            <path d="M15 12.5L14 16L18 13L17 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 4.5C13 4.22386 12.7761 4 12.5 4C12.2239 4 12 4.22386 12 4.5V7.5C12 7.77614 12.2239 8 12.5 8C12.7761 8 13 7.77614 13 7.5V4.5Z" fill="currentColor" />
            <path d="M4.5 11C4.22386 11 4 11.2239 4 11.5C4 11.7761 4.22386 12 4.5 12H7.5C7.77614 12 8 11.7761 8 11.5C8 11.2239 7.77614 11 7.5 11H4.5Z" fill="currentColor" />
            <path
                d="M6.7929 5.58578C6.59764 5.39052 6.28105 5.39052 6.08579 5.58578C5.89053 5.78104 5.89053 6.09762 6.08579 6.29288L8.20711 8.4142C8.40237 8.60947 8.71896 8.60947 8.91422 8.4142C9.10948 8.21894 9.10948 7.90236 8.91422 7.7071L6.7929 5.58578Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverFractures;
