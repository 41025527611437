import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverHelp = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16 28.5C22.9036 28.5 28.5 22.9036 28.5 16C28.5 9.09644 22.9036 3.5 16 3.5C9.09644 3.5 3.5 9.09644 3.5 16C3.5 22.9036 9.09644 28.5 16 28.5Z" stroke="currentColor" />
            <path d="M16 22.5C19.5899 22.5 22.5 19.5899 22.5 16C22.5 12.4101 19.5899 9.5 16 9.5C12.4101 9.5 9.5 12.4101 9.5 16C9.5 19.5899 12.4101 22.5 16 22.5Z" stroke="currentColor" />
            <path d="M13.5 3.5V10" stroke="currentColor" />
            <path d="M13.5 22V28" stroke="currentColor" />
            <path d="M18.5 22V28" stroke="currentColor" />
            <path d="M18.5 3.5V10" stroke="currentColor" />
            <path d="M3.5 18.5H10" stroke="currentColor" />
            <path d="M22 18.5H28" stroke="currentColor" />
            <path d="M22 13.5H28" stroke="currentColor" />
            <path d="M3.5 13.5H10" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverHelp;
