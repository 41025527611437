import React from "react";
import { useCalendarState, CalendarState } from "@react-stately/calendar";
import { useCalendar, useCalendarCell, useCalendarGrid, CalendarProps, AriaCalendarGridProps } from "@react-aria/calendar";
import { DateValue } from "@react-types/calendar";
import { createCalendar, getWeeksInMonth, CalendarDate } from "@internationalized/date";
import { Button } from "../../Button";
import { SVGSharedIcon32BasicsArrowLeft } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon32BasicsArrowRight } from "@monster/shared/dist/svg_assets";
import styled from "styled-components";
import { Surface } from "../../Surface";

export const Calendar = (props: CalendarProps<DateValue>) => {
    const locale = "DE";
    const state = useCalendarState({
        ...props,
        locale,
        createCalendar,
    });

    const ref = React.createRef<HTMLDivElement>();
    const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);

    return (
        <CalendarSurface $variant="white" {...calendarProps} ref={ref}>
            <CalendarHeader>
                <h2>{title}</h2>
                {/* NOTE: prevButtonProps and nextButtonProps doesn't have onClick handler. Setting onClick manually */}
                <DatePickerButton {...prevButtonProps} btnLabel="previous month" icon={<SVGSharedIcon32BasicsArrowLeft />} onClick={() => state.focusPreviousPage()} />
                <DatePickerButton {...nextButtonProps} btnLabel="previous month" icon={<SVGSharedIcon32BasicsArrowRight />} onClick={() => state.focusNextPage()} />
            </CalendarHeader>
            <CalendarGrid state={state} />
        </CalendarSurface>
    );
};

interface CalendarGirdProps extends AriaCalendarGridProps {
    state: CalendarState;
}

const CalendarGrid: React.FC<CalendarGirdProps> = ({ state, ...props }) => {
    const locale = "DE";
    const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

    // Get the number of weeks in the month so we can render the proper number of rows.
    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    return (
        <CalendarTable {...gridProps} cellPadding="0">
            <thead {...headerProps}>
                <tr>
                    {weekDays.map((day, index) => (
                        <th key={index}>{day}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {/* {[...new Array(weeksInMonth).keys()].map(weekIndex => ( */}

                {/* TODO: itt vissza kene rakni az eredeti megoldast (fent), mert ez igy nem mukodik: */}
                {new Array(weeksInMonth).fill(1).map(weekIndex => (
                    <tr key={weekIndex}>{state.getDatesInWeek(weekIndex).map((date, i) => (date ? <CalendarCell key={i} state={state} date={date} /> : <td key={i} />))}</tr>
                ))}
            </tbody>
        </CalendarTable>
    );
};

const CalendarCell: React.FC<{ state: CalendarState; date: CalendarDate }> = ({ state, date }) => {
    const ref = React.createRef<HTMLDivElement>();
    const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, isUnavailable, formattedDate } = useCalendarCell({ date }, state, ref);

    return (
        <td {...cellProps}>
            <CalendarCellButton {...buttonProps} ref={ref} hidden={isOutsideVisibleRange} $isSelected={isSelected} $isDisabled={isDisabled} $isUnavailable={isUnavailable}>
                {formattedDate}
            </CalendarCellButton>
        </td>
    );
};

const CalendarSurface = styled(Surface)`
    padding: 32px 24px 24px;
`;

const CalendarHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    h2 {
        flex: 1;
        margin: 0;
    }
`;

const DatePickerButton = styled(Button.IconOnlyBlueAzure)`
    &:hover {
        background-color: ${({ theme }) => theme.color.greyD};
    }
`;

const CalendarTable = styled.table`
    border-collapse: collapse;

    th {
        font-weight: 700;
        width: 48px;
        height: 48px;
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
    }
`;

const CalendarCellButton = styled.div<{
    $isSelected: boolean;
    $isDisabled: boolean;
    $isUnavailable: boolean;
}>`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color.blueD};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.blueN};
        color: ${({ theme }) => theme.color.white};
    }

    ${({ theme, $isSelected }) =>
        $isSelected
            ? `
        background-color: ${theme.color.blueN};
        color: ${theme.color.white};
        `
            : ""}

    ${({ theme, $isUnavailable }) =>
        $isUnavailable
            ? `
        color: ${theme.color.redN};
        cursor: not-allowed;

        &:hover {
            background-color: transparent;
            color: ${theme.color.redN};
        }
        `
            : ""}

    ${({ theme, $isDisabled }) =>
        $isDisabled
            ? `
        color: ${theme.color.blueL};
        cursor: not-allowed;

        &:hover {
            background-color: transparent;
            color: ${theme.color.blueL};
        }
        `
            : ""}
`;
