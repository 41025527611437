import React from "react";
import { useTheme } from "styled-components";
import { Dialog, Surface, Button, Text } from "@monster/chr-ui";
import { SharedDialog, Box, SharedDialogProps } from "@monster/shared";

type Props = {
    title: React.ReactNode;
    description: React.ReactNode;
    buttonLabel: React.ReactNode;
    onButtonClick: () => void;
} & SharedDialogProps;

export const ClaimSubmitDialog = ({ title, description, buttonLabel, onButtonClick, ...props }: Props) => {
    const theme = useTheme();

    return (
        <Dialog {...props}>
            <SharedDialog.Content aria-describedby={undefined} onEscapeKeyDown={e => e.preventDefault()} onPointerDownOutside={e => e.preventDefault()}>
                <Box $style={{ position: "fixed", inset: 0, zIndex: theme.zIndex.modal, overflow: "auto", display: "flex", alignItems: "center" }}>
                    <Box $style={{ maxWidth: 504, margin: "0 auto", width: "100%" }}>
                        <Surface $variant={"white"}>
                            <Box $style={{ padding: "56px 16px 40px", textAlign: "center", "&&&": { button: { paddingLeft: 8, paddingRight: 8 } } }} $styleMedium={{ padding: "40px 32px 32px" }}>
                                <SharedDialog.Title asChild>
                                    <Text as={"h2"} $variant={"h3Desktop"} $style={{ margin: "0 0 24px" }}>
                                        {title}
                                    </Text>
                                </SharedDialog.Title>

                                <Box as={"p"} $style={{ margin: "8px 0" }}>
                                    {description}
                                </Box>

                                <Box $style={{ maxWidth: 288, margin: "0 auto", paddingTop: 48 }} $styleMedium={{ maxWidth: 208, paddingTop: 24 }}>
                                    <Button.Primary btnLabel={buttonLabel} isExpanded onClick={onButtonClick} />
                                </Box>
                            </Box>
                        </Surface>
                    </Box>
                </Box>
            </SharedDialog.Content>
        </Dialog>
    );
};
