/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useMemo, useCallback, ForwardedRef } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { DayPickerSurface } from "../DayPicker";
import { TextField } from "../TextField";
import { DayModifiers, Matcher, DayPicker } from "react-day-picker";
import { YearMonthForm } from "./YearMonthForm";
import { DateFormat, DateUtils } from "@monster/shared";
import { SVGSharedIcon32BasicsArrowLeft } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon32BasicsArrowRight } from "@monster/shared/dist/svg_assets";
import { IMask, IMaskMixin } from "react-imask";

type Props = {
    id: string;
    name: string;
    label: React.ReactNode;
    placeholder?: string;
    error?: React.ReactNode | undefined;
    defaultSelectedDate?: string | null;
    value?: string | null;
    onChange: (event: { target: { name: string; value: string | null } }) => void;
    className?: string;
    dateFormat?: DateFormat;
    dateUtils: DateUtils;
    fromDate?: Date | null;
    disabled?: boolean;
    min?: string;
    max?: string;
    hideCalendar?: boolean;
    customSuffixElement?: React.ReactNode;
};

export const DateInput = React.forwardRef(
    (
        { id, name, fromDate, onChange, disabled, min, max, placeholder, label, value, defaultSelectedDate, dateFormat = DateFormat.de, dateUtils, error, hideCalendar, customSuffixElement }: Props,
        _ref: ForwardedRef<HTMLInputElement>
    ) => {
        const parsedValue = value ? dateUtils.parse(value) : null;
        const minDate = min ? dateUtils.parse(min) : undefined;
        const maxDate = max ? dateUtils.parse(max) : undefined;
        const [unmaskedValue, setUnmaskedValue] = useState(parsedValue ? dateUtils.format(parsedValue, dateFormat) : "");
        const [isPopoverOpen, setIsPopoverOpen] = useState(false);
        const defaultMonth = useMemo(() => (value || defaultSelectedDate) ?? undefined, [value, defaultSelectedDate]);
        const [month, setMonth] = useState<Date | undefined>(defaultMonth ? dateUtils.parse(defaultMonth) : undefined);
        const pattern = useMemo(() => getIMaskPattern(dateFormat), [dateFormat]);

        useEffect(() => {
            const parsedValue = value ? dateUtils.parse(value) : null;
            setUnmaskedValue(parsedValue ? dateUtils.format(parsedValue, dateFormat) : "");
        }, [dateFormat, dateUtils, value]);

        useEffect(() => {
            if (defaultMonth) {
                setMonth(dateUtils.parse(defaultMonth));
            }
        }, [dateUtils, defaultMonth]);

        const normalizeDateString = useCallback(
            (dateString: string) => {
                switch (dateFormat) {
                    case DateFormat.hu:
                        // Due to the lazy mask pattern, the dot is not appended automatically
                        // which causes parsing issues
                        if (dateString.length === DateFormat.hu.length - 1) {
                            return dateString + ".";
                        }
                        return dateString;
                    case DateFormat.de:
                    default:
                        return dateString;
                }
            },
            [dateFormat]
        );

        const handleDayClick = (day: Date, modifiers: DayModifiers) => {
            if (modifiers.disabled || (!modifiers.range_start && !modifiers.range_middle && !modifiers.range_end && modifiers.selected)) {
                return;
            }

            onChange({ target: { value: dateUtils.format(day), name } });
            setUnmaskedValue(dateUtils.format(day, dateFormat));
            setIsPopoverOpen(false);
        };

        const handleChange = (newUnmaskedValue: string, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>) => {
            const date = maskRef.typedValue as Date | null;

            if (newUnmaskedValue.length !== dateFormat.length || date === null) {
                // Unmasked value could be incomplete,
                // e.g. 01.0.2022 if the user is currently typing.
                // In this case we don't fire a change event.
                setUnmaskedValue(normalizeDateString(newUnmaskedValue));
            } else if (maxDate && dateUtils.isAfter(date, maxDate)) {
                setUnmaskedValue(dateUtils.format(maxDate, dateFormat));
                onChange({ target: { value: max ?? null, name } });
            } else if (minDate && dateUtils.isBefore(date, minDate)) {
                setUnmaskedValue(dateUtils.format(minDate, dateFormat));
                onChange({ target: { value: min ?? null, name } });
            } else {
                setUnmaskedValue(normalizeDateString(newUnmaskedValue));
                onChange({ target: { value: dateUtils.format(date) ?? null, name } });
            }
        };

        const handleBlur = () => {
            const date = dateUtils.parse(unmaskedValue, dateFormat);

            if (unmaskedValue.length !== dateFormat.length || isNaN(date.getTime())) {
                // Unmasked value could be incomplete,
                // e.g. 01.0.2022 if the user is currently typing.
                // In this case we reset the value.
                setUnmaskedValue(dateUtils.format(value, dateFormat));
            }
        };

        const monthNames = useMemo(() => {
            return Array.from({ length: 12 }).map((_, index) => dateUtils.format(new Date(1900, index, 1), DateFormat.monthName));
        }, [dateUtils]);

        const disabledDays: Matcher[] = useMemo(() => {
            const disabledDays = [];
            if (min) {
                disabledDays.push({ before: dateUtils.parse(min) });
            }
            if (max) {
                disabledDays.push({ after: dateUtils.parse(max) });
            }
            return disabledDays;
        }, [dateUtils, min, max]);

        return (
            // NOTE: https://www.radix-ui.com/docs/primitives/components/popover#anatomy
            <PopoverPrimitive.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                {/* <PopoverPrimitive.Trigger /> */}
                <PopoverPrimitive.Anchor>
                    {/* TODO: on mobile instead of DayPicker <input type="date" /> ? */}
                    <MaskedInput
                        id={id}
                        name={name}
                        // @ts-ignore
                        label={label}
                        value={unmaskedValue}
                        onAccept={handleChange}
                        showCalendarIcon={!disabled && !hideCalendar}
                        customSuffixElement={customSuffixElement}
                        placeholder={placeholder}
                        errorText={error}
                        disabled={disabled}
                        autoComplete="off"
                        onCalendarClick={() => (hideCalendar ? {} : setIsPopoverOpen(true))}
                        onBlur={handleBlur}
                        mask={Date}
                        pattern={pattern}
                        format={(date: Date) => dateUtils.format(date, dateFormat)}
                        parse={(str: string) => dateUtils.parse(normalizeDateString(str), dateFormat)}
                        autofix={true}
                        lazy={true}
                        overwrite={true}
                        // @ts-ignore
                        unmask={true}
                    />
                </PopoverPrimitive.Anchor>
                {/* TODO: config event handling. Show DayPicker when focus within the input or in the DayPicker only */}
                <PopoverPrimitive.Content
                    align="end"
                    side="top"
                    alignOffset={-16}
                    onOpenAutoFocus={event => {
                        event.preventDefault();
                    }}
                    onPointerDownOutside={event => {
                        event.preventDefault();
                        setIsPopoverOpen(false);
                    }}
                    onFocusOutside={event => {
                        event.preventDefault();
                        setIsPopoverOpen(false);
                    }}
                    onInteractOutside={event => {
                        event.preventDefault();
                        setIsPopoverOpen(false);
                    }}
                >
                    <DayPickerSurface $variant="white">
                        {/* @ts-ignore */}
                        <DayPicker
                            mode={fromDate ? "range" : "single"}
                            month={month}
                            selected={fromDate ? { from: fromDate, to: parsedValue ?? undefined } : parsedValue ?? undefined}
                            onDayClick={handleDayClick}
                            disabled={disabledDays}
                            onMonthChange={setMonth}
                            locale={dateUtils.locale}
                            components={{
                                CaptionLabel: () => {
                                    return (
                                        <YearMonthForm
                                            date={month ?? parsedValue ?? dateUtils.startOfToday()}
                                            disabledDays={disabledDays ? (Array.isArray(disabledDays) ? disabledDays : [disabledDays]) : undefined}
                                            onChange={setMonth}
                                            getMonths={() => monthNames}
                                            dateUtils={dateUtils}
                                        />
                                    );
                                },
                                IconLeft: () => {
                                    return <SVGSharedIcon32BasicsArrowLeft />;
                                },
                                IconRight: () => {
                                    return <SVGSharedIcon32BasicsArrowRight />;
                                },
                            }}
                        />
                    </DayPickerSurface>
                </PopoverPrimitive.Content>
            </PopoverPrimitive.Root>
        );
    }
);

const MaskedInput = IMaskMixin(({ id, inputRef, ...props }) => <TextField {...props} id={id!} ref={inputRef} />);

const getIMaskPattern = (format: DateFormat) => {
    switch (format) {
        case DateFormat.hu:
            return "Y{. }`m{. }`d{.}";
        case DateFormat.de:
        default:
            return "d{.}`m{.}`Y";
    }
};
