import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverVehicleassisst = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.8293 25C10.4175 26.1652 9.30622 27 8 27C6.69378 27 5.58254 26.1652 5.17071 25H3C2.44772 25 2 24.5523 2 24V20C2 19.4477 2.44772 19 3 19H30V24C30 24.5523 29.5523 25 29 25H26.8293C26.4175 26.1652 25.3062 27 24 27C22.6938 27 21.5825 26.1652 21.1707 25H10.8293ZM29 20H3V24H5.87788L6.11355 24.6668C6.3936 25.4591 7.14548 26 8 26C8.85452 26 9.6064 25.4591 9.88645 24.6668L10.1221 24H21.8779L22.1135 24.6668C22.3936 25.4591 23.1455 26 24 26C24.8545 26 25.6064 25.4591 25.8865 24.6668L26.1221 24H29V20Z"
                fill="currentColor"
            />
            <path
                d="M17 9H20.7352C22.1403 9 23.4423 9.73719 24.1652 10.942L26 14H28C29.1046 14 30 14.8954 30 16V19.0228C30 19.5751 29.5523 20.0228 29 20.0228H17C16.4477 20.0228 16 19.5751 16 19.0228V10C16 9.44772 16.4477 9 17 9ZM17 10V19.0228H29V16C29 15.4477 28.5523 15 28 15H25.4338L23.3077 11.4565C22.7655 10.5529 21.789 10 20.7352 10H17Z"
                fill="currentColor"
            />
            <path
                d="M8 27C9.65685 27 11 25.6569 11 24C11 22.3431 9.65685 21 8 21C6.34315 21 5 22.3431 5 24C5 25.6569 6.34315 27 8 27ZM8 26C6.89543 26 6 25.1046 6 24C6 22.8954 6.89543 22 8 22C9.10457 22 10 22.8954 10 24C10 25.1046 9.10457 26 8 26Z"
                fill="currentColor"
            />
            <path
                d="M24 27C25.6569 27 27 25.6569 27 24C27 22.3431 25.6569 21 24 21C22.3431 21 21 22.3431 21 24C21 25.6569 22.3431 27 24 27ZM24 26C22.8954 26 22 25.1046 22 24C22 22.8954 22.8954 22 24 22C25.1046 22 26 22.8954 26 24C26 25.1046 25.1046 26 24 26Z"
                fill="currentColor"
            />
            <path d="M5 5V11C4.44772 11 4 10.5523 4 10V6C4 5.44772 4.44772 5 5 5Z" fill="currentColor" />
            <path d="M10 5H5V6H10V5Z" fill="currentColor" />
            <path d="M6 5H10.323C10.7319 5 11.0996 5.24895 11.2514 5.62861L17 20H11L6 5ZM7.38743 6L11.7208 19H15.523L10.323 6H7.38743Z" fill="currentColor" />
            <path d="M19 6C20.1046 6 21 6.89543 21 8V10H17V8C17 6.89543 17.8954 6 19 6ZM20 9V8C20 7.44772 19.5523 7 19 7C18.4477 7 18 7.44772 18 8V9H20Z" fill="currentColor" />
            <path
                d="M4 10.0354C4.1633 10.0121 4.33024 10 4.5 10C6.433 10 8 11.567 8 13.5C8 15.433 6.433 17 4.5 17C2.73676 17 1.24261 15.6961 1 14C1 13.8333 1 13.6667 1 13.5C1 13.2239 1.22386 13 1.5 13C1.77614 13 2 13.2239 2 13.5V14C2.23164 15.1411 3.29052 16 4.5 16C5.88071 16 7 14.8807 7 13.5C7 12.1193 5.88071 11 4.5 11C4.22386 11 4 10.7761 4 10.5V10.0354Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverVehicleassisst;
