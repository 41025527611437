export enum TravelClaimCauseType {
    damaged_luggage = "damaged_luggage",
    illness = "illness",
    other = "other",
    flight_delay = "flight_delay",
    cancellation = "cancellation",
    luggage_theft = "luggage_theft",
    luggage_delay = "luggage_delay",
}

export enum DeHomePackagesClaimCauseType {
    modularHome_physicalNatureEvents = "modularHome_physicalNatureEvents",
    modularHome_fireAndSimilarDamages = "modularHome_fireAndSimilarDamages",
    modularHome_propertyCrime = "modularHome_propertyCrime",
    modularHome_tapWater = "modularHome_tapWater",
    modularHome_glassBreakage = "modularHome_glassBreakage",
    modularHome_elementaryDamage = "modularHome_elementaryDamage",
    modularHome_bicycleAndEbikeTheft = "modularHome_bicycleAndEbikeTheft",
    modularHome_otherDamage = "modularHome_otherDamage",
}

export enum HomeClaimCauseType {
    home_tapWater = "home_tapWater",
    home_glassBreakage = "home_glassBreakage",
    home_natureEvents = "home_natureEvents",
    home_propertyCrime = "home_propertyCrime",

    /**
     * BE naming and specs use different names. To clarify it means
     * Physical events such as fire, lightning or other external factors
     */
    home_physicalEvents = "home_physicalEvents",
    home_otherDamage = "home_otherDamage",
}

export type ClaimCauseType = TravelClaimCauseType | DeHomePackagesClaimCauseType | HomeClaimCauseType;

export class ClaimCauseHelpers {
    public static deTravelModS = [
        { id: 2006, name: "Damaged luggage", claimCauseType: TravelClaimCauseType.damaged_luggage },
        { id: 2007, name: "Disease/Accident abroad", claimCauseType: TravelClaimCauseType.illness },
        { id: 2009, name: "Other damage", claimCauseType: TravelClaimCauseType.other },
        { id: 2010, name: "Flight delay", claimCauseType: TravelClaimCauseType.flight_delay },
        { id: 2011, name: "Travel cancellation or interruption", claimCauseType: TravelClaimCauseType.cancellation },
        { id: 2114, name: "Theft of luggage", claimCauseType: TravelClaimCauseType.luggage_theft },
        { id: 2115, name: "Delay of luggage", claimCauseType: TravelClaimCauseType.luggage_delay },
    ];

    public static deTravelModA = [
        { id: 2000, name: "Damaged luggage", claimCauseType: TravelClaimCauseType.damaged_luggage },
        { id: 2001, name: "Disease/Accident abroad", claimCauseType: TravelClaimCauseType.illness },
        { id: 2003, name: "Other damage", claimCauseType: TravelClaimCauseType.other },
        { id: 2004, name: "Flight delay", claimCauseType: TravelClaimCauseType.flight_delay },
        { id: 2005, name: "Travel cancellation or interruption", claimCauseType: TravelClaimCauseType.cancellation },
        { id: 2112, name: "Theft of luggage", claimCauseType: TravelClaimCauseType.luggage_theft },
        { id: 2113, name: "Delay of luggage", claimCauseType: TravelClaimCauseType.luggage_delay },
    ];

    public static deHomePackages = [
        { id: 3000, name: "Physical/Nature events", claimCauseType: DeHomePackagesClaimCauseType.modularHome_physicalNatureEvents },
        { id: 3001, name: "Fire and similar damages", claimCauseType: DeHomePackagesClaimCauseType.modularHome_fireAndSimilarDamages },
        { id: 3002, name: "Property Crimes", claimCauseType: DeHomePackagesClaimCauseType.modularHome_propertyCrime },
        { id: 3003, name: "Tap Water", claimCauseType: DeHomePackagesClaimCauseType.modularHome_tapWater },
        { id: 3004, name: "Glass Breakage", claimCauseType: DeHomePackagesClaimCauseType.modularHome_glassBreakage },
        { id: 3005, name: "Elementary damage", claimCauseType: DeHomePackagesClaimCauseType.modularHome_elementaryDamage },
        { id: 3006, name: "Bicycle and E-bike Theft", claimCauseType: DeHomePackagesClaimCauseType.modularHome_bicycleAndEbikeTheft },
        { id: 3007, name: "Other damage", claimCauseType: DeHomePackagesClaimCauseType.modularHome_otherDamage },
    ];

    public static home = [
        { id: 1201, name: "Pipe breaking", claimCauseType: HomeClaimCauseType.home_tapWater },
        { id: 1202, name: "Breakage of glass", claimCauseType: HomeClaimCauseType.home_glassBreakage },
        { id: 1203, name: "Natural disasters", claimCauseType: HomeClaimCauseType.home_natureEvents },
        { id: 1204, name: "Burglary/Vandalism", claimCauseType: HomeClaimCauseType.home_propertyCrime },
        { id: 1205, name: "Fire", claimCauseType: HomeClaimCauseType.home_physicalEvents },
        { id: 1206, name: "Miscellaneous", claimCauseType: HomeClaimCauseType.home_otherDamage },
    ];

    public static getClaimCauseTypeById(id?: number | null): ClaimCauseType | null {
        const find = (claimCauseData: { id: number }) => claimCauseData.id === id;
        const claimCauseData =
            ClaimCauseHelpers.deTravelModS.find(find) ?? ClaimCauseHelpers.deTravelModA.find(find) ?? ClaimCauseHelpers.deHomePackages.find(find) ?? ClaimCauseHelpers.home.find(find);

        return claimCauseData?.claimCauseType ?? null;
    }

    public static isOptionalDocumentNoteVisible(claimCauseType: ClaimCauseType | null): boolean {
        if (!claimCauseType) {
            return false;
        }
        const claimCausesToHide: ClaimCauseType[] = [
            HomeClaimCauseType.home_natureEvents,
            DeHomePackagesClaimCauseType.modularHome_elementaryDamage,
            DeHomePackagesClaimCauseType.modularHome_bicycleAndEbikeTheft,
        ];
        return !claimCausesToHide.includes(claimCauseType);
    }
}
