import { ClaimCause, RelatedData } from "@api/graphql/types";
import { AppStateActionTypes } from "@redux/actions/appStateActions";
import { ReduxAction } from "@redux/actions/ReduxAction";
import { SessionActionTypes } from "@redux/actions/sessionActions";

export type SessionReducerType = {
    relatedData: RelatedData | null;
    availableClaimCauses: ClaimCause[];
    claimInput: ClaimInputType | null;
};

export type ClaimInputType = {
    claimCauseId: number | null;
    subCauseId: number | null;
    dateOfIncident: string | null;
    description: string | null;
    claimAmount: number | null;
    iban: string | null;
};

const emptyClaimInput: ClaimInputType = {
    claimCauseId: null,
    subCauseId: null,
    dateOfIncident: null,
    description: null,
    claimAmount: null,
    iban: null,
};

const initialState: SessionReducerType = {
    relatedData: null,
    availableClaimCauses: [],
    claimInput: null,
};

export default function sessionReducer(state: SessionReducerType = initialState, action: ReduxAction): SessionReducerType {
    switch (action.type) {
        case SessionActionTypes.updateRelatedData: {
            return { ...state, relatedData: action.payload.relatedData };
        }
        case SessionActionTypes.updateClaimCauses: {
            return { ...state, availableClaimCauses: action.payload.clauses };
        }
        case SessionActionTypes.updateClaimInput: {
            return { ...state, claimInput: { ...(state.claimInput || emptyClaimInput), ...(action.payload.claimInput ?? emptyClaimInput) } };
        }
        case SessionActionTypes.resetClaimInput: {
            return { ...state, claimInput: null };
        }
        case AppStateActionTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
}
