import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverAlmostgone = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.4919 5.5C24.4098 8.02253 23.6999 10.1859 22.35 11.9857C20.93 13.8791 18.809 15.3666 15.9996 16.4639C13.1907 15.3664 11.0699 13.879 9.64999 11.9857C8.30012 10.1859 7.5902 8.02253 7.50806 5.5H24.4919Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.50967 26.5C7.5989 24.2218 8.30456 22.2665 9.63696 20.638C11.0592 18.8998 13.1848 17.5366 16.0004 16.5304C18.8154 17.5367 20.9409 18.8999 22.363 20.638C23.6954 22.2665 24.4011 24.2218 24.4903 26.5H7.50967Z"
                stroke="currentColor"
            />
            <path d="M22.5 21H9.5C9.22386 21 9 21.2239 9 21.5C9 21.7761 9.22386 22 9.5 22H22.5C22.7761 22 23 21.7761 23 21.5C23 21.2239 22.7761 21 22.5 21Z" fill="currentColor" />
            <path d="M25 2.5H7C6.17157 2.5 5.5 3.17157 5.5 4C5.5 4.82843 6.17157 5.5 7 5.5H25C25.8284 5.5 26.5 4.82843 26.5 4C26.5 3.17157 25.8284 2.5 25 2.5Z" stroke="currentColor" />
            <path d="M25 26.5H7C6.17157 26.5 5.5 27.1716 5.5 28C5.5 28.8284 6.17157 29.5 7 29.5H25C25.8284 29.5 26.5 28.8284 26.5 28C26.5 27.1716 25.8284 26.5 25 26.5Z" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverAlmostgone;
