import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsPause = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <line x1={12.5} y1={10.5} x2={12.5} y2={21.5} stroke="currentColor" strokeLinecap="round" />
            <line x1={19.5} y1={10.5} x2={19.5} y2={21.5} stroke="currentColor" strokeLinecap="round" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsPause;
