import React from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteProps, RouteComponentProps } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { Path } from "@utils/Path";
import { ApplicationState } from "@redux/Reducers";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";

type ReduxProps = {
    isAuthenticated: boolean;
};

type ComponentProps = RouteProps;

type Props = ComponentProps & ReduxProps & DispatchProp;

class GuestRoute extends React.Component<Props> {
    public render() {
        const { component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props: RouteComponentProps): React.ReactNode | null => {
                    if (this.props.isAuthenticated) {
                        return <Redirect to={{ pathname: Path.createClaim }} />;
                    } else {
                        if (Component) {
                            return <Component {...props} />;
                        } else if (this.props.render) {
                            return this.props.render(props);
                        }
                        return null;
                    }
                }}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return {
        isAuthenticated: SessionSelectors.isAuthenticated(state),
    };
};

export default connect(mapStateToProps)(GuestRoute);
