import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsHome = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4 6.72424L16 2.52967L28 6.72424V12.0001C28 15.1764 27.1976 18.3116 25.6837 21.0031C23.4455 24.9822 20.1495 26.8581 16 28.4642C11.8505 26.8581 8.55455 24.9822 6.31633 21.0031C4.80237 18.3116 4 15.1764 4 12.0001V6.72424Z"
                stroke="currentColor"
            />
            <path
                d="M15.7 10.85C15.8778 10.7167 16.1222 10.7167 16.3 10.85L19.3 13.1C19.4259 13.1944 19.5 13.3426 19.5 13.5V18C19.5 18.2761 19.2761 18.5 19 18.5H13C12.7239 18.5 12.5 18.2761 12.5 18V13.5C12.5 13.3426 12.5741 13.1944 12.7 13.1L15.7 10.85Z"
                stroke="currentColor"
                strokeLinejoin="round"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsHome;
