import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32MenuPayment = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M26 7H7C5.89543 7 5 7.89543 5 9V22C5 23.1046 5.89543 24 7 24H26C27.1046 24 28 23.1046 28 22V9C28 7.89543 27.1046 7 26 7Z" stroke="currentColor" />
            <path d="M12 11H9C8.44772 11 8 11.4477 8 12V14C8 14.5523 8.44772 15 9 15H12C12.5523 15 13 14.5523 13 14V12C13 11.4477 12.5523 11 12 11Z" stroke="currentColor" />
            <path d="M8 19H25" stroke="currentColor" strokeLinecap="square" />
        </SVGBase>
    );
};

export default SvgIcon32MenuPayment;
