import React from "react";
import { Box, Flex, FontWeightJumpPlaceholder } from "@monster/shared";
import { ButtonProps } from "./ButtonTypes";

export const ButtonContent = ({ btnLabel, icon, iconPosition, hasFontWeightJump700 }: ButtonProps & { hasFontWeightJump700?: boolean }) => {
    const renderButtonContent = () => {
        if (icon) {
            if (!iconPosition) {
                return (
                    <>
                        {btnLabel !== "" && <span className="show-for-sr">{btnLabel}</span>}

                        {icon}
                    </>
                );
            }

            if (iconPosition === "right") {
                return (
                    <>
                        <Flex.Item as="span" $shrink="shrink">
                            {hasFontWeightJump700 ? (
                                <Box as="span" $style={{ display: "block", textAlign: "right" }}>
                                    {btnLabel}

                                    <FontWeightJumpPlaceholder $fontWeight={700}>{btnLabel}</FontWeightJumpPlaceholder>
                                </Box>
                            ) : (
                                btnLabel
                            )}
                        </Flex.Item>

                        <Flex.Item as="span" $shrink="shrink">
                            {icon}
                        </Flex.Item>
                    </>
                );
            }

            return (
                <>
                    <Flex.Item as="span" $shrink="shrink">
                        {icon}
                    </Flex.Item>

                    <Flex.Item as="span" $shrink="shrink">
                        {hasFontWeightJump700 ? (
                            <Box as="span" $style={{ display: "block", textAlign: "left" }}>
                                {btnLabel}

                                <FontWeightJumpPlaceholder $fontWeight={700}>{btnLabel}</FontWeightJumpPlaceholder>
                            </Box>
                        ) : (
                            btnLabel
                        )}
                    </Flex.Item>
                </>
            );
        }

        return (
            <>
                {hasFontWeightJump700 ? (
                    <Box as="span" $style={{ display: "block", textAlign: "center" }}>
                        {btnLabel}

                        <FontWeightJumpPlaceholder $fontWeight={700}>{btnLabel}</FontWeightJumpPlaceholder>
                    </Box>
                ) : (
                    btnLabel
                )}
            </>
        );
    };

    return <>{renderButtonContent()}</>;
};
