import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { Box, DateFormat, Flex } from "@monster/shared";
import { Text } from "@monster/chr-ui";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { SVGSharedIcon68X72ProductsHome } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon68X72ProductsHomeShadow } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon68X72ProductsTravel } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon68X72ProductsTravelShadow } from "@monster/shared/dist/svg_assets";
import { useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { DateInputParser, DateUtils } from "@utils/DateHelpers";
import { TravelInsuranceHelpers } from "@utils/InsuranceHelpers";

export const Cart = () => {
    const theme = useTheme();
    const relatedData = useSelector((state: ApplicationState) => state.session.relatedData);
    const isAnnual = TravelInsuranceHelpers.isAnnual(relatedData?.modTravelData);
    const travelInsureeCategory = TravelInsuranceHelpers.getCategory(relatedData?.modTravelData);

    const insuranceDetails = useMemo(() => {
        if (!relatedData) {
            return null;
        }
        /**
         * ACCIDENT
         * {
            productIcon: (
                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                        <SVGSharedIcon72X71ProductsAccidentShadow width={72 / 2} height={72 / 2} />
                    </Box>

                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                        <SVGSharedIcon72X71ProductsAccident color={theme.color.greenN} width={72 / 2} height={72 / 2} />
                    </Box>
                </Box>
            ),
            details: [
                { label: "Insurance ID", value: "CDE2161684" },
                { label: "Insurees", value: "2" },
                { label: "Payout limit", value: "€ 30.000,00" },
                { label: "Billing period", value: "12 month" },
            ],
        }
         */
        if (relatedData.modTravelData) {
            return {
                productIcon: (
                    <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                        <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                            <SVGSharedIcon68X72ProductsTravelShadow width={68 / 2} height={72 / 2} />
                        </Box>

                        <Box as="span" $style={{ display: "block", position: "absolute" }}>
                            <SVGSharedIcon68X72ProductsTravel color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                        </Box>
                    </Box>
                ),
                details: [
                    { label: <LoctoolMessage id="pages.fileClaim.cart.modtravel.insuranceid" />, value: relatedData.insuranceData?.id },
                    { label: <LoctoolMessage id="pages.fileClaim.cart.modtravel.tariff" />, value: <LoctoolMessage id={`enums.travelInsureeCategory.${travelInsureeCategory}`} /> },
                    { label: <LoctoolMessage id="pages.fileClaim.cart.modtravel.duration" />, value: <LoctoolMessage id={isAnnual ? "enums.travelDuration.annual" : "enums.travelDuration.single"} /> },
                    {
                        label: <LoctoolMessage id="pages.fileClaim.cart.modtravel.destination" />,
                        value: <LoctoolMessage id={`enums.travelDestination.${relatedData.modTravelData?.destination ?? "europe"}`} />,
                    },
                ],
            };
        }
        if (relatedData.homeData) {
            return {
                productIcon: (
                    <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                        <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                            <SVGSharedIcon68X72ProductsHomeShadow width={68 / 2} height={72 / 2} />
                        </Box>

                        <Box as="span" $style={{ display: "block", position: "absolute" }}>
                            <SVGSharedIcon68X72ProductsHome color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                        </Box>
                    </Box>
                ),
                details: [
                    { label: <LoctoolMessage id="pages.fileClaim.cart.insuranceid" />, value: relatedData.insuranceData?.id },
                    { label: <LoctoolMessage id="pages.fileClaim.cart.tariff" />, value: <LoctoolMessage id={`enums.homeInsuranceType.${relatedData.homeData.homeInsuranceType}`} /> },
                    {
                        label: <LoctoolMessage id="pages.fileClaim.cart.coveredUntil" />,
                        value: relatedData.homeData.endOfCoverage ? DateUtils.format(DateUtils.parseISO(relatedData.homeData.endOfCoverage), DateFormat.deShort) : "",
                    },
                ],
            };
        }
        if (relatedData.deHomePackagesData) {
            return {
                productIcon: (
                    <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                        <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                            <SVGSharedIcon68X72ProductsHomeShadow width={68 / 2} height={72 / 2} />
                        </Box>

                        <Box as="span" $style={{ display: "block", position: "absolute" }}>
                            <SVGSharedIcon68X72ProductsHome color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                        </Box>
                    </Box>
                ),
                details: [
                    { label: <LoctoolMessage id="pages.fileClaim.cart.insuranceid" />, value: relatedData.insuranceData?.id },
                    { label: <LoctoolMessage id="pages.fileClaim.cart.tariff" />, value: <LoctoolMessage id={`enums.modHomeBase.${relatedData.deHomePackagesData.typeOfCoverages}`} /> },
                    {
                        label: <LoctoolMessage id="pages.fileClaim.cart.coveredUntil" />,
                        value: relatedData.deHomePackagesData.endOfCoverage ? DateUtils.format(DateUtils.parseISO(relatedData.deHomePackagesData.endOfCoverage), DateFormat.deShort) : "",
                    },
                ],
            };
        }

        return null;
    }, [isAnnual, relatedData, theme.color.greenN, travelInsureeCategory]);

    if (!insuranceDetails) {
        return null;
    }

    return (
        <Box as="table" $style={{ backgroundColor: theme.color.white, borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
            <Text as="caption" $variant="h3Desktop" $style={{ captionSide: "top", padding: "44px 16px 20px", fontWeight: 700, textAlign: "left" }}>
                <LoctoolMessage id="pages.fileClaim.cart.title" />
            </Text>
            <thead>
                <tr>
                    <Box as="th" colSpan={2} scope="colgroup" $style={{ padding: 0, fontWeight: 400, textAlign: "left" }}>
                        <Box as="dl" $style={{ borderRadius: 16, boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyD}`, margin: "0 0 16px", padding: 16 }}>
                            <Box as="dt">{Loctool.instance.formatName({ firstName: relatedData?.accountData.firstName ?? "", lastName: relatedData?.accountData.lastName ?? "" })}</Box>

                            <Box as="dd" $style={{ margin: 0 }}>
                                {DateUtils.formatForLocale(DateInputParser.parse(relatedData?.accountData.dateOfBirth))}
                            </Box>

                            <Box as="dd" $style={{ margin: 0 }}>
                                {relatedData?.accountData.email}
                            </Box>

                            <Box as="dd" $style={{ margin: 0 }}>
                                {relatedData?.accountData.phoneNumber}
                            </Box>
                        </Box>
                    </Box>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <Box
                        as="th"
                        colSpan={2}
                        scope="colgroup"
                        $style={{
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            backgroundColor: theme.color.greyN,
                            boxShadow: `inset 0 -1px 0 0 ${theme.color.greyD}`,
                            textAlign: "left",
                            padding: "12px 16px",
                        }}
                    >
                        <Flex.Container $style={{ columnGap: 16, alignItems: "center" }}>
                            <Flex.Item className="cart__svg-wrapper" $shrink="shrink" $style={{ alignSelf: "flex-start" }}>
                                {/* NOTE: two svgs because of shadow + mixBlendMode */}
                                {/* NOTE: according to Figma these are 32px*32px (half of the original 64*64). But the shadow spreads larger. */}
                                {insuranceDetails.productIcon}
                            </Flex.Item>
                            <Flex.Item $shrink="auto">
                                <Box $style={{ position: "relative", paddingTop: 4, paddingBottom: 4 }}>
                                    <LoctoolMessage id={`enums.insuranceType.${relatedData?.insuranceData.insuranceType}`} />
                                </Box>
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </tr>
            </tbody>
            <tbody>
                {insuranceDetails.details.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    position: index + 1 === insuranceDetails.details.length ? "relative" : undefined,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    backgroundColor: index + 1 === insuranceDetails.details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 0 0 12px" : index + 1 === insuranceDetails.details.length ? "0 0 12px 12px" : "0 0 0 12px",
                                    "&::before":
                                        index + 1 === insuranceDetails.details.length
                                            ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomLeftRadius: 16 }
                                            : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.label}</Box>
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    position: index + 1 === insuranceDetails.details.length ? "relative" : undefined,
                                    textAlign: "right",
                                    backgroundColor: index + 1 === insuranceDetails.details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 12px 0 0" : index + 1 === insuranceDetails.details.length ? "0 12px 12px 0" : "0 12px 0 0",
                                    verticalAlign: "top",
                                    "&::before":
                                        index + 1 === insuranceDetails.details.length
                                            ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomRightRadius: 16 }
                                            : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.value}</Box>
                            </Box>
                        </tr>
                    );
                })}
            </tbody>
        </Box>
    );
};
