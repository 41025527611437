import React from "react";
import { LoctoolMessage, LoctoolValues } from "@monster/loctool";
import { FieldError } from "react-hook-form";

export class FormHelpers {
    public static getError(field: FieldError | undefined, nameOverride?: string | undefined, values?: LoctoolValues) {
        if (!field?.type) {
            return undefined;
        }
        if (field.type === "api" && field?.message) {
            return <LoctoolMessage id={`validation.${nameOverride ?? field.ref?.name}.${field.message}`} values={values} defaultMessage={field.message} />;
        }
        if (field.type === "validate") {
            return <LoctoolMessage id={`validation.${nameOverride ?? field.ref?.name}.${field.message}`} values={values} />;
        }
        return <LoctoolMessage id={`validation.${field.type}`} values={values} />;
    }
}
