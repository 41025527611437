import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsHomeSummerhome = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.5 12.5841C4.5 12.4567 4.54861 12.3341 4.6359 12.2414L7.06797 9.65732C7.16246 9.55692 7.29421 9.5 7.43207 9.5H18.5679C18.7058 9.5 18.8375 9.55692 18.932 9.65732L21.3641 12.2414C21.4514 12.3341 21.5 12.4567 21.5 12.5841V25C21.5 25.2761 21.2761 25.5 21 25.5H5C4.72386 25.5 4.5 25.2761 4.5 25V12.5841Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29 16.6153C29 19.017 27.0054 20.9999 24.5 20.9999C23.5713 20.9999 22.7128 20.7275 22 20.2622V21.4128C22.7501 21.7882 23.5998 21.9999 24.5 21.9999C27.5376 21.9999 30 19.5892 30 16.6153C30 12.2421 26.8397 6.10091 25.3103 3.38491C24.9467 2.73921 24.0533 2.73921 23.6897 3.38491C22.8141 4.93984 21.404 7.61742 20.3583 10.4432L21.1267 11.2595C21.3037 10.7574 21.4957 10.2538 21.6981 9.75423C22.6318 7.45025 23.7464 5.32969 24.5 3.98434C25.2536 5.32969 26.3682 7.45025 27.3019 9.75423C28.2711 12.146 29 14.6278 29 16.6153Z"
                fill="currentColor"
            />
            <rect x={24} y={21} width={1} height={5} rx={0.5} fill="currentColor" />
            <rect x={14.5} y={18.5} width={4} height={7} rx={0.5} stroke="currentColor" />
            <path d="M7.5 18C7.5 17.1716 8.17157 16.5 9 16.5H10C10.8284 16.5 11.5 17.1716 11.5 18V21C11.5 21.2761 11.2761 21.5 11 21.5H8C7.72386 21.5 7.5 21.2761 7.5 21V18Z" stroke="currentColor" />
            <rect x={3} y={12} width={20} height={1} rx={0.5} fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32ProductsHomeSummerhome;
