import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsArrowOpen = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M20.5 14L16 18L11.5 14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsArrowOpen;
