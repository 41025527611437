import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { Box, DateFormat, Flex } from "@monster/shared";
import { Accordion, AccordionItem, AccordionHeader, AccordionTriggerWithoutHeader, AccordionContent, Button, Tooltip, TooltipContent, TooltipTrigger } from "@monster/chr-ui";
import { SVGChrIcon32BasicsArrowOpen, SVGChrIcon32BasicsQuestionMark } from "@monster/chr-ui/dist/svg_assets";
import { ClaimData, Duration } from "@api/graphql/types";
import { LoctoolMessage, LoctoolNumber } from "@monster/loctool";
import { DateUtils } from "@utils/DateHelpers";
import { useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { Helpers } from "@utils/Helpers";
import { TravelInsuranceHelpers } from "@utils/InsuranceHelpers";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { ClaimTaskHelpers, ClaimTaskItemType } from "@utils/ClaimTaskHelpers";

type Props = {
    claim: ClaimData;
};

export const ClaimProgressAccordion = ({ claim }: Props) => {
    const theme = useTheme();
    const blocks = useClaimProgressBlocks(claim);

    return (
        <Accordion type={"multiple"}>
            {blocks.map((block, index) => (
                <AccordionItem key={`item-${index}`} value={`item-${index + 1}`}>
                    <AccordionHeader asChild>
                        <h2>
                            <AccordionTriggerWithoutHeader
                                iconElement={
                                    <Box as={"span"} $style={{ display: "block", borderRadius: "50%", boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyD}` }}>
                                        <SVGChrIcon32BasicsArrowOpen />
                                    </Box>
                                }
                            >
                                {block.label}
                            </AccordionTriggerWithoutHeader>
                        </h2>
                    </AccordionHeader>
                    <Box as={AccordionContent} $style={{ backgroundColor: theme.color.greyL }}>
                        <Flex.Container as={"dl"} $style={{ flexWrap: "wrap", margin: 0 }}>
                            {block.items.map((item, itemDataIndex) => (
                                <React.Fragment key={`item-${index}-${itemDataIndex}`}>
                                    <Flex.Item as={"dt"} $shrink={2 / 5} $style={{ boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, padding: "16px 8px 16px 16px" }}>
                                        {item.key}
                                    </Flex.Item>
                                    <Flex.Item
                                        as={"dd"}
                                        $shrink={3 / 5}
                                        $style={{ boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, margin: 0, padding: "16px 16px 16px 8px", textAlign: "right", wordBreak: "break-all" }}
                                    >
                                        {item.value}
                                    </Flex.Item>
                                </React.Fragment>
                            ))}
                        </Flex.Container>
                    </Box>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

const useClaimProgressBlocks = (claim: ClaimData) => {
    const relatedData = useSelector((state: ApplicationState) => state.session.relatedData);

    const insuranceRelatedData = useMemo(() => {
        if (!relatedData) {
            return [];
        }

        const { insuranceData, modTravelData, deHomePackagesData, homeData } = relatedData;
        if (modTravelData) {
            const isAnnual = modTravelData?.duration === Duration.YEARLY;
            const travelInsureeCategory = TravelInsuranceHelpers.getCategory(modTravelData);

            return [
                { key: <LoctoolMessage id="pages.fileClaim.cart.modtravel.insuranceid" />, value: insuranceData?.id },
                { key: <LoctoolMessage id="pages.fileClaim.cart.modtravel.tariff" />, value: <LoctoolMessage id={`enums.travelInsureeCategory.${travelInsureeCategory}`} /> },
                { key: <LoctoolMessage id="pages.fileClaim.cart.modtravel.duration" />, value: <LoctoolMessage id={isAnnual ? "enums.travelDuration.annual" : "enums.travelDuration.single"} /> },
                { key: <LoctoolMessage id="pages.fileClaim.cart.modtravel.destination" />, value: <LoctoolMessage id={`enums.travelDestination.${modTravelData?.destination ?? "europe"}`} /> },
            ];
        } else if (deHomePackagesData) {
            return [
                { key: <LoctoolMessage id="pages.fileClaim.cart.insuranceid" />, value: insuranceData?.id },
                { key: <LoctoolMessage id="pages.fileClaim.cart.tariff" />, value: <LoctoolMessage id={`enums.modHomeBase.${deHomePackagesData.typeOfCoverages}`} /> },
                {
                    key: <LoctoolMessage id="pages.fileClaim.cart.coveredUntil" />,
                    value: deHomePackagesData.endOfCoverage ? DateUtils.format(DateUtils.parseISO(deHomePackagesData.endOfCoverage), DateFormat.deShort) : "",
                },
            ];
        } else if (homeData) {
            return [
                { key: <LoctoolMessage id="pages.fileClaim.cart.insuranceid" />, value: insuranceData?.id },
                { key: <LoctoolMessage id="pages.fileClaim.cart.tariff" />, value: <LoctoolMessage id={`enums.homeInsuranceType.${homeData.homeInsuranceType}`} /> },
                {
                    key: <LoctoolMessage id="pages.fileClaim.cart.coveredUntil" />,
                    value: homeData.endOfCoverage ? DateUtils.format(DateUtils.parseISO(homeData.endOfCoverage), DateFormat.deShort) : "",
                },
            ];
        }
        return [];
    }, [relatedData]);

    const mandatoryDocuments = useSelector(SessionSelectors.getUploadedMandatoryDocumentsByClaimId(claim.id));
    const optionalDocuments = useSelector(SessionSelectors.getClaimOptionalUploadedDocumentsByClaimId(claim.id));
    const firstTaskDocuments = useMemo(() => [...mandatoryDocuments, ...optionalDocuments], [mandatoryDocuments, optionalDocuments]);
    const claimData = useMemo(() => {
        return [
            {
                key: (
                    <Box as={"span"} $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id="pages.claim.details.claimId" />
                    </Box>
                ),
                value: (
                    <Box as={"span"} $style={{ fontWeight: 700 }}>
                        {claim.id}
                    </Box>
                ),
            },
            {
                key: (
                    <>
                        <Box as={"span"} id={"b2cc"} $style={{ display: "block", fontWeight: 700 }}>
                            <LoctoolMessage id="pages.claim.details.bankAccountNumber" />{" "}
                        </Box>
                        {claim.bankAccountNumber}
                    </>
                ),
                value: (
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button.Secondary btnLabel={""} aria-label={"b2cc"} icon={<SVGChrIcon32BasicsQuestionMark />} size="xsmall" />
                        </TooltipTrigger>
                        <TooltipContent align="end" alignOffset={-16}>
                            <Box $style={{ width: "calc(100vw - 3.5 * 16px)" }} $styleSmall={{ width: 320 }} $styleMedium={{ width: 374 }}>
                                <LoctoolMessage id="pages.claim.details.bankAccountNumber.tooltip" />
                            </Box>
                        </TooltipContent>
                    </Tooltip>
                ),
            },
            { key: <LoctoolMessage id="pages.claim.details.claimCause" />, value: <LoctoolMessage id={`enums.cause.${claim.claimCause.translationKey ?? ""}`} /> },
            { key: <LoctoolMessage id="pages.claim.details.amount" />, value: <LoctoolNumber value={claim.claimAmount} format="currency" currency="EUR" /> },
            { key: <LoctoolMessage id="pages.claim.details.dateOfEvent" />, value: claim.dateOfEvent ? DateUtils.format(DateUtils.parseISO(claim.dateOfEvent), DateFormat.deShort) : "" },
            {
                key: <LoctoolMessage id="pages.claim.details.uploadedDocuments" />,
                value: (
                    <>
                        {firstTaskDocuments.length > 0 ? (
                            firstTaskDocuments.map(uploadedDocument => {
                                return <div key={uploadedDocument.id}>{Helpers.getUploadedClaimDocumentFileName(uploadedDocument)}</div>;
                            })
                        ) : (
                            <LoctoolMessage id="pages.claim.details.uploadedDocuments.noDocuments" />
                        )}
                    </>
                ),
            },
            {
                key: <LoctoolMessage id="pages.claim.details.dateOfReport" />,
                value: <span title={claim.dateOfReport}>{claim.dateOfReport ? DateUtils.format(DateUtils.parseISO(claim.dateOfReport), DateFormat.deShort) : ""}</span>,
            },
        ];
    }, [claim, firstTaskDocuments]);

    const accountData = useMemo(() => {
        if (!relatedData) {
            return [];
        }
        const { accountData } = relatedData;
        return [
            { key: <LoctoolMessage id="pages.claim.details.policyHolder.name" />, value: `${accountData?.firstName} ${accountData?.lastName}` },
            { key: <LoctoolMessage id="pages.claim.details.policyHolder.dateOfBirth" />, value: DateUtils.format(accountData?.dateOfBirth, DateFormat.deShort) },
            { key: <LoctoolMessage id="pages.claim.details.policyHolder.email" />, value: accountData?.email },
            { key: <LoctoolMessage id="pages.claim.details.policyHolder.phoneNumber" />, value: accountData?.phoneNumber },
        ];
    }, [relatedData]);

    const completedTasks = useSelector(SessionSelectors.getClaimAdditionalCompletedTasksByClaimId(claim.id));
    const completedTaskBlocks = useMemo(() => {
        return completedTasks.map(completedTask => {
            const additionalInfos = completedTask.items.filter(i => ClaimTaskHelpers.getAdditionalTextClaimTaskItemTypes().includes(i.claimTaskItemType as ClaimTaskItemType)).map(i => i.answer);
            const fileTaskItems = completedTask.items.filter(i => ClaimTaskHelpers.getAdditionalFileClaimTaskItemTypes().includes(i.claimTaskItemType as ClaimTaskItemType));
            const filesUploaded = claim?.uploadedDocuments.filter(ud => fileTaskItems.some(f => f.claimTaskItemId === ud.id)) ?? [];
            return {
                label: <LoctoolMessage id="pages.claim.details.additionalInfo.title" values={{ date: DateUtils.format(DateUtils.parseISO(completedTask.dateOfTask), DateFormat.deShort) }} />,
                items: [
                    ...(additionalInfos ? [{ key: <LoctoolMessage id="pages.claim.details.additionalInfo.info" />, value: additionalInfos.join("\n") }] : []),
                    {
                        key: <LoctoolMessage id="pages.claim.details.additionalInfo.uploadedFiles" />,
                        value: (
                            <>
                                {filesUploaded.length > 0 ? (
                                    filesUploaded.map(uploadedDocument => {
                                        return <div key={uploadedDocument.id}>{Helpers.getUploadedClaimDocumentFileName(uploadedDocument)}</div>;
                                    })
                                ) : (
                                    <LoctoolMessage id="pages.claim.details.uploadedDocuments.noDocuments" />
                                )}
                            </>
                        ),
                    },
                ],
            };
        });
    }, [claim?.uploadedDocuments, completedTasks]);

    const blocks = useMemo(
        () => [
            {
                label: <LoctoolMessage id="pages.claim.details.title" />,
                items: claimData,
            },
            {
                label: <LoctoolMessage id="pages.claim.details.policyHolder.title" />,
                items: accountData,
            },
            {
                label: <LoctoolMessage id="pages.claim.details.insuranceDetails.title" />,
                items: insuranceRelatedData,
            },
            ...completedTaskBlocks,
        ],
        [accountData, claimData, completedTaskBlocks, insuranceRelatedData]
    );
    return blocks;
};
