export enum ClaimTaskItemType {
    FILELIST__PROOF = "FILELIST__PROOF",
    IMAGELIST__DAMAGE = "IMAGELIST__DAMAGE",
    FILELIST__OTHER_ANYTIME = "FILELIST__OTHER_ANYTIME",
    FILE__OTHER = "FILE__OTHER",
    IMAGE__OTHER = "IMAGE__OTHER",
    TEXT__OTHER = "TEXT__OTHER",
    FILELIST__INVOICE = "FILELIST__INVOICE",
}

export class ClaimTaskHelpers {
    public static getMandatoryClaimTaskItemTypes(): ClaimTaskItemType[] {
        return [ClaimTaskItemType.FILELIST__PROOF, ClaimTaskItemType.IMAGELIST__DAMAGE, ClaimTaskItemType.FILELIST__INVOICE];
    }

    public static getAdditionalFileClaimTaskItemTypes(): ClaimTaskItemType[] {
        return [ClaimTaskItemType.FILE__OTHER, ClaimTaskItemType.IMAGE__OTHER];
    }

    public static getAdditionalTextClaimTaskItemTypes(): ClaimTaskItemType[] {
        return [ClaimTaskItemType.TEXT__OTHER];
    }
}
