import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverBag = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 3.5L26.5 27C26.5 27.4142 26.3321 27.7892 26.0607 28.0607C25.7892 28.3321 25.4142 28.5 25 28.5H7C6.58579 28.5 6.21079 28.3321 5.93934 28.0607C5.66789 27.7892 5.5 27.4142 5.5 27V4L26 3.5Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 3.5L26.5 13C26.5 13.4142 26.3321 13.7892 26.0607 14.0607C25.7892 14.3321 25.4142 14.5 25 14.5H7C6.58579 14.5 6.21079 14.3321 5.93934 14.0607C5.66789 13.7892 5.5 13.4142 5.5 13V4L26 3.5Z"
                stroke="currentColor"
            />
            <path d="M9.5 14.5V28.5" stroke="currentColor" strokeLinecap="square" />
            <path d="M21.5 14.5V28.5" stroke="currentColor" strokeLinecap="square" />
            <path d="M18 10H14V11H18V10Z" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverBag;
