import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverRealestate = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.5 11.5207C6.5 11.3575 6.57961 11.2046 6.71327 11.111L15.7133 4.81104L15.4265 4.40142L15.7133 4.81104C15.8854 4.69053 16.1146 4.69053 16.2867 4.81104L25.2867 11.111C25.4204 11.2046 25.5 11.3575 25.5 11.5207V25C25.5 25.2761 25.2761 25.5 25 25.5H7C6.72386 25.5 6.5 25.2761 6.5 25V11.5207Z"
                stroke="currentColor"
            />
            <rect x={13.5} y={18.5} width={5} height={7} rx={0.5} stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverRealestate;
