import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsNogo = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <line x1={20.8995} y1={11.4142} x2={12.4142} y2={19.8995} stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
            <circle cx={16} cy={16} r={7} stroke="currentColor" strokeWidth={2} />
        </SVGBase>
    );
};

export default SvgIcon32BasicsNogo;
