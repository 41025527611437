/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ForwardedRef } from "react";
import { IMaskMixin } from "react-imask";
import { TextFieldProps } from "@monster/shared";
import { TextField } from "@monster/chr-ui";

const MaskedInput = IMaskMixin(({ id, inputRef, ...props }) => <TextField {...props} id={id!} ref={inputRef} />);

export const BankAccountNumberInput = React.forwardRef(({ onChange, onBlur, ...props }: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const onAccept = (newUnmaskedValue: string, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>) => {
        const value = newUnmaskedValue.replace(/ /g, "");
        maskRef.value = value; // TODO: check if needed
        // @ts-ignore
        onChange && onChange({ target: { value, name: props.name! }, currentTarget: { value, name: props.name! } });
    };

    return (
        <MaskedInput
            // @ts-ignore
            inputRef={ref}
            mask="DE00 0000 0000 0000 0000 00"
            placeholderChar="_"
            {...props}
            // @ts-ignore
            unmask="typed"
            onAccept={onAccept}
            onBlur={event => {
                // @ts-ignore
                event.target = { value: event.target.value.replace(/ /g, ""), name: props.name };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onBlur && onBlur(event as any);
            }}
        />
    );
});
