import React from "react";
import { ButtonProps, isAnchorButtonProps } from "./ButtonTypes";
import { ButtonContent } from "./ButtonContent";
import { StyledIconOnlyBlueAzureButton } from "./ButtonIconOnlyBlueAzure.styled";

export const ButtonIconOnlyBlueAzure = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isAnchorButtonProps(props)) {
        return (
            <StyledIconOnlyBlueAzureButton forwardedAs={"a"} {...props} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
                <ButtonContent {...props} />
            </StyledIconOnlyBlueAzureButton>
        );
    }
    return (
        <StyledIconOnlyBlueAzureButton type={props.type ?? "button"} {...props} ref={ref}>
            <ButtonContent {...props} />
        </StyledIconOnlyBlueAzureButton>
    );
});
