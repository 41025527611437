// NTH: https://www.radix-ui.com/docs/primitives/components/toast
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import SvgIcon32BasicsOk from "../../svg_assets/Icon32BasicsOk";
import { SVGSharedIcon32BasicsX } from "@monster/shared/dist/svg_assets";
import { Box, Flex } from "@monster/shared";

const NOTIFICATION_DEFAULT_TIMEOUT = 3000;
let NOTIFICATION_ID = 1;

export type Notification = {
    id: number;
    message: string;
    type: "error" | "success";
    timeout?: number;
};

type Props = {};

type NotificationBarHandle = {
    showNotification: (message: string, type: "error" | "success", timeout?: number) => void;
};

export const NotificationBar = forwardRef<NotificationBarHandle, Props>((_, ref) => {
    const theme = useTheme();
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [currentNotification, setCurrentNotification] = useState<Notification | null>(notifications[0] ?? null);

    const removeNotification = useCallback(
        (id: number) => {
            setNotifications([...notifications].filter(n => n.id !== id));
        },
        [notifications]
    );

    useImperativeHandle(
        ref,
        () => {
            return {
                showNotification: (message: string, type: "error" | "success" = "error", timeout?: number) => {
                    notifications.push({ id: NOTIFICATION_ID++, message, type, timeout });
                    setNotifications([...notifications]);
                },
            };
        },
        [notifications]
    );

    useEffect(() => {
        if (!currentNotification && notifications.length > 0) {
            const newNotification = notifications[0];
            setCurrentNotification(newNotification);
            setTimeout(() => {
                removeNotification(newNotification.id);
                setCurrentNotification(null);
            }, newNotification.timeout ?? NOTIFICATION_DEFAULT_TIMEOUT);
        }
    }, [currentNotification, notifications, removeNotification]);

    const isSuccess = currentNotification?.type === "success";
    return (
        <div role="alert">
            {currentNotification && (
                <NotificationWrapper $animationDuration={currentNotification.timeout ?? NOTIFICATION_DEFAULT_TIMEOUT}>
                    <Box $style={{ color: theme.color.white, backgroundColor: isSuccess ? theme.color.greenN : theme.color.redN, borderRadius: 2, padding: "12px 16px" }}>
                        <Flex.Container $style={{ columnGap: 16 }}>
                            <Flex.Item $shrink="shrink">
                                <Box as={isSuccess ? SvgIcon32BasicsOk : SVGSharedIcon32BasicsX} $style={{ borderRadius: "50%", backgroundColor: theme.color.white, color: theme.color.blueD }} />
                            </Flex.Item>

                            <Flex.Item $shrink="auto" $style={{ padding: `${(32 - 24) / 2}px 0` }}>
                                {currentNotification.message}
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </NotificationWrapper>
            )}
        </div>
    );
});

export const keyframesSlideDownAndBackWithOpacity = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
`;

const NotificationWrapper = styled.div<{ $animationDuration: number }>`
    animation: ${keyframesSlideDownAndBackWithOpacity} ${({ $animationDuration }) => $animationDuration}ms forwards;
    left: 0;
    position: fixed;
    right: 0;
    top: 64px;
    z-index: ${({ theme }) => theme.zIndex.snackbar};
`;
