import { BackendEnvironments } from "./BackendEnvironments";
import { DateUtils } from "./DateHelpers";

export class Constants {
    public static accountDateOfBirthMin(): Date {
        return DateUtils.subYears(DateUtils.startOfToday(), 100);
    }

    public static defaultDateOfBirth(): Date {
        return DateUtils.subYears(new Date(), 30);
    }

    public static accountDateOfBirthMax(): Date {
        return DateUtils.subYears(DateUtils.startOfToday(), BackendEnvironments.accountMinAge());
    }

    public static firstDayMin(): Date {
        return DateUtils.startOfToday();
    }

    public static firstDayMax(): Date {
        return DateUtils.addDays(Constants.firstDayMin(), BackendEnvironments.forwardDateMaxDays());
    }

    public static readonly claimAllowedFileTypes = ["pdf", "jpg", "jpeg", "png", "img", "doc", "docx"];
    public static readonly claimFileInputAccept = Constants.claimAllowedFileTypes.map(type => `.${type}`).join(", ");
    public static readonly claimMaxMandatoryAttachedFileCount = 3;
    public static readonly claimMaxOptionalAttachedFileCount = 20;
    public static readonly claimDescriptionMaxLength = 2000;
    public static readonly claimMaxAdditionalInformationAttachedFileCount = 5;
    public static readonly claimAdditionalInformationMaxLength = 2000;
}
