import React from "react";
import { SharedDialog, SharedDialogProps, SharedDialogContentProps, hexToRGBA, bp } from "@monster/shared";
import styled, { keyframes } from "styled-components";

export const Dialog: React.FC<SharedDialogProps> = ({ triggerElement, portalled = true, children, ...rootProps }) => {
    return (
        <SharedDialog.Root {...rootProps}>
            {triggerElement && <SharedDialog.Trigger asChild>{triggerElement}</SharedDialog.Trigger>}
            {/* NOTE: because of Sandbox's iframe portalled={false} */}
            {portalled ? (
                <SharedDialog.Portal>
                    <StyledOverlay />
                    {children}
                </SharedDialog.Portal>
            ) : (
                <>
                    <StyledOverlay />
                    {children}
                </>
            )}
        </SharedDialog.Root>
    );
};

const overlayShow = keyframes`
    from {
        opacity: 0;
    }
    
    to {
        opacity: 1;
    }
`;

const contentShowMobile = keyframes`
    from {
        opacity: 0;
        transform: translate(0%, 2%) scale(.96);
    }
    to {
        opacity: 1;
        transform: translate(0%, 0%) scale(1);
    }
`;

const contentShowDesktop = keyframes`
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`;

export const StyledOverlay = styled(SharedDialog.Overlay)`
    background-color: ${({ theme }) => hexToRGBA(theme.color.greyD, 0.8)};
    position: fixed;
    inset: 0;
    z-index: ${({ theme }) => theme.zIndex.modal};

    @media (prefers-reduced-motion: no-preference) {
        animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
`;

export const DialogContent: React.FC<SharedDialogContentProps> = ({ children, ...contentProps }) => {
    return (
        <StyledDialogContent {...contentProps}>
            <DialogContentBox>{children}</DialogContentBox>
        </StyledDialogContent>
    );
};

const StyledDialogContent = styled(SharedDialog.Content)`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: ${({ theme }) => theme.zIndex.modal + 1};
    animation: ${contentShowMobile} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;

    &:focus {
        outline: none;
    }

    ${bp.medium} {
        left: 50%;
        top: 50%;
        right: unset;
        bottom: unset;
        transform: translate(-50%, -50%);
        animation: ${contentShowDesktop} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
`;

const DialogContentBox = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;

    ${bp.medium} {
        border-radius: 16px;
        width: 90vw;
        max-width: 764px;
        height: auto;
        max-height: 85vh;
        box-shadow: ${({ theme }) => theme.shadow.white};
        mix-blend-mode: multiply;
    }
`;
