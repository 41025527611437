import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverMovebaleproperty = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 13.75L19.3 16.1C19.4259 16.1944 19.5 16.3426 19.5 16.5V21C19.5 21.1381 19.444 21.2631 19.3536 21.3536C19.2631 21.444 19.1381 21.5 19 21.5H13C12.8619 21.5 12.7369 21.444 12.6464 21.3536C12.556 21.2631 12.5 21.1381 12.5 21V16.5C12.5 16.3426 12.5741 16.1944 12.7 16.1L15.7 13.85C15.7889 13.7833 15.8944 13.75 16 13.75Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 5.7207L25.2867 12.1111C25.4204 12.2047 25.5 12.3575 25.5 12.5207V26C25.5 26.1381 25.444 26.2631 25.3536 26.3536C25.2631 26.4441 25.1381 26.5 25 26.5H7C6.86193 26.5 6.73693 26.4441 6.64645 26.3536C6.55596 26.2631 6.5 26.1381 6.5 26V12.5207C6.5 12.3575 6.57961 12.2047 6.71327 12.1111L15.7133 5.81109C15.7993 5.75083 15.8997 5.7207 16 5.7207Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverMovebaleproperty;
