import React, { useCallback, useState } from "react";
import { useTheme } from "styled-components";
import { Box } from "@monster/shared";
import { Button, Radio, Surface } from "@monster/chr-ui";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { LoctoolMessage } from "@monster/loctool";
import { batchActions } from "redux-batched-actions";
import { SessionActions } from "@redux/actions/sessionActions";
import { AppStateActions, PageSteps } from "@redux/actions/appStateActions";
import { ClaimCause } from "@api/graphql/types";

type Props = {
    disabled: boolean;
};

export const ClaimCauseSelectionForm = ({ disabled }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const availableClaimCauses = useSelector((state: ApplicationState) => state.session.availableClaimCauses);
    const lastSelectedClaimCauseId = useSelector((state: ApplicationState) => state.session.claimInput?.claimCauseId ?? "");

    const [selectedClaimCauseId, setSelectedClaimCauseId] = useState<number>((availableClaimCauses.find(claimCause => claimCause.id === lastSelectedClaimCauseId) ?? availableClaimCauses[0])?.id);

    const onSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (!selectedClaimCauseId) {
                return;
            }
            dispatch(batchActions([SessionActions.updateClaimInputData({ claimCauseId: selectedClaimCauseId }), AppStateActions.setCurrentStep(PageSteps.ClaimDescription)]));
        },
        [dispatch, selectedClaimCauseId]
    );

    if (disabled) {
        const selectedClaimCause = availableClaimCauses.find(c => c.id === lastSelectedClaimCauseId);
        return (
            <Surface $variant="greyBorder">
                <Box $style={{ padding: 16 }}>
                    <LoctoolMessage id={`enums.cause.${selectedClaimCause?.translationKey ?? ""}`} />
                </Box>
            </Surface>
        );
    }

    return (
        <Box as="form" $style={{ paddingBottom: 24 }} $styleLarge={{ paddingBottom: 0 }} onSubmit={onSubmit}>
            <Surface $variant="whiteArrowBottom" $styleLarge={{ boxShadow: theme.shadow.white, "&::after": { content: "normal" } }}>
                <Box $style={{ paddingTop: 8, ">*+*": { boxShadow: `0px -1px 0px 0px ${theme.color.greyD}` } }}>
                    {availableClaimCauses.map((claimCause: ClaimCause) => {
                        const value = `${claimCause.id}`;
                        return (
                            <Box key={claimCause.id} $style={{ padding: "12px 16px" }}>
                                <Radio id={`cause-${claimCause.name}`} name="cause" onChange={() => setSelectedClaimCauseId(claimCause.id)} checked={`${selectedClaimCauseId}` === value} value={value}>
                                    <Box as="span" $style={{ display: "block", paddingTop: 4, paddingLeft: 16, fontWeight: 700 }}>
                                        <LoctoolMessage id={`enums.cause.${claimCause.translationKey ?? ""}`} />
                                    </Box>
                                </Radio>
                            </Box>
                        );
                    })}

                    <div>
                        <Box $style={{ maxWidth: 242 + 2 * 16, margin: "0 auto", padding: "24px 16px" }}>
                            <Button.Primary type="submit" btnLabel={<LoctoolMessage id="components.causeselectionform.btn.continue" />} isExpanded />
                        </Box>
                    </div>
                </Box>

                <Surface className="hide-for-large" $variant="whiteArrowBottomShadow" />
            </Surface>
        </Box>
    );
};
