import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverEverydaylife = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M27 11C28.1046 11 29 11.8954 29 13V26C29 27.1046 28.1046 28 27 28H8C6.89543 28 6 27.1046 6 26V21H7V26C7 26.5523 7.44772 27 8 27H27C27.5523 27 28 26.5523 28 26V13C28 12.4477 27.5523 12 27 12H26V11H27Z"
                fill="currentColor"
            />
            <path
                d="M23.5 4.5H5.5C4.39543 4.5 3.5 5.39543 3.5 6.5V18.5C3.5 19.6046 4.39543 20.5 5.5 20.5H23.5C24.6046 20.5 25.5 19.6046 25.5 18.5V6.5C25.5 5.39543 24.6046 4.5 23.5 4.5Z"
                stroke="currentColor"
            />
            <path d="M22.5 10H16.5C16.2239 10 16 10.2239 16 10.5C16 10.7761 16.2239 11 16.5 11H22.5C22.7761 11 23 10.7761 23 10.5C23 10.2239 22.7761 10 22.5 10Z" fill="currentColor" />
            <path d="M24.5 24H18.5C18.2239 24 18 24.2239 18 24.5C18 24.7761 18.2239 25 18.5 25H24.5C24.7761 25 25 24.7761 25 24.5C25 24.2239 24.7761 24 24.5 24Z" fill="currentColor" />
            <path d="M19.5 13H16.5C16.2239 13 16 13.2239 16 13.5C16 13.7761 16.2239 14 16.5 14H19.5C19.7761 14 20 13.7761 20 13.5C20 13.2239 19.7761 13 19.5 13Z" fill="currentColor" />
            <path d="M13 8.5H7C6.72386 8.5 6.5 8.72386 6.5 9V16C6.5 16.2761 6.72386 16.5 7 16.5H13C13.2761 16.5 13.5 16.2761 13.5 16V9C13.5 8.72386 13.2761 8.5 13 8.5Z" stroke="currentColor" />
            <path d="M10 12C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11C9 11.5523 9.44772 12 10 12Z" fill="currentColor" />
            <path d="M10 13C11.6569 13 13 14.3431 13 16H12C12 14.8954 11.1046 14 10 14C8.89543 14 8 14.8954 8 16H7C7 14.3431 8.34315 13 10 13Z" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverEverydaylife;
