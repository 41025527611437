import { css } from "styled-components";

export const commonNormalize = css`
    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        min-height: 100vh;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    button {
        /* NOTE: legacy normalization */
        /* background-color: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: block;
        font-family: inherit;
        margin: 0;
        padding: 0; */

        /* NOTE: more modern normalization */
        all: unset;
        box-sizing: inherit;
        cursor: pointer;
        font-family: inherit;
        user-select: none;
    }

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }

    // NOTE: added hyphens: auto; (Or use &shy; HTML entity in long words.)
    h1 {
        hyphens: auto;
    }

    legend {
        display: block;
        float: left;
        padding: 0;
        width: 100%;

        & + * {
            clear: both;
        }
    }

    svg,
    img {
        display: block;
    }

    /* Remember to define focus and focus-visible styles! http://www.outlinenone.com/ */
    :focus {
        outline: 0;
        /* outline: 4px solid red !important; */
    }

    :focus-visible {
        outline: 2px solid;
        outline-offset: -2px;
    }
`;
