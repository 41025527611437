export class ObjectUtils {
    public static keys<T extends Object>(obj: T): Array<keyof T> {
        return Object.keys(obj) as unknown as Array<keyof T>;
    }

    public static hasValue<T>(value: T | null | undefined): value is T {
        return typeof value !== "undefined" && value !== null;
    }

    public static clone<T>(value: T): T {
        return JSON.parse(JSON.stringify(value));
    }

    public static enumAsArray<T>(enumType: object): T[] {
        const attributes: string[] = Object.keys(enumType);
        const keys: string[] = attributes.filter((x: string): boolean => {
            return Number.isNaN(Number.parseInt(x, 10));
        });
        return keys as unknown as T[];
    }
}
