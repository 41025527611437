import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { Flex, flexItemShrinkVariant } from "@monster/shared";
import { Selector } from "./Button/Selector";
import { SVGSharedIcon32ProductsTravelCouple } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon32ProductsTravelFamily } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon32ProductsTravelIndividual } from "@monster/shared/dist/svg_assets";
import { SVGChrIcon32BasicsOk, SVGChrIcon32BasicsX, SVGChrIcon32ProductsTravelDestinationEu } from "../svg_assets";
import { SVGChrIcon32ProductsTravelDestinationInternational } from "../svg_assets";
import { SVGChrIcon32ProductsTravelDestinationWorldwide } from "../svg_assets";

type SelectorOption<T> = {
    id: string;
    label: React.ReactNode;
    value: T;
};

type Props<T> = {
    radioGroupName: string;
    value: T;
    onChange: (value: T) => void;
    options: Array<SelectorOption<T>>;
    renderOption?: (option: SelectorOption<T>, index: number, array: Array<SelectorOption<T>>) => React.ReactNode;
};

export function OptionSelector<T>({ radioGroupName, value, onChange, renderOption, options }: Props<T>) {
    const theme = useTheme();

    // TODO: where to put this render logic?
    const renderIcon = (key: string) => {
        if (key === "couple") {
            return <SVGSharedIcon32ProductsTravelCouple />;
        }

        if (key === "family") {
            return <SVGSharedIcon32ProductsTravelFamily />;
        }

        if (key === "single") {
            return <SVGSharedIcon32ProductsTravelIndividual />;
        }

        if (key === "EU") {
            return <SVGChrIcon32ProductsTravelDestinationEu />;
        }

        if (key === "WorldwideWithoutAmerica") {
            return <SVGChrIcon32ProductsTravelDestinationInternational />;
        }

        if (key === "Worldwide") {
            return <SVGChrIcon32ProductsTravelDestinationWorldwide />;
        }

        if (key === "yes") {
            return <SVGChrIcon32BasicsOk />;
        }

        if (key === "no") {
            return <SVGChrIcon32BasicsX />;
        }

        console.warn(`OptionSelector, missing svg: ${key}`);

        return <span style={{ width: 32, height: 32 }} />;
    };

    const defaultOptionRenderer = useMemo(
        () =>
            (option: SelectorOption<T>): React.ReactNode => {
                return (
                    <Flex.Item key={option.id} $shrink={1}>
                        <Selector.Input className="show-for-sr" id={`r-${option.id}`} type="radio" name={radioGroupName} checked={option.value === value} onChange={() => onChange(option.value)} />

                        <label htmlFor={`r-${option.id}`}>
                            <Flex.Container as="span" $style={{ gap: 16, alignItems: "center" }} $styleMedium={{ gap: 8, flexWrap: "wrap", textAlign: "center" }}>
                                <Flex.Item as="span" $shrink="shrink" $styleMedium={{ ...flexItemShrinkVariant({ $shrink: 1 }), width: "100%" }}>
                                    <Selector.Button icon={renderIcon(option.id)} size="large" />
                                </Flex.Item>

                                <Flex.Item
                                    as="span"
                                    $shrink="auto"
                                    $styleMedium={{ ...flexItemShrinkVariant({ $shrink: 1 }), width: "100%", minHeight: parseInt(theme.text.bodyDesktop.lineHeight) * 2 }}
                                >
                                    {option.label}
                                </Flex.Item>
                            </Flex.Container>
                        </label>
                    </Flex.Item>
                );
            },
        [theme, onChange, value, radioGroupName]
    );
    return (
        <Flex.Container $style={{ gap: 32, flexDirection: "column" }} $styleMedium={{ gap: 0, flexDirection: "row" }}>
            {options.map(renderOption ?? defaultOptionRenderer)}
        </Flex.Container>
    );
}
