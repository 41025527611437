import { LabelHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { BoxProps, boxTransientStylePropsCSS } from "@monster/shared";

const hasLinkStylesCSS = css`
    a {
        color: ${({ theme }) => theme.color.blueN};
        text-decoration: none;
    }

    @media (hover: hover) {
        a {
            &:hover {
                color: ${({ theme }) => theme.color.greenN};
            }
        }
    }
`;

export type ISurface = {
    $hasLinkStyles?: boolean;
    $variant: "grey" | "greyBorder" | "white" | "whiteArrowBottom" | "whiteArrowBottomShadow";
};

export const Surface = styled.div<BoxProps & ISurface & LabelHTMLAttributes<HTMLLabelElement>>`
    ${({ theme, $variant }) =>
        `
        ${
            $variant === "grey"
                ? `
            background-color: ${theme.color.greyN};
            border-radius: 16px;
            position: relative;

            &::before {
                border-radius: inherit;
                bottom: 0;
                box-shadow: ${theme.shadow.white};
                content: "";
                left: 0;
                mix-blend-mode: multiply;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
            }
        `
                : ""
        }

        ${
            $variant === "greyBorder"
                ? `
            background-color: ${theme.color.body.backgroundColor};
            border-radius: 16px;
            box-shadow: inset 0 0 0 1px ${theme.color.greyD};
        `
                : ""
        }

        ${
            $variant === "white" || $variant === "whiteArrowBottom" || $variant === "whiteArrowBottomShadow"
                ? `
            background-color: ${theme.color.white};
            border-radius: 16px;
            position: relative;

            &::before {
                border-radius: inherit;
                bottom: 0;
                box-shadow: ${theme.shadow.white};
                content: "";
                left: 0;
                mix-blend-mode: multiply;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: -2;
            }

            ${
                $variant === "whiteArrowBottom" || $variant === "whiteArrowBottomShadow"
                    ? `
                &::before {
                    @supports (filter: drop-shadow(${theme.shadow.white})) {
                        content: normal;
                    }
                }

                &::after {
                    background-color: ${theme.color.white};
                    border-radius: 4px;
                    bottom: -17px;
                    content: "";
                    height: 40px;
                    left: 50%;
                    pointer-events: none;
                    position: absolute;
                    transform-origin: center;
                    transform: translateX(-50%) rotate(45deg);
                    width: 40px;
                    z-index: -1;
                }

                ${
                    $variant === "whiteArrowBottomShadow"
                        ? `
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: -1;

                    @supports (filter: drop-shadow(${theme.shadow.white})) {
                        filter: drop-shadow(${theme.shadow.white});
                        mix-blend-mode: multiply;
                    }
                `
                        : ""
                }
            `
                    : ""
            }
        `
                : ""
        }
    `}

    ${({ $hasLinkStyles }) => ($hasLinkStyles ? hasLinkStylesCSS : "")}

    ${boxTransientStylePropsCSS}
`;
