import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsQuestionMark = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.5527 18.04L16.5287 17.608C16.2567 17.416 16.1127 17.096 16.1127 16.84C16.1127 15.272 19.2007 15.064 19.2007 12.648C19.2007 11.272 18.0167 10.168 16.0647 10.168C14.5447 10.168 13.3607 10.792 12.5927 11.8L13.3927 12.664C13.9847 11.864 14.7367 11.352 15.9527 11.352C17.2007 11.352 17.8247 12.04 17.8247 12.84C17.8247 14.536 14.9447 14.696 14.9447 16.728C14.9447 17.224 15.1207 17.672 15.5527 18.04ZM15.8887 21.16C16.3687 21.16 16.7687 20.76 16.7687 20.28C16.7687 19.8 16.3687 19.4 15.8887 19.4C15.4087 19.4 15.0087 19.8 15.0087 20.28C15.0087 20.76 15.4087 21.16 15.8887 21.16Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32BasicsQuestionMark;
