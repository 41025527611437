import { Duration, RelatedData_modTravelData } from "@api/graphql/types";

export enum TravelInsureeCategory {
    single = "single",
    couple = "couple",
    family = "family",
}

export class TravelInsuranceHelpers {
    public static isAnnual(modTravelData?: RelatedData_modTravelData | null): boolean {
        return modTravelData?.duration === Duration.YEARLY;
    }

    public static getCategory(modTravelData?: RelatedData_modTravelData | null): TravelInsureeCategory {
        const members = modTravelData?.travelMembers.filter(m => m.deleted === null) ?? [];
        if (members.length === 2) {
            return TravelInsureeCategory.couple;
        } else if (members.length > 2) {
            return TravelInsureeCategory.family;
        }
        return TravelInsureeCategory.single;
    }
}
