import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsCalendar = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x={5.5} y={8.5} width={21} height={17} rx={1.5} stroke="currentColor" />
            <rect x={10} y={5} width={1} height={6} rx={0.5} fill="currentColor" />
            <rect x={27} y={14} width={1} height={22} rx={0.5} transform="rotate(90 27 14)" fill="currentColor" />
            <rect x={21} y={5} width={1} height={6} rx={0.5} fill="currentColor" />
            <rect x={17.5} y={17.5} width={5} height={5} rx={0.5} stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsCalendar;
