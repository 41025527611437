import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverLaw = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 4.5L25.5 27L8 27.5C7.58579 27.5 7.21079 27.3321 6.93934 27.0607C6.66789 26.7892 6.5 26.4142 6.5 26V6C6.5 5.58579 6.66789 5.21079 6.93934 4.93934C7.21079 4.66789 7.58579 4.5 8 4.5H25Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 24.5L25.5 27L8 27.5C7.58579 27.5 7.21079 27.3321 6.93934 27.0607C6.66789 26.7892 6.5 26.4142 6.5 26C6.5 25.5858 6.66789 25.2108 6.93934 24.9393C7.21079 24.6679 7.58579 24.5 8 24.5H25Z"
                stroke="currentColor"
            />
            <path d="M17 9H16V18H17V9Z" fill="currentColor" />
            <path d="M20 11H13V12H20V11Z" fill="currentColor" />
            <path d="M18 18H15V19H18V18Z" fill="currentColor" />
            <path d="M11 14C11 15.1046 11.8954 16 13 16C14.1046 16 15 15.1046 15 14" stroke="currentColor" />
            <path d="M18 14C18 15.1046 18.8954 16 20 16C21.1046 16 22 15.1046 22 14" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverLaw;
