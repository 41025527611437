import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Path } from "@utils/Path";
import { Loctool } from "@monster/loctool";
import RouteContainer from "@components/RouteContainer";
import { Cookie, LoadingProp, withLoading } from "@monster/shared";
import { ServerDate } from "@utils/DateHelpers";
import { NotificationProp, Page500, withNotification } from "@monster/chr-ui";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { Api, CmsGql, Gql } from "@api/Api";
import { connect, DispatchProp } from "react-redux";
import { BackendEnvironmentsActions } from "@redux/actions/backendEnvironmentsActions";
import { batchActions } from "redux-batched-actions";
import { SessionActions } from "@redux/actions/sessionActions";
import { AppStateActions } from "@redux/actions/appStateActions";
import { ClaimCustomerCarePage } from "@pages/ClaimCustomerCarePage";
import { ClaimPage } from "@pages/Claim/ClaimPage";
import { LegalDocumentActions } from "@redux/actions/legalDocumentActions";
import GuestRoute from "@components/routes/GuestRoute";
import PrivateRoute from "@components/routes/PrivateRoute";
import { LoginPage } from "@pages/Login/LoginPage";
import { CreateClaimPage } from "@pages/CreateClaim/CreateClaimPage";
import { AccidentClaimPage } from "@pages/AccidentClaim/AccidentClaimPage";
import { Helpers } from "@utils/Helpers";
import { LoginByClaimPage } from "@pages/LoginWithClaim/LoginByClaimPage";
import { OpeningHoursActions } from "@redux/actions/openingHoursActions";
import { ApplicationState } from "@redux/Reducers";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { ClaimErrorPage } from "@pages/ClaimErrorPage";

type ReduxProps = {
    isAuthenticated: boolean;
    isClaimInProgress: boolean;
};

type Props = ReduxProps & NotificationProp & LoadingProp & DispatchProp;

type State = {
    currentKey: number;
    isLoading: boolean;
    isErrorOccurred: boolean;
};

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { currentKey: 0, isLoading: true, isErrorOccurred: false };
    }

    public async componentDidMount(): Promise<void> {
        Loctool.mount(this.onIntlConfigChange);
        try {
            await ServerDate.syncDateFromServer();
            const urlAuthToken = Helpers.getLocationParameterByName("sessionId", window.location.search);
            if (urlAuthToken) {
                Cookie.claimSessionId.set(urlAuthToken);
            }

            try {
                const appData = await Api.getAppData(Gql.CountryCode.DE);
                this.props.dispatch(batchActions([BackendEnvironmentsActions.update(appData.backendEnvironments), LegalDocumentActions.update(appData.effectiveLegalDocuments ?? [])]));
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    this.props.showNotification(error.intlMessage);
                }
            }

            const authToken = Cookie.claimSessionId.get();
            if (authToken) {
                try {
                    const relatedData = await Api.getRelatedData();
                    const claimCauses = await Api.getClaimCauses(relatedData.insuranceData.id);
                    this.props.dispatch(batchActions([SessionActions.updateRelatedData(relatedData), SessionActions.updateClaimCauses(claimCauses)]));
                } catch (error) {
                    this.props.dispatch(AppStateActions.reset());
                    Cookie.claimSessionId.remove();
                }
            }

            this.setState({ isLoading: false });
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                this.props.showNotification(error.intlMessage);
            }
            this.props.dispatch(AppStateActions.reset());
            Cookie.claimSessionId.remove();
            this.setState({ isErrorOccurred: true, isLoading: false });
        }

        try {
            const openingHours = await Api.getOpeningHours(CmsGql.CountryCode.DE);
            this.props.dispatch(OpeningHoursActions.update(openingHours));
        } catch (error) {
            console.warn({ error });
        }

        this.props.removeLoading();
    }

    public componentWillUnmount(): void {
        Loctool.unmount();
    }

    private onIntlConfigChange = (): void => {
        this.setState({ currentKey: this.state.currentKey + 1 });
    };

    private getFallbackRedirectPath = () => {
        if (!this.props.isAuthenticated) {
            return Path.login;
        }

        return Path.createClaim;
    };

    public render() {
        if (this.state.isLoading) {
            return null;
        }

        if (this.state.isErrorOccurred) {
            return <Page500 />;
        }

        return (
            <RouteContainer key={this.state.currentKey}>
                <Switch>
                    <Route exact path={Path.login} component={LoginPage} />
                    <Route exact path={Path.loginByClaim} component={LoginByClaimPage} />
                    <GuestRoute exact path={Path.accidentClaim} component={AccidentClaimPage} />
                    <Route exact path={Path.customerCare} component={ClaimCustomerCarePage} />
                    <PrivateRoute exact path={Path.claimError} component={ClaimErrorPage} />
                    <PrivateRoute exact path={Path.createClaim} component={CreateClaimPage} />
                    <PrivateRoute exact path={Path.claim(":claimId")} component={ClaimPage} />
                    <Redirect from="*" to={this.getFallbackRedirectPath()} />
                </Switch>
            </RouteContainer>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { isAuthenticated: SessionSelectors.isAuthenticated(state), isClaimInProgress: SessionSelectors.isClaimInProgress(state) };
};

export default withLoading(withNotification(connect(mapStateToProps)(App)));
