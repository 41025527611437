import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverEmergency = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.6787 7.5C19.3067 7.5 19.9106 7.66825 20.4355 7.97281C20.9603 8.27738 21.406 8.71826 21.7176 9.26351L24.7098 14.5H28C28.4142 14.5 28.7892 14.6679 29.0607 14.9393C29.3321 15.2108 29.5 15.5858 29.5 16V24L26.4499 24.5011C26.3209 25.1357 25.9512 25.6827 25.4408 26.0434C25.0336 26.3311 24.5365 26.5 24 26.5C23.4635 26.5 22.9664 26.3311 22.5592 26.0434C22.0487 25.6826 21.6791 25.1356 21.5501 24.5009H10.4499C10.3209 25.1356 9.95129 25.6826 9.44079 26.0434C9.03359 26.3311 8.5365 26.5 8 26.5C7.4635 26.5 6.96641 26.3311 6.55921 26.0434C6.04875 25.6827 5.67911 25.1357 5.55011 24.5011L3 24.5L2.5 8L18.6787 7.5Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 4.5C16.4142 4.5 16.7892 4.66789 17.0607 4.93934C17.3321 5.21079 17.5 5.58579 17.5 6V7.5H14.5V6C14.5 5.58579 14.6679 5.21079 14.9393 4.93934C15.2108 4.66789 15.5858 4.5 16 4.5Z"
                stroke="currentColor"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M8 10V12H10V13H8V15H7V12.999L5 13V12L7 11.999V10H8Z" fill="currentColor" />
            <path d="M2.5 18.5H29.5185" stroke="currentColor" strokeLinecap="square" />
            <path d="M8 26.5C9.38071 26.5 10.5 25.3807 10.5 24C10.5 22.6193 9.38071 21.5 8 21.5C6.61929 21.5 5.5 22.6193 5.5 24C5.5 25.3807 6.61929 26.5 8 26.5Z" stroke="currentColor" />
            <path d="M24 26.5C25.3807 26.5 26.5 25.3807 26.5 24C26.5 22.6193 25.3807 21.5 24 21.5C22.6193 21.5 21.5 22.6193 21.5 24C21.5 25.3807 22.6193 26.5 24 26.5Z" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverEmergency;
