import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverHelicopter = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 13.5C25.5188 13.5 26.8938 14.1156 27.8891 15.1109C28.8844 16.1062 29.5 17.4812 29.5 19V20.5H18.3385L16.5619 16.0585L18.2676 13.5H24Z"
                stroke="currentColor"
            />
            <path d="M16.5 15.5H2.5" stroke="currentColor" strokeLinecap="square" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.62285 9.5L7.33713 15.5H2.5V9.5H5.62285Z" stroke="currentColor" />
            <path d="M30 9H11V10H30V9Z" fill="currentColor" />
            <path d="M30 23H17V24H30V23Z" fill="currentColor" />
            <path d="M21 14V10H20V14H21Z" fill="currentColor" />
            <path d="M21 23V20H20V23H21Z" fill="currentColor" />
            <path d="M27 23V20H26V23H27Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 15V16H22V17H21V18H20V16.999L19 17V16L20 15.999V15H21Z" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverHelicopter;
