import { createStore, applyMiddleware, compose, Middleware, Reducer } from "redux";
import reducers, { ApplicationState } from "./Reducers";
import { persistStore } from "redux-persist";
import { enableBatching } from "redux-batched-actions";

const middlewares: Middleware[] = [];

const composeEnhancers = (process.env.REACT_APP_ENVIRONMENT !== "prod" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(enableBatching(reducers as unknown as Reducer<ApplicationState>), enhancer);

const persistor = persistStore(store);

export { persistor, store };
