import de from "date-fns/locale/de";
import en from "date-fns/locale/en-US";
import { ServerDateClass, DateUtils as DateUtilsClass, DateFormat } from "@monster/shared";
import { Locale } from "@monster/loctool";

export const ServerDate = new ServerDateClass(async () => {
    return new Date().toISOString();
});

export const DateUtils = new DateUtilsClass(ServerDate, de);

export const setDateUtilsLocale = (locale: Locale) => {
    if (locale === "de") {
        DateUtils.setLocale(de);
        return;
    }
    DateUtils.setLocale(en);
};

export const DateInputParser = {
    format: (date: Date): string => {
        return DateUtils.format(date, DateFormat.de);
    },
    parse: (dateString: string): Date => {
        return DateUtils.parse(dateString, DateFormat.api);
    },
};
