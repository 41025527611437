import styled, { css } from "styled-components";
import { StyledButton } from "./Button.styled";

const cherriskCommonButtonCSS = css`
    border-radius: 36px;
`;

const cherriskCTAButtonCSS = css`
    ${cherriskCommonButtonCSS}

    background-color: ${({ theme }) => theme.color.yellowN};
    color: ${({ theme }) => theme.color.blueD};

    /* NOTE: missing from Figma */
    /* @media (hover: hover) {&:hover {
  }} */

    /* NOTE: missing from Figma */
    /* &:disabled {
  } */
`;

const cherriskCTAButtonIsInverseCSS = css`
    ${cherriskCommonButtonCSS}/* NOTE: missing from Figma */

  /* NOTE: missing from Figma */
  /* @media (hover: hover) {&:hover {
    }} */

  /* NOTE: missing from Figma */
  /* &:disabled {
  } */
`;

export const StyledCTAButton = styled(StyledButton)`
    ${({ isInverse }) => (isInverse ? cherriskCTAButtonIsInverseCSS : cherriskCTAButtonCSS)}
`;
