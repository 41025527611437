import React from "react";
import { useTheme } from "styled-components";
import { StyledOverlay, Surface, Button, Text } from "@monster/chr-ui";
import { LoctoolMessage } from "@monster/loctool";
import { SharedDialogProps, SharedDialog, Box } from "@monster/shared";

type Props = SharedDialogProps;

export const ClaimNotAllowedDialog = (props: Props) => {
    const theme = useTheme();
    const onCloseClick = () => {
        window.location.reload();
    };

    return (
        <SharedDialog.Root {...props}>
            <Box as={StyledOverlay} $style={{ display: "grid", placeItems: "flex-start center", overflow: "auto" }} $styleMedium={{ placeItems: "center" }}>
                <SharedDialog.Content asChild aria-describedby={undefined}>
                    <Surface
                        $variant={"white"}
                        $style={{ maxWidth: 508, width: "100%", margin: "64px 0", padding: "56px 16px 40px", "&:focus-visible": { outline: "none" } }}
                        $styleMedium={{ paddingLeft: 32, paddingRight: 32 }}
                    >
                        <Box $style={{ textAlign: "center" }}>
                            <SharedDialog.Title asChild>
                                <Text as={"h2"} $variant={"h3Desktop"} $style={{ margin: 0 }}>
                                    <LoctoolMessage id="components.claimNotAllowedDialog.title" />
                                </Text>
                            </SharedDialog.Title>
                            <Box as={"p"} $style={{ margin: "24px 0 32px", a: { color: theme.color.secondary500, textDecoration: "none" } }}>
                                <LoctoolMessage id="components.claimNotAllowedDialog.description" />
                            </Box>
                        </Box>
                        <Box $style={{ maxWidth: 288, margin: "0 auto", paddingTop: 8 }} $styleMedium={{ maxWidth: 208 }}>
                            <SharedDialog.Close asChild>
                                <Button.Secondary btnLabel={<LoctoolMessage id="common.close" />} isExpanded onClick={onCloseClick} />
                            </SharedDialog.Close>
                        </Box>
                    </Surface>
                </SharedDialog.Content>
            </Box>
        </SharedDialog.Root>
    );
};
