import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravelTypeDomestic = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5 2C27.5376 2 30 4.45461 30 7.48252C30 8.4319 29.5945 9.56279 28.7834 10.8752L28.6391 11.1039C29.4298 11.3703 30 12.1186 30 13V26C30 27.0544 29.1841 27.9182 28.1493 27.9945L28 28C16 28 16 22 5 22H4C2.89543 22 2 21.1046 2 20V7C2 5.89543 2.89543 5 4 5H5C12.5429 5 14.9135 7.82128 19.5278 9.59461L19.5518 9.64761C19.1839 8.84359 19 8.12189 19 7.48252C19 4.45461 21.4624 2 24.5 2ZM25.5236 14.9493L25.1938 15.2937C24.8266 15.6474 24.259 15.6645 23.8725 15.3522L23.7798 15.2673L23.4764 14.9494C22.9349 14.3766 22.4426 13.8258 21.9994 13.297L22.0007 26.3554C23.7355 26.812 25.558 27.0003 27.9633 27.0007L28.0757 26.9972C28.5945 26.9589 29 26.5243 29 26V13C29 12.4574 28.5679 12.0158 28.021 12.0004L28.0597 11.9478C27.3885 12.8704 26.5431 13.8709 25.5236 14.9493ZM10.0005 6.51833L10.001 21.4625C11.8869 21.875 13.618 22.5875 15.779 23.6935L17.0056 24.3295L17.7838 24.7194C18.9306 25.2803 19.967 25.7213 21.0005 26.0599V12.0301C20.8207 11.7855 20.653 11.5464 20.4975 11.3127L20.2424 10.9168C18.9271 10.477 17.6068 9.88436 16.0922 9.10881L14.8819 8.48108C13.0339 7.53432 11.5545 6.90584 10.0005 6.51833ZM5 6H4C3.44772 6 3 6.44772 3 7V20C3 20.5523 3.44772 21 4 21H5C6.5176 21 7.80707 21.0843 9.00113 21.2741L9.00096 6.30416C7.81777 6.09107 6.54324 6 5 6ZM24.5 3C22.014 3 20 5.00761 20 7.48252C20 8.97309 21.3818 11.278 24.1998 14.2589L24.5 14.5735C27.519 11.4394 29 9.02734 29 7.48252C29 5.00761 26.986 3 24.5 3Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravelTypeDomestic;
