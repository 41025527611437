import React from "react";

export const accordionClickHandler =
    ({ parent, offset, timeout }: { offset: number; parent: string; timeout: number }) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const button = event.target as HTMLButtonElement;
        const parentElement = button.closest(parent);
        const { state } = button.dataset;

        if (state === "closed" && parentElement) {
            setTimeout(() => {
                const parentTop = parentElement?.getBoundingClientRect().top ?? 0;
                const scrollToPos = parentTop + window.scrollY - offset;
                window.scrollTo({ top: scrollToPos, behavior: "smooth" });
            }, timeout);
        }
    };
