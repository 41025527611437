import { AppBarLogo } from "@components/AppBar";
import { Page } from "@components/Page";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LoctoolMessage } from "@monster/loctool";
import { Box, Cookie } from "@monster/shared";
import { Text, AppBar as ChrAppBar } from "@monster/chr-ui";
import { EmailCodeConfirmationForm } from "@components/EmailCodeConfirmationForm";
import { FileClaimPageList, FileClaimPageListItem } from "@components/PageListItems";
import { Api } from "@api/Api";
import { ClaimIdForm } from "./ClaimIdForm";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { useSelector } from "react-redux";
import { Path } from "@utils/Path";
import { useHistory } from "react-router-dom";

enum Step {
    PolicyInput = 1,
    VerifyCode = 2,
}

export const LoginByClaimPage = () => {
    const [currentStep, setCurrentStep] = useState<Step>(Step.PolicyInput);
    const [policyResult, setPolicyResult] = useState({ claimId: "", sessionId: "", maskedEmail: "" });
    const isAuthenticated = useSelector(SessionSelectors.isAuthenticated);
    const history = useHistory();

    const onClaimFormSubmit = useCallback(async (data: { claimId: string }) => {
        const result = await Api.sendValidationEmailByClaimId(data.claimId);
        Cookie.claimSessionId.set(result.sessionId);
        setPolicyResult({ ...result, ...data });
        setCurrentStep(Step.VerifyCode);
    }, []);

    const initialValues = useMemo(() => {
        return { claimId: policyResult?.claimId ?? "" };
    }, [policyResult?.claimId]);

    useEffect(() => {
        if (isAuthenticated) {
            const path = policyResult.claimId ? Path.claim(policyResult.claimId) : Path.createClaim;
            history.push(path);
        }
    }, [history, isAuthenticated, policyResult.claimId]);

    return (
        <Page appBar={<ChrAppBar leftItemChild={<AppBarLogo />} />}>
            <Box $style={{ maxWidth: 504, margin: "0 auto" }}>
                <Text as="h1" $variant="h2Desktop" $style={{ margin: 0, padding: "40px 16px 16px" }} $styleLarge={{ paddingTop: 44, paddingBottom: 0 }}>
                    <LoctoolMessage id="page.claimLoginPage.title" />
                </Text>
                <FileClaimPageList as="ol">
                    <FileClaimPageListItem
                        id="PolicyDetails"
                        headingText={<LoctoolMessage id="page.claimLoginPage.policyDetails.title" />}
                        isActive={currentStep === Step.PolicyInput}
                        isFinished={currentStep > Step.PolicyInput}
                        forceEditButton={currentStep < Step.PolicyInput}
                        onEditClick={() => {
                            Cookie.claimSessionId.remove();
                            setCurrentStep(Step.PolicyInput);
                        }}
                    >
                        <ClaimIdForm initialValues={initialValues} onSubmit={onClaimFormSubmit} disabled={currentStep !== Step.PolicyInput} />
                    </FileClaimPageListItem>

                    <FileClaimPageListItem
                        id="EmailConfirmation"
                        headingText={<LoctoolMessage id="page.claimLoginPage.emailConfirmation.title" />}
                        isActive={currentStep === Step.VerifyCode}
                        isFinished={currentStep > Step.VerifyCode}
                        forceEditButton={currentStep > Step.VerifyCode}
                        hideContent={currentStep < Step.VerifyCode}
                    >
                        <EmailCodeConfirmationForm
                            maskedEmail={policyResult.maskedEmail}
                            onResendClick={async () => {
                                const result = await Api.sendValidationEmailByClaimId(policyResult.claimId);
                                setPolicyResult(pr => ({ ...pr, ...result }));
                            }}
                        />
                    </FileClaimPageListItem>
                </FileClaimPageList>
            </Box>
        </Page>
    );
};
