import React, { useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router";
import { Loctool } from "@monster/loctool";
import { usePrevious } from "@monster/shared";

type Props = { children: React.ReactNode | undefined };

const RouteContainer = (props: Props) => {
    const location = useLocation();
    const prevPathname = usePrevious(location.pathname);
    useLayoutEffect(() => {
        if (prevPathname && location.pathname !== prevPathname) {
            Loctool.clearUsedIntlKeys();
        }
        if (location.pathname !== prevPathname) {
        }
    }, [location, prevPathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return <>{props.children}</>;
};

export default RouteContainer;
