import { gql } from "@apollo/client";

export const LegalDocumentWLFragment = gql`
    fragment LegalDocumentWL on LegalDocumentWL {
        category
        interaction
        version
        effectiveFrom
        effectiveUntil
        name
        url
    }
`;
