import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverTerrorism = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16 27.5C22.3513 27.5 27.5 22.3513 27.5 16C27.5 9.64873 22.3513 4.5 16 4.5C9.64873 4.5 4.5 9.64873 4.5 16C4.5 22.3513 9.64873 27.5 16 27.5Z" stroke="currentColor" />
            <path
                d="M16 21C17.3062 21 18.4175 21.8349 18.8293 23.0001L17.7324 22.9999C17.3866 22.4022 16.7402 22 16 22C15.2597 22 14.6134 22.4022 14.2676 22.9999L13.1707 23.0001C13.5825 21.8349 14.6937 21 16 21Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.6561 15.6371C22.8386 17.0536 23.5 18.1876 23.5 19C23.5 19.6904 23.2202 20.3154 22.7678 20.7678C22.3154 21.2202 21.6904 21.5 21 21.5C20.3096 21.5 19.6846 21.2202 19.2322 20.7678C18.7798 20.3154 18.5 19.6904 18.5 19C18.5 18.1876 19.1614 17.0536 20.6561 15.6371Z"
                stroke="currentColor"
                strokeLinejoin="round"
            />
            <path
                d="M14.9645 13.0006C14.7216 14.6964 13.263 16 11.5 16C9.73696 16 8.27839 14.6964 8.03552 13.0006L9.04991 12.9995C9.28136 14.1409 10.2903 15 11.5 15C12.7096 15 13.7186 14.1409 13.9501 12.9995L14.9645 13.0006Z"
                fill="currentColor"
            />
            <path
                d="M23.929 13.0006C23.6862 14.6964 22.2276 16 20.4646 16C18.7015 16 17.243 14.6964 17.0001 13.0006L18.0145 12.9995C18.2459 14.1409 19.2549 15 20.4646 15C21.6742 15 22.6832 14.1409 22.9146 12.9995L23.929 13.0006Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverTerrorism;
