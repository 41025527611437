import React from "react";
import styled, { useTheme } from "styled-components";
import { Box, Flex, Container, bp, SharedDialog, SharedDialogProps } from "@monster/shared";
import { Text, useTextVariant } from "../Text";
import { SVGChrBrandCherriskByUniqa } from "../../svg_assets";
import Lottie from "lottie-react";
import lottieAccident from "./lottieAccident.json";
import lottieGeneral from "./lottieGeneral.json";
import lottieHome from "./lottieHome.json";
import lottieTravel from "./lottieTravel.json";

export const ProcessAnimatedIcon: React.FC<{ variant: "accident" | "general" | "home" | "travel" }> = props => {
    const renderIcon = () => {
        switch (props.variant) {
            case "accident":
                return <Lottie animationData={lottieAccident} loop={true} />;
            case "general":
                return <Lottie animationData={lottieGeneral} loop={true} />;
            case "home":
                return <Lottie animationData={lottieHome} loop={true} />;
            case "travel":
                return <Lottie animationData={lottieTravel} loop={true} />;
        }
    };

    return <Box $style={{ width: 152, height: 152 }}>{renderIcon()}</Box>;
};

type Props = {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    icon: React.ReactElement;
};

export const ProcessScreen: React.FC<Props> = props => {
    const theme = useTheme();
    const TextVariant = useTextVariant();
    return (
        <Box $style={{ display: "flex", flexDirection: "column", height: "100vh", backgroundColor: theme.color.white }}>
            <Header>
                <HeaderContainer>
                    <SVGChrBrandCherriskByUniqa title="Cherrisk by Uniqa" />
                </HeaderContainer>
            </Header>
            <ContentContainer>
                <Flex.Container $style={{ justifyContent: "center", paddingBottom: 40 }}>{props.icon}</Flex.Container>
                <Text $variant="h3Mobile" $style={{ fontWeight: 700, marginBottom: 6 }} $styleMedium={{ ...TextVariant({ $variant: "h2Desktop" }), marginBottom: 20 }}>
                    {props.title}
                </Text>
                <Text $variant="bodyMobile">{props.subTitle}</Text>
            </ContentContainer>
        </Box>
    );
};

const Header = styled.header`
    height: 64px;
    background-color: ${({ theme }) => theme.color.white};
    z-index: ${({ theme }) => theme.zIndex.appBar};
    box-shadow: inset 0 -1px ${({ theme }) => theme.color.greyD};
`;

const HeaderContainer = styled(Container)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${bp.large} {
        justify-content: flex-start;
    }
`;

const ContentContainer = styled(Container)`
    text-align: center;
    padding-block: 32px;

    ${bp.medium} {
        padding-block: 48px;
    }

    ${bp.large} {
        padding-block: 120px;
    }
`;

export const ProcessScreenDialog: React.FC<SharedDialogProps> = ({ children, ...rootProps }) => {
    return (
        <SharedDialog.Root {...rootProps}>
            <SharedDialog.Portal>
                <SharedDialog.Overlay />
                {children}
            </SharedDialog.Portal>
        </SharedDialog.Root>
    );
};

export const ProcessScreenDialogContent = styled(SharedDialog.Content)`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: ${({ theme }) => theme.zIndex.modal + 2};

    &:focus {
        outline: none;
    }
`;
