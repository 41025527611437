import styled, { css } from "styled-components";
import { StyledButton } from "./Button.styled";

const cherriskCommonButtonCSS = css`
    border-radius: 36px;
`;

const cherriskIconOnlyGreyDButtonCSS = css`
    ${cherriskCommonButtonCSS}

    background-color: ${({ theme }) => theme.color.greyD};
    /* box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD}; */
    color: ${({ theme }) => theme.color.blueD};

    /* @media (hover: hover) {&:hover {
        box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.greyD};
    }} */

    &:disabled {
        background-color: ${({ theme }) => theme.color.greyN};
        /* box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD}; */
        color: ${({ theme }) => theme.color.blueL};
    }
`;

const cherriskIconOnlyGreyDButtonIsInverseCSS = css`
    ${cherriskCommonButtonCSS}

    background-color: ${({ theme }) => theme.color.blueN};
    /* box-shadow: none; */
    color: ${({ theme }) => theme.color.white};

    /* NOTE: missing from Figma */
    /* @media (hover: hover) {&:hover {
        box-shadow: none;
    }} */

    &:disabled {
        background-color: ${({ theme }) => theme.color.greyN};
        /* box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD}; */
        color: ${({ theme }) => theme.color.blueL};
    }
`;
export const StyledIconOnlyGreyDButton = styled(StyledButton)`
    ${({ isInverse }) => (isInverse ? cherriskIconOnlyGreyDButtonIsInverseCSS : cherriskIconOnlyGreyDButtonCSS)}
`;

export const StyledIconOnlyGreyDSelectorButton = styled(StyledButton)`
    ${cherriskIconOnlyGreyDButtonCSS}

    cursor: pointer;
    user-select: none;
`;

export const StyledIconOnlyGreyDSelectorInput = styled.input`
    &:checked {
        & + label {
            ${StyledIconOnlyGreyDSelectorButton} {
                ${cherriskIconOnlyGreyDButtonIsInverseCSS}
            }
        }
    }

    &:disabled {
        & + label {
            ${StyledIconOnlyGreyDSelectorButton} {
                background-color: ${({ theme }) => theme.color.greyN};
                /* box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD}; */
                color: ${({ theme }) => theme.color.blueL};
                cursor: not-allowed;
            }
        }
    }
`;
