import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverDisabled = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.5 9.5V20.5001L17.4835 20.5009C17.4944 20.3354 17.5 20.1683 17.5 20C17.5 17.9289 16.6605 16.0539 15.3033 14.6967C13.9461 13.3395 12.0711 12.5 9.99999 12.5C9.12337 12.5 8.2819 12.6505 7.49994 12.9269L7.49999 9.5H22.5Z"
                stroke="currentColor"
            />
            <path d="M8 4H7V10H8V4Z" fill="currentColor" />
            <path d="M26 20H25V26H26V20Z" fill="currentColor" />
            <path d="M8 5V4L2 4V5L8 5Z" fill="currentColor" />
            <path d="M30 27V26H25V27H30Z" fill="currentColor" />
            <path d="M26 21V20H20V21H26Z" fill="currentColor" />
            <path d="M10 27.5C14.1421 27.5 17.5 24.1421 17.5 20C17.5 15.8579 14.1421 12.5 10 12.5C5.85786 12.5 2.5 15.8579 2.5 20C2.5 24.1421 5.85786 27.5 10 27.5Z" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverDisabled;
