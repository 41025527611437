import * as DateFns from "date-fns";

export class ServerDateClass {
    private lastServerDate = 0;
    private lastSyncDate = 0;
    private syncDelay: number = 10 * 60 * 1000;
    private syncInterval: number | null = null;
    private syncing = false;
    private getServerTime: () => Promise<string>;

    constructor(getServerTime: () => Promise<string>) {
        this.getServerTime = getServerTime;
    }

    public startSync = () => {
        this.syncInterval = window.setInterval(this.syncDateFromServer, this.syncDelay);
    };

    public stopSync = () => {
        if (this.syncInterval) {
            clearInterval(this.syncInterval);
        }
    };

    public syncDateFromServer = async () => {
        if (this.syncing) {
            console.debug("Server date already being synced...");
            return;
        }
        this.syncing = true;
        this.getServerTime()
            .then((time: string) => {
                const parsedDate = DateFns.parseISO(time);
                this.syncing = false;
                console.debug(`Server date synced: ${parsedDate}`);
                this.setServerDate(parsedDate);
            })
            .catch(error => {
                this.syncing = false;
                console.debug("Error syncing server time", error);
            });
    };

    private setServerDate(serverDate: Date) {
        if (!DateFns.isValid(serverDate)) {
            console.debug("Invalid server date sent: ", serverDate);
            return;
        }
        this.lastServerDate = serverDate.getTime();
        this.lastSyncDate = new Date().getTime();
    }

    public getDate(): Date {
        if (this.lastServerDate === 0 || this.lastSyncDate === 0) {
            console.debug("Server date not synced, returning client date");
            return new Date();
        }
        if (Date.now() - this.lastSyncDate > this.syncDelay) {
            console.debug("Server date out of sync");
            this.syncDateFromServer();
            return new Date();
        }
        const diff = Date.now() - this.lastSyncDate;
        return new Date(this.lastServerDate + diff);
    }
}
