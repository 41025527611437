import styled, { keyframes } from "styled-components";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

const slideUpAndFade = keyframes`
    0% { opacity: 0; transform: translateY(2px); }
    100% { opacity: 1; transform: translateY(0); }
`;

const slideRightAndFade = keyframes`
    0% { opacity: 0; transform: translateX(-2px); }
    100% { opacity: 1; transform: translateX(0); }
`;

const slideDownAndFade = keyframes`
    0% { opacity: 0; transform: translateY(-2px); }
    100% { opacity: 1; transform: translateY(0); }
`;

const slideLeftAndFade = keyframes`
    0% { opacity: 0; transform: translateX(2px); }
    100% { opacity: 1; transform: translateX(0); }
`;

export const DropdownMenu = DropdownMenuPrimitive.Root;

export const DropdownMenuTrigger = styled(DropdownMenuPrimitive.Trigger)`
    &:focus {
        outline: none;
    }
`;

export const DropdownMenuContent = styled(DropdownMenuPrimitive.Content)`
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: forwards;
    will-change: transform, opacity;

    &[data-state="open"] {
        &[data-side="top"] {
            animation-name: ${slideDownAndFade};
        }
        &[data-side="right"] {
            animation-name: ${slideLeftAndFade};
        }
        &[data-side="bottom"] {
            animation-name: ${slideUpAndFade};
        }
        &[data-side="left"] {
            animation-name: ${slideRightAndFade};
        }
    }
`;

export const DropdownMenuArrow = styled(DropdownMenuPrimitive.Arrow)`
    fill: ${({ theme }) => theme.color.white};
`;
