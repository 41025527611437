import styled, { css } from "styled-components";
import { StyledButton } from "./Button.styled";

/*
    NOTE: missing from Figma.
    In uniqa skin this is a green button. An alternative version to uniqa skin's Primary button but green.
    In chr skin we can use Button.Primary (which is also greenish)
*/
const cherriskCommonButtonCSS = css`
    border-radius: 36px;
`;

const cherriskPrimaryAltButtonCSS = css`
    ${cherriskCommonButtonCSS}/* @media (hover: hover) {&:hover {
    }} */

    /* &:disabled {
    } */
`;

/* NOTE: missing from Figma */
const cherriskPrimaryAltButtonIsInverseCSS = css`
    ${cherriskCommonButtonCSS}/* &:disabled {
    } */
`;

export const StyledPrimaryAltButton = styled(StyledButton)`
    ${({ isInverse }) => (isInverse ? cherriskPrimaryAltButtonIsInverseCSS : cherriskPrimaryAltButtonCSS)}
`;
