import styled from "styled-components";
/*
    NOTE: next.js has issues with this kind of css file import.
    "Global CSS cannot be imported from files other than your Custom <App>. Due to the Global nature of stylesheets, and to avoid conflicts, Please move all first-party global CSS imports to pages/_app.js. Or convert the import to Component-Level CSS (CSS Modules)."
*/
// import "react-day-picker/dist/style.css";
// As a temporary solution we copy-pasted it to Shame.
import { shameDayPickerCSS } from "@monster/shared";
import { Surface } from "../Surface";

/* NOTE: some states are missing from Figma */
export const DayPickerSurface = styled(Surface)`
    ${shameDayPickerCSS}

    --rdp-cell-size: 48px;
    --rdp-accent-color: ${({ theme }) => theme.color.blueN};
    --rdp-background-color: ${({ theme }) => theme.color.blueN};
    /* Switch to dark colors for dark themes (not in use) */
    --rdp-accent-color-dark: ${({ theme }) => theme.color.greyD};
    --rdp-background-color-dark: ${({ theme }) => theme.color.blueN};
    --rdp-outline: 3px solid ${({ theme }) => theme.color.blueD};
    --rdp-outline-selected: 2px solid ${({ theme }) => theme.color.blueD};

    .rdp {
        margin: 0;
        max-width: 384px;
        padding: 32px 24px 24px;
    }

    .rdp-button {
        border: 3px solid transparent;
        padding: 0;
    }

    .rdp-button[disabled] {
        background-color: ${({ theme }) => theme.color.greyN};
        color: ${({ theme }) => theme.color.blueL};
        cursor: not-allowed;
        opacity: 1;
    }

    .rdp-button:focus:not([disabled]),
    .rdp-button:active:not([disabled]) {
        color: ${({ theme }) => theme.color.white};
    }

    .rdp-button:hover:not([disabled]) {
        color: ${({ theme }) => theme.color.white};
    }

    .rdp-caption {
        gap: 16px;
    }

    .rdp-nav {
        margin-bottom: -8px;
        margin-top: -8px;
    }
`;
