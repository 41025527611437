import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsXBold = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <line x1={20.3398} y1={20.3541} x2={11.7541} y2={11.7683} stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
            <line x1={1} y1={-1} x2={13.1421} y2={-1} transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 10.3 20.3541)" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsXBold;
