import { gql } from "@apollo/client";
import { EmailValidationResultFragment } from "./fragments/EmailValidationResultFragment";
import { VoidTypeFragment } from "./fragments/VoidTypeFragment";

export const Mutations = Object.freeze({
    sendValidationEmail: gql`
        mutation sendValidationEmail($insuranceId: String!, $dateOfBirth: LocalDate!) {
            sendValidationEmail(id: $insuranceId, date: $dateOfBirth) {
                ...EmailValidationResult
            }
        }
        ${EmailValidationResultFragment}
    `,

    sendValidationEmailByClaimId: gql`
        mutation sendValidationEmailByClaimId($claimId: String!) {
            sendValidateEmailByClaimId(claimId: $claimId) {
                ...EmailValidationResult
            }
        }
        ${EmailValidationResultFragment}
    `,

    validateEmail: gql`
        mutation validateEmail($code: String!) {
            validateEmail(code: $code) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    createClaim: gql`
        mutation createClaim($insuranceId: String!, $claimCauseId: Long!, $dateOfIncident: LocalDate!, $description: String!, $claimAmount: BigDecimal!, $iban: String!) {
            createClaim(insuranceId: $insuranceId, claimCauseId: $claimCauseId, dateOfIncident: $dateOfIncident, description: $description, claimAmount: $claimAmount, iban: $iban) {
                id
            }
        }
    `,

    updateClaim: gql`
        mutation updateClaim($claimId: String!, $dateOfIncident: LocalDate!, $description: String!, $claimAmount: BigDecimal!, $iban: String!) {
            updateClaim(claimId: $claimId, dateOfIncident: $dateOfIncident, description: $description, claimAmount: $claimAmount, iban: $iban) {
                id
            }
        }
    `,

    submitClaim: gql`
        mutation submitClaim($claimId: String!) {
            submitClaim(claimId: $claimId) {
                id
            }
        }
    `,

    submitClaimTask: gql`
        mutation submitClaimTask($claimTaskId: String!) {
            submitClaimTask(claimTaskId: $claimTaskId) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    uploadAnswerForClaimTaskItem: gql`
        mutation uploadAnswerForClaimTaskItem($claimTaskItemId: String!, $answer: String!) {
            uploadAnswerForClaimTaskItem(claimTaskItemId: $claimTaskItemId, answer: $answer) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    deleteUploadedPhoto: gql`
        mutation deleteUploadedPhoto($claimTaskItemId: String!) {
            deleteUploadedPhoto(claimTaskItemId: $claimTaskItemId) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    updateActiveClaim: gql`
        mutation updateActiveClaim($claimId: String!) {
            updateActiveClaim(claimId: $claimId) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,
});
