import styled, { css } from "styled-components";
import * as SwitchPrimitive from "@radix-ui/react-switch";

// Root
const StyledRoot = styled(SwitchPrimitive.Root)`
    border-radius: 16px;
    display: block;
    padding: 4px;
    position: relative;
    transition: background-color 240ms cubic-bezier(0.87, 0, 0.13, 1);
    width: 56px;

    /* &[data-state="checked"] {
  } */
`;

// Thumb
const thumbIconCommonCSS = css`
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ThumbCheckedIcon = styled.span`
    ${thumbIconCommonCSS}

    opacity: 0;
`;

const ThumbUncheckedIcon = styled.span`
    ${thumbIconCommonCSS}
`;

const StyledThumb = styled(SwitchPrimitive.Thumb)`
    border-radius: 12px;
    display: block;
    height: 24px;
    position: relative;
    transform: translateX(0);
    transition: transform 240ms cubic-bezier(0.87, 0, 0.13, 1);
    width: 24px;

    &[data-state="checked"] {
        transform: translateX(24px);

        ${ThumbCheckedIcon} {
            opacity: 1;
        }

        ${ThumbUncheckedIcon} {
            opacity: 0;
        }
    }

    /* NOTE: in Figma there is no disabled Switch in use at the moment. */
    /* &[data-disabled] {
    cursor: not-allowed;
  } */
`;

export const Switch = {
    Root: StyledRoot,
    Thumb: StyledThumb,
    ThumbCheckedIcon,
    ThumbUncheckedIcon,
};
