import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32Umbrella = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1051 4.90788C19.1972 4.90788 21.9965 6.16119 24.0229 8.18752C25.9161 10.0808 27.1345 12.6488 27.2864 15.5H4.92383C5.0757 12.6488 6.29413 10.0808 8.18738 8.18752C10.2137 6.16119 13.013 4.90788 16.1051 4.90788Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1049 4.90788C16.8371 4.90788 17.5312 5.18766 18.1662 5.67615C18.8844 6.22862 19.5252 7.04247 20.0658 8.04649C21.1047 9.97579 21.7655 12.595 21.8465 15.5H10.3633C10.4443 12.595 11.1051 9.97579 12.1439 8.04649C12.6846 7.04247 13.3253 6.22862 14.0435 5.67615C14.6785 5.18766 15.3726 4.90788 16.1049 4.90788Z"
                stroke="currentColor"
            />
            <path d="M15.6504 23.3684C15.6504 24.8727 16.8698 26.0921 18.3741 26.0921C19.8783 26.0921 21.0978 24.8727 21.0978 23.3684" stroke="currentColor" strokeLinecap="round" />
            <path d="M15.6504 16.5592V22.9145" stroke="currentColor" strokeLinecap="square" />
        </SVGBase>
    );
};

export default SvgIcon32Umbrella;
