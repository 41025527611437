/* eslint-disable @typescript-eslint/no-explicit-any */
export function parseQueryString(queryString: string): {} {
    let query = queryString;
    if (query.length > 0 && query[0] === "?") {
        query = query.slice(1);
    }
    return query.split("&").reduce((prevState, nameValuePair: string): {} => {
        const [name, value] = nameValuePair.split("=");
        prevState[name] = decodeURIComponent(value);
        return prevState;
    }, {} as { [key: string]: string });
}

export function getLocationParameterByName(name: string, url: string): string | null {
    name = name.replace(/[[]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function stringifyPrimitive(v: any): string | number {
    switch (typeof v) {
        case "string":
            return v;

        case "boolean":
            return v ? "true" : "false";

        case "number":
            return isFinite(v) ? v : "";

        default:
            return "";
    }
}

export function encodeQueryString(obj: any, sep?: string | undefined, eq?: string, name?: any) {
    sep = sep || "&";
    eq = eq || "=";
    if (obj === null) {
        obj = undefined;
    }

    if (typeof obj === "object") {
        return Object.keys(obj)
            .map(k => {
                const ks = encodeURIComponent(stringifyPrimitive(k)) + eq;
                if (Array.isArray(obj[k])) {
                    return obj[k]
                        .map((v: any) => {
                            return ks + encodeURIComponent(stringifyPrimitive(v));
                        })
                        .join(sep);
                } else {
                    return ks + encodeURIComponent(stringifyPrimitive(obj[k]));
                }
            })
            .filter(Boolean)
            .join(sep);
    }

    if (!name) return "";
    return encodeURIComponent(stringifyPrimitive(name)) + eq + encodeURIComponent(stringifyPrimitive(obj));
}
