import React, { useCallback, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Loctool, LoctoolMessage } from "@monster/loctool";

import { Cookie, bp, useLoading } from "@monster/shared";
import { Box } from "@monster/shared";
import { AppBar as ChrAppbar, Button, Surface, useNotification } from "@monster/chr-ui";
import { SVGChrBrandCherriskByUniqa } from "@monster/chr-ui/dist/svg_assets";
import { ClaimProgress } from "@pages/Claim/ClaimProgress";
import { ClaimProgressAccordion } from "@pages/Claim/ClaimProgressAccordion";
import { Footer } from "@components/Footer";
import { Redirect, useParams } from "react-router-dom";
import { Path } from "@utils/Path";
import { useDispatch, useSelector } from "react-redux";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { ClaimSubmitDialog } from "./ClaimSubmitDialog";
import { AdditionalClaimTask } from "./AdditionalClaimTask";
import { AppStateActions } from "@redux/actions/appStateActions";
import { Api } from "@api/Api";
import { SessionActions } from "@redux/actions/sessionActions";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";

type RouteParams = {
    claimId?: string;
};

export const ClaimPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { claimId } = useParams<RouteParams>();
    const claim = useSelector(SessionSelectors.getActiveClaim());
    const currentClaimTask = useSelector(SessionSelectors.getCurrentClaimTaskByClaimId(claimId));
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const { addLoading, removeLoading, loadingCount } = useLoading();
    const showNotification = useNotification();
    const activeClaimId = claim?.id;
    const [isLoading, setLoading] = useState(!!claimId && activeClaimId !== claimId);

    useEffect(() => {
        if (!claimId || activeClaimId === claimId) {
            return;
        }

        addLoading();
        const refreshClaimData = async () => {
            try {
                await Api.updateActiveClaim(claimId);
                const relatedData = await Api.getRelatedData();
                dispatch(SessionActions.updateRelatedData(relatedData));
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    showNotification(error.intlMessage);
                }
                console.error({ error });
            }
            removeLoading();
            setLoading(false);
        };
        refreshClaimData();
    }, [addLoading, claimId, activeClaimId, dispatch, removeLoading, showNotification]);

    const onRefreshNowClick = useCallback(() => {
        Cookie.claimSessionId.remove();
        dispatch(AppStateActions.reset());
        window.location.href = process.env.REACT_APP_WEBSITE_URL;
    }, [dispatch]);

    useEffect(() => {
        if (logoutDialogOpen) {
            const timeout = setTimeout(onRefreshNowClick, 3000);
            return () => clearTimeout(timeout);
        }
    }, [logoutDialogOpen, onRefreshNowClick]);

    if ((activeClaimId !== claimId && loadingCount > 0) || isLoading) {
        return null;
    }

    if (!claim || !claimId) {
        return <Redirect to={Path.createClaim} />;
    }

    return (
        <>
            <PageWrapper>
                <ChrAppbar
                    leftItemChild={
                        <SVGChrBrandCherriskByUniqa title={`${Loctool.instance.formatMessage({ id: "common.brandName" })} ${Loctool.instance.formatMessage({ id: "common.brandNameBy" })}`} />
                    }
                />
                <Main aria-atomic={"true"} aria-live={"polite"}>
                    <Box $style={{ maxWidth: 504, margin: "0 auto", paddingTop: 16 }} $styleMedium={{ paddingTop: 40 }}>
                        <Surface $variant={"white"}>
                            <ClaimProgress claim={claim} />
                            <Box $style={{ boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, borderBottomLeftRadius: "inherit", borderBottomRightRadius: "inherit", overflow: "hidden" }}>
                                <ClaimProgressAccordion claim={claim} />
                            </Box>
                        </Surface>

                        {currentClaimTask && <AdditionalClaimTask claim={claim} claimTask={currentClaimTask} />}

                        <Box $style={{ marginTop: 56, textAlign: "center" }}>
                            <Button.Text btnLabel={<LoctoolMessage id="common.logout" />} onClick={() => setLogoutDialogOpen(true)} />
                        </Box>
                    </Box>
                </Main>
                <Footer />
            </PageWrapper>
            <ClaimSubmitDialog
                open={logoutDialogOpen}
                title={<LoctoolMessage id="pages.claim.logoutDialog.title" />}
                description={<LoctoolMessage id="pages.claim.logoutDialog.description" />}
                buttonLabel={<LoctoolMessage id="pages.claim.logoutDialog.buttonLabel" />}
                onButtonClick={onRefreshNowClick}
            />
        </>
    );
};

const Main = styled.main`
    padding-bottom: 80px;
    width: 100%;

    ${bp.large} {
        flex: 1 1 0px;
        position: relative;
    }
`;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
`;
