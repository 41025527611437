import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32NavLogin = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C12.9618 26 10.1459 24.6361 8.25834 22.3303C8.08342 22.1166 8.11484 21.8016 8.32852 21.6267C8.5422 21.4518 8.85722 21.4832 9.03214 21.6969C10.7318 23.7731 13.2649 25 16 25C20.9705 25 25 20.9706 25 16C25 11.0294 20.9705 7 16 7C13.2441 7 10.6937 8.24579 8.99481 10.349C8.8213 10.5639 8.50649 10.5973 8.29167 10.4238C8.07686 10.2503 8.04338 9.93548 8.2169 9.72067C10.1037 7.38483 12.9387 6 16 6ZM17.315 12.1116L17.3796 12.1746L20.3796 15.6746C20.52 15.8385 20.5376 16.0705 20.4323 16.2514L20.3796 16.3254L17.3796 19.8254C17.1999 20.0351 16.8843 20.0593 16.6746 19.8796C16.4882 19.7199 16.4483 19.4527 16.568 19.2481L16.6204 19.1746L18.911 16.5H6.49998C6.22384 16.5 5.99998 16.2762 5.99998 16C5.99998 15.7239 6.22384 15.5 6.49998 15.5H18.912L16.6204 12.8254C16.4606 12.639 16.462 12.3689 16.6116 12.185L16.6746 12.1204C16.861 11.9606 17.1311 11.9621 17.315 12.1116Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32NavLogin;
