import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32HouseElectrical = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5735 5.40147L25.5735 11.7015C25.8408 11.8886 26 12.1944 26 12.5207V26C26 26.5523 25.5523 27 25 27H7C6.44772 27 6 26.5523 6 26V12.5207C6 12.1944 6.15921 11.8886 6.42654 11.7015L15.4265 5.40147C15.7709 5.16045 16.2291 5.16045 16.5735 5.40147ZM16 6.2207L7 12.5207V26H25V12.5207L21.2937 9.92609C21.2032 9.86274 21.0785 9.88474 21.0151 9.97523C21.0143 9.97649 21.0134 9.97777 21.0125 9.97905L19.2591 12.6114C19.1825 12.7263 19.2136 12.8815 19.3284 12.9581C19.3695 12.9854 19.4178 13 19.4671 13H23.6693C23.8135 13 23.9506 13.0623 24.0456 13.1708C24.2072 13.3555 24.2085 13.6256 24.0609 13.8111L23.9985 13.8763L11.396 24.9035C11.2327 25.0465 10.9957 25.0677 10.8095 24.956C10.5991 24.8297 10.515 24.573 10.5987 24.3512L10.638 24.27L15 17H10.9819C10.7058 17 10.4819 16.7762 10.4819 16.5C10.4819 16.3944 10.5154 16.2914 10.5775 16.206L17.0983 7.23836C17.1632 7.14902 17.1435 7.02394 17.0541 6.95898C17.0532 6.95829 17.0522 6.9576 17.0512 6.95692L16 6.2207ZM18.037 7.6467L12.0785 15.8412C12.046 15.8859 12.0559 15.9484 12.1006 15.9809C12.1176 15.9934 12.1382 16 12.1594 16H15.8829C16.1591 16 16.3829 16.2239 16.3829 16.5C16.3829 16.5907 16.3583 16.6796 16.3116 16.7574L13.133 22.053L22.338 14H18.0655C17.7893 14 17.5655 13.7762 17.5655 13.5C17.5655 13.4014 17.5947 13.3049 17.6494 13.2228L20.306 9.2347L18.037 7.6467Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32HouseElectrical;
