import React, { ComponentType, useContext } from "react";
import { NotificationContext, NotificationProp } from "./NotificationProvider";

export const useNotification = () => {
    const context = useContext(NotificationContext);
    return context.showNotification;
};

export function withNotification<P extends NotificationProp>(WrappedComponent: ComponentType<P>) {
    return class WithNotificationClass extends React.Component<Omit<P, keyof NotificationProp>> {
        public render() {
            return <NotificationContext.Consumer>{({ showNotification }) => <WrappedComponent {...(this.props as P)} showNotification={showNotification} />}</NotificationContext.Consumer>;
        }
    };
}

export { NotificationProp };

export { NotificationProvider } from "./NotificationProvider";
