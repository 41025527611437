import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsSearch = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.5 20C17.5376 20 20 17.5376 20 14.5C20 11.4624 17.5376 9 14.5 9C11.4624 9 9 11.4624 9 14.5C9 17.5376 11.4624 20 14.5 20Z" stroke="currentColor" />
            <path d="M18.999 18L23.999 22" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsSearch;
