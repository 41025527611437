import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverDamagedhealth = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="mask0_162_496"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={16}
                y={0}
                width={16}
                height={32}
            >
                <rect width={16} height={32} transform="matrix(-1 0 0 1 32 0)" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_162_496)">
                <path
                    d="M16.331 5.47451L16 5.76697L15.669 5.47451C15.3145 5.16133 14.9337 4.87994 14.5307 4.63439L14.7909 4.20741C13.9423 3.69031 12.9998 3.32189 11.9964 3.13655L11.9056 3.62823C11.4496 3.544 10.9799 3.5 10.5 3.5C9.95582 3.5 9.42482 3.55657 8.91192 3.66423L8.80921 3.17489C7.67991 3.41194 6.63172 3.88179 5.71292 4.53421L6.0024 4.94188C5.13587 5.5572 4.39027 6.34601 3.8124 7.26129L3.38961 6.99436C2.79547 7.93543 2.3694 9.00153 2.15581 10.1465L2.64733 10.2382C2.5507 10.7562 2.5 11.2917 2.5 11.84C2.5 12.4123 2.57733 13.0029 2.72389 13.6071L2.23798 13.725C2.51813 14.8801 3.03213 16.0584 3.70747 17.2245L4.14015 16.9739C4.73704 18.0046 5.46657 19.031 6.27789 20.0269L5.89025 20.3427C6.68845 21.3224 7.5604 22.268 8.45693 23.1555L8.80868 22.8002C9.73087 23.7131 10.6799 24.5642 11.6005 25.3264L11.2816 25.7115C12.3658 26.6092 13.4109 27.3848 14.3291 27.9956L14.606 27.5792C15.1208 27.9216 15.5907 28.2084 16 28.433C16.4093 28.2084 16.8792 27.9216 17.394 27.5792L17.6709 27.9956C18.5891 27.3848 19.6342 26.6092 20.7184 25.7115L20.3995 25.3264C21.3201 24.5642 22.2691 23.7131 23.1913 22.8002L23.5431 23.1555C24.4396 22.268 25.3116 21.3224 26.1097 20.3427L25.7221 20.0269C26.5334 19.031 27.263 18.0046 27.8598 16.9739L28.2925 17.2245C28.9679 16.0584 29.4819 14.8801 29.762 13.725L29.2761 13.6071C29.4227 13.0029 29.5 12.4123 29.5 11.84C29.5 11.2917 29.4493 10.7562 29.3527 10.2382L29.8442 10.1465C29.6306 9.00153 29.2045 7.93543 28.6104 6.99436L28.1876 7.26129C27.6097 6.34601 26.8641 5.5572 25.9976 4.94188L26.2871 4.53421C25.3683 3.88179 24.3201 3.41194 23.1908 3.17489L23.0881 3.66423C22.5752 3.55657 22.0442 3.5 21.5 3.5C21.0201 3.5 20.5504 3.544 20.0944 3.62823L20.0036 3.13655C19.0002 3.32189 18.0577 3.69031 17.2091 4.20742L17.4693 4.63439C17.0663 4.87994 16.6855 5.16133 16.331 5.47451ZM16 28.8999L15.9773 28.9854C15.9924 28.9894 16.0076 28.9894 16.0227 28.9854L16 28.8999Z"
                    stroke="currentColor"
                    strokeDasharray="4 4"
                />
            </g>
            <mask
                id="mask1_162_496"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={16}
                height={32}
            >
                <rect width={16} height={32} fill="currentColor" />
            </mask>
            <g mask="url(#mask1_162_496)">
                <path
                    d="M15.669 5.47451L16 5.76697L16.331 5.47451C17.7273 4.24098 19.5312 3.5 21.5 3.5C25.9 3.5 29.5 7.21528 29.5 11.84C29.5 13.4055 28.9211 15.0995 27.9507 16.8152C26.9826 18.5269 25.6433 20.2279 24.1684 21.7978C21.3056 24.8449 17.9829 27.3449 16 28.433C14.0171 27.345 10.6944 24.8449 7.83165 21.7978C6.35668 20.2279 5.01738 18.5269 4.04931 16.8152C3.07894 15.0995 2.5 13.4055 2.5 11.84C2.5 7.21528 6.10002 3.5 10.5 3.5C12.4688 3.5 14.2727 4.24098 15.669 5.47451Z"
                    stroke="currentColor"
                />
            </g>
        </SVGBase>
    );
};

export default SvgIcon32CoverDamagedhealth;
