import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverCars = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.9448 4C23.7486 4 25.3293 5.20729 25.8039 6.94753L27.727 14H29C29.5128 14 29.9355 14.386 29.9933 14.8834L30 15V17C30 17.5128 29.614 17.9355 29.1166 17.9933L29 18H28V26C28 27.1046 27.1046 28 26 28H24C22.8954 28 22 27.1046 22 26V25H10V26C10 27.1046 9.10457 28 8 28H6C4.89543 28 4 27.1046 4 26V18H3C2.44772 18 2 17.5523 2 17V15C2 14.4477 2.44772 14 3 14H4.273L6.19613 6.94753C6.67074 5.20729 8.25138 4 10.0552 4H21.9448ZM21.9448 5H10.0552C8.76382 5 7.62497 5.82502 7.21452 7.03516L7.1609 7.21061L5.1373 14.6315C5.07798 14.8491 4.88039 15 4.65491 15H3V17H4.5C4.77614 17 5 17.2239 5 17.5V26C5 26.5128 5.38604 26.9355 5.88338 26.9933L6 27H8C8.51284 27 8.93551 26.614 8.99327 26.1166L9 26V24.5C9 24.2239 9.22386 24 9.5 24H22.5C22.7761 24 23 24.2239 23 24.5V26C23 26.5128 23.386 26.9355 23.8834 26.9933L24 27H26C26.5128 27 26.9355 26.614 26.9933 26.1166L27 26V17.5C26.9996 17.2545 27.1765 17.0504 27.4097 17.0081L27.5 17.0004L28.9424 17.0017L29 16.999L29.0017 15.0576L28.999 15H27.3451C27.1196 15 26.922 14.8491 26.8627 14.6315L24.8391 7.21065C24.4993 5.96479 23.4037 5.08314 22.1282 5.00556L21.9448 5ZM11 17C12.1046 17 13 17.8954 13 19C13 20.1046 12.1046 21 11 21H9C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17H11ZM23 17C24.1046 17 25 17.8954 25 19C25 20.1046 24.1046 21 23 21H21C19.8954 21 19 20.1046 19 19C19 17.8954 19.8954 17 21 17H23ZM11 18H9C8.44772 18 8 18.4477 8 19C8 19.5128 8.38604 19.9355 8.88338 19.9933L9 20H11C11.5523 20 12 19.5523 12 19C12 18.4872 11.614 18.0645 11.1166 18.0067L11 18ZM23 18H21C20.4477 18 20 18.4477 20 19C20 19.5128 20.386 19.9355 20.8834 19.9933L21 20H23C23.5523 20 24 19.5523 24 19C24 18.4872 23.614 18.0645 23.1166 18.0067L23 18Z"
                fill="currentColor"
            />
            <rect x={8} y={13} width={16} height={1} rx={0.5} fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverCars;
