import { useCallback, useEffect, useState } from "react";

export const useCities = (zipCode: string, isValid: boolean, getCitiesByZipCode: (zipCode: string) => Promise<string[]>) => {
    const [cities, setCities] = useState<string[]>([]);

    const updateCities = useCallback(
        async (zipCode: string) => {
            try {
                const result = await getCitiesByZipCode(zipCode);
                setCities(result);
            } catch (error) {
                setCities([]);
            }
        },
        [getCitiesByZipCode]
    );

    useEffect(() => {
        if (zipCode && isValid) {
            updateCities(zipCode);
        } else {
            setCities([]);
        }
    }, [zipCode, isValid, updateCities]);

    return cities;
};
