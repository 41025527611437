import { useState, useRef, useEffect, useCallback } from "react";

export const useCountdown = () => {
    const [secondsRemaining, setSecondsRemaining] = useState(0);
    const resendTimer = useRef<NodeJS.Timeout | null>(null);

    const restart = useCallback((seconds: number) => {
        setSecondsRemaining(seconds);
    }, []);

    useEffect(() => {
        if (secondsRemaining > 0) {
            resendTimer.current = setTimeout(() => {
                setSecondsRemaining(seconds => seconds - 1);
            }, 1000);
        } else if (secondsRemaining === 0 && resendTimer.current !== null) {
            clearTimeout(resendTimer.current);
        }
    }, [secondsRemaining]);

    useEffect(() => {
        return () => {
            if (resendTimer.current !== null) {
                clearTimeout(resendTimer.current);
            }
        };
    }, []);

    return {
        secondsRemaining,
        restart,
    };
};
