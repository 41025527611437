import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravelTypeTravelcancel2 = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 4C27.3333 4 28 4.66667 28 6V11C26.3431 11 25 12.3431 25 14C25 14.3899 25.0744 14.7625 25.2098 15.1043L28.4472 16.7236C28.786 16.893 29 17.2393 29 17.618V19.5C29 23.5 27 26.3333 23 28C20.8559 27.1066 19.2864 25.878 18.2916 24.3141L18.1026 24.001L4 24C2.89543 24 2 23.1046 2 22V17C3.65685 17 5 15.6569 5 14C5 12.3431 3.65685 11 2 11C2 11 2 9.33333 2 6C2 4.89543 2.89543 4 4 4H26ZM22.7764 15.2298L18.2764 17.4798C18.107 17.5645 18 17.7377 18 17.9271V19.5C18 22.9566 19.6246 25.3823 23 26.9099C26.3754 25.3823 28 22.9566 28 19.5V17.9271C28 17.7377 27.893 17.5645 27.7236 17.4798L23.2236 15.2298C23.0828 15.1595 22.9172 15.1595 22.7764 15.2298ZM17.7618 23.3316L17.622 23.0009L17.7011 23.1931L17.7618 23.3316ZM9 4.999L4 5C3.44772 5 3 5.44772 3 6V10.126C4.72523 10.5701 6 12.1362 6 14C6 15.8638 4.72523 17.4299 3 17.874V22C3 22.5523 3.44772 23 4 23L9 22.999V21.5C9 21.2239 9.22386 21 9.5 21C9.77614 21 10 21.2239 10 21.5V22.999L17.622 23.0009L17.5303 22.7582C17.1768 21.7753 17 20.6892 17 19.5V17.618C17 17.2393 17.214 16.893 17.5528 16.7236L22.5528 14.2236C22.8343 14.0828 23.1657 14.0828 23.4472 14.2236L24.033 14.516L24.0149 14.3473C24.005 14.2328 24 14.117 24 14C24 12.2052 25.1821 10.6865 26.8102 10.1799L27 10.126V6C27 5.44772 26.5523 5 26 5L10 4.999V6.5C10 6.77614 9.77614 7 9.5 7C9.22386 7 9 6.77614 9 6.5V4.999ZM9.5 15C9.77614 15 10 15.2239 10 15.5V18.5C10 18.7761 9.77614 19 9.5 19C9.22386 19 9 18.7761 9 18.5V15.5C9 15.2239 9.22386 15 9.5 15ZM9.5 9C9.77614 9 10 9.22386 10 9.5V12.5C10 12.7761 9.77614 13 9.5 13C9.22386 13 9 12.7761 9 12.5V9.5C9 9.22386 9.22386 9 9.5 9Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravelTypeTravelcancel2;
