import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32BasicsDelete = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12.5 8C12.5 7.72386 12.7239 7.5 13 7.5H19C19.2761 7.5 19.5 7.72386 19.5 8V9.5H12.5V8Z" stroke="currentColor" />
            <rect x={13} y={13} width={1} height={8} rx={0.5} fill="currentColor" />
            <rect x={18} y={13} width={1} height={8} rx={0.5} fill="currentColor" />
            <path d="M9.5 10C9.5 9.72386 9.72386 9.5 10 9.5H22C22.2761 9.5 22.5 9.72386 22.5 10V23C22.5 23.8284 21.8284 24.5 21 24.5H11C10.1716 24.5 9.5 23.8284 9.5 23V10Z" stroke="currentColor" />
            <rect x={7} y={9} width={18} height={1} rx={0.5} fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32BasicsDelete;
