import React from "react";
import { ButtonProps, isAnchorButtonProps } from "./ButtonTypes";
import { ButtonContent } from "./ButtonContent";
import { StyledSecondaryButton } from "./ButtonSecondary.styled";

export const ButtonSecondary = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isAnchorButtonProps(props)) {
        return (
            <StyledSecondaryButton forwardedAs="a" {...props} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
                <ButtonContent {...props} />
            </StyledSecondaryButton>
        );
    }
    return (
        <StyledSecondaryButton type={props.type ?? "button"} {...props} ref={ref}>
            <ButtonContent {...props} />
        </StyledSecondaryButton>
    );
});
