import React from "react";
import { ButtonProps, isAnchorButtonProps } from "./ButtonTypes";
import { ButtonContent } from "./ButtonContent";
import { StyledPrimaryAltButton } from "./ButtonPrimaryAlt.styled";

export const ButtonPrimaryAlt = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isAnchorButtonProps(props)) {
        return (
            <StyledPrimaryAltButton forwardedAs="a" {...props} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
                <ButtonContent {...props} />
            </StyledPrimaryAltButton>
        );
    }
    return (
        <StyledPrimaryAltButton type={props.type ?? "button"} {...props} ref={ref}>
            <ButtonContent {...props} />
        </StyledPrimaryAltButton>
    );
});
