import React from "react";
import styled, { css, keyframes } from "styled-components";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

const slideDown = keyframes`
    from { 
        height: 0;
    }
    to { 
        height: var(--radix-accordion-content-height);
    }
`;

const accordionSlideDownAnimation = () =>
    css`
        ${slideDown} 240ms cubic-bezier(0.87, 0, 0.13, 1) forwards;
    `;

const slideUp = keyframes`
    from { 
        height: var(--radix-accordion-content-height);
    }
    to { 
        height: 0;
    }
`;

const accordionSlideUpAnimation = () =>
    css`
        ${slideUp} 240ms cubic-bezier(0.87, 0, 0.13, 1) forwards;
    `;

const StyledAccordion = styled(AccordionPrimitive.Root)({});

const StyledItem = styled(AccordionPrimitive.Item)({
    overflow: "hidden",

    // "&:first-child": {},

    // "&:last-child": {},

    // "&:focus-within": {
    //     position: "relative",
    //     zIndex: 1,
    //     outline: "1px solid",
    // },
});

const StyledHeader = styled(AccordionPrimitive.Header)({
    all: "unset",
    display: "flex",
});

export const accordionTriggerTransitionCSS = css`
    transition: transform 240ms cubic-bezier(0.87, 0, 0.13, 1);

    [data-state="open"] & {
        transform: rotate(180deg);
    }
`;

const StyledTrigger = styled(AccordionPrimitive.Trigger)({
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // '&[data-state="closed"]': {},
    // '&[data-state="open"]': {},
    // "@media (hover: hover)": { "&:hover": {} },
});

const StyledTriggerButton = styled(AccordionPrimitive.Trigger)`
    ${accordionTriggerTransitionCSS}
`;

const StyledContent = styled(AccordionPrimitive.Content)`
    overflow: hidden;

    &[data-state="open"] {
        animation: ${accordionSlideDownAnimation};
    }

    &[data-state="closed"] {
        animation: ${accordionSlideUpAnimation};
    }
`;

const StyledIconWrapper = styled.span`
    display: block;
    ${accordionTriggerTransitionCSS}
`;

export const Accordion = StyledAccordion;
export const AccordionItem = StyledItem;
export const AccordionHeader = StyledHeader;
export const AccordionTrigger = React.forwardRef(({ children, iconElement, ...props }: AccordionPrimitive.AccordionTriggerProps & { iconElement?: React.ReactNode }, forwardedRef) => (
    <StyledHeader>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <StyledTrigger {...props} ref={forwardedRef}>
            {children}

            {iconElement && <StyledIconWrapper aria-hidden>{iconElement}</StyledIconWrapper>}
        </StyledTrigger>
    </StyledHeader>
));

// NOTE: for custom AccordionHeader
export const AccordionTriggerWithoutHeader = React.forwardRef(
    ({ children, iconElement, suffixElement, ...props }: AccordionPrimitive.AccordionTriggerProps & { iconElement?: React.ReactNode; suffixElement?: React.ReactNode }, forwardedRef) => (
        <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledTrigger {...props} ref={forwardedRef}>
                {children}

                {iconElement && <StyledIconWrapper aria-hidden>{iconElement}</StyledIconWrapper>}

                {suffixElement}
            </StyledTrigger>
        </>
    )
);

// NOTE: for custom AccordionHeader
export const AccordionTriggerButtonOnly = React.forwardRef(({ children, ...props }: AccordionPrimitive.AccordionTriggerProps & { iconElement?: React.ReactNode }, forwardedRef) => (
    <>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <StyledTriggerButton {...props} ref={forwardedRef}>
            {children}
        </StyledTriggerButton>
    </>
));

export const AccordionContent = React.forwardRef(({ children, ...props }: AccordionPrimitive.AccordionContentProps, forwardedRef) => (
    <>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <StyledContent {...props} ref={forwardedRef}>
            {children}
        </StyledContent>
    </>
));
