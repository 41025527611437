import React from "react";
import { AppBar } from "@components/AppBar";
import { Page } from "@components/Page";
import { useHistory } from "react-router-dom";
import { Path } from "@utils/Path";
import { LoctoolMessage, LoctoolHTMLMessage } from "@monster/loctool";
import { Box } from "@monster/shared";
import { useTheme } from "styled-components";
import { Text } from "@monster/chr-ui";

export const ClaimCustomerCarePage = () => {
    const history = useHistory();
    const theme = useTheme();
    return (
        <Page appBar={<AppBar activeIndex={0} onLogoClick={() => history.push(Path.login)} />}>
            <Box $style={{ maxWidth: 504, margin: "0 auto", width: "100%" }}>
                <Text as="h1" $variant="h2Desktop" $style={{ margin: 0, padding: "40px 16px 16px" }} $styleLarge={{ paddingTop: 36, paddingBottom: 20 }}>
                    <LoctoolMessage id="pages.claimResult.submitError.title" />
                </Text>

                <Box $style={{ backgroundColor: theme.color.white, marginBottom: 80, borderRadius: 16, padding: "40px 24px 32px", textAlign: "center" }}>
                    <Text as="p" $variant="h3Desktop" $style={{ margin: 0, a: { color: theme.color.blueD, textDecoration: "none" } }}>
                        <LoctoolHTMLMessage id="pages.claimResult.submitError.text" />
                    </Text>

                    {/* <Box $style={{ maxWidth: 208, margin: "40px auto 0" }}>
                        <Button.Primary btnLabel={<LoctoolMessage id="pages.claimResult.submitError.btn.tryAgain" />} isExpanded onClick={onClickSubmitErrorTryAgain} />
                    </Box> */}
                </Box>
            </Box>
        </Page>
    );
};
