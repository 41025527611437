import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverFire = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.4801 3.67554C22.4468 9.64079 25.5 14.5479 25.5 18C25.5 20.6234 24.4367 22.9984 22.7175 24.7175C20.9984 26.4367 18.6234 27.5 16 27.5C13.3766 27.5 11.0016 26.4367 9.28249 24.7175C7.56332 22.9984 6.5 20.6234 6.5 18C6.5 16.217 7.33038 14.0399 8.9507 11.4608C9.26954 12.2377 9.63006 12.8815 10.0175 13.3654C10.625 14.1241 11.3068 14.5 12 14.5C12.6675 14.5 13.3234 14.1523 13.9126 13.4507C14.4333 12.8306 14.908 11.9249 15.3007 10.796C15.9487 8.93282 16.383 6.44629 16.4801 3.67554Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverFire;
