var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target, mod));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  LivePreviewHelper: () => LivePreviewHelper,
  Locale: () => Locale,
  Loctool: () => Loctool,
  LoctoolHTMLMessage: () => LoctoolHTMLMessage_default,
  LoctoolMessage: () => LoctoolMessage_default,
  LoctoolNumber: () => LoctoolNumber_default,
  NumberFormatter: () => NumberFormatter,
  NumberFormatters: () => NumberFormatters
});
module.exports = __toCommonJS(src_exports);

// src/LoctoolMessage.tsx
var import_react4 = __toESM(require("react"));

// src/Loctool.tsx
var import_react = __toESM(require("react"));
var import_shared = require("@monster/shared");

// src/types.ts
var Locale = /* @__PURE__ */ ((Locale2) => {
  Locale2["hu"] = "hu";
  Locale2["en"] = "en";
  Locale2["de"] = "de";
  Locale2["cz"] = "cz";
  Locale2["sk"] = "sk";
  Locale2["ro"] = "ro";
  return Locale2;
})(Locale || {});

// src/Loctool.tsx
var import_react_intl = require("react-intl");

// src/windowMessages.ts
var OPMAction = {
  onPageReady: () => createPreviewMessage("pageReady" /* pageReady */),
  onPreviewReady: (currentLocale) => createPreviewMessage("previewReady" /* previewReady */, { currentLocale }),
  onPreviewEnded: () => createPreviewMessage("previewEnded" /* previewEnded */),
  onLocaleChanged: (locale) => createPreviewMessage("localeChanged" /* localeChanged */, { locale }),
  sendUsedKeys: (keys) => createPreviewMessage("sendUsedKeys" /* sendUsedKeys */, { keys }),
  highlightKey: (key) => createPreviewMessage("scrollTo" /* highlightKey */, { key }),
  editKey: (key) => createPreviewMessage("editKey" /* editKey */, { key })
};
function createPreviewMessage(type, payload) {
  return payload === void 0 ? { type } : { type, payload };
}

// src/LivePreviewHelper.ts
var LivePreviewHelperClass = class {
  constructor() {
    this.isPreviewEnabled = false;
    this.postMessage = (message) => {
      if (Loctool.instance.isLivePreviewEnabled) {
        parent.postMessage(message, "*");
      }
    };
    this.receiveMessage = (message) => {
      if (message.data && message.data.type) {
        switch (message.data.type) {
          case "setPreview" /* setPreview */: {
            Loctool.instance.setPreviewMode(message.data.payload.enabled);
            this.isPreviewEnabled = message.data.payload.enabled;
            this.postMessage(this.isPreviewEnabled ? OPMAction.onPreviewReady(Loctool.instance.getConfig().locale) : OPMAction.onPreviewEnded());
            break;
          }
          case "getUsedKeys" /* getUsedKeys */: {
            this.postMessage(OPMAction.sendUsedKeys(Array.from((window._intlKeys || /* @__PURE__ */ new Set()).values())));
            break;
          }
          case "setMessages" /* setMessages */: {
            message.data.payload.messages.forEach((msg) => {
              Loctool.instance.setMessages(msg.locale, msg.messages);
            });
            break;
          }
          case "setMobileView" /* setMobileView */: {
            Loctool.instance.setForceMobileView(message.data.payload.enabled);
            break;
          }
        }
      }
    };
  }
  initialize() {
    if (Loctool.instance.isLivePreviewEnabled) {
      window.addEventListener("message", this.receiveMessage, false);
    }
  }
  destruct() {
    if (Loctool.instance.isLivePreviewEnabled) {
      window.removeEventListener("message", this.receiveMessage);
    }
  }
  sendPageReady() {
    this.postMessage(OPMAction.onPageReady());
  }
};
var LivePreviewHelper = new LivePreviewHelperClass();

// src/NumberFormatter.ts
var NumberFormatter = class {
  constructor(decimalDelimiter, fractionDelimiter) {
    this.decimalDelimiter = decimalDelimiter;
    this.fractionDelimiter = fractionDelimiter;
  }
  groupByThousands(asString) {
    return asString.replace(/\B(?=(\d{3})+(?!\d))/g, this.decimalDelimiter);
  }
  decimal(value) {
    const asString = value.toString();
    if (Math.abs(value) < 1e4) {
      return asString;
    }
    return this.groupByThousands(asString);
  }
  float(value) {
    if (Number.isInteger(value)) {
      return this.decimal(value);
    }
    const strPrice = value.toFixed(2).toString();
    const integer = strPrice.split(".")[0];
    const result = this.decimal(Number.parseInt(integer));
    let fractional = Number.parseInt(strPrice.split(".")[1]);
    if (fractional % 10 === 0) {
      fractional /= 10;
    }
    if (fractional === 0) {
      return result;
    }
    return `${result}${this.fractionDelimiter}${fractional}`;
  }
};
var HuNumberFormatter = class extends NumberFormatter {
  constructor() {
    super(" ", ",");
  }
  currency(value, currency) {
    if (currency !== "HUF") {
      throw new Error(`Missing implementation for currency: ${currency}`);
    }
    if (!Number.isInteger(value)) {
      throw new Error("HUF cannot be fractional!");
    }
    return `${this.decimal(value)} Ft`;
  }
};
var EnNumberFormatter = class extends NumberFormatter {
  constructor() {
    super(",", ".");
  }
  currency(value, currency, toInteger) {
    let strPrice = value.toFixed(2).toString();
    if (toInteger) {
      if (Number.isInteger(value)) {
        strPrice = value.toString();
      } else {
        strPrice = Math.ceil(value < 0 ? value - 1 : value).toString();
      }
    }
    const [integer, fractional] = strPrice.split(".");
    const prefix = integer.startsWith("-") ? "-" : "";
    switch (currency) {
      case "HUF":
        return `${prefix}${this.decimal(Math.abs(Number.parseInt(integer, 10)))} HUF`;
      case "EUR":
        return `\u20AC${prefix}${this.decimal(Math.abs(Number.parseInt(integer)))}${fractional ? this.fractionDelimiter : ""}${fractional != null ? fractional : ""}`;
      case "CZK":
        return `${prefix}${this.decimal(Math.abs(Number.parseInt(integer)))}${fractional ? this.fractionDelimiter : ""}${fractional != null ? fractional : ""} K\u010D`;
      default:
        throw new Error(`Missing implementation for currency: ${currency}`);
    }
  }
};
var DeNumberFormatter = class extends NumberFormatter {
  constructor() {
    super(".", ",");
  }
  currency(value, currency, toInteger) {
    if (currency !== "EUR") {
      throw new Error(`Missing implementation for currency: ${currency}`);
    }
    let strPrice = value.toFixed(2).toString();
    if (toInteger) {
      if (Number.isInteger(value)) {
        strPrice = value.toString();
      } else {
        strPrice = Math.ceil(value < 0 ? value - 1 : value).toString();
      }
    }
    const [integer, fractional] = strPrice.split(".");
    const prefix = integer.startsWith("-") ? "-" : "";
    return `${prefix}${this.decimal(Math.abs(Number.parseInt(integer)))}${fractional ? this.fractionDelimiter : ""}${fractional != null ? fractional : ""} \u20AC`;
  }
};
var CzNumberFormatter = class extends NumberFormatter {
  constructor() {
    super(" ", ",");
  }
  currency(value, currency, toInteger) {
    if (currency !== "CZK") {
      throw new Error(`Missing implementation for currency: ${currency}`);
    }
    let strPrice = value.toFixed(2).toString();
    if (toInteger) {
      if (Number.isInteger(value)) {
        strPrice = value.toString();
      } else {
        strPrice = Math.ceil(value < 0 ? value - 1 : value).toString();
      }
    }
    const [integer, fractional] = strPrice.split(".");
    const prefix = integer.startsWith("-") ? "-" : "";
    return `${prefix}${this.decimal(Math.abs(Number.parseInt(integer)))}${fractional ? this.fractionDelimiter : ""}${fractional != null ? fractional : ""} K\u010D`;
  }
};
var SkNumberFormatter = class extends NumberFormatter {
  constructor() {
    super(" ", ",");
  }
  currency(value, currency, toInteger) {
    if (currency !== "EUR") {
      throw new Error(`Missing implementation for currency: ${currency}`);
    }
    let strPrice = value.toFixed(2).toString();
    if (toInteger) {
      if (Number.isInteger(value)) {
        strPrice = value.toString();
      } else {
        strPrice = Math.ceil(value < 0 ? value - 1 : value).toString();
      }
    }
    const [integer, fractional] = strPrice.split(".");
    const prefix = integer.startsWith("-") ? "-" : "";
    return `${prefix}${this.decimal(Math.abs(Number.parseInt(integer)))}${fractional ? this.fractionDelimiter : ""}${fractional != null ? fractional : ""} \u20AC`;
  }
};
var RoNumberFormatter = class extends NumberFormatter {
  constructor() {
    super(" ", ",");
  }
  currency(value, currency) {
    if (currency !== "EUR") {
      throw new Error(`Missing implementation for currency: ${currency}`);
    }
    const strPrice = value.toFixed(2).toString();
    const [integer, fractional] = strPrice.split(".");
    const prefix = integer.startsWith("-") ? "-" : "";
    return `${prefix}${this.decimal(Math.abs(Number.parseInt(integer)))}${this.fractionDelimiter}${fractional} \u20AC`;
  }
};
var NumberFormatters = Object.freeze({
  hu: new HuNumberFormatter(),
  en: new EnNumberFormatter(),
  de: new DeNumberFormatter(),
  cz: new CzNumberFormatter(),
  sk: new SkNumberFormatter(),
  ro: new RoNumberFormatter()
});

// src/Loctool.tsx
var getIntlLocaleFromI18nLocale = (locale) => {
  if (locale === "cz" /* cz */) {
    return "cs-CZ";
  }
  return locale;
};
var Loctool = class {
  constructor(messages, defaultLocale, isLivePreviewEnabled = false) {
    this.messages = {};
    this.configChangeListeners = [];
    this.currentBreakPointMeasured = import_shared.breakpoints.large;
    this.isMobileViewport = () => this.currentBreakPointMeasured < import_shared.breakpoints.large;
    this.onBreakpointChanged = () => {
      if (typeof window === "undefined") {
        return;
      }
      this.currentBreakPointMeasured = this.getBreakpointByWindowWidth(window.innerWidth);
      console.debug(`[INTL] Viewport changed, isMobile: ${this.isMobileViewport()}`);
    };
    this.setPreviewMode = (isPreview) => {
      this.setConfig(__spreadProps(__spreadValues({}, this.config), { isPreviewMode: isPreview }));
    };
    this.setForceMobileView = (forceMobilePreview) => {
      this.setConfig(__spreadProps(__spreadValues({}, this.config), { forceMobilePreview }));
    };
    this.getConfig = () => {
      return this.config;
    };
    this.messages = messages;
    this.defaultLocale = defaultLocale;
    this.isLivePreviewEnabled = isLivePreviewEnabled;
    this.intl = (0, import_react_intl.createIntl)({ locale: getIntlLocaleFromI18nLocale(defaultLocale), messages: this.messages[defaultLocale] }, (0, import_react_intl.createIntlCache)());
    this.config = this.getInitialConfig();
    if (typeof window !== "undefined") {
      if (isLivePreviewEnabled) {
        window.setPreview = this.setPreviewMode;
        window.getIntlConfig = this.getConfig;
      }
      const onWindowSizeChange = () => {
        const newBreakPoint = this.getBreakpointByWindowWidth(window.innerWidth);
        if (newBreakPoint !== this.currentBreakPointMeasured) {
          this.onBreakpointChanged();
        }
      };
      window.addEventListener("resize", onWindowSizeChange);
    }
  }
  static addUsedIntlKey(key) {
    if (Loctool.instance.isLivePreviewEnabled && typeof window !== "undefined") {
      window._intlKeys = window._intlKeys || /* @__PURE__ */ new Set();
      window._intlKeys.add(key);
    }
  }
  static clearUsedIntlKeys() {
    if (Loctool.instance.isLivePreviewEnabled && typeof window !== "undefined") {
      window._intlKeys = /* @__PURE__ */ new Set();
    }
  }
  static mount(onIntlConfigChange) {
    Loctool.instance.addListener(onIntlConfigChange);
    LivePreviewHelper.initialize();
    LivePreviewHelper.sendPageReady();
  }
  static unmount() {
    Loctool.clearUsedIntlKeys();
    LivePreviewHelper.destruct();
  }
  getInitialConfig() {
    return {
      isPreviewMode: false,
      forceMobilePreview: false,
      locale: this.defaultLocale
    };
  }
  setMessages(locale, messages) {
    if (locale !== this.config.locale) {
      console.warn("Got invalid locale from LivePreview, which is not used and not a fallback language, ignoring...");
      return;
    }
    this.messages = __spreadProps(__spreadValues({}, this.messages), { [locale]: messages });
    this.intl = (0, import_react_intl.createIntl)({ locale: getIntlLocaleFromI18nLocale(locale), messages }, (0, import_react_intl.createIntlCache)());
    this.setConfig(__spreadValues({}, this.config));
  }
  getMessages() {
    const messages = this.messages[this.config.locale];
    if (!messages) {
      throw new Error(`Translations missing for language: ${this.config.locale}`);
    }
    return messages;
  }
  getBreakpointByWindowWidth(width) {
    if (width >= import_shared.breakpoints.xxlarge) {
      return import_shared.breakpoints.xxlarge;
    }
    if (width >= import_shared.breakpoints.xlarge) {
      return import_shared.breakpoints.xlarge;
    }
    if (width >= import_shared.breakpoints.large) {
      return import_shared.breakpoints.large;
    }
    if (width >= import_shared.breakpoints.medium) {
      return import_shared.breakpoints.medium;
    }
    return import_shared.breakpoints.small;
  }
  addListener(listener) {
    this.configChangeListeners.push(listener);
  }
  removeListener(listener) {
    const index = this.configChangeListeners.findIndex((ccl) => ccl === listener);
    if (index !== -1) {
      this.configChangeListeners.splice(index, 1);
    }
  }
  setLocale(locale) {
    this.setConfig(__spreadProps(__spreadValues({}, this.config), { locale }));
  }
  setConfig(newConfig) {
    if (newConfig.locale !== this.config.locale) {
      this.intl = (0, import_react_intl.createIntl)({ locale: getIntlLocaleFromI18nLocale(newConfig.locale), messages: this.messages[newConfig.locale] }, (0, import_react_intl.createIntlCache)());
    }
    this.config = newConfig;
    this.configChangeListeners.forEach((listener) => {
      listener(this.config);
    });
  }
  getDescriptorId(id) {
    const mobileId = `${id}:mobile`;
    if ((this.isMobileViewport() || this.getConfig().forceMobilePreview === true) && this.isTranslationExists(mobileId)) {
      return mobileId;
    }
    return id;
  }
  isTranslationExists(key) {
    const messages = this.getMessages();
    return !!messages[key];
  }
  formatMessage(messageDescriptor, values) {
    const id = this.getDescriptorId(messageDescriptor.id);
    Loctool.addUsedIntlKey(id);
    return this.intl.formatMessage(__spreadProps(__spreadValues({}, messageDescriptor), { id }), values);
  }
  formatHTMLMessage(messageDescriptor, values, jsxValues) {
    const id = this.getDescriptorId(messageDescriptor.id);
    Loctool.addUsedIntlKey(id);
    const formattedMessage = this.intl.formatMessage(__spreadProps(__spreadValues({}, messageDescriptor), { id }), values);
    if (jsxValues) {
      const parts = formattedMessage.split(/(\{.*?\})/);
      const keys = Object.keys(jsxValues).map((x) => `{${x}}`);
      const values2 = Object.values(jsxValues);
      const outputValues = parts.map((part) => {
        const indexOfValue = keys.indexOf(part);
        if (indexOfValue !== -1) {
          return values2[indexOfValue];
        }
        return part;
      });
      return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, outputValues.map((value, index) => {
        if (typeof value === "string") {
          return /* @__PURE__ */ import_react.default.createElement("span", {
            key: index,
            dangerouslySetInnerHTML: { __html: value }
          });
        }
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, {
          key: index
        }, value);
      }));
    }
    return /* @__PURE__ */ import_react.default.createElement("span", {
      dangerouslySetInnerHTML: { __html: formattedMessage }
    });
  }
  formatNumber(value, opts) {
    const numberFormatter = NumberFormatters[this.config.locale];
    switch (opts == null ? void 0 : opts.format) {
      case "float":
        return numberFormatter.float(value);
      case "currency": {
        return numberFormatter.currency(value, opts.currency, opts.toInteger);
      }
      case "decimal":
      default:
        return numberFormatter.decimal(value);
    }
  }
  formatName({ firstName, lastName }) {
    switch (this.config.locale) {
      case "hu" /* hu */: {
        return `${lastName} ${firstName}`.trim();
      }
      default: {
        return `${firstName} ${lastName}`.trim();
      }
    }
  }
};

// src/PreviewMessage.tsx
var import_react3 = __toESM(require("react"));
var import_styled_components = __toESM(require("styled-components"));

// src/Portal.tsx
var import_react2 = __toESM(require("react"));
var import_react_dom = __toESM(require("react-dom"));
var Portal = class extends import_react2.default.Component {
  constructor(props) {
    super(props);
    this.portalEl = document.createElement("div");
  }
  componentDidMount() {
    document.body.appendChild(this.portalEl);
  }
  componentWillUnmount() {
    document.body.removeChild(this.portalEl);
  }
  render() {
    return import_react_dom.default.createPortal(this.props.children, this.portalEl);
  }
};

// src/PreviewMessage.tsx
var PreviewMessage = class extends import_react3.default.Component {
  constructor() {
    super(...arguments);
    this.state = {
      iconWrapperLeft: 0,
      isButtonsVisible: false,
      showIconBottom: false,
      rect: { height: 0, width: 0, x: 0, y: 0, bottom: 0, left: 0, right: 0, top: 0 }
    };
    this.containerRef = null;
    this.onEditClick = (event) => {
      event.stopPropagation();
      event.preventDefault();
      LivePreviewHelper.postMessage(OPMAction.editKey(this.props.messageId));
    };
    this.onScrollToClick = (event) => {
      event.stopPropagation();
      event.preventDefault();
      LivePreviewHelper.postMessage(OPMAction.highlightKey(this.props.messageId));
    };
  }
  componentDidMount() {
    if (this.containerRef) {
      if (this.containerRef.getBoundingClientRect().width < 49) {
        this.setState({ iconWrapperLeft: -2 });
      }
      const rect = this.containerRef.getClientRects();
      if (rect && rect[0] && rect[0].y < 58) {
        this.setState({ showIconBottom: true });
      }
    }
  }
  render() {
    return /* @__PURE__ */ import_react3.default.createElement(RefContainer, {
      ref: (ref) => {
        this.containerRef = ref;
      },
      onMouseEnter: () => {
        this.setState({ isButtonsVisible: true });
        if (this.containerRef && window) {
          this.setState({
            rect: {
              height: this.containerRef.getBoundingClientRect().height,
              width: this.containerRef.getBoundingClientRect().width,
              x: this.containerRef.getBoundingClientRect().x + window.scrollX,
              y: this.containerRef.getBoundingClientRect().y + window.scrollY,
              bottom: 0,
              left: 0,
              right: 0,
              top: 0
            }
          });
        }
      },
      onMouseLeave: () => {
        this.setState({ isButtonsVisible: false });
      }
    }, this.props.message, /* @__PURE__ */ import_react3.default.createElement(Portal, null, /* @__PURE__ */ import_react3.default.createElement(Container, {
      isButtonsVisible: this.state.isButtonsVisible,
      showIconBottom: this.state.showIconBottom,
      style: {
        height: Math.round(this.state.rect.height),
        width: Math.round(this.state.rect.width),
        left: Math.round(this.state.rect.x),
        top: Math.round(this.state.rect.y)
      }
    }, this.state.isButtonsVisible && /* @__PURE__ */ import_react3.default.createElement(IconWrapper, {
      onClick: (e) => e.preventDefault(),
      left: this.state.iconWrapperLeft,
      showIconBottom: this.state.showIconBottom,
      style: { left: this.state.iconWrapperLeft ? this.state.iconWrapperLeft : void 0 }
    }, /* @__PURE__ */ import_react3.default.createElement(IconArrowRightTop, {
      width: 18,
      height: 18,
      onClick: this.onScrollToClick
    }), /* @__PURE__ */ import_react3.default.createElement(IconEdit, {
      width: 18,
      height: 18,
      onClick: this.onEditClick
    })))));
  }
};
var RefContainer = import_styled_components.default.span`
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    z-index: 1;
`;
var Container = import_styled_components.default.span`
    position: absolute;
    box-shadow: ${(props) => props.isButtonsVisible ? "0px 0px 0px 2px #ff7657" : "none"};
    box-sizing: border-box;
    border-radius: ${(props) => props.showIconBottom ? "5px 5px 0px 5px" : "5px 0 5px 5px"};
    /* ${(props) => props.isButtonsVisible ? "margin: -2px;" : ""}; */
    z-index: 1500;
    display: block;
    pointer-events: none;
`;
var IconWrapper = import_styled_components.default.span`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 78px;
    height: 33px;
    ${(props) => props.showIconBottom ? "bottom: -33px;" : "top: -33px;"}
    right: -2px;
    border-radius: ${(props) => props.showIconBottom ? "0 0 5px 5px" : "5px 5px 0 0"};
    background: #ff7657;
    pointer-events: all;

    svg {
        transform-origin: center;
        transform: scale(1);
        transition: 125ms transform ease;
        color: #ffffff;
        pointer-events: all;
        flex: 1;
        display: block;
        cursor: pointer;

        :hover {
            transform: scale(1.35);
        }
    }
`;
function IconEdit(props) {
  const width = props.width || "11";
  const height = props.height || "11";
  return /* @__PURE__ */ import_react3.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width,
    height,
    viewBox: "0 0 11 11",
    onClick: props.onClick
  }, /* @__PURE__ */ import_react3.default.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "currentColor"
  }, /* @__PURE__ */ import_react3.default.createElement("path", {
    d: "M1.165 7.281L.516 9.877a.5.5 0 0 0 .607.607l2.596-.65 6.477-6.477a1.037 1.037 0 0 0 0-1.466L9.11.804A1.03 1.03 0 0 0 8.377.5a1.03 1.03 0 0 0-.733.303l-6.48 6.479z"
  }), /* @__PURE__ */ import_react3.default.createElement("path", {
    strokeLinejoin: "round",
    d: "M1 7l1.518 1.518L4 10m2-8l1.518 1.518L9 5"
  })));
}
var IconArrowRightTop = (props) => /* @__PURE__ */ import_react3.default.createElement("svg", __spreadValues({
  viewBox: "0 0 19 19",
  fill: "none"
}, props), /* @__PURE__ */ import_react3.default.createElement("path", {
  d: "M1.515.515v2.99l11.847.011L.454 16.425l2.121 2.12L15.484 5.639l-.011 11.847h3.012V.515H1.515z",
  fill: "currentColor"
}));
var PreviewMessage_default = PreviewMessage;

// src/LoctoolMessage.tsx
var LoctoolMessage = class extends import_react4.Component {
  render() {
    const { id, values, defaultMessage } = this.props;
    const intlMessage = Loctool.instance.formatMessage({ id, defaultMessage }, values);
    if (Loctool.instance.getConfig().isPreviewMode) {
      return /* @__PURE__ */ import_react4.default.createElement(PreviewMessage_default, {
        messageId: id,
        message: intlMessage
      });
    }
    return intlMessage;
  }
};
var LoctoolMessage_default = LoctoolMessage;

// src/LoctoolHTMLMessage.tsx
var import_react5 = __toESM(require("react"));
var FormattedHTMLMessage = class extends import_react5.Component {
  render() {
    const { id, values, jsxValues } = this.props;
    const intlMessage = Loctool.instance.formatHTMLMessage({ id }, values, jsxValues);
    if (Loctool.instance.getConfig().isPreviewMode) {
      return /* @__PURE__ */ import_react5.default.createElement(PreviewMessage_default, {
        messageId: id,
        message: intlMessage
      });
    }
    return /* @__PURE__ */ import_react5.default.createElement(import_react5.default.Fragment, null, intlMessage);
  }
};
var LoctoolHTMLMessage_default = FormattedHTMLMessage;

// src/LoctoolNumber.tsx
var import_react6 = __toESM(require("react"));
var LoctoolNumber = class extends import_react6.PureComponent {
  render() {
    const { value, format, currency, toInteger } = this.props;
    if (format === "currency") {
      if (currency) {
        return /* @__PURE__ */ import_react6.default.createElement("span", {
          dangerouslySetInnerHTML: { __html: Loctool.instance.formatNumber(value, { format, currency, toInteger }).replace(/ +/g, "&nbsp") }
        });
      }
      return "";
    }
    return Loctool.instance.formatNumber(value, { format });
  }
};
var LoctoolNumber_default = LoctoolNumber;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  LivePreviewHelper,
  Locale,
  Loctool,
  LoctoolHTMLMessage,
  LoctoolMessage,
  LoctoolNumber,
  NumberFormatter,
  NumberFormatters
});
