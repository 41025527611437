/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ForwardedRef, useMemo } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Box, TextFieldProps } from "@monster/shared";
import { StyledTextField, StyledTextFieldBorder, StyledTextFieldHelperText, StyledTextFieldLabel, StyledTextFieldStateStyleWrapper, StyledTextFieldSuffixWrapper } from "./TextField.styled";
import { SVGChrIcon32BasicsOk, SVGChrIcon32BasicsArrowOpen } from "../../svg_assets";
import { SVGSharedIcon32BasicsCalendar } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon32BasicsNogo } from "@monster/shared/dist/svg_assets";

export const TextField = React.forwardRef(
    (
        {
            id,
            label,
            multiline,
            errorText,
            helperText,
            successText,
            showCalendarIcon,
            showErrorIcon,
            showSuccessIcon,
            customSuffixElement,
            onCalendarClick,
            hasError: _hasError,
            ...props
        }: TextFieldProps,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        const hasError = !!errorText || _hasError;
        const hasSuccess = !!successText;
        const hasSuffixItem = !!showCalendarIcon || !!showErrorIcon || !!showSuccessIcon || !!customSuffixElement || !!props.list;
        const renderHelperText = errorText ?? successText ?? helperText;

        const onChange =
            props.inputMode === "numeric"
                ? (event: React.ChangeEvent<HTMLTextAreaElement> & React.ChangeEvent<HTMLInputElement>) => {
                      event.target.value = event.target.value.replace(/[^0-9]/g, "");
                      props.onChange && props.onChange(event);
                  }
                : props.onChange;

        const suffixElement = useMemo(() => {
            if (customSuffixElement) {
                return customSuffixElement;
            }
            if (props.list) {
                return <SVGChrIcon32BasicsArrowOpen />;
            }
            if (showCalendarIcon) {
                return <SVGSharedIcon32BasicsCalendar />;
            }
            if (hasError || hasSuccess)
                <>
                    {hasError && <SVGSharedIcon32BasicsNogo />}
                    {hasSuccess && <SVGChrIcon32BasicsOk />}
                </>;
            return null;
        }, [customSuffixElement, hasError, hasSuccess, props.list, showCalendarIcon]);
        return (
            <StyledTextFieldStateStyleWrapper $hasError={hasError} $hasSuccess={hasSuccess} $hasSuffixItem={hasSuffixItem}>
                <Box $style={{ position: "relative", lineHeight: 1 }}>
                    {label && (
                        <StyledTextFieldLabel as="label" htmlFor={id}>
                            {label}
                        </StyledTextFieldLabel>
                    )}

                    <Box $style={{ position: "relative", lineHeight: 1 }}>
                        {/* @ts-ignore */}
                        <StyledTextField ref={ref} as={multiline ? TextareaAutosize : "input"} id={id} aria-describedby={renderHelperText ? `${id}-desc` : undefined} {...props} onChange={onChange} />

                        <StyledTextFieldBorder />

                        {/* NOTE: dummy label for styling by input's states */}
                        {label && (
                            <StyledTextFieldLabel aria-hidden="true" $style={{ pointerEvents: "none", position: "absolute", left: 0, bottom: "100%", zIndex: 1 }}>
                                {label}
                            </StyledTextFieldLabel>
                        )}

                        {suffixElement && (
                            <StyledTextFieldSuffixWrapper
                                as={showCalendarIcon ? "button" : "div"}
                                type={showCalendarIcon ? "button" : undefined}
                                onClick={onCalendarClick}
                                isCalendarSuffix={showCalendarIcon}
                                tabIndex={showCalendarIcon ? -1 : undefined}
                            >
                                {suffixElement}
                            </StyledTextFieldSuffixWrapper>
                        )}
                    </Box>

                    <div aria-atomic="true" aria-live="assertive">
                        {renderHelperText && <StyledTextFieldHelperText id={`${id}-desc`}>{renderHelperText}</StyledTextFieldHelperText>}
                    </div>
                </Box>
            </StyledTextFieldStateStyleWrapper>
        );
    }
);
