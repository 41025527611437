import React from "react";
import styled, { useTheme } from "styled-components";
import { Box, SharedDialog } from "@monster/shared";
import { SVGSharedIcon32BasicsArrowRight } from "@monster/shared/dist/svg_assets";
import { Button, Surface, StyledOverlay, Text } from "@monster/chr-ui";
import { ClaimCauseIcon } from "@pages/CreateClaim/ClaimCauseIcon";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Path } from "@utils/Path";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { LoctoolMessage } from "@monster/loctool";
import { ApplicationState } from "@redux/Reducers";
import { PageSteps } from "@redux/actions/appStateActions";
import { ClaimCauseHelpers } from "@utils/ClaimCauseHelpers";
import { ClaimData } from "@api/graphql/types";

export const OpenClaimsDialog = () => {
    const theme = useTheme();
    const history = useHistory();
    const claimsInProgress = useSelector(SessionSelectors.getClaimsInProgress);
    const currentStep = useSelector((state: ApplicationState) => state.appState.currentStep);
    const [claimsDialogOpen, setClaimsDialogOpen] = React.useState(claimsInProgress.length > 0 && currentStep === PageSteps.ClauseSelection);
    return (
        <SharedDialog.Root open={claimsDialogOpen}>
            <Box as={StyledOverlay} $style={{ display: "grid", placeItems: "flex-start center", overflow: "auto" }} $styleMedium={{ placeItems: "center" }}>
                <SharedDialog.Content asChild aria-describedby={undefined} onEscapeKeyDown={e => e.preventDefault()} onPointerDownOutside={e => e.preventDefault()}>
                    <Surface
                        $variant={"white"}
                        $style={{ maxWidth: 504, width: "100%", margin: "56px 0", padding: "40px 8px 48px", "&:focus-visible": { outline: "none" } }}
                        $styleMedium={{ paddingLeft: 32, paddingRight: 32 }}
                    >
                        <Box $style={{ textAlign: "center" }}>
                            <SharedDialog.Title asChild>
                                <Text as={"h2"} $variant={"h3Desktop"} $style={{ margin: 0 }}>
                                    <LoctoolMessage id="pages.fileClaim.createNewDialog.title" />
                                </Text>
                            </SharedDialog.Title>
                            <Box as={"p"} $style={{ margin: "24px 0" }}>
                                <LoctoolMessage id="pages.fileClaim.createNewDialog.description" />
                            </Box>
                        </Box>
                        <Box $style={{ maxWidth: 390, margin: "0 auto", paddingTop: 16 }}>
                            {claimsInProgress.map((claim: ClaimData) => {
                                const claimCauseType = ClaimCauseHelpers.getClaimCauseTypeById(claim.claimCause.id);
                                return (
                                    <ClaimButton key={claim.id} onClick={() => history.push(Path.claim(claim.id))}>
                                        <ClaimCauseIcon claimCauseType={claimCauseType} />
                                        <Box as={"span"} $style={{ padding: "4px 0px 4px 8px", fontWeight: 700 }}>
                                            <LoctoolMessage id={`enums.cause.${claim.claimCause.translationKey ?? ""}`} />{" "}
                                            <Text as={"span"} $variant={"captionDesktop"} $style={{ fontWeight: 400 }}>
                                                (ID:{claim.id})
                                            </Text>
                                        </Box>
                                        <SVGSharedIcon32BasicsArrowRight />
                                    </ClaimButton>
                                );
                            })}
                            <Box
                                $style={{
                                    display: "grid",
                                    justifyItems: "center",
                                    position: "relative",
                                    margin: "18px 0 10px",
                                    "&::before": { content: '""', position: "absolute", top: "50%", left: 0, right: 0, borderTop: `1px solid ${theme.color.neutral200}` },
                                }}
                            >
                                <Box $style={{ position: "relative", padding: "0 10px", backgroundColor: theme.color.neutral0 }}>
                                    <LoctoolMessage id="common.or" />
                                </Box>
                            </Box>
                            <Button.Secondary btnLabel={<LoctoolMessage id="pages.fileClaim.createNewDialog.createButtonLabel" />} isExpanded onClick={() => setClaimsDialogOpen(false)} />
                        </Box>
                    </Surface>
                </SharedDialog.Content>
            </Box>
        </SharedDialog.Root>
    );
};

const ClaimButton = styled.button.attrs(() => ({ type: "button" }))`
    border-radius: 16px;
    box-shadow: ${({ theme }) => `inset 0px 0px 0px 1px ${theme.color.neutral300}`};
    column-gap: 8px;
    display: grid;
    grid-template-columns: 32px 1fr auto;
    padding: 12px 16px;
    width: 100%;

    & + & {
        margin-top: 10px;
    }

    &:focus-within {
        outline: none;
    }

    @media (hover: hover) {
        &:hover {
            box-shadow: ${({ theme }) => `inset 0px 0px 0px 3px ${theme.color.neutral300}`};
        }
    }
`;
