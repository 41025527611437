import React from "react";
import styled, { useTheme } from "styled-components";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { bp, Box, Flex, flexItemShrinkVariant, List } from "@monster/shared";
import { Button, Text, Tooltip, TooltipContent, TooltipTrigger } from "@monster/chr-ui";
import { SVGSharedIcon32BasicsArrowRight } from "@monster/shared/dist/svg_assets";
import { SVGChrIcon32BasicsQuestionMark } from "@monster/chr-ui/dist/svg_assets";
import { LegalDocumentLink } from "./LegalDocumentLink";
import { legalDocumentCategory } from "@api/graphql/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { Helpers } from "@utils/Helpers";
import { OpeningHoursHelpers } from "@utils/OpeningHoursHelpers";

export const Footer = () => {
    const theme = useTheme();
    const shadowBottom = `inset 0 -1px 0 0 ${theme.color.greyN}`;
    const { chat, tel, isChatAvailable, isTelAvailable } = useSelector((state: ApplicationState) => state.openingHours);

    return (
        <StyledFooter>
            <Box $style={{ maxWidth: 1024 + 2 * 16, margin: "0 auto", padding: "16px 16px 24px" }} $styleLarge={{ paddingTop: 48, paddingBottom: 72 }}>
                <Flex.Container $style={{ columnGap: 32, flexWrap: "wrap" }}>
                    <Flex.Item $shrink={1} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
                        <Text
                            as="h2"
                            $variant="bodyMobile"
                            $style={{ boxShadow: shadowBottom, margin: 0, padding: "32px 0 16px" }}
                            $styleLarge={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", minHeight: 80, padding: 16 }}
                        >
                            <LoctoolMessage id="components.footer.questions" />{" "}
                            <Box as="span" $style={{ fontWeight: 400 }}>
                                <LoctoolMessage id="components.footer.contactUs" />
                            </Box>
                        </Text>

                        <List>
                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <Box $style={{ position: "relative", paddingRight: 32 + 16 }}>
                                    <StyledFooterListItemInnerElement as="button" type="button" disabled={false} onClick={Helpers.openLivePersonChat}>
                                        <Flex.Container as="span">
                                            <StyledIsOpenDotItem $style={{ color: isChatAvailable ? theme.color.greenN : theme.color.greyD }} />

                                            <Flex.Item as="span" $shrink="auto">
                                                <LoctoolMessage id="components.footer.liveChat" />{" "}
                                                <Box as="span" $style={{ color: isChatAvailable ? theme.color.greenN : theme.color.redN }}>
                                                    – <LoctoolMessage id={`common.${isChatAvailable ? "open" : "closed"}`} />
                                                </Box>
                                            </Flex.Item>
                                        </Flex.Container>
                                    </StyledFooterListItemInnerElement>

                                    {chat && (
                                        <Box
                                            $style={
                                                isChatAvailable
                                                    ? { position: "absolute", top: 12, right: 0, zIndex: 1 }
                                                    : {
                                                          position: "absolute",
                                                          inset: 0,
                                                          zIndex: 1,
                                                          paddingTop: 12,
                                                          textAlign: "right",
                                                          "&&&>button": { "&::after": { content: '""', position: "absolute", inset: 0 } },
                                                      }
                                            }
                                            $styleLarge={{ right: 16 }}
                                        >
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button.Secondary tabIndex={!isChatAvailable ? undefined : -1} btnLabel={"info"} icon={<SVGChrIcon32BasicsQuestionMark />} size="xsmall" />
                                                </TooltipTrigger>
                                                <TooltipContent align="end" alignOffset={-16}>
                                                    <Box $style={{ width: "calc(100vw - 3.5 * 16px)" }} $styleSmall={{ width: 320, minHeight: 72 }} $styleMedium={{ width: 374 }}>
                                                        <strong>
                                                            <LoctoolMessage id="components.footer.openinghours" />
                                                        </strong>
                                                        <br />
                                                        {OpeningHoursHelpers.get(chat.data).map(opening => {
                                                            return (
                                                                <React.Fragment key={opening.day}>
                                                                    <LoctoolMessage id={`common.day.${opening.day}`} />
                                                                    {opening.dayTo && " - "}
                                                                    {opening.dayTo && <LoctoolMessage id={`common.day.${opening.dayTo}`} />}:{" "}
                                                                    <strong>{opening.from ? `${opening.from} - ${opening.to}` : <LoctoolMessage id={"common.closed"} />}</strong>
                                                                    <br />
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </Box>
                                                </TooltipContent>
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <Box $style={{ position: "relative", paddingRight: 32 + 16 }}>
                                    <StyledFooterListItemInnerElement as="a" href={`tel:${Loctool.instance.formatMessage({ id: "components.footer.telLink" }).replace(/\s/g, "")}`}>
                                        <Flex.Container as="span">
                                            <StyledIsOpenDotItem $style={{ color: isTelAvailable ? theme.color.greenN : theme.color.greyD }} />

                                            <Flex.Item as="span" $shrink="auto">
                                                <LoctoolMessage id="components.footer.tel" />{" "}
                                                <Box as="span" $style={{ color: isTelAvailable ? theme.color.greenN : theme.color.redN }}>
                                                    – <LoctoolMessage id={`common.${isTelAvailable ? "open" : "closed"}`} />
                                                </Box>
                                            </Flex.Item>
                                        </Flex.Container>
                                    </StyledFooterListItemInnerElement>

                                    {tel && (
                                        <Box
                                            $style={
                                                isTelAvailable
                                                    ? { position: "absolute", top: 12, right: 0, zIndex: 1 }
                                                    : {
                                                          position: "absolute",
                                                          inset: 0,
                                                          zIndex: 1,
                                                          paddingTop: 12,
                                                          textAlign: "right",
                                                          "&&&>button": { "&::after": { content: '""', position: "absolute", inset: 0 } },
                                                      }
                                            }
                                            $styleLarge={{ right: 16 }}
                                        >
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button.Secondary tabIndex={!isTelAvailable ? undefined : -1} btnLabel={"info"} icon={<SVGChrIcon32BasicsQuestionMark />} size="xsmall" />
                                                </TooltipTrigger>
                                                <TooltipContent align="end" alignOffset={-16}>
                                                    <Box $style={{ width: "calc(100vw - 3.5 * 16px)" }} $styleSmall={{ width: 320, minHeight: 72 }} $styleMedium={{ width: 374 }}>
                                                        <strong>
                                                            <LoctoolMessage id="components.footer.openinghours" />
                                                        </strong>
                                                        <br />
                                                        {OpeningHoursHelpers.get(tel.data).map(opening => {
                                                            return (
                                                                <React.Fragment key={opening.day}>
                                                                    <LoctoolMessage id={`common.day.${opening.day}`} />
                                                                    {opening.dayTo && " - "}
                                                                    {opening.dayTo && <LoctoolMessage id={`common.day.${opening.dayTo}`} />}:{" "}
                                                                    <strong>{opening.from ? `${opening.from} - ${opening.to}` : <LoctoolMessage id={"common.closed"} />}</strong>
                                                                    <br />
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </Box>
                                                </TooltipContent>
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <StyledFooterListItemInnerElement as="a" href="mailto:cherrisk@cherrisk.com">
                                    <Flex.Item as="span" $shrink="auto">
                                        cherrisk@cherrisk.com
                                    </Flex.Item>
                                </StyledFooterListItemInnerElement>
                            </Box>
                        </List>
                    </Flex.Item>

                    <Flex.Item $shrink={1} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
                        <Text
                            as="h2"
                            $variant="bodyMobile"
                            $style={{ boxShadow: shadowBottom, margin: 0, padding: "32px 0 16px" }}
                            $styleLarge={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", minHeight: 80, padding: 16 }}
                        >
                            <LoctoolMessage id="components.footer.legalDocuments" />
                        </Text>

                        <List>
                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <StyledFooterListItemInnerElement as={LegalDocumentLink} legalDocumentCategory={legalDocumentCategory.CHERRISK_PRIVACY_POLICY}>
                                    <SVGSharedIcon32BasicsArrowRight />
                                </StyledFooterListItemInnerElement>
                            </Box>
                        </List>
                    </Flex.Item>

                    <Flex.Item $shrink={1} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
                        <Text
                            as="h2"
                            $variant="bodyMobile"
                            $style={{ boxShadow: shadowBottom, margin: 0, padding: "32px 0 16px" }}
                            $styleLarge={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", minHeight: 80, padding: 16 }}
                        >
                            <LoctoolMessage id="components.footer.faq" />
                        </Text>

                        <List>
                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <StyledFooterListItemInnerElement
                                    as="a"
                                    href={`${process.env.REACT_APP_WEBSITE_URL}/de-${Loctool.instance.getConfig().locale.toLowerCase()}/faq`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <LoctoolMessage id="components.footer.faq.answers" />

                                    <SVGSharedIcon32BasicsArrowRight />
                                </StyledFooterListItemInnerElement>
                            </Box>
                        </List>
                    </Flex.Item>
                </Flex.Container>
            </Box>
        </StyledFooter>
    );
};

const StyledIsOpenDotItem = styled(Flex.Item).attrs(() => ({
    as: "span",
    $shrink: "shrink",
}))`
    padding: 8px 16px 0 0;
    position: relative;

    &::before {
        background-color: currentColor;
        border-radius: 50%;
        content: "";
        display: block;
        height: 8px;
        width: 8px;
    }
`;

const StyledFooterListItemInnerElement = styled(Flex.Container)`
    align-items: center;
    color: ${({ theme }) => theme.color.blueD};
    column-gap: 16px;
    justify-content: space-between;
    min-height: 56px;
    padding: 12px 0;
    text-decoration: none;
    width: 100%;

    ${bp.large} {
        padding: 12px 16px;
    }

    @media (hover: hover) {
        a&,
        button& {
            &:hover {
                color: ${({ theme }) => theme.color.greenN};
            }
        }
    }
`;

const StyledFooter = styled.footer`
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.color.greyD};
    margin-top: auto;
    position: relative;
    z-index: 1;
`;
