import React from "react";
import styled, { css } from "styled-components";
import { ButtonProps, ButtonSize, ButtonVariantProps } from "./ButtonTypes";

export const StyledBaseButton = styled.button<ButtonVariantProps & { $hasIcon: boolean; $iconPosition?: "left" | "right" }>`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    /* position: relative; */
    vertical-align: bottom;

    a& {
        text-decoration: none;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const cherriskButtonSizeXSmallCSS = css`
    padding: 4px 24px;
`;

const cherriskButtonSizeXSmallHasIconCSS = css``;

const cherriskButtonSizeXSmallHasIconLeftCSS = css`
    gap: 4px;
    padding: 0 16px 0 4px;
`;

const cherriskButtonSizeXSmallHasIconRightCSS = css`
    gap: 4px;
    padding: 0 4px 0 16px;
`;

const cherriskButtonSizeSmallCSS = css`
    padding: 8px 24px;
`;

const cherriskButtonSizeSmallHasIconCSS = css`
    padding: 4px;
`;

const cherriskButtonSizeSmallHasIconLeftCSS = css`
    gap: 4px;
    padding: 4px 16px 4px 4px;
`;

const cherriskButtonSizeSmallHasIconRightCSS = css`
    gap: 4px;
    padding: 4px 4px 4px 16px;
`;

const cherriskButtonSizeMediumCSS = css`
    padding: 12px 24px;
`;

const cherriskButtonSizeMediumHasIconCSS = css`
    padding: 8px;
`;

const cherriskButtonSizeMediumHasIconLeftCSS = css`
    gap: 4px;
    padding: 8px 24px 8px 16px;
`;

const cherriskButtonSizeMediumHasIconRightCSS = css`
    gap: 4px;
    padding: 8px 16px 8px 24px;
`;

const cherriskButtonSizeLargeCSS = css`
    padding: 16px 24px;
`;

const cherriskButtonSizeLargeHasIconCSS = css`
    padding: 12px;
`;

const cherriskButtonSizeLargeHasIconLeftCSS = css`
    gap: 4px;
    padding: 12px 24px 12px 16px;
`;

const cherriskButtonSizeLargeHasIconRightCSS = css`
    gap: 4px;
    padding: 12px 16px 12px 24px;
`;

export const StyledBaseButtonSize = styled(StyledBaseButton)`
    ${({ size, $hasIcon, $iconPosition }) => `
  ${
      size === "xsmall"
          ? $hasIcon
              ? $iconPosition
                  ? $iconPosition === "left"
                      ? cherriskButtonSizeXSmallHasIconLeftCSS
                      : cherriskButtonSizeXSmallHasIconRightCSS
                  : cherriskButtonSizeXSmallHasIconCSS
              : cherriskButtonSizeXSmallCSS
          : ""
  }

  ${
      size === "small"
          ? $hasIcon
              ? $iconPosition
                  ? $iconPosition === "left"
                      ? cherriskButtonSizeSmallHasIconLeftCSS
                      : cherriskButtonSizeSmallHasIconRightCSS
                  : cherriskButtonSizeSmallHasIconCSS
              : cherriskButtonSizeSmallCSS
          : ""
  }

  ${
      size === "medium"
          ? $hasIcon
              ? $iconPosition
                  ? $iconPosition === "left"
                      ? cherriskButtonSizeMediumHasIconLeftCSS
                      : cherriskButtonSizeMediumHasIconRightCSS
                  : cherriskButtonSizeMediumHasIconCSS
              : cherriskButtonSizeMediumCSS
          : ""
  }

  ${
      size === "large"
          ? $hasIcon
              ? $iconPosition
                  ? $iconPosition === "left"
                      ? cherriskButtonSizeLargeHasIconLeftCSS
                      : cherriskButtonSizeLargeHasIconRightCSS
                  : cherriskButtonSizeLargeHasIconCSS
              : cherriskButtonSizeLargeCSS
          : ""
  }
  `}
`;

export const StyledBaseButtonIsExpanded = styled(StyledBaseButtonSize)`
    display: flex;
    width: 100%;
`;

export const StyledButton = React.forwardRef(({ isExpanded, size, ...props }: ButtonProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) => {
    const defaultSize: ButtonSize = "medium";

    if (isExpanded) {
        // TODO:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <StyledBaseButtonIsExpanded size={size ?? defaultSize} $hasIcon={!!props.icon} $iconPosition={props.iconPosition} {...props} ref={ref} />;
    }

    // TODO:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <StyledBaseButtonSize size={size ?? defaultSize} $hasIcon={!!props.icon} $iconPosition={props.iconPosition} {...props} ref={ref} />;
});
