import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { Box, Flex, List, useScrollDirection } from "@monster/shared";
import { Text } from "./Text";
import { SVGChrIcon32BasicsArrowOpen } from "../svg_assets";

export type AppBarBreadcrumb = {
    id: string;
    label: React.ReactNode;
    labelString: string;
    path: string;
    isActive?: boolean;
};

type Props = {
    breadcrumbs?: AppBarBreadcrumb[];
    leftItemChild?: React.ReactNode;
    centerItemChild?: React.ReactNode;
    rightItemChild?: React.ReactNode;
};

export const AppBar = (props: Props) => {
    const theme = useTheme();
    const scrollDirection = useScrollDirection(64);
    const { breadcrumbs, leftItemChild, centerItemChild, rightItemChild } = props;

    const renderBreadcrumbs = useMemo(() => {
        let isActiveFound = false;
        return breadcrumbs
            ? breadcrumbs.map((breadcrumb: AppBarBreadcrumb, index: number) => {
                  let state: "done" | "active" | undefined = "done";
                  if (isActiveFound) {
                      state = undefined;
                  } else if (breadcrumb.isActive) {
                      state = "active";
                      isActiveFound = true;
                  }
                  return (
                      index > 0 &&
                      state === "active" && (
                          <Text key={breadcrumb.id} className="hide-for-large" $variant="bodyMobile">
                              <Box as="span" $style={{ fontWeight: 700 }}>
                                  {index + 1}
                              </Box>{" "}
                              / {breadcrumbs.length} <span className="show-for-sr">{breadcrumb.labelString}</span>
                          </Text>
                      )
                  );
              })
            : undefined;
    }, [breadcrumbs]);

    const renderBreadcrumbsLarge = useMemo(() => {
        let isActiveFound = false;
        return breadcrumbs
            ? breadcrumbs.map((breadcrumb: AppBarBreadcrumb, index: number) => {
                  let state: "done" | "active" | undefined = "done";
                  if (isActiveFound) {
                      state = undefined;
                  } else if (breadcrumb.isActive) {
                      state = "active";
                      isActiveFound = true;
                  }
                  return (
                      <BreadcrumbItem
                          as="li"
                          key={`${breadcrumb.id}-large`}
                          $shrink="shrink"
                          $style={{ color: state === "done" ? theme.color.greenN : state === "active" ? theme.color.blueD : theme.color.blueL }}
                      >
                          {index > 0 ? (
                              <>
                                  <Box as="span" $style={{ display: "block", transformOrigin: "center", transform: "rotate(-90deg)" }}>
                                      <SVGChrIcon32BasicsArrowOpen />
                                  </Box>

                                  {breadcrumb.label}
                              </>
                          ) : (
                              breadcrumb.label
                          )}
                      </BreadcrumbItem>
                  );
              })
            : undefined;
    }, [breadcrumbs, theme]);

    return (
        <Wrapper style={{ transform: scrollDirection === "down" ? "translate3d(0, -100%, 0)" : "translate3d(0, 0, 0)" }}>
            <Flex.Container
                $style={{
                    maxWidth: 1024 + 2 * 16,
                    minHeight: 64,
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: 16,
                    alignItems: "center",
                    gap: 16,
                }}
            >
                {leftItemChild && <Flex.Item $shrink="shrink">{leftItemChild}</Flex.Item>}

                <Flex.Item $shrink="auto">
                    {centerItemChild ?? (
                        <>
                            {renderBreadcrumbs}

                            <Box as="nav" className="show-for-large" $style={{ textAlign: "center" }}>
                                <Flex.Container as={List} forcedAs="ol" $style={{ display: "inline-flex", flexWrap: "wrap" }}>
                                    {renderBreadcrumbsLarge}
                                </Flex.Container>
                            </Box>
                        </>
                    )}
                </Flex.Item>

                {rightItemChild && <Flex.Item $shrink="shrink">{rightItemChild}</Flex.Item>}
            </Flex.Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.color.greyD};
    position: sticky;
    top: 0;
    transition: transform 240ms cubic-bezier(0.87, 0, 0.13, 1);
    z-index: ${({ theme }) => theme.zIndex.appBar};
`;

const BreadcrumbItem = styled(Flex.Item)`
    align-items: center;
    display: flex;

    a {
        color: inherit;
        text-decoration: none;
    }

    @media (hover: hover) {
        a {
            &:hover {
                color: ${({ theme }) => theme.color.greenN};
            }
        }
    }
`;
