import { AppBar } from "@components/AppBar";
import { useDispatch, useSelector } from "react-redux";
import { AppStateActions } from "@redux/actions/appStateActions";
import { useHistory } from "react-router-dom";
import { Page } from "@components/Page";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LoctoolMessage } from "@monster/loctool";
import { Box, Cookie } from "@monster/shared";
import { Text } from "@monster/chr-ui";
import { PolicyDetailsForm } from "@pages/Login/PolicyDetailsForm";
import { EmailCodeConfirmationForm } from "@components/EmailCodeConfirmationForm";
import { FileClaimPageList, FileClaimPageListItem } from "@components/PageListItems";
import { Api } from "@api/Api";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { Path } from "@utils/Path";

enum Step {
    PolicyInput = 1,
    VerifyCode = 2,
}

export const LoginPage = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(SessionSelectors.isAuthenticated);
    const history = useHistory();
    const [currentStep, setCurrentStep] = useState<Step>(Step.PolicyInput);
    const [policyResult, setPolicyResult] = useState({ insuranceId: "", dateOfBirth: "", sessionId: "", maskedEmail: "" });

    const onPolicyDetailsSubmit = useCallback(async (data: { insuranceId: string; dateOfBirth: string }) => {
        const result = await Api.sendValidationEmail(data);
        Cookie.claimSessionId.set(result.sessionId);
        setPolicyResult({ ...result, ...data });
        setCurrentStep(Step.VerifyCode);
    }, []);

    const initialValues = useMemo(() => {
        return { insuranceId: policyResult?.insuranceId ?? "", dateOfBirth: policyResult?.dateOfBirth ?? "" };
    }, [policyResult?.dateOfBirth, policyResult?.insuranceId]);

    useEffect(() => {
        if (isAuthenticated) {
            history.push(Path.createClaim);
        }
    }, [history, isAuthenticated]);

    return (
        <Page
            appBar={
                <AppBar
                    activeIndex={currentStep}
                    onBackButtonClick={() => history.goBack()}
                    onItemClick={itemIndex => {
                        if (itemIndex === 0) {
                            dispatch(AppStateActions.reset());
                        }
                    }}
                />
            }
        >
            <Box $style={{ maxWidth: 504, margin: "0 auto" }}>
                <Text as="h1" $variant="h2Desktop" $style={{ margin: 0, padding: "40px 16px 16px" }} $styleLarge={{ paddingTop: 44, paddingBottom: 0 }}>
                    <LoctoolMessage id="page.fileClaim.title" />
                </Text>
                <FileClaimPageList as="ol">
                    <FileClaimPageListItem
                        id="PolicyDetails"
                        headingText={<LoctoolMessage id="pages.identification.policyDetails.title" />}
                        isActive={currentStep === Step.PolicyInput}
                        isFinished={currentStep > Step.PolicyInput}
                        forceEditButton={currentStep < Step.PolicyInput}
                        onEditClick={() => {
                            Cookie.claimSessionId.remove();
                            setCurrentStep(Step.PolicyInput);
                        }}
                    >
                        <PolicyDetailsForm initialValues={initialValues} onSubmit={onPolicyDetailsSubmit} disabled={currentStep !== Step.PolicyInput} />
                    </FileClaimPageListItem>

                    <FileClaimPageListItem
                        id="EmailConfirmation"
                        headingText={<LoctoolMessage id="pages.identification.emailConfirmation.title" />}
                        isActive={currentStep === Step.VerifyCode}
                        isFinished={currentStep > Step.VerifyCode}
                        forceEditButton={currentStep > Step.VerifyCode}
                        hideContent={currentStep < Step.VerifyCode}
                    >
                        <EmailCodeConfirmationForm
                            maskedEmail={policyResult.maskedEmail}
                            onResendClick={async () => {
                                const result = await Api.sendValidationEmail({ dateOfBirth: policyResult.dateOfBirth, insuranceId: policyResult.insuranceId });
                                setPolicyResult(policyResult => ({ ...policyResult, ...result }));
                            }}
                        />
                    </FileClaimPageListItem>
                </FileClaimPageList>
            </Box>
        </Page>
    );
};
