import * as TabsPrimitive from "@radix-ui/react-tabs";

// Root
const RootComponent = TabsPrimitive.Root;

// List
const ListComponent = TabsPrimitive.List;

// Trigger
const TriggerComponent = TabsPrimitive.Trigger;

// Content
const ContentComponent = TabsPrimitive.Content;

export const Tabs = {
    Root: RootComponent,
    List: ListComponent,
    Trigger: TriggerComponent,
    Content: ContentComponent,
};
