import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverShare = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5 17C25.7761 17 26 17.2239 26 17.5V25.5C26 25.7761 25.7761 26 25.5 26H6.5C6.22386 26 6 25.7761 6 25.5V17.5C6 17.2239 6.22386 17 6.5 17C6.77614 17 7 17.2239 7 17.5V25H25V17.5C25 17.2239 25.2239 17 25.5 17ZM16.2689 5.57836L16.3463 5.63931L20.5129 9.63931C20.7121 9.83054 20.7186 10.1471 20.5274 10.3463C20.3574 10.5233 20.0884 10.5481 19.8908 10.4171L19.8204 10.3607L16.5 7.172V18.5C16.5 18.7761 16.2761 19 16 19C15.7239 19 15.5 18.7761 15.5 18.5V7.172L12.1796 10.3607C12.0025 10.5307 11.7328 10.5445 11.5407 10.4055L11.4726 10.3463C11.3027 10.1692 11.2889 9.89943 11.4278 9.70736L11.4871 9.63931L15.6537 5.63931C15.823 5.47678 16.0781 5.45647 16.2689 5.57836Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverShare;
