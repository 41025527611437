import React from "react";
import { useDateFieldState, DateFieldState, DateSegment } from "@react-stately/datepicker";
import { useDateField, useDateSegment, AriaDatePickerProps } from "@react-aria/datepicker";
import { DateValue } from "@react-types/datepicker";
import { createCalendar } from "@internationalized/date";
import styled, { useTheme } from "styled-components";
import { StyledTextFieldBorder } from "../TextField.styled";
import { SVGSharedIcon32BasicsNogo } from "@monster/shared/dist/svg_assets";
import { Flex } from "@monster/shared";

export const DateField = (props: AriaDatePickerProps<DateValue>) => {
    const theme = useTheme();
    const locale = "DE";
    const state = useDateFieldState({
        ...props,
        locale,
        createCalendar,
    });

    const ref = React.createRef<HTMLDivElement>();
    const { fieldProps } = useDateField(props, state, ref);

    return (
        <StyledDateField {...fieldProps} ref={ref}>
            {state.segments.map((segment, i) => (
                <DateSegment key={i} segment={segment} state={state} />
            ))}
            {state.validationState === "invalid" && (
                <Flex.Container $style={{ height: 24, overflow: "hidden", justifyContent: "center", alignItems: "center" }} aria-hidden="true">
                    <SVGSharedIcon32BasicsNogo color={theme.color.redN} />
                </Flex.Container>
            )}
            <StyledTextFieldBorder />
        </StyledDateField>
    );
};

const DateSegment = (props: { segment: DateSegment; state: DateFieldState }) => {
    const theme = useTheme();
    const ref = React.createRef<HTMLDivElement>();
    const { segmentProps } = useDateSegment(props.segment, props.state, ref);

    // console.log("segmentProps: ", segmentProps);

    return (
        <StyledDateSegment
            {...segmentProps}
            ref={ref}
            style={{
                ...segmentProps.style,
                minWidth: props.segment.type === "literal" ? "0" : props.segment.maxValue !== null ? String(props.segment.maxValue).length + "ch" : 0,
                color: props.segment.isPlaceholder ? theme.color.blueL : theme.color.blueN,
            }}
        >
            {/* TODO: add placeholder element
            Always reserve space for the placeholder, to prevent layout shift when editing.
            <StyledDateSegmentPlaceholder
                className="placeholder"
                aria-hidden="true"
                style={{
                    visibility: props.segment.isPlaceholder ? "visible" : "hidden",
                    height: props.segment.isPlaceholder ? "" : 0,
                    pointerEvents: "none",
                }}
            >
                {props.segment.placeholder}
                <FontWeightJumpPlaceholder $fontWeight={700}>{props.segment.placeholder}</FontWeightJumpPlaceholder>
            </StyledDateSegmentPlaceholder>
            {props.segment.isPlaceholder ? "" : props.segment.text} */}

            {props.segment.text}
        </StyledDateSegment>
    );
};

const StyledDateField = styled.div`
    background-clip: padding-box;
    display: inline-flex;
    width: 100%;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 16px;
    padding: 12px 16px;
    position: relative;

    &:hover {
        ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.blueN};
        }
    }

    &:focus-within {
        ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.blueN};
            border-width: 2px;
        }
    }
`;

const StyledDateSegment = styled.div`
    padding: 0 2px;
    font-variant-numeric: tabular-nums;
    text-align: end;
    font-size: ${({ theme }) => theme.text.bodyDesktop.fontSize};
    line-height: ${({ theme }) => theme.text.bodyDesktop.lineHeight};
    font-weight: 700;

    &:focus {
        background-color: ${({ theme }) => theme.color.blueN};
        color: ${({ theme }) => theme.color.white} !important;
        outline: none;
        border-radius: 2px;
    }
`;

// const StyledDateSegmentPlaceholder = styled.span`
//     display: block;
//     width: 100%;
//     text-align: center;
//     color: ${({ theme }) => theme.color.blueL};
// `;
