/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { IMaskMixin } from "react-imask";
import { TextFieldProps } from "@monster/shared";
import { TextField } from "@monster/chr-ui";

const MaskedInput = IMaskMixin(({ id, inputRef, ...props }) => <TextField {...props} id={id!} ref={inputRef} />);

/**
 * // We hack ref, so we need default value to set starting value
 */
export const CurrencyInput = React.forwardRef(({ name, value, onChange, defaultValue, ...props }: TextFieldProps & { defaultValue: string }, ref) => {
    const maskedInputRef = useRef();
    const [unmaskedValue, setUnmaskedValue] = useState(value?.toString() ?? "");

    const handleBlur = () => {
        const value = parseFloat(unmaskedValue);
        // @ts-ignore
        onChange && onChange({ target: { value: Number.isNaN(value) ? "" : unmaskedValue, name } });
    };

    useEffect(() => setUnmaskedValue(value?.toString() ?? ""), [value]);
    useEffect(() => {
        if (unmaskedValue !== (value ?? "")) {
            // @ts-ignore
            onChange && onChange({ target: { value: Number.isNaN(value) ? "" : unmaskedValue, name } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unmaskedValue, name]);

    // react-hook-form uses ref for focus & get name attribute
    useImperativeHandle(
        ref,
        () => {
            return {
                focus: () => {
                    // @ts-ignore
                    maskedInputRef.current?.element?.focus();
                },
                // @ts-ignore
                name: maskedInputRef.current?.element?.name,
            };
        },
        []
    );
    useEffect(() => {
        // set starting value of input
        setUnmaskedValue(defaultValue);
    }, [defaultValue]);

    return (
        <MaskedInput
            // we cannot use ref from forwardRef. Mask start with € break when inputRef forwarded
            // @ts-ignore
            ref={maskedInputRef}
            {...props}
            name={name}
            type="text"
            value={unmaskedValue}
            onAccept={setUnmaskedValue}
            onBlur={handleBlur}
            mask={"€ Number"}
            blocks={{
                Number: {
                    mask: Number,
                    scale: 2,
                    signed: false,
                    thousandsSeparator: ".",
                    radix: ",",
                    max: 999999.99,
                    min: 0.01,
                },
            }}
            // @ts-ignore
            unmask={true}
        />
    );
});
