import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverTravelcancellation = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14 5C14.7881 5 15.5525 5.10129 16.2809 5.29155C15.9661 5.54043 15.6775 5.81969 15.4188 6.12516C14.9582 6.04301 14.4841 6 14 6C9.58172 6 6 9.58172 6 14C6 16.8377 8.64805 21.0808 14 26.5754C18.3454 22.1141 20.9083 18.478 21.7186 15.7492C22.1202 15.6304 22.5047 15.4694 22.8666 15.2722C22.2542 18.3229 19.5375 22.3234 14.7163 27.2731C14.7102 27.2794 14.704 27.2856 14.6977 27.2917C14.3021 27.6771 13.669 27.6687 13.2837 27.2731C7.76122 21.6035 5 17.1791 5 14C5 9.02944 9.02944 5 14 5Z"
                fill="currentColor"
            />
            <path d="M16.5 13.5L25.5 4.5" stroke="currentColor" strokeLinecap="square" />
            <path
                d="M14 10.5C14.0547 10.5 14.1091 10.5013 14.1632 10.5037C14.2405 10.8625 14.3464 11.2105 14.4774 11.5459C14.3229 11.5156 14.1633 11.5 14 11.5C12.6193 11.5 11.5 12.6193 11.5 14C11.5 15.3807 12.6193 16.5 14 16.5C15.265 16.5 16.3106 15.5604 16.4769 14.341C16.7528 14.5765 17.048 14.79 17.3592 14.9799C16.9373 16.4359 15.5929 17.5 14 17.5C12.067 17.5 10.5 15.933 10.5 14C10.5 12.067 12.067 10.5 14 10.5Z"
                fill="currentColor"
            />
            <path d="M21 15.5C24.5899 15.5 27.5 12.5899 27.5 9C27.5 5.41015 24.5899 2.5 21 2.5C17.4101 2.5 14.5 5.41015 14.5 9C14.5 12.5899 17.4101 15.5 21 15.5Z" stroke="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverTravelcancellation;
