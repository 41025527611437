import React, { useCallback, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { LoctoolMessage } from "@monster/loctool";
import { Box, Flex } from "@monster/shared";
import { SVGSharedIcon32BasicsPlus, SVGSharedIcon32BasicsOkBold } from "@monster/shared/dist/svg_assets";
import { Button, Surface } from "@monster/chr-ui";
import { useDispatch, useSelector } from "react-redux";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { ClaimData, ClaimTaskItemData, UploadedClaimDocument } from "@api/graphql/types";
import { Api } from "@api/Api";
import { Constants } from "@utils/Constants";
import { ClaimDocument, DocumentItem } from "../../../components/ClaimDocument";
import { AppStateActions, PageSteps } from "@redux/actions/appStateActions";
import { ApplicationState } from "@redux/Reducers";
import { ClaimCauseHelpers } from "@utils/ClaimCauseHelpers";
import { ClaimTaskItemType } from "@utils/ClaimTaskHelpers";
import { AttachmentList } from "./AttachmentList";
import { UploadFunction, OnDropZoneProgressChange, useDropZone } from "@components/Input/DropZone";

type Props = {
    claim: ClaimData;
    disabled: boolean;
};

export const MandatoryAttachmentForm = ({ claim, disabled }: Props) => {
    const claimId = claim.id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const claimInput = useSelector((state: ApplicationState) => state.session.claimInput);
    const currentClaimTask = useSelector(SessionSelectors.getCurrentClaimTaskByClaimId(claimId));
    const claimCauseType = useMemo(() => ClaimCauseHelpers.getClaimCauseTypeById(claimInput?.claimCauseId), [claimInput]);

    const { proofTasks, invoiceTasks, imageTasks } = useMemo(() => {
        const filterByType = (claimTaskItemType: ClaimTaskItemType) => (claimTaskItem: ClaimTaskItemData) => claimTaskItem.claimTaskItemType === claimTaskItemType;
        return {
            proofTasks: currentClaimTask?.items.filter(filterByType(ClaimTaskItemType.FILELIST__PROOF)) ?? [],
            invoiceTasks: currentClaimTask?.items.filter(filterByType(ClaimTaskItemType.FILELIST__INVOICE)) ?? [],
            imageTasks: currentClaimTask?.items.filter(filterByType(ClaimTaskItemType.IMAGELIST__DAMAGE)) ?? [],
        };
    }, [currentClaimTask?.items]);

    const isTaskItemsCompleted = useCallback((claimTaskItems: ClaimTaskItemData[]) => {
        if (claimTaskItems.length === 0) {
            return true;
        }
        return claimTaskItems.some((claimTaskItem: ClaimTaskItemData) => !!claimTaskItem.answer);
    }, []);

    const [showError, setShowError] = useState(false);
    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const isTasksCompleted = isTaskItemsCompleted(proofTasks) && isTaskItemsCompleted(imageTasks) && isTaskItemsCompleted(invoiceTasks);
            if (isTasksCompleted) {
                dispatch(AppStateActions.setCurrentStep(PageSteps.ClaimOptionalAttachments));
            } else {
                setShowError(true);
            }
        },
        [dispatch, imageTasks, invoiceTasks, isTaskItemsCompleted, proofTasks]
    );

    const alreadyAttachedFiles = useSelector(SessionSelectors.getUploadedMandatoryDocumentsByClaimId(claimId));
    if (disabled) {
        return <AttachmentList uploadedDocuments={alreadyAttachedFiles} />;
    }

    return (
        <>
            <Flex.Container
                $style={{
                    columnGap: 16,
                    backgroundColor: theme.color.greyN,
                    margin: "-4px 0 16px",
                    borderRadius: 8,
                    boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyD}`,
                    padding: 16,
                }}
            >
                <Flex.Item $shrink="shrink">
                    <Box
                        $style={{
                            width: 32,
                            height: 32,
                            backgroundImage:
                                'image-set( url("/images/global-emma-avatar-question-32.png") 1x, url("/images/global-emma-avatar-question-32@2x.png") 2x, url("/images/global-emma-avatar-question-32@3x.png") 3x)',
                        }}
                    />
                </Flex.Item>

                <Flex.Item $shrink="auto" $style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <Box $style={{ maxWidth: 420 }}>
                        <LoctoolMessage id="pages.fileClaim.mandatoryAttachment.note" />
                    </Box>
                </Flex.Item>
            </Flex.Container>

            <Box $style={{ paddingBottom: 24 }} $styleLarge={{ paddingBottom: 0 }}>
                <Surface $variant="whiteArrowBottom" $styleLarge={{ boxShadow: theme.shadow.white, "&::after": { content: "normal" } }}>
                    <form onSubmit={onSubmit}>
                        {proofTasks.length > 0 && (
                            <AttachmentClaimTask
                                key="proof"
                                showError={!isTaskItemsCompleted(proofTasks) && showError}
                                claim={claim}
                                counter={1}
                                legend={<LoctoolMessage id={`pages.fileClaim.mandatoryAttachment.proofTasks.title.${claimCauseType}`} />}
                                claimTaskItems={proofTasks}
                            />
                        )}
                        {invoiceTasks.length > 0 && (
                            <AttachmentClaimTask
                                key="invoice"
                                showError={!isTaskItemsCompleted(invoiceTasks) && showError}
                                claim={claim}
                                counter={proofTasks.length > 0 ? 2 : 1}
                                legend={<LoctoolMessage id={`pages.fileClaim.mandatoryAttachment.invoiceTasks.title.${claimCauseType}`} />}
                                claimTaskItems={invoiceTasks}
                            />
                        )}
                        {imageTasks.length > 0 && (
                            <AttachmentClaimTask
                                key="image"
                                claim={claim}
                                showError={!isTaskItemsCompleted(imageTasks) && showError}
                                counter={proofTasks.length === 0 && invoiceTasks.length === 0 ? 1 : 2}
                                legend={<LoctoolMessage id={`pages.fileClaim.mandatoryAttachment.imageList.title.${claimCauseType}`} />}
                                claimTaskItems={imageTasks}
                            />
                        )}
                        <Box $style={{ position: "relative" }}>
                            <Box $style={{ maxWidth: 288 + 2 * 16, margin: "0 auto", padding: "24px 16px" }} $styleLarge={{ maxWidth: 308 + 2 * 16, padding: "40px 16px" }}>
                                <Button.Primary type="submit" btnLabel={<LoctoolMessage id="pages.fileClaim.attachments.btn.continue" />} isExpanded />
                            </Box>
                        </Box>
                    </form>

                    <Surface className="hide-for-large" $variant="whiteArrowBottomShadow" />
                </Surface>
            </Box>
        </>
    );
};

type AttachmentClaimTaskProps = {
    claim: ClaimData;
    counter: number;
    legend: React.ReactNode;
    claimTaskItems: ClaimTaskItemData[];
    showError: boolean;
};

const AttachmentClaimTask = ({ claim, counter, legend, claimTaskItems, showError }: AttachmentClaimTaskProps) => {
    const theme = useTheme();
    const isSuccessful = useMemo(() => claimTaskItems.some(cti => !!cti.answer), [claimTaskItems]);
    const claimTaskItemsWithoutAnswer = useMemo(() => claimTaskItems.filter(cti => !cti.answer), [claimTaskItems]);
    const uploadFile: UploadFunction = useCallback(
        async (file: File, onProgressChange: OnDropZoneProgressChange): Promise<void> => {
            if (claimTaskItemsWithoutAnswer.length === 0) {
                throw new Error("No claimTaskItem found without answer");
            }
            await Api.uploadImageForClaimTaskItem(claimTaskItemsWithoutAnswer[0].claimTaskItemId, file, onProgressChange);
        },
        [claimTaskItemsWithoutAnswer]
    );
    const alreadyAttachedFiles: UploadedClaimDocument[] = useMemo(() => {
        return claim.uploadedDocuments.filter(ud => claimTaskItems.some(cti => cti.claimTaskItemId === ud.id));
    }, [claim.uploadedDocuments, claimTaskItems]);
    const { onFileDrop, uploadInProgress, uploadingDocList, alreadyUploadedDocumentItems } = useDropZone(uploadFile, alreadyAttachedFiles, claimTaskItems.length);

    const renderClaimDocument = useCallback((claimDocument: DocumentItem) => {
        return <ClaimDocument key={claimDocument.id} claimDocument={claimDocument} />;
    }, []);

    return (
        <Box as={"fieldset"} $style={{ position: "relative", "&+*": { boxShadow: `0px -1px 0px 0px ${theme.color.neutral200}` } }}>
            <legend className={"show-for-sr"}>
                {counter}. {legend}
            </legend>
            <Flex.Container aria-hidden={"true"} $style={{ boxShadow: `inset 0px -1px 0px 0px ${theme.color.neutral300}`, padding: "12px 16px", columnGap: 8, alignItems: "center" }}>
                <Flex.Item $shrink={"auto"}>
                    {/* NOTE: presentation only text. Same text as legend has. */}
                    <Flex.Container $style={{ columnGap: "1ch", padding: "4px 0 4px 8px", lineHeight: "20px" }}>
                        <Flex.Item $shrink={"shrink"} $style={{ fontVariantNumeric: "tabular-nums" }}>
                            {counter}.
                        </Flex.Item>

                        <Flex.Item $shrink={"auto"}>{legend}</Flex.Item>
                    </Flex.Container>
                </Flex.Item>

                <Flex.Item
                    $shrink={40}
                    $style={{
                        alignItems: "center",
                        alignSelf: "flex-start",
                        backgroundColor: isSuccessful ? theme.color.secondary400 : theme.color.neutral200,
                        borderRadius: 40,
                        boxShadow: `inset 0px 0px 0px 1px ${theme.color.neutral300}`,
                        color: theme.color.neutral0,
                        display: "flex",
                        height: 40,
                        justifyContent: "center",
                    }}
                >
                    <SVGSharedIcon32BasicsOkBold />
                </Flex.Item>
            </Flex.Container>

            <div aria-atomic={"true"} aria-live={"polite"}>
                <Box $style={{ backgroundColor: theme.color.neutral100, ">*+*": { boxShadow: `0px -1px 0px 0px ${theme.color.neutral200}` } }}>
                    {[...alreadyUploadedDocumentItems, ...uploadingDocList].map(renderClaimDocument)}

                    {alreadyAttachedFiles.length < Constants.claimMaxMandatoryAttachedFileCount && claimTaskItemsWithoutAnswer.length > 0 && (
                        <Box $style={{ position: "relative", padding: 16 }}>
                            <input
                                type={"file"}
                                id={`manualFile${counter}`}
                                className={"show-for-sr"}
                                accept={Constants.claimFileInputAccept}
                                multiple
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    e.target.files && onFileDrop(e.target.files);
                                }}
                                disabled={uploadInProgress}
                            />

                            <Box $style={{ textAlign: "center" }}>
                                <StyledUploadButton htmlFor={`manualFile${counter}`}>
                                    <Box as={"span"} $style={{ alignSelf: "center" }}>
                                        <LoctoolMessage id="pages.fileClaim.mandatoryAttachment.upload" />
                                    </Box>
                                    <SVGSharedIcon32BasicsPlus />
                                </StyledUploadButton>
                            </Box>

                            {/* TODO: add tooltip after we get some text to show
                                <Box $style={{ position: "absolute", top: 20, right: 16, zIndex: 1 }}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button.Secondary btnLabel={"info"} icon={<SVGChrIcon32BasicsQuestionMark />} size="xsmall" />
                                        </TooltipTrigger>
                                        <TooltipContent align="end" alignOffset={-16}>
                                            <Box $style={{ width: "calc(100vw - 3.5 * 16px)" }} $styleSmall={{ width: 226 }}>
                                                Please use this formats: .pdf, .jpg, .jpeg, .png, .img, .doc, .docx
                                            </Box>
                                        </TooltipContent>
                                    </Tooltip>
                                </Box> */}
                        </Box>
                    )}
                </Box>
                {showError && (
                    <Box $style={{ boxShadow: `inset 0px -1px 0px 0px ${theme.color.neutral300}`, padding: "16px 16px 16px 24px", color: theme.color.error500 }}>
                        <LoctoolMessage id="pages.fileClaim.mandatoryAttachment.uploadAtLeastOneError" />
                    </Box>
                )}
            </div>
        </Box>
    );
};

const StyledUploadButton = styled.label`
    background-color: ${({ theme }) => theme.color.neutral0};
    border-radius: 20px;
    box-shadow: ${({ theme }) => `inset 0px 0px 0px 1px ${theme.color.neutral300}`};
    column-gap: 4px;
    cursor: pointer;
    display: inline-grid;
    grid-template-columns: 1fr auto;
    padding: 4px 16px 4px 24px;
    text-align: center;

    &:focus-within {
        outline: none;
    }

    @media (hover: hover) {
        &:hover {
            box-shadow: ${({ theme }) => `inset 0px 0px 0px 3px ${theme.color.neutral300}`};
        }
    }
`;
