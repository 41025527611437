import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";

export type ButtonSize = "xsmall" | "small" | "medium" | "large";

export type ButtonVariantProps = {
    isExpanded?: boolean;
    isInverse?: boolean;
    size?: ButtonSize;
};

export type ButtonProps = AnchorButtonProps | ButtonButtonProps;

export type AnchorButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> &
    ButtonVariantProps & {
        btnLabel: React.ReactNode;
        icon?: React.ReactElement;
        iconPosition?: "left" | "right";
    };

export type ButtonButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    ButtonVariantProps & {
        btnLabel: React.ReactNode;
        icon?: React.ReactElement;
        iconPosition?: "left" | "right";
        href?: undefined; // href define anchor or button
    };

export const isAnchorButtonProps = (props: ButtonProps): props is AnchorButtonProps => {
    return !!props.href;
};
