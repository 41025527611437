import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravelTypeAbroad = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5 2C27.5376 2 30 4.45461 30 7.48252C30 8.58715 29.451 9.93751 28.353 11.5336L28.1621 11.8058C28.6489 13.12 28.9375 14.5303 28.9909 16.0005L29 16V17L28.9909 17.0011C28.7335 24.0541 23.0656 29.7262 16.0144 29.9904L15.5909 30H15.5C8.21193 30 2.27272 24.2248 2.00913 17.0011L2 17V16C2.26613 8.9417 7.9417 3.26613 14.9999 3.00909L15 3H16C17.5262 3.06474 18.9872 3.37348 20.3427 3.8946L20.3986 3.82957C21.4058 2.70674 22.8701 2 24.5 2ZM22.5853 17.0009L16 17L16.0008 28.9777C19.4654 28.6006 22.4389 23.4841 22.5853 17.0009ZM15 17L8.59655 17.0009C8.74039 23.3713 11.6138 28.4221 15.0006 28.9537L15 17ZM7.59632 17.0011L3.00984 17.0007C3.22365 22.4283 6.89814 26.9665 11.8879 28.4702C9.48733 26.3514 7.80269 22.2345 7.60948 17.4271L7.59632 17.0011ZM27.9902 17.0007L23.5855 17.0011C23.479 21.9253 21.8097 26.1764 19.3897 28.3839C24.2376 26.7971 27.7803 22.3271 27.9902 17.0007ZM16.0002 4.02222L16 16H22.584V15.944C22.5655 15.2071 22.5105 14.488 22.4224 13.7917L22.4814 13.8588C20.1605 11.2139 19 9.08853 19 7.48252C19 7.02508 19.0562 6.58072 19.1621 6.15594L19.2384 5.88095C18.2742 4.81112 17.165 4.1488 16.0002 4.02222ZM23.5855 16.0007L27.9902 16.0001C27.9461 14.8781 27.754 13.7941 27.4331 12.767L27.4494 12.7481C26.8971 13.4417 26.2552 14.1755 25.5236 14.9493L25.1938 15.2937C24.8266 15.6474 24.259 15.6645 23.8725 15.3522L23.7798 15.2673L23.5433 15.019C23.5643 15.3429 23.5784 15.6703 23.5855 16.0007ZM11.8889 4.52927L11.6605 4.60074C6.78652 6.17223 3.21994 10.6551 3.00981 16.0003L7.5963 15.9999C7.70091 11.1525 9.32001 6.95723 11.6789 4.72143L11.8889 4.52927ZM8.59653 16.0001H15L15.0006 4.04627C11.6136 4.57791 8.74009 9.62925 8.59653 16.0001ZM24.5 3C22.014 3 20 5.00761 20 7.48252C20 8.97309 21.3818 11.278 24.1998 14.2589L24.5 14.5735C27.519 11.4394 29 9.02734 29 7.48252C29 5.00761 26.986 3 24.5 3Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravelTypeAbroad;
