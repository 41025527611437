import styled, { css } from "styled-components";
import { Box } from "@monster/shared";

// Label
export const StyledTextFieldLabel = styled(Box)`
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blueL};
    display: inline-block;
    font-size: ${({ theme }) => theme.text["captionDesktop"].fontSize};
    line-height: ${({ theme }) => theme.text["captionDesktop"].lineHeight};
    padding: 0 4px;
    transform: translate(12px, 8px);
    user-select: none;
    vertical-align: bottom;
`;

// Border
export const StyledTextFieldBorder = styled.div.attrs(() => ({ "aria-hidden": true }))`
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.color.greyD};
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
`;

// HelperText
export const StyledTextFieldHelperText = styled.div`
    color: ${({ theme }) => theme.color.blueN};
    font-size: ${({ theme }) => theme.text["captionDesktop"].fontSize};
    line-height: ${({ theme }) => theme.text["captionDesktop"].lineHeight};
    padding: 8px 12px;
`;

// SuffixItem
export const StyledTextFieldSuffixWrapper = styled.div<{ isCalendarSuffix?: boolean }>`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 4px 10px;
    pointer-events: ${({ isCalendarSuffix }) => (isCalendarSuffix ? "" : "none")};
    position: absolute;
    right: 0;
    top: 0;
`;

// TextField
const StyledTextFieldBase = styled.div`
    appearance: none;
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.color.white};
    border: 0;
    caret-color: currentColor;
    display: block;
    font-family: inherit;
    margin: 0;
    /* NOTE: filter: none; to overdefine :-moz-autofill yellow input backgroundcolor */
    filter: none;
    width: 100%;

    &::placeholder {
        font-size: inherit;
        line-height: inherit;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5500s ease-in-out 0s;
    }

    &::-webkit-calendar-picker-indicator {
        display: none !important;
    }

    &:focus-visible {
        outline: 0;
    }

    textarea& {
        resize: none;
        overflow: hidden;
    }
`;

export const StyledTextField = styled(StyledTextFieldBase)`
    border-radius: 16px;
    color: ${({ theme }) => theme.color.blueN};
    font-size: ${({ theme }) => theme.text.bodyDesktop.fontSize};
    line-height: ${({ theme }) => theme.text.bodyDesktop.lineHeight};
    padding: 12px 16px;

    &::placeholder {
        color: ${({ theme }) => theme.color.blueL};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: ${({ theme }) => theme.color.blueN} !important;
    }

    &:not(:placeholder-shown) {
        font-weight: 700;

        ~ ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.blueN};
        }

        ~ ${StyledTextFieldLabel} {
            color: ${({ theme }) => theme.color.blueN};
        }
    }

    @media (hover: hover) {
        &:hover {
            ~ ${StyledTextFieldBorder} {
                border-color: ${({ theme }) => theme.color.blueN};
            }
        }
    }

    &:focus {
        ~ ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.blueN};
            border-width: 2px;
        }

        ~ ${StyledTextFieldLabel} {
            color: ${({ theme }) => theme.color.blueN};
        }
    }

    &:disabled {
        color: ${({ theme }) => theme.color.blueD};
        cursor: not-allowed;

        &::placeholder {
            color: ${({ theme }) => theme.color.blueD};
        }

        &:not(:placeholder-shown) {
            ~ ${StyledTextFieldBorder} {
                border-color: ${({ theme }) => theme.color.blueN};
            }

            ~ ${StyledTextFieldLabel} {
                color: ${({ theme }) => theme.color.blueN};
            }
        }

        ~ ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.greyD};
        }

        ~ ${StyledTextFieldLabel} {
            color: ${({ theme }) => theme.color.blueD};
        }
    }

    /* NOTE: there is no UI for readOnly at the moment. We assume it looks the similar as disabled. But it might change. */
    input&:read-only {
        color: ${({ theme }) => theme.color.blueD};

        &::placeholder {
            color: ${({ theme }) => theme.color.blueD};
        }

        &:not(:placeholder-shown) {
            background-color: ${({ theme }) => theme.color.greyL};
            font-weight: 400;

            ~ ${StyledTextFieldBorder} {
                border-color: ${({ theme }) => theme.color.greyD};
            }

            ~ ${StyledTextFieldLabel} {
                color: ${({ theme }) => theme.color.blueN};
            }
        }

        ~ ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.greyD};
        }

        ~ ${StyledTextFieldLabel} {
            color: ${({ theme }) => theme.color.blueD};
        }
    }

    textarea& {
        min-height: 72px;

        ~ ${StyledTextFieldSuffixWrapper} {
            height: 52px;
        }
    }
`;

// Has error
const cherriskTextFieldHasErrorCSS = css`
    ${StyledTextFieldLabel} {
        color: ${({ theme }) => theme.color.redN};
    }

    ${StyledTextField} {
        color: ${({ theme }) => theme.color.redN};

        &::placeholder {
            color: inherit;
        }

        &:not(:placeholder-shown) {
            ~ ${StyledTextFieldBorder} {
                border-color: ${({ theme }) => theme.color.redN};
            }

            ~ ${StyledTextFieldLabel} {
                color: ${({ theme }) => theme.color.redN};
            }
        }

        ~ ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.redN};
        }
    }

    ${StyledTextFieldHelperText} {
        color: ${({ theme }) => theme.color.redN};
    }

    ${StyledTextFieldSuffixWrapper} {
        > svg {
            color: ${({ theme }) => theme.color.redN};
        }
    }
`;

// Has success
const cherriskTextFieldHasSuccessCSS = css`
    ${StyledTextFieldLabel} {
        color: ${({ theme }) => theme.color.greenN};
    }

    ${StyledTextField} {
        /* color: ${({ theme }) => theme.color.greenN}; */

        /* &::placeholder {
            color: inherit;
        } */

        &:not(:placeholder-shown) {
            ~ ${StyledTextFieldBorder} {
                border-color: ${({ theme }) => theme.color.greenN};
            }

            ~ ${StyledTextFieldLabel} {
                color: ${({ theme }) => theme.color.greenN};
            }
        }

        ~ ${StyledTextFieldBorder} {
            border-color: ${({ theme }) => theme.color.greenN};
        }
    }

    ${StyledTextFieldHelperText} {
        color: ${({ theme }) => theme.color.greenN};
    }

    ${StyledTextFieldSuffixWrapper} {
        > svg {
            color: ${({ theme }) => theme.color.blueD};
        }
    }
`;

export const StyledTextFieldStateStyleWrapper = styled.div<{ $hasError?: boolean; $hasSuccess?: boolean; $hasSuffixItem?: boolean }>`
    ${({ $hasError }) => ($hasError ? cherriskTextFieldHasErrorCSS : "")}

    ${({ $hasSuccess }) => ($hasSuccess ? cherriskTextFieldHasSuccessCSS : "")}

    ${({ $hasSuffixItem }) =>
        $hasSuffixItem
            ? `
        ${StyledTextField} {
            padding-right: 52px;
        }
    `
            : ""}
`;
