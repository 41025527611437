import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverSki = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M26 9C27.3807 9 28.5 7.88071 28.5 6.5C28.5 5.11929 27.3807 4 26 4C24.6193 4 23.5 5.11929 23.5 6.5C23.5 7.88071 24.6193 9 26 9Z" stroke="currentColor" />
            <path
                d="M29 25.5V25.7324C29 25.8996 28.9164 26.0557 28.7773 26.1484L26.4193 27.7204C26.1556 27.8963 25.8241 27.9366 25.5259 27.8293L1.33064 19.119C1.13226 19.0476 1 18.8594 1 18.6486V18.5C1 18.3059 1.15733 18.1486 1.35141 18.1486C1.39199 18.1486 1.43226 18.1556 1.47044 18.1693L26 27L28.584 25.2773C28.7069 25.1953 28.8731 25.2286 28.9551 25.3515C28.9844 25.3955 29 25.4471 29 25.5Z"
                fill="currentColor"
            />
            <path
                d="M3.47225 6.16426L26 14V15L3.33574 7.11678C3.13472 7.04686 3 6.85736 3 6.64453V6.5C3 6.30368 3.15915 6.14453 3.35547 6.14453C3.39523 6.14453 3.4347 6.1512 3.47225 6.16426Z"
                fill="currentColor"
            />
            <path
                d="M19.0775 7.6745C19.588 7.38275 20.1672 7.30393 20.707 7.41564C21.2468 7.52735 21.7472 7.82961 22.1 8.30003L22.9713 9.46171C23.2666 9.85548 23.3499 10.3691 23.1943 10.836L22.3675 13.3163L27.4743 15.0185C27.7806 15.1206 28.037 15.315 28.2169 15.5645C28.3967 15.814 28.5 16.1187 28.5 16.4415V17C28.5 17.26 28.3946 17.4954 28.2242 17.6658C28.0693 17.8207 27.8607 17.9219 27.6287 17.939L21.5257 15.9815C21.2194 15.8795 20.963 15.6851 20.7831 15.4356C20.6033 15.186 20.5 14.8813 20.5 14.5585V11.7929L17.2388 15.0541L20.7776 17.7082L15.8664 23.6653L11.9585 22.1921L16.7704 17.9912L13.2344 15.0445C12.704 14.6025 12.403 13.9874 12.3451 13.3503C12.2872 12.7131 12.4723 12.0538 12.9143 11.5235C13.0385 11.3744 13.1796 11.2403 13.3348 11.1239L14.2751 10.4187L19.0775 7.6745Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverSki;
