import React, { useCallback, useMemo, useRef } from "react";
import { NotificationBar } from "./NotificationBar";

export type NotificationProp = {
    showNotification: (message: string, type?: "error" | "success", timeout?: number) => void;
};

export const NotificationContext = React.createContext<NotificationProp>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showNotification: () => {},
});

export const NotificationProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const notificationBarRef = useRef<React.ElementRef<typeof NotificationBar>>(null);

    const showNotification = useCallback((message: string, type: "error" | "success" = "error", timeout?: number) => {
        notificationBarRef.current?.showNotification(message, type, timeout);
    }, []);

    const contextValue = useMemo(() => ({ showNotification }), [showNotification]);

    return (
        <NotificationContext.Provider value={contextValue}>
            <NotificationBar ref={notificationBarRef} />
            {children}
        </NotificationContext.Provider>
    );
};
