import React from "react";
import { Box } from "../style";

export const SquareMeterSuffix = () => (
    <Box as="span" $style={{ display: "inline-block", whiteSpace: "nowrap" }}>
        m
        <Box
            as="sup"
            $style={{
                display: "inline-block",
                fontSize: "0.625em",
                verticalAlign: "bottom",
                transform: "translate3d(0, -0.375em, 0)",
            }}
        >
            2
        </Box>
    </Box>
);
