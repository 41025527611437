import { ReduxAction } from "@redux/actions/ReduxAction";
import { AppStateActionTypes, PageSteps } from "@redux/actions/appStateActions";

export type AppStateReducerType = {
    currentStep: PageSteps;
};

const initialState: AppStateReducerType = {
    currentStep: PageSteps.ClauseSelection,
};

export default function appStateReducer(state: AppStateReducerType = initialState, action: ReduxAction): AppStateReducerType {
    switch (action.type) {
        case AppStateActionTypes.SET_CURRENT_STEP:
            return { ...state, currentStep: action.payload.step };
        case AppStateActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
}
