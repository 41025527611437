import { css } from "styled-components";
import { bp, breakpoints } from "./Breakpoints";

export const showForSr = css`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
`;

export const commonUtilityClassNames = css`
    .show-for-sr {
        ${showForSr}
    }

    .hide-for-medium {
        ${bp.custom(`(min-width: ${breakpoints.medium}px)`)} {
            display: none !important;
        }
    }

    .show-for-medium {
        ${bp.custom(`(max-width:${breakpoints.medium - 0.02}px)`)} {
            display: none !important;
        }
    }

    .hide-for-large {
        ${bp.custom(`(min-width: ${breakpoints.large}px)`)} {
            display: none !important;
        }
    }

    .show-for-large {
        ${bp.custom(`(max-width:${breakpoints.large - 0.02}px)`)} {
            display: none !important;
        }
    }

    .show-for-sr-large-down {
        ${bp.custom(`(max-width:${breakpoints.large - 0.02}px)`)} {
            ${showForSr}
        }
    }

    .show-for-sr-large-up {
        ${bp.large} {
            ${showForSr}
        }
    }
`;
