import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon72X71ProductsAccidentShadow = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={72} height={71} viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_298_671)">
                <path
                    d="M47 6C56.3888 6 64 13.6112 64 23C64 23.4317 63.9839 23.8596 63.9523 24.2832L63.9515 24.2965C63.2234 34.086 54.3048 45.3591 37.1955 58.116C36.4862 58.6448 35.5138 58.6448 34.8045 58.116L34.2372 57.6913C17.4981 45.1088 8.7685 33.9772 8.04854 24.2965L8.0477 24.2832C8.01609 23.8596 8 23.4317 8 23C8 13.6112 15.6112 6 25 6C29.1952 6 33.0354 7.5196 36.0004 10.0384C38.9654 7.51926 42.8053 6 47 6Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter id="filter0_d_298_671" x={0} y={2} width={72} height={68.5126} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={4} />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.92549 0 0 0 0 0.941176 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_298_671" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_298_671" result="shape" />
                </filter>
            </defs>
        </SVGBase>
    );
};

export default SvgIcon72X71ProductsAccidentShadow;
