/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: openingHours
// ====================================================

export interface openingHours_chat_data_monday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_chat_data_tuesday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_chat_data_wednesday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_chat_data_thursday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_chat_data_friday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_chat_data_saturday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_chat_data_sunday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_chat_data {
    __typename: "OpeningHoursData";
    monday: openingHours_chat_data_monday;
    tuesday: openingHours_chat_data_tuesday;
    wednesday: openingHours_chat_data_wednesday;
    thursday: openingHours_chat_data_thursday;
    friday: openingHours_chat_data_friday;
    saturday: openingHours_chat_data_saturday;
    sunday: openingHours_chat_data_sunday;
}

export interface openingHours_chat {
    __typename: "OpeningHours";
    isActive: boolean;
    data: openingHours_chat_data;
}

export interface openingHours_tel_data_monday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_tel_data_tuesday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_tel_data_wednesday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_tel_data_thursday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_tel_data_friday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_tel_data_saturday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_tel_data_sunday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface openingHours_tel_data {
    __typename: "OpeningHoursData";
    monday: openingHours_tel_data_monday;
    tuesday: openingHours_tel_data_tuesday;
    wednesday: openingHours_tel_data_wednesday;
    thursday: openingHours_tel_data_thursday;
    friday: openingHours_tel_data_friday;
    saturday: openingHours_tel_data_saturday;
    sunday: openingHours_tel_data_sunday;
}

export interface openingHours_tel {
    __typename: "OpeningHours";
    isActive: boolean;
    data: openingHours_tel_data;
}

export interface openingHours {
    chat: openingHours_chat;
    isChatAvailable: boolean;
    tel: openingHours_tel;
    isTelAvailable: boolean;
}

export interface openingHoursVariables {
    countryCode: CountryCode;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OpeningHourInterval
// ====================================================

export interface OpeningHourInterval {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OpeningHours
// ====================================================

export interface OpeningHours_data_monday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface OpeningHours_data_tuesday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface OpeningHours_data_wednesday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface OpeningHours_data_thursday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface OpeningHours_data_friday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface OpeningHours_data_saturday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface OpeningHours_data_sunday {
    __typename: "OpeningHourInterval";
    from: string | null;
    to: string | null;
}

export interface OpeningHours_data {
    __typename: "OpeningHoursData";
    monday: OpeningHours_data_monday;
    tuesday: OpeningHours_data_tuesday;
    wednesday: OpeningHours_data_wednesday;
    thursday: OpeningHours_data_thursday;
    friday: OpeningHours_data_friday;
    saturday: OpeningHours_data_saturday;
    sunday: OpeningHours_data_sunday;
}

export interface OpeningHours {
    __typename: "OpeningHours";
    isActive: boolean;
    data: OpeningHours_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CountryCode {
    DE = "DE",
    HU = "HU",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
