import React from "react";
import { SVGSharedLogoMaestro } from "../svg_assets";
import { SVGSharedLogoMastercard } from "../svg_assets";
import { SVGSharedLogoPayPal } from "../svg_assets";
import { SVGSharedLogoPayPalFull } from "../svg_assets";
import { SVGSharedLogoSepa } from "../svg_assets";
import { SVGSharedLogoSepaDebit } from "../svg_assets";
import { SVGSharedLogoVisa } from "../svg_assets";

export type PaymentOptionName = "maestro" | "mastercard" | "paypal" | "paypalfull" | "sepa" | "sepadebit" | "visa";

export type Props = {
    name: PaymentOptionName;
    width?: number;
    height?: number;
};

export const PaymentOption = ({ name, width, height }: Props) => {
    if (name === "maestro") {
        const title = "Maestro";

        return width || height ? <SVGSharedLogoMaestro width={width} height={height} title={title} /> : <SVGSharedLogoMaestro title={title} />;
    }

    if (name === "mastercard") {
        const title = "MasterCard";

        return width || height ? <SVGSharedLogoMastercard width={width} height={height} title={title} /> : <SVGSharedLogoMastercard title={title} />;
    }

    if (name === "paypal") {
        const title = "PayPal";

        return width || height ? <SVGSharedLogoPayPal width={width} height={height} title={title} /> : <SVGSharedLogoPayPal title={title} />;
    }

    if (name === "paypalfull") {
        const title = "PayPal";

        return width || height ? <SVGSharedLogoPayPalFull width={width} height={height} title={title} /> : <SVGSharedLogoPayPalFull title={title} />;
    }

    if (name === "sepa") {
        const title = "Single Euro Payments Area (SEPA)";

        return width || height ? <SVGSharedLogoSepa width={width} height={height} title={title} /> : <SVGSharedLogoSepa title={title} />;
    }

    if (name === "sepadebit") {
        const title = "Single Euro Payments Area (SEPA) Direct Debits";

        return width || height ? <SVGSharedLogoSepaDebit width={width} height={height} title={title} /> : <SVGSharedLogoSepaDebit title={title} />;
    }

    if (name === "visa") {
        const title = "Visa";

        return width || height ? <SVGSharedLogoVisa width={width} height={height} title={title} /> : <SVGSharedLogoVisa title={title} />;
    }

    return null;
};
