import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverDeath = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M26.5 24.5H5.5C4.94772 24.5 4.5 24.9477 4.5 25.5V26.5C4.5 27.0523 4.94772 27.5 5.5 27.5H26.5C27.0523 27.5 27.5 27.0523 27.5 26.5V25.5C27.5 24.9477 27.0523 24.5 26.5 24.5Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 4.5C18.6234 4.5 20.9984 5.56332 22.7175 7.28249C24.4367 9.00165 25.5 11.3766 25.5 14V24.5H6.5V14C6.5 11.3766 7.56332 9.00165 9.28249 7.28249C11.0016 5.56332 13.3766 4.5 16 4.5Z"
                stroke="currentColor"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M13 11H19V12H13V11Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 16H22V17H10V16Z" fill="currentColor" />
        </SVGBase>
    );
};

export default SvgIcon32CoverDeath;
