import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverLimitedtime = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="mask0_597_2495"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={-1}
                y={18}
                width={31}
                height={14}
            >
                <path d="M30 32V20H21V18H-1V32H30Z" fill="white" />
            </mask>
            <g mask="url(#mask0_597_2495)">
                <path
                    d="M16 2.5C12.2721 2.5 8.89708 4.01104 6.45406 6.45406C4.01104 8.89708 2.5 12.2721 2.5 16C2.5 19.7279 4.01104 23.1029 6.45406 25.5459C8.89708 27.989 12.2721 29.5 16 29.5C19.7279 29.5 23.1029 27.989 25.5459 25.5459C27.989 23.1029 29.5 19.7279 29.5 16C29.5 12.2721 27.989 8.89708 25.5459 6.45406C23.1029 4.01104 19.7279 2.5 16 2.5ZM16 6.5C18.6234 6.5 20.9984 7.56332 22.7175 9.28249C24.4367 11.0016 25.5 13.3766 25.5 16C25.5 18.6234 24.4367 20.9984 22.7175 22.7175C20.9984 24.4367 18.6234 25.5 16 25.5C13.3766 25.5 11.0016 24.4367 9.28249 22.7175C7.56332 20.9984 6.5 18.6234 6.5 16C6.5 13.3766 7.56332 11.0016 9.28249 9.28249C11.0016 7.56332 13.3766 6.5 16 6.5Z"
                    stroke="currentColor"
                />
            </g>
            <mask
                id="mask1_597_2495"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={32}
                height={18}
            >
                <path d="M0 0H32V18H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask1_597_2495)">
                <path
                    d="M16 2.5C12.2721 2.5 8.89708 4.01104 6.45406 6.45406C4.01104 8.89708 2.5 12.2721 2.5 16C2.5 19.5531 3.87264 22.7857 6.11662 25.1962C8.26113 27.4999 11.2013 29.0531 14.4998 29.4176L14.4994 25.3821C12.2936 25.0319 10.3405 23.9229 8.91647 22.3305C7.41372 20.6501 6.5 18.4318 6.5 16C6.5 13.3766 7.56332 11.0016 9.28249 9.28249C11.0016 7.56332 13.3766 6.5 16 6.5C17.9211 6.5 19.709 7.07039 21.2038 8.05078C22.835 9.12069 24.1169 10.6793 24.8416 12.5184L22.8906 14.6637L30 15.5L30.5 7.58661L27.7399 9.32983C25.4171 5.25081 21.0298 2.5 16 2.5Z"
                    stroke="currentColor"
                />
            </g>
            <path d="M14.5 11.5V17.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.5 17.5H14.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </SVGBase>
    );
};

export default SvgIcon32CoverLimitedtime;
