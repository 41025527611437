import React from "react";
import styled from "styled-components";
import { bp } from "@monster/shared";
import { Footer } from "./Footer";

type Props = {
    appBar: React.ReactNode;
};

export const Page = ({ appBar, children }: React.PropsWithChildren<Props>) => {
    return (
        <PageWrapper aria-atomic="true" aria-live="polite">
            {appBar}
            <Main>{children}</Main>
            <Footer />
        </PageWrapper>
    );
};

const Main = styled.main`
    padding-bottom: 80px;
    width: 100%;

    ${bp.large} {
        flex: 1 1 0px;
        position: relative;
    }
`;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
`;
