import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgLogoMastercard = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={66} height={46} viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M38.1606 14.5246H27.6576V31.4425H38.1606V14.5246Z" fill="#FF5F00" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.7394 22.9851C28.7368 19.6843 30.2732 16.5656 32.9061 14.5276C28.4333 11.061 22.0102 11.5659 18.1507 15.6876C14.2913 19.8093 14.2913 26.1639 18.1507 30.2856C22.0102 34.4072 28.4333 34.9122 32.9061 31.4455C30.2724 29.4069 28.7358 26.2869 28.7394 22.9851Z"
                fill="#EB001B"
            />
            <path
                d="M49.5361 30.0825V29.3795H49.7027V29.234H49.3061V29.3795H49.4627V30.0825H49.5361ZM50.3061 30.0825V29.234H50.1861L50.0461 29.8401L49.9061 29.234H49.7861V30.0825H49.8727V29.4401L50.0027 29.9916H50.0927L50.2227 29.4401V30.0825H50.3061Z"
                fill="#F79E1B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.5576 22.9851C50.5576 27.1043 48.1726 30.8618 44.4157 32.6618C40.6588 34.4619 36.1896 33.9884 32.9061 31.4426C35.5378 29.4028 37.0743 26.2846 37.0743 22.9836C37.0743 19.6826 35.5378 16.5643 32.9061 14.5246C36.1896 11.9787 40.6588 11.5053 44.4157 13.3053C48.1726 15.1054 50.5576 18.8629 50.5576 22.9821V22.9851Z"
                fill="#F79E1B"
            />
        </SVGBase>
    );
};

export default SvgLogoMastercard;
