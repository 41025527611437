import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsAccident = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18 13H20C20.5523 13 21 13.4477 21 14V16C21 16.5523 20.5523 17 20 17H18V19C18 19.5523 17.5523 20 17 20H15C14.4477 20 14 19.5523 14 19V17H12C11.4477 17 11 16.5523 11 16V14C11 13.4477 11.4477 13 12 13H14V11C14 10.4477 14.4477 10 15 10H17C17.5523 10 18 10.4477 18 11V13ZM17.5 14C17.2239 14 17 13.7761 17 13.5V11H15V13.5C15 13.7761 14.7761 14 14.5 14H12V16H14.5C14.7761 16 15 16.2239 15 16.5V19H17V16.5C17 16.2239 17.2239 16 17.5 16H20V14H17.5Z"
                fill="currentColor"
            />
            <mask id="path-2-inside-1_291_0" fill="white">
                <path d="M16.0426 27.9786C16.0145 27.9928 15.9855 27.9928 15.9574 27.9786C11.9295 25.9435 2 17.977 2 11.5C2 6.80558 5.80558 3 10.5 3C12.5975 3 14.5176 3.75974 16 5.01903C17.4824 3.75974 19.4025 3 21.5 3C26.1944 3 30 6.80558 30 11.5C30 17.977 20.0705 25.9435 16.0426 27.9786Z" />
            </mask>
            <path
                d="M16 5.01903L15.3526 5.78117C15.7259 6.09832 16.2741 6.09832 16.6474 5.78117L16 5.01903ZM30 11.5H29H30ZM15.9574 27.9786L15.5064 28.8711L15.9574 27.9786ZM16.0426 27.9786L16.4936 28.8711L16.0426 27.9786ZM3 11.5C3 7.35786 6.35786 4 10.5 4V2C5.25329 2 1 6.25329 1 11.5H3ZM10.5 4C12.3515 4 14.0441 4.66965 15.3526 5.78117L16.6474 4.2569C14.991 2.84982 12.8435 2 10.5 2V4ZM16.6474 5.78117C17.9559 4.66965 19.6485 4 21.5 4V2C19.1565 2 17.009 2.84982 15.3526 4.2569L16.6474 5.78117ZM21.5 4C25.6421 4 29 7.35786 29 11.5H31C31 6.25329 26.7467 2 21.5 2V4ZM16.4083 27.086C14.5108 26.1273 11.1082 23.6992 8.18346 20.7061C6.72655 19.2152 5.41663 17.6125 4.47635 16.0141C3.53084 14.4067 3 12.8731 3 11.5H1C1 13.3654 1.71035 15.2565 2.75249 17.0281C3.79985 18.8086 5.22406 20.5392 6.75301 22.1039C9.79997 25.2221 13.3761 27.7948 15.5064 28.8711L16.4083 27.086ZM29 11.5C29 12.8731 28.4692 14.4067 27.5236 16.0141C26.5834 17.6125 25.2735 19.2152 23.8165 20.7061C20.8918 23.6992 17.4892 26.1273 15.5917 27.086L16.4936 28.8711C18.6239 27.7948 22.2 25.2221 25.247 22.1039C26.7759 20.5392 28.2001 18.8086 29.2475 17.0281C30.2897 15.2565 31 13.3654 31 11.5H29ZM15.5064 28.8711C15.8181 29.0286 16.1819 29.0286 16.4936 28.8711L15.5917 27.086C15.7042 27.0292 15.8434 26.9892 16 26.9892C16.1566 26.9892 16.2958 27.0292 16.4083 27.086L15.5064 28.8711Z"
                fill="currentColor"
                mask="url(#path-2-inside-1_291_0)"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsAccident;
