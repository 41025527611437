import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32MobilePhone = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_12403_41907)">
                <path
                    d="M20.3553 3.43359C20.989 3.43359 21.5618 3.67731 21.9767 4.06831C22.4008 4.46795 22.6608 5.02072 22.6608 5.62988V25.2373C22.6608 25.8465 22.4008 26.3992 21.9767 26.7989C21.5618 27.1899 20.989 27.4336 20.3553 27.4336H10.8972C10.2635 27.4336 9.6908 27.1899 9.27585 26.7989C8.85175 26.3992 8.5918 25.8465 8.5918 25.2373V5.62988C8.5918 5.02072 8.85175 4.46795 9.27585 4.06831C9.6908 3.67731 10.2635 3.43359 10.8972 3.43359H20.3553Z"
                    stroke="currentColor"
                />
                <path d="M14.3848 6.74414H16.8675" stroke="currentColor" strokeLinecap="round" />
                <path
                    d="M15.6261 24.9515C16.3117 24.9515 16.8675 24.3957 16.8675 23.7101C16.8675 23.0245 16.3117 22.4688 15.6261 22.4688C14.9406 22.4688 14.3848 23.0245 14.3848 23.7101C14.3848 24.3957 14.9406 24.9515 15.6261 24.9515Z"
                    stroke="currentColor"
                    strokeLinejoin="round"
                />
                <path d="M4.86719 26.1914L26.3844 4.67416" stroke="currentColor" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_12403_41907">
                    <rect width={24.2667} height={26} fill="white" transform="translate(4 3)" />
                </clipPath>
            </defs>
        </SVGBase>
    );
};

export default SvgIcon32MobilePhone;
