import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32ProductsTravelInsurance = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 5C19.1046 5 20 5.89543 20 7V9.999L26 10C27.1046 10 28 10.8954 28 12V18L27 17.5V12C27 11.4477 26.5523 11 26 11L10 10.999V25.999L19.1026 26.001L18.9861 25.7896C18.3287 24.5515 18 23.1216 18 21.5V19.618C18 19.2393 18.214 18.893 18.5528 18.7236L22 16.999V11H23V16.499L23.5528 16.2236C23.8343 16.0828 24.1657 16.0828 24.4472 16.2236L29.4472 18.7236C29.786 18.893 30 19.2393 30 19.618V21.5C30 25.5 28 28.3333 24 30C22.0613 29.1922 20.5924 28.1103 19.5933 26.7544C19.655 26.8381 19.718 26.9201 19.7827 27.0011L6 27C4.89543 27 4 26.1046 4 25V12C4 10.8954 4.89543 10 6 10L12 9.999V7C12 5.89543 12.8954 5 14 5H18ZM23.7764 17.2298L19.2764 19.4798C19.107 19.5645 19 19.7377 19 19.9271V21.5C19 24.9566 20.6246 27.3823 24 28.9099C27.3754 27.3823 29 24.9566 29 21.5V19.9271C29 19.7377 28.893 19.5645 28.7236 19.4798L24.2236 17.2298C24.0828 17.1595 23.9172 17.1595 23.7764 17.2298ZM9 10.999L6 11C5.44772 11 5 11.4477 5 12V25C5 25.5523 5.44772 26 6 26L9 25.999V10.999ZM18 6H14C13.4477 6 13 6.44772 13 7V9.999H19V7C19 6.44772 18.5523 6 18 6Z"
                fill="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32ProductsTravelInsurance;
