import styled, { css } from "styled-components";
import { StyledButton } from "./Button.styled";

/*
    NOTE: missing from styleguides.
    Similar to uniqa skin IconOnly button but blueAzure inset shadow.
    It is for cz-travel-app's TooltipTriggers.
*/
const cherriskCommonButtonCSS = css`
    border-radius: 36px;
`;

const cherriskIconOnlyBlueAzureButtonCSS = css`
    ${cherriskCommonButtonCSS}/* @media (hover: hover) {&:hover {
    }} */

    /* &:disabled {
    } */
`;

/* NOTE: missing from Figma */
const cherriskIconOnlyBlueAzureButtonIsInverseCSS = css`
    ${cherriskCommonButtonCSS}/* &:disabled {
    } */
`;

export const StyledIconOnlyBlueAzureButton = styled(StyledButton)`
    ${({ isInverse }) => (isInverse ? cherriskIconOnlyBlueAzureButtonIsInverseCSS : cherriskIconOnlyBlueAzureButtonCSS)}
`;
