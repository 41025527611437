/* eslint-disable @typescript-eslint/no-explicit-any */
export type LanguageAndCountry = {
    siteLanguage: "hu" | "en" | "de" | "cz" | "ro";
    userCountry: "HU" | "DE" | "CZ";
};

type GTMParamsVirtualPageView = LanguageAndCountry & {
    product: "lakas" | "utas" | "baleset";
    virtualPageURL: string;
    userId?: string;
    flow?: string;
} & any;

type GTMParamsSiteVirtualPageView = LanguageAndCountry & {
    virtualPageURL: string;
} & any;

type GTMParamsPaymentSuccess = LanguageAndCountry & {
    orderId: string;
    paymentMode: string;
    paymentRecurringEnabled: boolean;
    currency: string;
    userId: string;
    product: {
        name: string;
        coupon?: string;
        variant: "lakas" | "haz" | "europa" | "nem_europa" | "amerikai";
        price: number;
    };
} & any;

type GTMParamsPaymentFailure = LanguageAndCountry & {
    orderId: string;
    paymentError: string;
    userId: string;
} & any;

export type GTMParamsInteractionEvent = {
    siteLanguage: string;
    userCountry: string;
    screen: string;
    page: string;
    interaction: string;
    value?: string;
    product: "lakas" | "utas" | "baleset";
    userId?: string;
    flow?: string;
} & any;

export class GTM {
    public static pushVirtualPageView(params: GTMParamsVirtualPageView) {
        const mergedParams = Object.assign({ userId: "unsigned", flow: "sales" }, params);
        GTM.pushEvent({
            event: "virtualPageView",
            value: undefined,
            ...mergedParams,
        });
    }

    public static pushSiteVirtualPageView(params: GTMParamsSiteVirtualPageView) {
        GTM.pushEvent({
            event: "virtualPageView",
            value: undefined,
            ...params,
        });
    }

    public static pushCustomEvent(params: any) {
        GTM.pushEvent({
            ...params,
        });
    }

    public static pushSalesInteractionEvent(params: GTMParamsInteractionEvent) {
        const mergedParams = Object.assign({ userId: "unsigned", flow: "sales", event: "flowEvent" }, params);
        GTM.pushEvent(mergedParams);
    }

    public static pushClientId(clientId: string) {
        GTM.pushEvent({ event: "emailActivation", clientId });
    }

    public static pushFormError(formErrorData: { form: string; field: string; error: string }): void {
        GTM.pushEvent({ event: "formError", product: "travel", formErrorData });
    }

    public static pushPaymentSuccess(params: GTMParamsPaymentSuccess) {
        GTM.pushEvent({
            flow: "sales",
            screen: "fizetes",
            page: "payment_result",
            value: {
                event: "paymentFinished",
                paymentResult: "success",
                transactionId: params.orderId, // GTM expects orderId as transactionId ¯\_(ツ)_/¯
                paymentMode: params.paymentMode,
                paymentRecurringEnabled: params.paymentRecurringEnabled,
                currency: params.currency,
                products: [
                    {
                        type: "new",
                        name: params.product.name,
                        price: params.product.price,
                        variant: params.product.variant,
                        coupon: params.product.coupon,
                    },
                ],
            },
            event: "flowEvent",
            siteLanguage: params.siteLanguage,
            userCountry: params.userCountry,
            userId: params.userId,
        });
    }

    public static pushPaymentFailure(params: GTMParamsPaymentFailure) {
        GTM.pushEvent({
            flow: "sales",
            screen: "fizetes",
            page: "payment_result",
            value: {
                event: "paymentFinished",
                paymentResult: "error",
                transactionId: params.orderId, // GTM expects orderId as transactionId ¯\_(ツ)_/¯
                paymentError: params.paymentError,
            },
            event: "flowEvent",
            siteLanguage: params.siteLanguage,
            userCountry: params.userCountry,
            userId: params.userId,
        });
    }

    private static pushEvent(event: any): void {
        if (process.env.NODE_ENV !== "production") {
            console.log("dataLayer.push", event);
            return;
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push(event);
        } else {
            console.warn("window.dataLayer not defined", event);
        }
    }

    public static getClientId(): string | undefined {
        if (typeof window !== "undefined" && typeof window.ga === "function" && typeof window.ga.getAll === "function") {
            const tracker = window.ga.getAll()[0];
            if (tracker && typeof tracker.get === "function") {
                return tracker.get("clientId");
            }
        }

        return undefined;
    }
}
