import React from "react";
import { ButtonProps, isAnchorButtonProps } from "./ButtonTypes";
import { ButtonContent } from "./ButtonContent";
import { StyledIconOnlyGreyDButton } from "./ButtonIconOnlyGreyD.styled";

export const ButtonIconOnlyGreyD = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isAnchorButtonProps(props)) {
        return (
            <StyledIconOnlyGreyDButton forwardedAs={"a"} {...props} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
                <ButtonContent {...props} />
            </StyledIconOnlyGreyDButton>
        );
    }
    return (
        <StyledIconOnlyGreyDButton type={props.type ?? "button"} {...props} ref={ref}>
            <ButtonContent {...props} />
        </StyledIconOnlyGreyDButton>
    );
});
