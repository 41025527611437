import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./CherriskGlobalStyle";
import { CherriskTheme } from "./CherriskTheme";

type Props = React.PropsWithChildren<{}>;

export const CherriskThemeProvider = (props: Props) => {
    return (
        <ThemeProvider theme={CherriskTheme}>
            <>
                <GlobalStyle />
                {props.children}
            </>
        </ThemeProvider>
    );
};
