import { ButtonCTA } from "./ButtonCTA";
import { ButtonIconOnly } from "./ButtonIconOnly";
import { ButtonIconOnlyBlueAzure } from "./ButtonIconOnlyBlueAzure";
import { ButtonIconOnlyGreyD } from "./ButtonIconOnlyGreyD";
import { ButtonPrimary } from "./ButtonPrimary";
import { ButtonPrimaryAlt } from "./ButtonPrimaryAlt";
import { ButtonSecondary } from "./ButtonSecondary";
import { ButtonText } from "./ButtonText";

// NOTE: sorted by Figma plans
export const Button = {
    Primary: ButtonPrimary,
    Secondary: ButtonSecondary,
    Text: ButtonText,
    CTA: ButtonCTA,
    IconOnly: ButtonIconOnly,
    // NOTE: not in stylequide
    IconOnlyBlueAzure: ButtonIconOnlyBlueAzure,
    IconOnlyGreyD: ButtonIconOnlyGreyD,
    PrimaryAlt: ButtonPrimaryAlt,
};

export * from "./Selector";
