import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon68X72ProductsHomeShadow = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={68} height={72} viewBox="0 0 68 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_241_642)">
                <path
                    d="M33.1619 4.38684L9.16188 15.4638C8.45358 15.7907 8 16.4996 8 17.2797V26C8 41.3241 16.5724 52.5506 33.7171 59.6797V59.6797C34.2177 59.8879 34.7814 59.8839 35.2791 59.6686C51.7597 52.539 60 41.3161 60 26C60 21.9613 60 19.0545 60 17.2797C60 16.4996 59.5464 15.7907 58.8381 15.4638L34.8381 4.38684C34.3063 4.1414 33.6937 4.1414 33.1619 4.38684Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter id="filter0_d_241_642" x={0} y={0.202759} width={68} height={71.6302} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={4} />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.92549 0 0 0 0 0.941176 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_241_642" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_241_642" result="shape" />
                </filter>
            </defs>
        </SVGBase>
    );
};

export default SvgIcon68X72ProductsHomeShadow;
