import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { useDatePickerState } from "@react-stately/datepicker";
import { useDatePicker } from "@react-aria/datepicker";
import { AriaDatePickerProps, DateValue } from "@react-types/datepicker";
import { SVGSharedIcon32BasicsCalendar } from "@monster/shared/dist/svg_assets";
import { Box } from "@monster/shared";
import { DateField } from "./DateField";
import { Calendar } from "./Calendar";
import styled from "styled-components";
import { Button } from "../../Button";

export const DatePicker = (props: AriaDatePickerProps<DateValue>) => {
    const state = useDatePickerState(props);
    const ref = React.createRef<HTMLDivElement>();
    const { groupProps, labelProps, fieldProps, buttonProps, dialogProps, calendarProps } = useDatePicker(props, state, ref);

    return (
        <Box $style={{ position: "relative" }}>
            <span {...labelProps}>{props.label}</span>
            <Box {...groupProps} ref={ref} $style={{ display: "flex" }}>
                <DateField {...fieldProps} />

                <PopoverPrimitive.Root {...dialogProps} open={state.isOpen} onOpenChange={(open: boolean) => state.setOpen(open)}>
                    <PopoverPrimitive.Trigger asChild>
                        <DatePickerButton btnLabel="Calendar" icon={<SVGSharedIcon32BasicsCalendar />} {...buttonProps} />
                    </PopoverPrimitive.Trigger>
                    <PopoverPrimitive.Content align="end" alignOffset={-16} portalled={false}>
                        <Calendar {...calendarProps} />
                    </PopoverPrimitive.Content>
                </PopoverPrimitive.Root>
            </Box>
        </Box>
    );
};

const DatePickerButton = styled(Button.IconOnlyBlueAzure)`
    position: absolute;
    right: 0;
    bottom: 0;

    &:focus {
        outline: none;
        background-color: ${({ theme }) => theme.color.greyD};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.greyD};
    }

    &[aria-expanded="true"] {
        background-color: ${({ theme }) => theme.color.greyD};
    }
`;
