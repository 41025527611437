import React from "react";
import styled from "styled-components";
import { PopoverContentProps } from "@radix-ui/react-popover";
import { Tooltip as SharedTooltip, TooltipTrigger as SharedTooltipTrigger, TooltipArrow as SharedTooltipArrow, TooltipContent as SharedTooltipContent } from "@monster/shared";

export const Tooltip = SharedTooltip;
export const TooltipTrigger = SharedTooltipTrigger;

export const TooltipContent = React.forwardRef(({ children, ...props }: PopoverContentProps, forwardedRef) => {
    return (
        <StyledPopoverPrimitiveContent
            onOpenAutoFocus={event => {
                event.preventDefault();
                (event.currentTarget as HTMLElement)?.focus();
            }}
            {...props}
            // TODO:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={forwardedRef}
        >
            {children}

            <StyledPopoverPrimitiveArrow offset={props.align !== "center" && props.alignOffset ? props.alignOffset * -1 + 8 : 24 + 8} asChild>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none" focusable="false" aria-hidden="true">
                    <path d="M8.70711 7.29289C8.31658 7.68342 7.68342 7.68342 7.29289 7.29289L0 0L16 0L8.70711 7.29289Z" fill="currentColor" />
                </svg>
            </StyledPopoverPrimitiveArrow>
        </StyledPopoverPrimitiveContent>
    );
});

const StyledPopoverPrimitiveArrow = styled(SharedTooltipArrow)`
    color: ${({ theme }) => theme.color.blueD};
`;

const StyledPopoverPrimitiveContent = styled(SharedTooltipContent)`
    background-color: ${({ theme }) => theme.color.blueD};
    border-radius: 16px;
    color: ${({ theme }) => theme.color.white};
    padding: 16px;

    &:focus-visible {
        outline: 0;
    }
`;
