import { gql } from "@apollo/client";
import { UploadedClaimDocumentFragment } from "./UploadedClaimDocumentFragment";

export const ClaimDataFragment = gql`
    fragment ClaimData on ClaimData {
        id
        claimCause {
            id
            translationKey
        }
        claimStatus
        mappedClaimStatus
        bankAccountNumber
        claimAmount
        dateOfReport
        dateOfEvent
        closeTime
        uploadedDocuments {
            ...UploadedClaimDocument
        }
    }
    ${UploadedClaimDocumentFragment}
`;
