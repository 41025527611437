import { gql } from "@apollo/client";
import { ClaimTaskItemDataFragment } from "./ClaimTaskItemDataFragment";

export const ClaimTaskDataFragment = gql`
    fragment ClaimTaskData on ClaimTaskData {
        claimTaskId
        claimId
        dateOfTask
        deadLine
        items {
            ...ClaimTaskItemData
        }
    }
    ${ClaimTaskItemDataFragment}
`;
