import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32Mail = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 8C6.36739 8 6.24021 8.05268 6.14645 8.14645C6.05268 8.24021 6 8.36739 6 8.5V21.5C6 21.8978 6.15804 22.2794 6.43934 22.5607C6.72064 22.842 7.10218 23 7.5 23H25.5C25.8978 23 26.2794 22.842 26.5607 22.5607C26.842 22.2794 27 21.8978 27 21.5V8.5C27 8.36739 26.9473 8.24021 26.8536 8.14645C26.7598 8.05268 26.6326 8 26.5 8H6.5Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.79 15.889C16.7053 15.9493 16.6039 15.9817 16.5 15.9817C16.396 15.9817 16.2947 15.9493 16.21 15.889L6.43399 8.907C6.34778 8.84548 6.28333 8.75816 6.24993 8.65765C6.21653 8.55714 6.21592 8.44861 6.24818 8.34773C6.28043 8.24684 6.34389 8.1588 6.42941 8.09631C6.51493 8.03382 6.61808 8.00009 6.72399 8H26.274C26.3799 8.00009 26.4831 8.03382 26.5686 8.09631C26.6541 8.1588 26.7176 8.24684 26.7498 8.34773C26.7821 8.44861 26.7815 8.55714 26.7481 8.65765C26.7147 8.75816 26.6502 8.84548 26.564 8.907L16.79 15.889V15.889Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32Mail;
