import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { Box, DateFormat, Flex, List } from "@monster/shared";
import { Text } from "@monster/chr-ui";
import { ClaimFrontendStatus, Helpers } from "@utils/Helpers";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { ClaimData } from "@api/graphql/types";
import { useSelector } from "react-redux";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { ApplicationState } from "@redux/Reducers";
import { DateUtils } from "@utils/DateHelpers";

type Props = {
    claim: ClaimData;
};

export const ClaimProgress = ({ claim }: Props) => {
    const theme = useTheme();
    const reportClaimTask = useSelector(SessionSelectors.getReportTasksByClaimId(claim.id));
    const email = useSelector((state: ApplicationState) => state.session.relatedData?.accountData.email ?? "");

    const status = useMemo(() => Helpers.getFrontendClaimStatus(claim.claimStatus), [claim.claimStatus]);
    const steps = useMemo(
        () => [
            Loctool.instance.formatMessage({ id: "pages.claim.progress.status.filed" }),
            Loctool.instance.formatMessage({ id: "pages.claim.progress.status.in_progress" }),
            Loctool.instance.formatMessage({ id: "pages.claim.progress.status.closed" }),
        ],
        []
    );

    const step = useMemo(() => {
        switch (status) {
            case ClaimFrontendStatus.filed:
                return 1;
            case ClaimFrontendStatus.in_progress:
            case ClaimFrontendStatus.waiting_client_task:
                return 2;
            case ClaimFrontendStatus.closed:
                return 3;
            default:
                return 1;
        }
    }, [status]);

    return (
        <>
            <Box
                $style={{
                    pointerEvents: "none",
                    position: "relative",
                    paddingTop: 32,
                    "&::before": { content: '""', position: "absolute", left: 0, right: 0, bottom: 48 / 2, borderTop: `1px solid ${theme.color.greyD}` },
                }}
            >
                <Box
                    $style={{
                        position: "relative",
                        width: 48,
                        height: 48,
                        margin: "0 auto",
                        backgroundImage:
                            'image-set( url("/images/global-emma-avatar-statement-48.png") 1x, url("/images/global-emma-avatar-statement-48@2x.png") 2x, url("/images/global-emma-avatar-statement-48@3x.png") 3x)',
                    }}
                />
            </Box>
            <Box $style={{ padding: 16, textAlign: "center" }}>
                <Text as={"h1"} $variant={"h3Mobile"} $style={{ margin: 0 }}>
                    <LoctoolMessage
                        id={`pages.claim.progress.${status}.title`}
                        values={{
                            deadline: reportClaimTask?.deadLine ? (
                                <Box as={"span"} $style={{ fontWeight: 400 }}>
                                    {DateUtils.format(DateUtils.parseISO(reportClaimTask?.deadLine ?? ""), DateFormat.deShort)}
                                </Box>
                            ) : (
                                ""
                            ),
                            email,
                            closed: (
                                <Box as={"span"} $style={{ display: "block", fontWeight: 400 }}>
                                    {claim?.closeTime ? DateUtils.format(DateUtils.parseISO(claim?.closeTime ?? ""), DateFormat.deShort) : null}
                                </Box>
                            ),
                        }}
                    />
                </Text>
                <Box as={"p"} $style={{ margin: "24px 0 0" }}>
                    <LoctoolMessage
                        id={`pages.claim.progress.${status}.info`}
                        values={{
                            email,
                            deadline: reportClaimTask?.deadLine ? (
                                <Box as={"span"} $style={{ fontWeight: 400 }}>
                                    {DateUtils.format(DateUtils.parseISO(reportClaimTask?.deadLine ?? ""), DateFormat.deShort)}
                                </Box>
                            ) : (
                                ""
                            ),
                        }}
                    />
                </Box>
            </Box>
            <Flex.Container
                $style={{
                    padding: "24px 36px 10px",
                    ">*": {
                        position: "relative",
                        "&::before,&::after": {
                            backgroundColor: "currentColor",
                            color: theme.color.greyN,
                            content: '""',
                            display: "block",
                        },
                        "&::before": {
                            height: 2,
                            left: 0,
                            position: "absolute",
                            right: 0,
                            top: 7,
                        },
                        "&::after": {
                            border: "2px solid currentColor",
                            borderRadius: 8,
                            height: 16,
                            marginLeft: "auto",
                            position: "relative",
                            width: 16,
                        },
                    },
                }}
                $styleMedium={{ paddingLeft: 60, paddingRight: 60 }}
            >
                <Flex.Item $shrink={"shrink"} $style={{ "&::after": { color: theme.color.greenN } }} />
                <Flex.Item
                    $shrink={"auto"}
                    $style={
                        step > 1 && step < steps.length
                            ? { "&::before": { color: theme.color.greenN }, "&::after": { borderColor: theme.color.greenN } }
                            : step === steps.length
                            ? { "&::before,&::after": { color: theme.color.greenN } }
                            : {}
                    }
                />
                <Flex.Item $shrink={"auto"} $style={step === steps.length ? { "&::before,&::after": { color: theme.color.greenN } } : {}} />
            </Flex.Container>
            <List as={"ol"} $style={{ display: "flex", columnGap: 8, padding: "0 8px 24px" }} $styleMedium={{ paddingLeft: 32, paddingRight: 32 }}>
                {steps.map((item, index) => (
                    <Flex.Item
                        key={`ClaimProgressItem-${index}`}
                        as={"li"}
                        $shrink={"auto"}
                        $style={{ paddingTop: (32 - 24) / 2, paddingBottom: (32 - 24) / 2 }}
                        aria-current={index + 1 === step ? "step" : undefined}
                    >
                        <Box $style={index > 0 ? { textAlign: index + 1 === steps.length ? "right" : "center" } : {}}>{item}</Box>
                    </Flex.Item>
                ))}
            </List>
        </>
    );
};
