import styled, { css } from "styled-components";
import { StyledButton } from "./Button.styled";

const cherriskCommonButtonCSS = css`
    border-radius: 36px;
`;

const cherriskPrimaryButtonCSS = css`
    ${cherriskCommonButtonCSS}

    background-color: ${({ theme }) => theme.color.greenN};
    color: ${({ theme }) => theme.color.blueD};

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.color.greenN200};
            box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.greenN};
        }
    }

    &:disabled {
        background-color: ${({ theme }) => theme.color.greyN};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
        color: ${({ theme }) => theme.color.blueL};
    }
`;

const cherriskPrimaryButtonIsInverseCSS = css`
    ${cherriskCommonButtonCSS}

    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blueD};

    /* NOTE: missing from Figma */
    /* @media (hover: hover) {&:hover {
    }} */

    /* NOTE: missing from Figma */
    &:disabled {
        background-color: ${({ theme }) => theme.color.greyN};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
        color: ${({ theme }) => theme.color.blueL};
    }
`;

export const StyledPrimaryButton = styled(StyledButton)`
    ${({ isInverse }) => (isInverse ? cherriskPrimaryButtonIsInverseCSS : cherriskPrimaryButtonCSS)}
`;
