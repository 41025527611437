import { openingHours } from "@api/graphql/cmsTypes";
import { ActionsUnion, ReduxHelpers } from "@monster/shared";

export enum OpeningHoursActionTypes {
    UPDATE_LEGAL_DOCUMENTS = "OpeningHoursAction/UPDATE_OPENING_HOURS",
}

export const OpeningHoursActions = {
    update: (openingHours: openingHours) => ReduxHelpers.createAction(OpeningHoursActionTypes.UPDATE_LEGAL_DOCUMENTS, { openingHours }),
};

export type OpeningHoursActions = ActionsUnion<typeof OpeningHoursActions>;
