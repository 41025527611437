/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DateUtils, Flex } from "@monster/shared";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Matcher } from "react-day-picker";
import { StyledTextFieldSuffixWrapper } from "../TextField.styled";
import { Select, StyledSelect } from "../Select";

interface Props {
    disabledDays?: Matcher[];
    date: Date;
    onChange: (date: Date) => void;
    getMonths: () => string[];
    dateUtils: DateUtils;
}

export const YearMonthForm = ({ date, disabledDays, dateUtils, onChange, getMonths }: Props) => {
    const monthNames = useMemo(() => getMonths().map((month: string) => month[0].toUpperCase() + month.slice(1)), [getMonths]);
    const years = useMemo(() => {
        // @ts-ignore
        const beforeModifier = (disabledDays ?? []).find(dd => dd.before);
        // @ts-ignore
        const afterModifier = (disabledDays ?? []).find(dd => dd.after);
        if (beforeModifier && afterModifier) {
            // @ts-ignore
            const minYear = beforeModifier.before.getFullYear();
            // @ts-ignore
            const maxYear = afterModifier.after.getFullYear() + 1;
            return Array.from({ length: maxYear - minYear }).map((_, index) => minYear + index);
        } else {
            return Array.from({ length: dateUtils.startOfToday().getFullYear() - 1900 }).map((_, index) => 1900 + index);
        }
    }, [dateUtils, disabledDays]);

    const handleYearChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            onChange(new Date(event.target.value as unknown as number, date.getMonth()));
        },
        [date, onChange]
    );

    const handleMonthChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            onChange(new Date(date.getFullYear(), event.target.value as unknown as number));
        },
        [date, onChange]
    );

    return (
        <StyledYearMonthContainer>
            <Flex.Item $shrink="shrink">
                <Select id="month" name="month" onChange={handleMonthChange} value={date.getMonth()}>
                    {monthNames.map((month, i) => (
                        <option key={month} value={i}>
                            {month}
                        </option>
                    ))}
                </Select>
            </Flex.Item>
            <Flex.Item $shrink="shrink" $style={{ maxWidth: 80 }}>
                <Select id="year" name="year" onChange={handleYearChange} value={date.getFullYear()}>
                    {years.map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </Select>
            </Flex.Item>
        </StyledYearMonthContainer>
    );
};

const StyledYearMonthContainer = styled(Flex.Container)`
    gap: 16px;

    ${StyledTextFieldSuffixWrapper} {
        padding: 0;
    }

    ${StyledSelect} {
        color: ${({ theme }) => theme.color.blueD};
        padding: 4px 28px 4px 12px;

        &:not(:placeholder-shown) {
            font-weight: 400;
        }
    }
`;
