import React, { useCallback, useMemo, useRef, useState } from "react";
import { Surface, TextField, Button, useNotification } from "@monster/chr-ui";
import { SVGChrIcon32CoverAlmostGone } from "@monster/chr-ui/dist/svg_assets";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { Box, Flex } from "@monster/shared";
import { ClaimSubmitDialog } from "./ClaimSubmitDialog";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { ClaimData, ClaimTaskData, UploadedClaimDocument } from "@api/graphql/types";
import { UploadFunction, DropZone, OnDropZoneProgressChange, useDropZone } from "@components/Input/DropZone";
import { Api } from "@api/Api";
import { ClaimTaskHelpers, ClaimTaskItemType } from "@utils/ClaimTaskHelpers";
import { Constants } from "@utils/Constants";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";

type Props = {
    claim: ClaimData;
    claimTask: ClaimTaskData;
};

export const AdditionalClaimTask = ({ claim, claimTask }: Props) => {
    const theme = useTheme();
    const [openThankYouSubmitDialog, setOpenThankYouSubmitDialog] = useState(false);
    const showNotification = useNotification();
    const textClaimTask = useMemo(() => claimTask.items.find(item => item.claimTaskItemType === ClaimTaskItemType.TEXT__OTHER), [claimTask.items]);
    const [description, setDescription] = useState<string>(textClaimTask?.answer ?? "");

    const fileClaimTaskItems = useMemo(
        () => claimTask.items.filter(claimTaskItem => ClaimTaskHelpers.getAdditionalFileClaimTaskItemTypes().includes(claimTaskItem.claimTaskItemType as ClaimTaskItemType)),
        [claimTask.items]
    );
    const claimTaskItemsWithoutAnswer = useMemo(() => fileClaimTaskItems.filter(cti => !cti.answer), [fileClaimTaskItems]);
    const alreadyAttachedFiles: UploadedClaimDocument[] = useSelector(SessionSelectors.getClaimTaskUploadedDocuments(claim.id, claimTask.claimTaskId));

    const inProgressRef = useRef<number>(0);
    const uploadFile: UploadFunction = useCallback(
        async (file: File, onProgressChange: OnDropZoneProgressChange): Promise<void> => {
            if (claimTaskItemsWithoutAnswer.length - inProgressRef.current === 0) {
                throw new Error("No claimTaskItem found without answer");
            }
            inProgressRef.current += 1;
            await Api.uploadImageForClaimTaskItem(claimTaskItemsWithoutAnswer[inProgressRef.current - 1].claimTaskItemId, file, onProgressChange);
            inProgressRef.current -= 1;
        },
        [claimTaskItemsWithoutAnswer]
    );
    const { onFileDrop, uploadInProgress, uploadingDocList, alreadyUploadedDocumentItems } = useDropZone(uploadFile, alreadyAttachedFiles, Constants.claimMaxAdditionalInformationAttachedFileCount);

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement | HTMLLabelElement>) => {
            event.preventDefault();
            try {
                if (textClaimTask) {
                    await Api.uploadAnswerForClaimTaskItem({ answer: description, claimTaskItemId: textClaimTask?.claimTaskItemId });
                }
                await Api.submitClaimTask({ claimTaskId: claimTask.claimTaskId });
                setOpenThankYouSubmitDialog(true);
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    showNotification(error.intlMessage);
                }
                console.warn(error);
            }
        },
        [claimTask.claimTaskId, description, showNotification, textClaimTask]
    );

    return (
        <>
            <Box $style={{ marginTop: 32 }}>
                <Surface as="form" $variant="white" onSubmit={onSubmit}>
                    <fieldset>
                        {/* NOTE: valid, but visually hidden legend */}
                        <legend className={"show-for-sr"}>
                            <LoctoolMessage id="pages.claim.additionalClaimTask.title" />
                        </legend>
                        <Flex.Container aria-hidden={"true"} $style={{ padding: "12px 16px", columnGap: 16, alignItems: "center" }}>
                            <Flex.Item $shrink={"shrink"} $style={{ alignSelf: "flex-start" }}>
                                <SVGChrIcon32CoverAlmostGone />
                            </Flex.Item>
                            <Flex.Item $shrink={"auto"} $style={{ fontWeight: 700 }}>
                                <LoctoolMessage id="pages.claim.additionalClaimTask.title" />
                            </Flex.Item>
                        </Flex.Container>
                        <Box $style={{ boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, padding: "16px 16px 24px" }}>
                            <Box $style={{ backgroundColor: theme.color.highlight300, borderRadius: 16, padding: 16 }}>
                                <LoctoolMessage id="pages.claim.additionalClaimTask.info" />
                            </Box>
                            <Box $style={{ marginTop: 8, "&&&": { textarea: { minHeight: 157 } } }}>
                                <TextField
                                    id={"a5547"}
                                    label={<LoctoolMessage id="pages.claim.additionalClaimTask.description.label" />}
                                    placeholder={Loctool.instance.formatMessage({ id: "pages.claim.additionalClaimTask.description.placeholder" })}
                                    maxLength={Constants.claimAdditionalInformationMaxLength}
                                    value={description}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
                                    helperText={
                                        <Box $style={{ color: theme.color.blueL, textAlign: "right" }}>
                                            {description.length} / {Constants.claimAdditionalInformationMaxLength}
                                        </Box>
                                    }
                                    multiline
                                    maxRows={10}
                                />
                            </Box>

                            <DropZone
                                disabled={alreadyAttachedFiles.length >= fileClaimTaskItems.length}
                                attachedFiles={alreadyUploadedDocumentItems}
                                onFileDrop={onFileDrop}
                                maxFilesCount={fileClaimTaskItems.length}
                                uploadInProgress={uploadInProgress}
                                uploadingDocList={uploadingDocList}
                            />
                            <Box $style={{ marginTop: 24 }}>
                                <Box $style={{ maxWidth: 288, margin: "0 auto" }} $styleMedium={{ maxWidth: 242 }}>
                                    <Button.Primary
                                        type="submit"
                                        btnLabel={<LoctoolMessage id="pages.claim.additionalClaimTask.submit" />}
                                        isExpanded
                                        disabled={alreadyAttachedFiles.length === 0 && description.length === 0}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </fieldset>
                </Surface>
            </Box>
            <ClaimSubmitDialog
                open={openThankYouSubmitDialog}
                title={<LoctoolMessage id="pages.claim.submitSucceed.title" />}
                description={<LoctoolMessage id="pages.claim.submitSucceed.description" />}
                buttonLabel={<LoctoolMessage id="pages.claim.submitSucceed.buttonLabel" />}
                onButtonClick={() => window.location.reload()}
            />
        </>
    );
};
