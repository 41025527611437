import React from "react";
import { CSSObject, useTheme } from "styled-components";
import { Box } from "@monster/shared";
import { Surface } from "./Surface";
import { Text, useTextVariant } from "./Text";

type Props = {
    title: React.ReactNode;
    details: Array<{ label: React.ReactNode; value: React.ReactNode }>;
    cartItems: Array<{ label: React.ReactNode; value: React.ReactNode; $labelStyle?: CSSObject; $valueStyle?: CSSObject }>;
    total: { label: React.ReactNode; value: React.ReactNode };
};

export const Cart = (props: Props) => {
    const theme = useTheme();
    const TextVariant = useTextVariant();
    return (
        <Surface as="table" $variant="white" $style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }} $styleLarge={{ "&::before": { content: "normal" } }}>
            <Text as="caption" $variant="h3Mobile" $style={{ captionSide: "top", padding: "40px 16px 16px", fontWeight: 700, textAlign: "left" }} $styleLarge={{ marginLeft: -12, marginRight: -12 }}>
                {props.title}
            </Text>

            <tbody>
                {props.details.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    fontWeight: 400,
                                    textAlign: "left",
                                    backgroundColor: theme.color.greyN,
                                    borderTopLeftRadius: index === 0 ? 16 : undefined,
                                    padding: index === 0 ? "12px 0 0 12px" : index + 1 === props.details.length ? "0 0 12px 12px" : "0 0 0 12px",
                                }}
                                $styleLarge={{ borderBottomLeftRadius: index + 1 === props.details.length ? 16 : undefined }}
                            >
                                <Box $style={{ padding: 4 }}>{detail.label}</Box>
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    textAlign: "right",
                                    backgroundColor: theme.color.greyN,
                                    borderTopRightRadius: index < 1 ? 16 : undefined,
                                    padding: index === 0 ? "12px 12px 0 0" : index + 1 === props.details.length ? "0 12px 12px 0" : "0 12px 0 0",
                                }}
                                $styleLarge={{ borderBottomRightRadius: index + 1 === props.details.length ? 16 : undefined }}
                            >
                                <Box $style={{ padding: 4 }}>{detail.value}</Box>
                            </Box>
                        </tr>
                    );
                })}
            </tbody>

            <tbody>
                {props.cartItems.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    boxShadow: index > 0 ? `0 -1px 0 0 ${theme.color.greyN}` : undefined,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    padding: "16px 4px 16px 16px",
                                    ...detail.$labelStyle,
                                }}
                            >
                                {detail.label}
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    boxShadow: index > 0 ? `0 -1px 0 0 ${theme.color.greyN}` : undefined,
                                    fontWeight: 700,
                                    textAlign: "right",
                                    padding: "16px 16px 16px 4px",
                                    verticalAlign: "top",
                                    ...detail.$valueStyle,
                                }}
                            >
                                {detail.value}
                            </Box>
                        </tr>
                    );
                })}
            </tbody>

            <tfoot>
                <tr>
                    <Box as="th" scope="row" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 400, textAlign: "left", verticalAlign: "top", padding: "16px 4px 8px 16px" }}>
                        <Text $variant="bodyMobile" $styleLarge={{ ...TextVariant({ $variant: "h3Desktop" }) }}>
                            {props.total.label}
                        </Text>
                    </Box>
                    <Box as="td" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 700, textAlign: "right", padding: "16px 16px 8px 4px", whiteSpace: "nowrap" }}>
                        <Text $variant="h3Mobile">{props.total.value}</Text>
                    </Box>
                </tr>
            </tfoot>
        </Surface>
    );
};
