import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgLogoVisa = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={66} height={46} viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M30.0224 29.744H26.4827L28.6966 16.4119H32.2361L30.0224 29.744Z" fill="#0742A6" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.8541 16.7378C42.1559 16.4681 41.0485 16.1702 39.6793 16.1702C36.1837 16.1702 33.7221 17.9856 33.707 20.581C33.678 22.4958 35.4694 23.5594 36.8093 24.1979C38.1787 24.8503 38.6443 25.2762 38.6443 25.8577C38.6303 26.7509 37.5377 27.1626 36.5186 27.1626C35.1053 27.1626 34.3481 26.9503 33.1974 26.4534L32.7313 26.2404L32.236 29.2332C33.0662 29.6015 34.5957 29.9284 36.1837 29.9427C39.8978 29.9427 42.3158 28.1554 42.3444 25.3896C42.3586 23.872 41.4126 22.709 39.3732 21.7588C38.1352 21.1488 37.377 20.7374 37.377 20.1133C37.3915 19.5459 38.0183 18.9647 39.4158 18.9647C40.5665 18.9362 41.412 19.2056 42.0525 19.4752L42.3727 19.6167L42.8541 16.7378Z"
                fill="#0742A6"
            />
            <path
                d="M47.5586 25.0209C47.8501 24.255 48.9718 21.2907 48.9718 21.2907C48.9571 21.3191 49.2628 20.5106 49.4376 20.0143L49.6849 21.163C49.6849 21.163 50.3552 24.3543 50.5008 25.0209C49.9476 25.0209 48.2578 25.0209 47.5586 25.0209ZM51.9279 16.4119H49.19C48.3457 16.4119 47.7042 16.6528 47.3399 17.518L42.0822 29.7438H45.7962C45.7962 29.7438 46.4077 28.0983 46.5391 27.7439C46.9466 27.7439 50.5596 27.7439 51.0838 27.7439C51.1854 28.212 51.5062 29.7438 51.5062 29.7438H54.7836L51.9279 16.4119Z"
                fill="#0742A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5262 16.4119L20.0597 25.5031L19.6809 23.6593C19.04 21.5318 17.03 19.2203 14.787 18.0709L17.9622 29.7298H21.7053L27.2691 16.4119H23.5262Z"
                fill="#0742A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8407 16.4119H11.1457L11.0874 16.6812C15.5299 17.7876 18.472 20.4544 19.6808 23.6599L18.4428 17.5326C18.239 16.6811 17.6126 16.44 16.8407 16.4119Z"
                fill="#0742A6"
            />
        </SVGBase>
    );
};

export default SvgLogoVisa;
