import React from "react";
import styled from "styled-components";
import { Flex } from "@monster/shared";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    id: string;
    name: string;
};

export const Radio: React.FC<Props> = ({ id, children, ...props }) => {
    return (
        <>
            <StyledInput id={id} className="show-for-sr" type="radio" {...props} />

            <Flex.Container as="label" htmlFor={id} $style={{ display: "inline-flex", verticalAlign: "bottom" }}>
                <StyledRadio as="span" $shrink={32} />

                {children && (
                    <Flex.Item as="span" $shrink="auto">
                        {children}
                    </Flex.Item>
                )}
            </Flex.Container>
        </>
    );
};

const StyledRadio = styled(Flex.Item).attrs(() => ({
    "aria-hidden": true,
}))`
    align-items: center;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.blueN};
    cursor: pointer;
    display: flex;
    height: ${({ $shrink }) => $shrink}px;
    justify-content: center;
    position: relative;

    &::before {
        border-radius: inherit;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
        content: "";
        height: 16px;
        position: absolute;
        width: 16px;
    }

    @media (hover: hover) {
        &:hover {
            &::before {
                background-color: ${({ theme }) => theme.color.greyD};
            }
        }
    }
`;

const StyledInput = styled.input`
    &:focus-visible {
        & + label {
            ${StyledRadio} {
                outline: 2px solid;
            }
        }
    }

    &:checked {
        & + label {
            ${StyledRadio} {
                &::before {
                    background-color: ${({ theme }) => theme.color.blueN};
                    box-shadow: none;
                }
            }
        }
    }

    /* NOTE: in Figma there is no disabled Radio in use at the moment. */
    /* &:disabled {
        & + label {
            cursor: not-allowed;

            ${StyledRadio} {
                box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.greyD};
                cursor: inherit;

                &::before {
                    background-color: inherit;
                }
            }
        }
    } */
`;
