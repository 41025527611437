import { store } from "@redux/store";

enum BackendEnvironmentName {
    validatorEmail = "validator.email",
    validatorName = "validator.name",
    accountMinAge = "account.min.age",
    flatAreaMin = "homeInsurance.flatAreaMin",
    flatAreaMax = "homeInsurance.flatAreaMax",
    houseAreaMin = "homeInsurance.houseAreaMin",
    houseAreaMax = "homeInsurance.houseAreaMax",
    pricePerSqmMin = "homeInsurance.pricePerSqmMin",
    forwardDateMaxDays = "homeInsurance.forwardDateMaxDays",
}

const defaultBackendEnvironments: { [key in BackendEnvironmentName]: string } = {
    [BackendEnvironmentName.validatorEmail]: "^[a-zA-Z0-9\\.!#$%&\\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
    [BackendEnvironmentName.validatorName]: "^(?:[\\u00c0-\\u01ffa-zA-Z\\'-\\s\\.]){2,}$",
    [BackendEnvironmentName.accountMinAge]: "18",
    [BackendEnvironmentName.flatAreaMin]: "20",
    [BackendEnvironmentName.flatAreaMax]: "250",
    [BackendEnvironmentName.houseAreaMin]: "40",
    [BackendEnvironmentName.houseAreaMax]: "300",
    [BackendEnvironmentName.pricePerSqmMin]: "650",
    [BackendEnvironmentName.forwardDateMaxDays]: "20",
};

export class BackendEnvironments {
    private static get(envName: BackendEnvironmentName): string {
        const backendEnvironment = store.getState().backendEnvironments.find(({ name }) => name === envName);
        return backendEnvironment?.value ?? defaultBackendEnvironments[envName];
    }

    public static validatorEmail(): RegExp {
        const validatorEmail = BackendEnvironments.get(BackendEnvironmentName.validatorEmail);
        return new RegExp(validatorEmail);
    }

    public static validatorName(): RegExp {
        const validatorName = BackendEnvironments.get(BackendEnvironmentName.validatorName);
        return new RegExp(validatorName);
    }

    public static accountMinAge(): number {
        const accountMinAge = BackendEnvironments.get(BackendEnvironmentName.accountMinAge);
        return Number.parseInt(accountMinAge);
    }

    public static flatAreaMin(): number {
        const flatAreaMin = BackendEnvironments.get(BackendEnvironmentName.flatAreaMin);
        return Number.parseInt(flatAreaMin);
    }

    public static flatAreaMax(): number {
        const flatAreaMax = BackendEnvironments.get(BackendEnvironmentName.flatAreaMax);
        return Number.parseInt(flatAreaMax);
    }

    public static houseAreaMin(): number {
        const houseAreaMin = BackendEnvironments.get(BackendEnvironmentName.houseAreaMin);
        return Number.parseInt(houseAreaMin);
    }

    public static houseAreaMax(): number {
        const houseAreaMax = BackendEnvironments.get(BackendEnvironmentName.houseAreaMax);
        return Number.parseInt(houseAreaMax);
    }

    public static pricePerSqmMin(): number {
        const pricePerSqmMin = BackendEnvironments.get(BackendEnvironmentName.pricePerSqmMin);
        return Number.parseInt(pricePerSqmMin);
    }

    public static forwardDateMaxDays(): number {
        const forwardDateMaxDays = BackendEnvironments.get(BackendEnvironmentName.forwardDateMaxDays);
        return Number.parseInt(forwardDateMaxDays);
    }
}
