/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useCallback, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { IMaskMixin } from "react-imask";
import { Button } from "../Button";
import { Text } from "../Text";
import { TextField } from "./TextField";
import { Flex, Box, hexToRGBA } from "@monster/shared";
import { SVGSharedIcon32BasicsMinus } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon32BasicsPlus } from "@monster/shared/dist/svg_assets";

type StepperOption = {
    label: string;
    value: number;
};

type Props = {
    id: string;
    incButtonLabel: React.ReactNode;
    decButtonLabel: React.ReactNode;
    value: number;
    onChange: (newValue: number) => void;
    options: Array<StepperOption>;
    readOnly?: boolean;
    mask?: string;
    min?: number;
    max?: number;
    loctoolLowerLimitWarning?: string;
    loctoolUpperLimitWarning?: string;
    thousandsSeparator?: string;
};

const closestIndex = (num: number, arr: number[]) => {
    let curr = arr[0],
        diff = Math.abs(num - curr),
        index = 0;

    for (let val = 0; val < arr.length; val++) {
        const newdiff = Math.abs(num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
            index = val;
        }
    }
    return index;
};

export function Stepper({
    id,
    incButtonLabel,
    decButtonLabel,
    value,
    onChange,
    options,
    readOnly = true,
    mask,
    min,
    max,
    loctoolLowerLimitWarning,
    loctoolUpperLimitWarning,
    thousandsSeparator = ".",
}: Props) {
    const currentOptionIndex = closestIndex(
        value,
        options.map(o => o.value)
    );
    const currentOption = options[currentOptionIndex];
    const nextOptionIndex = options.findIndex(o => o.value > value);
    const prevOptionIndex = findLastIndex(options, o => o.value < value);
    const [limitWarning, setLimitWarning] = useState<string | null>(null);

    const handleStep = useCallback(
        (direction: "plus" | "minus", isDisabledClick: boolean) => () => {
            if (isDisabledClick) {
                setLimitWarning((direction === "plus" ? loctoolUpperLimitWarning : loctoolLowerLimitWarning) ?? null);
            }
            const newOption = options[direction === "plus" ? nextOptionIndex : prevOptionIndex];

            if (newOption) {
                onChange(newOption.value);
            }
        },
        [options, nextOptionIndex, prevOptionIndex, onChange, loctoolUpperLimitWarning, loctoolLowerLimitWarning]
    );

    useEffect(() => {
        setLimitWarning(null);
    }, [value]);

    const maxValueToEnter = parseInt(
        Array((max ?? options[options.length - 1].value).toString().length)
            .fill("9")
            .join(""),
        10
    );

    return (
        <>
            <Flex.Container $style={{ alignItems: "center" }}>
                <Flex.Item $shrink="shrink" $style={{ position: "relative", alignSelf: "flex-start" }}>
                    <Button.IconOnly
                        btnLabel={decButtonLabel}
                        icon={<SVGSharedIcon32BasicsMinus />}
                        isInverse
                        aria-controls={id}
                        onClick={handleStep("minus", prevOptionIndex === -1)}
                        className={prevOptionIndex === -1 ? "disabled" : ""}
                    />
                </Flex.Item>

                {readOnly && (
                    <Flex.Item $shrink="auto">
                        <input id={id} className="show-for-sr" type="text" readOnly value={currentOption?.label} />

                        <Text $variant="h3Mobile" aria-hidden="true" $style={{ fontWeight: 700, textAlign: "center" }}>
                            {currentOption?.label}
                        </Text>
                    </Flex.Item>
                )}

                {!readOnly && (
                    <StepperInputWrapper $shrink="auto">
                        <StepperInput
                            id={id}
                            value={value}
                            onChange={(newValue, showLimitWarning) => {
                                onChange(newValue);
                                if (showLimitWarning !== "none") {
                                    setTimeout(() => {
                                        setLimitWarning((showLimitWarning === "upper" ? loctoolUpperLimitWarning : loctoolLowerLimitWarning) ?? null);
                                    }, 0);
                                }
                            }}
                            firstValidValue={options[0].value}
                            lastValidValue={options[options.length - 1].value}
                            mask={mask}
                            min={min}
                            max={maxValueToEnter}
                            thousandsSeparator={thousandsSeparator}
                        />
                    </StepperInputWrapper>
                )}

                <Flex.Item $shrink="shrink" $style={{ alignSelf: "flex-start" }}>
                    <Button.IconOnly
                        btnLabel={incButtonLabel}
                        icon={<SVGSharedIcon32BasicsPlus />}
                        isInverse
                        aria-controls={id}
                        onClick={handleStep("plus", nextOptionIndex === -1)}
                        className={nextOptionIndex === -1 ? "disabled" : ""}
                    />
                </Flex.Item>
            </Flex.Container>
            {limitWarning && <LimitWarning message={limitWarning} />}
        </>
    );
}

const StepperInputWrapper = styled(Flex.Item)`
    padding: 0 16px;

    input {
        text-align: center;
    }
`;

type StepperInputProps = {
    id: string;
    value: number;
    onChange: (newValue: number, showLimitWarning: "upper" | "lower" | "none") => void;
    firstValidValue: number;
    lastValidValue: number;
    mask?: string;
    min?: number;
    max?: number;
    thousandsSeparator: string;
};

const MaskedInput = IMaskMixin(({ id, inputRef, ...props }) => <TextField {...props} id={id!} ref={inputRef} />);

function StepperInput({ id, value, onChange, firstValidValue, lastValidValue, mask = "Number", min, max, thousandsSeparator }: StepperInputProps) {
    const [unmaskedValue, setUnmaskedValue] = useState(value.toString());

    const handleBlur = () => {
        const newValue = parseFloat(unmaskedValue);

        if (newValue < firstValidValue || Number.isNaN(newValue)) {
            onChange(firstValidValue, "lower");
            setUnmaskedValue(firstValidValue.toString());
            return;
        }

        if (newValue > lastValidValue) {
            onChange(lastValidValue, "upper");
            setUnmaskedValue(lastValidValue.toString());
            return;
        }

        onChange(newValue, "none");
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            (event.target as HTMLElement).blur();
        }
    };

    useEffect(() => setUnmaskedValue(value.toString()), [value]);

    return (
        <MaskedInput
            id={id}
            type="text"
            value={unmaskedValue}
            onAccept={setUnmaskedValue}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            mask={mask}
            lazy={false}
            blocks={{
                Number: {
                    mask: Number,
                    scale: 0,
                    signed: false,
                    thousandsSeparator,
                    radix: ",",
                    min,
                    max,
                },
            }}
            // @ts-ignore
            unmask={true}
            style={{ lineHeight: "32px", fontSize: 24, padding: "8px 16px" }}
        />
    );
}

function findLastIndex<T>(arr: Array<T>, callback: (item: T) => boolean) {
    const itemFound = [...arr].reverse().find(callback);
    return arr.findIndex(item => item === itemFound);
}

type LimitWarningProps = {
    message: string;
};

const LimitWarning = ({ message }: LimitWarningProps) => {
    const theme = useTheme();

    return (
        <Box
            $style={{
                border: `1px solid ${theme.color.yellowD}`,
                borderRadius: 8,
                padding: "16px 32px",
                marginTop: 16,
                backgroundColor: hexToRGBA(theme.color.yellowN, 0.5),
                textAlign: "center",
            }}
        >
            {message}
        </Box>
    );
};
