import { ClaimTaskData, UploadedClaimDocument } from "@api/graphql/types";
import { ApplicationState } from "@redux/Reducers";
import { ClaimTaskHelpers, ClaimTaskItemType } from "@utils/ClaimTaskHelpers";
import { DateUtils } from "@utils/DateHelpers";
import { ClaimFrontendStatus, Helpers } from "@utils/Helpers";

export class SessionSelectors {
    public static isAuthenticated(state: ApplicationState): boolean {
        return !!state.session.relatedData;
    }

    public static getClaimsInProgress(state: ApplicationState) {
        const claimStatuses: string[] = [ClaimFrontendStatus.filed, ClaimFrontendStatus.in_progress, ClaimFrontendStatus.waiting_client_task].flatMap(claimFrontendStatus => {
            return Helpers.getClaimStatuses(claimFrontendStatus);
        });
        return (state.session.relatedData?.otherClaimData ?? []).filter(claimData => claimStatuses.includes(claimData.claimStatus));
    }

    public static isClaimInProgress(state: ApplicationState): boolean {
        return SessionSelectors.getClaimsInProgress(state).length > 0;
    }

    public static getActiveClaim() {
        return (state: ApplicationState) => {
            return state.session.relatedData?.claimData ?? null;
        };
    }

    public static getUploadedMandatoryDocumentsByClaimId(claimId: string) {
        return ({ session }: ApplicationState): UploadedClaimDocument[] => {
            const claimData = session.relatedData?.claimData?.id === claimId ? session.relatedData?.claimData : session.relatedData?.otherClaimData.find(cd => cd.id === claimId) ?? null;

            if (!claimData) {
                console.warn("ClaimData not found with id", { claimId });
                return [];
            }

            const mandatoryClaimTaskItemTypes: string[] = ClaimTaskHelpers.getMandatoryClaimTaskItemTypes();
            const mandatoryClaimTaskItems = [...(session.relatedData?.completedTasks ?? []), ...(session.relatedData?.openTasks ?? [])].flatMap(claimTask => {
                if (claimTask.claimId !== claimId) {
                    return [];
                }
                return claimTask.items.filter(item => mandatoryClaimTaskItemTypes.includes(item.claimTaskItemType));
            });
            return claimData.uploadedDocuments.filter(ud => mandatoryClaimTaskItems.some(cti => cti.claimTaskItemId === ud.id));
        };
    }

    public static getClaimTaskUploadedDocuments(claimId: string, claimTaskId: string) {
        return ({ session }: ApplicationState): UploadedClaimDocument[] => {
            const claim = session.relatedData?.claimData?.id === claimId ? session.relatedData?.claimData : session.relatedData?.otherClaimData.find(cd => cd.id === claimId) ?? null;

            if (!claim) {
                console.warn("ClaimData not found with id", { claimId });
                return [];
            }

            const claimTask = session.relatedData?.openTasks.find(ct => ct.claimTaskId === claimTaskId) ?? session.relatedData?.completedTasks.find(ct => ct.claimTaskId === claimTaskId) ?? null;
            if (!claimTask) {
                console.warn("ClaimTask not found with id", { claimTaskId });
                return [];
            }

            const fileClaimTaskItems = claimTask.items.filter(claimTaskItem => ClaimTaskHelpers.getAdditionalFileClaimTaskItemTypes().includes(claimTaskItem.claimTaskItemType as ClaimTaskItemType));
            return claim.uploadedDocuments.filter(ud => fileClaimTaskItems.some(cti => cti.claimTaskItemId === ud.id)) ?? [];
        };
    }

    public static getClaimOptionalUploadedDocumentsByClaimId(claimId: string) {
        return ({ session: { relatedData } }: ApplicationState): UploadedClaimDocument[] => {
            if (!relatedData) {
                return [];
            }
            const { claimData, openTasks, otherClaimData, completedTasks } = relatedData;
            const claim = claimData?.id === claimId ? claimData : otherClaimData.find(cd => cd.id === claimId) ?? null;
            if (!claim) {
                console.warn("ClaimData not found with id", { claimId });
                return [];
            }

            return (claim.uploadedDocuments ?? []).filter((uploadedDocument: UploadedClaimDocument) => {
                return ![...openTasks, ...completedTasks].some((claimTask: ClaimTaskData) =>
                    claimTask.items.some(taskItem => taskItem.claimTaskItemId === uploadedDocument.id && taskItem.claimTaskItemType !== ClaimTaskItemType.FILELIST__OTHER_ANYTIME)
                );
            });
        };
    }

    public static getReportTasksByClaimId(claimId: string) {
        return (state: ApplicationState): ClaimTaskData | null => {
            const openTasks = state.session.relatedData?.openTasks.filter(claimTask => claimTask.claimId === claimId) ?? [];
            return openTasks[0] ?? null;
        };
    }

    public static getCurrentClaimTaskByClaimId(claimId?: string | null) {
        return (state: ApplicationState) => {
            if (!claimId) {
                return null;
            }

            return state.session.relatedData?.openTasks.find(claimTask => claimTask.claimId === claimId) ?? null;
        };
    }

    public static getClaimAdditionalCompletedTasksByClaimId(claimId: string) {
        return (state: ApplicationState) => {
            const claimTasks = (state.session.relatedData?.completedTasks ?? []).filter(task => {
                return task.claimId === claimId;
            });

            const result = claimTasks
                .sort((c1, c2) => {
                    return DateUtils.parseISO(c1.dateOfTask).getTime() - DateUtils.parseISO(c2.dateOfTask).getTime();
                })
                .slice(1); // first task always the basic data

            return result;
        };
    }
}
