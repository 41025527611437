import { ClaimCause, RelatedData } from "@api/graphql/types";
import { ReduxHelpers, ActionsUnion } from "@monster/shared";
import { ClaimInputType } from "@redux/reducers/sessionReducer";

export enum SessionActionTypes {
    resetClaimInput = "SessionAction/RESET",
    updateRelatedData = "SessionAction/UPDATE_RELATED_DATA",
    updateClaimCauses = "SessionAction/UPDATE_CLAIM_CAUSES",
    updateClaimInput = "SessionAction/UPDATE_CLAIM_INPUT",
}

export const SessionActions = {
    resetClaimInput: () => ReduxHelpers.createAction(SessionActionTypes.resetClaimInput),
    updateRelatedData: (relatedData: RelatedData) => {
        return ReduxHelpers.createAction(SessionActionTypes.updateRelatedData, { relatedData });
    },
    updateClaimCauses: (clauses: ClaimCause[]) => {
        return ReduxHelpers.createAction(SessionActionTypes.updateClaimCauses, { clauses });
    },
    updateClaimInputData: (claimInput: Partial<ClaimInputType> | null) => {
        return ReduxHelpers.createAction(SessionActionTypes.updateClaimInput, { claimInput });
    },
};

export type SessionActions = ActionsUnion<typeof SessionActions>;
