import { hexToRGBA } from "@monster/shared";

// NOTE: Named after color styles in Figma.
export const color = {
    black: "#000000",
    blueD: "#445591",
    blueL: "#94ACFF",
    blueN: "#657ED6",
    greenD: "#00B8A1",
    greenN: "#40E7D3",
    greenN200: "#63F5E4",
    greyD: "#D9E1FF",
    greyL: "#F8F9FF",
    greyN: "#ECF0FF",
    redN: "#FF7657",
    white: "#FFFFFF",
    yellowD: "#F9DC2D",
    yellowN: "#FCED6B",
    // NOTE: added color aliases due to styleguide changed
    error200: "#FFC4B6",
    error300: "#FF967F",
    error400: "#FF7657",
    error500: "#FD6542",
    highlight300: "#FFF7AE",
    highlight400: "#FCED6B",
    highlight500: "#F9DC2D",
    neutral0: "#FFFFFF",
    neutral100: "#F8F9FF",
    neutral200: "#ECF0FF",
    neutral300: "#D9E1FF",
    neutral600: "#283259",
    primary200: "#C7FFF9",
    primary300: "#9DF5EB",
    primary400: "#40E7D3",
    primary500: "#00B8A1",
    secondary300: "#94ACFF",
    secondary400: "#657ED6",
    secondary500: "#445591",
};

export const CherriskTheme = {
    themeName: "cherrisk",
    color: {
        ...color,
        body: {
            backgroundColor: color.greyL,
            textColor: color.blueD,
        },
    },
    layout: {},
    text: {
        fontFamily: '"proxima-nova", sans-serif',
        h1Mobile: {
            fontSize: "60px",
            lineHeight: "72px",
        },
        h1Desktop: {
            fontSize: "60px",
            lineHeight: "72px",
        },
        h2Mobile: {
            fontSize: "40px",
            lineHeight: "48px",
        },
        h2Desktop: {
            fontSize: "40px",
            lineHeight: "48px",
        },
        h3Mobile: {
            fontSize: "24px",
            lineHeight: "32px",
        },
        h3Desktop: {
            fontSize: "24px",
            lineHeight: "32px",
        },
        bodyMobile: {
            fontSize: "16px",
            lineHeight: "24px",
        },
        bodyDesktop: {
            fontSize: "16px",
            lineHeight: "24px",
        },
        captionMobile: {
            fontSize: "12px",
            lineHeight: "16px",
        },
        captionDesktop: {
            fontSize: "12px",
            lineHeight: "16px",
        },
    },
    shadow: {
        white: `0 8px 24px ${hexToRGBA(color.greyD, 0.75)}`,
    },
    zIndex: {
        appBar: 1100,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
};

export type CherriskThemeType = typeof CherriskTheme;
