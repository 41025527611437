import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverProgress = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3" stroke="currentColor" strokeLinejoin="round" />
            <path d="M16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3" stroke="currentColor" strokeLinejoin="round" strokeDasharray="2 2" />
            <path
                d="M16 25.5C21.2467 25.5 25.5 21.2467 25.5 16C25.5 10.7533 21.2467 6.5 16 6.5C10.7533 6.5 6.5 10.7533 6.5 16C6.5 21.2467 10.7533 25.5 16 25.5Z"
                stroke="currentColor"
                strokeLinejoin="round"
            />
            <path d="M13.3536 15.3535L15.4749 17.4748" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.7071 17.2427L19.2426 13.7071" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </SVGBase>
    );
};

export default SvgIcon32CoverProgress;
