export class Path {
    public static readonly login = "/login";
    public static readonly loginByClaim = "/login-by-claim";
    public static readonly customerCare = "/customercare";
    public static readonly accidentClaim = "/accident";
    public static readonly createClaim = "/create";
    public static readonly claimError = "/claim-error";

    public static claim(claimId: string): string {
        return `/claim/${claimId}`;
    }
}
