import * as React from "react";
import { SVGProps } from "react";
import { SVGBase, SVGRProps } from "../components/SVGBase";

const SvgIcon32CoverWater = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
    return (
        <SVGBase width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0004 3.72644C22.2847 10.3733 25.5 15.4544 25.5 19C25.5 21.6234 24.4367 23.9984 22.7175 25.7175C20.9984 27.4367 18.6234 28.5 16 28.5C13.3766 28.5 11.0016 27.4367 9.28249 25.7175C7.56332 23.9984 6.5 21.6234 6.5 19C6.5 15.4544 9.71531 10.3733 16.0004 3.72644Z"
                stroke="currentColor"
            />
        </SVGBase>
    );
};

export default SvgIcon32CoverWater;
